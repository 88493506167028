import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "../../../../Modal/Modal/Modal";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";
import "./RoomChangeModal.scss";
import { FaExchangeAlt } from "react-icons/fa";
import { CgArrowsExchangeV } from "react-icons/cg";

export interface RoomChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  data?: any;
}


const formatDate = (dateString: string) => {
  const [datePart] = dateString.split(" ");
  const [month, day, year] = datePart.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

const RoomChangeModal: React.FC<RoomChangeModalProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  const { dbId } = useDbId();
  const [roomChangeType, setRoomChangeType] = useState("normal");
  const [autoExit, setAutoExit] = useState(false);
  const [roomOptions, setRoomOptions] = useState<
    Array<{ Oda: string; Konum: string }>
  >([]);
  const [girisTarihi, setGirisTarihi] = useState<string>("");
  const [cikisTarihi, setCikisTarihi] = useState<string>("");
  const [selectedRoom, setSelectedRoom] = useState<string>("");

  const handleRoomChange = (event: any) => {
    setSelectedRoom(event.value);
  };

  const handleRoomChangeType = (event: any) => {
    setRoomChangeType(event.value);
  };

  const handleAutoExitChange = (event: any) => {
    setAutoExit(event.value);
  };

  useEffect(() => {
    if (isOpen) {
      fetchDonBuroCalismaTarihi();
      if (data && data["Çıkış Tarihi"]) {
        const formattedDate = formatDate(data["Çıkış Tarihi"]);
        setCikisTarihi(formattedDate);
      }
    }
  }, [isOpen, dbId, data]);

  useEffect(() => {
    if (girisTarihi && cikisTarihi) {
      fetchRoomOptions();
    }
  }, [girisTarihi, cikisTarihi]);

  const calculateGeceleme = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return Math.ceil(differenceInDays);
  };

  const fetchRoomOptions = async () => {
    const geceleme = calculateGeceleme(girisTarihi, cikisTarihi);
    const body = {
      db_Id: dbId,
      xRoomhrk_Sirket: 0,
      xRoomhrk_Bas_tar: girisTarihi,
      xRoomhrk_Bit_tar: cikisTarihi,
      xRoomhrk_Konum: "1",
      xRez_Geceleme: geceleme,
      xTum_Tek: 1,
      xtip: 2,
      pm_10: 1,
    };
    console.log("Room options request body:", body);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Procedure/StpRoomhrk_Bul",
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Room options:", response.data.value);
      if (response.data.value && Array.isArray(response.data.value)) {
        setRoomOptions(
          response.data.value.map((room: any) => ({
            Oda: room.Oda,
            Konum: room.Konum,
          }))
        );
      } else {
        console.error("Invalid data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching room options:", error);
    }
  };

  const fetchDonBuroCalismaTarihi = async () => {
    const requestBody = {
      db_Id: dbId,
      Kod: 1,
      tip: 1,
    };

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Fishrk/Getir_Kod",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.isSucceded) {
        const curtar = response.data.value.Curtar;
        const formattedDate = new Date(curtar).toISOString().split("T")[0];
        setGirisTarihi(formattedDate);
        console.log("Giriş Tarihi:", formattedDate);
      } else {
        console.error("Veri çekme hatası:", response.data.message);
      }
    } catch (error) {
      console.error("API isteği hatası:", error);
    }
  };

  const handleApproval = async () => {
    const body = {
      db_Id: dbId,
      xtip: 1,
      rez_Master_id: data?.RezId || 0,
      eski_Oda: data?.OdaNo || null,
      yeni_Oda: selectedRoom.split(" - ")[0],
      rez_Birles_oda_10: 0,
      kulaniciid: 0,
      kullanici_kodu: "0",
      kullanici_Adsoy: "0",
    };
    console.log("Approval request body:", body);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Procedure/StpRoomchange",
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Approval response:", response.data);
      if (response.data.isSucceded) {
        onClose(); // Close modal on success
      } else {
        console.error("Error in approval:", response.data.message);
      }
    } catch (error) {
      console.error("Error in approval request:", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="medium"
      header={<h4 className="modal-header">Oda Değişikliği</h4>}
      footer={
        <div className="modal-footer-buttons">
          <Button onClick={handleApproval} className="btn-approve">
            Onay
          </Button>
        </div>
      }
    >
      <div className="modal-content">
        <div className="radio-buttons">
          <div>
            <RadioButton
              name="roomChangeType"
              value="normal"
              checked={roomChangeType === "normal"}
              onChange={handleRoomChangeType}
              label="Normal"
            />
          </div>
          <div>
            <RadioButton
              name="roomChangeType"
              value="combine"
              checked={roomChangeType === "combine"}
              onChange={handleRoomChangeType}
              label="Birleştirme"
            />
          </div>
          <div>
            <Checkbox
              checked={autoExit}
              onChange={handleAutoExitChange}
              label="Jandarma Otomatik Çıkış"
            />
          </div>
        </div>
        <div className="dates ">
          {girisTarihi && <label>Giriş Tarihi: {girisTarihi}</label>}
          {cikisTarihi && <label>Çıkış Tarihi: {cikisTarihi}</label>}
        </div>
        <div className="room-details d-flex flex-column ">
          <div className="old-room d-flex flex-column gap-2 align-items-center justify-content-center">
            <div className="old-room-custom w-100">
              <div className="old-room-header w-100  fs-3 fw-bold d-flex justify-content-center">
                <span className=" fs-3 fw-bold">
                  Eski Oda: {data?.OdaNo || ""}
                </span>
                {/* <span className=" fs-3 fw-bold">Eski Oda: {data?.RezId || ""}</span> */}
              </div>
              <hr className="divider" />
            </div>
            <div className="d-flex justify-content-between w-100">
              <div className="w-50">Ad: {data?.Adı || ""}</div>
              <div className="w-50">
                Soyad: {data?.Soyadı || ""}
              </div>
            </div>
          </div>

          {/* <FaExchangeAlt className="exchange-icon" /> */}
          <CgArrowsExchangeV className="exchange-icon" />

          <div className={`new-room ${!cikisTarihi ? "height-150" : ""}`}>
            <div className="mt-3 w-100 text-center fs-3 fw-bold mb-3 text-start">
              Yeni Oda: {selectedRoom || ""}
            </div>
            <div className="">
              <DropDownList
                data={roomOptions.map(
                  (option) => `${option.Oda} - ${option.Konum}`
                )}
                onChange={handleRoomChange}
              />
            </div>
            {cikisTarihi ? (
              <span></span>
            ) : (
              <div className="mt-3 w-100 text-center text-danger fw-bold  mb-3 text-start">
                çıkış tarihi olmadığı için apiden boş oda verisi döndü
              </div>
            )}
          </div>
          <div className="textarea-container">
            <label>Açıklama</label>
            <textarea placeholder="" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RoomChangeModal;
