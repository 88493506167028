import React, { useEffect, useState } from "react";
import { MisafirIslemeriApi } from "../MisafirIslemleriApi/MisafirIslemleriApi";
import Modal from "../../../../Modal/Modal/Modal";

const OnayBekleyenMisafirler: React.FC<{ otelGuid: string }> = ({
  otelGuid,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [onayBekleyenMisafirler, setOnayBekleyenMisafirler] = useState<any[]>(
    []
  ); // Adjust the type based on your guest structure
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedMisafirId, setSelectedMisafirId] = useState<number | null>(
    null
  );
  useEffect(() => {
    OnayBekleyen();
  }, [otelGuid]); // Refetch when otelGuid changes

  const OnayBekleyen = async () => {
    try {
      setIsLoading(true);
      const response = await MisafirIslemeriApi.LoyaltyMisafirIslemleri({
        otelGuid,
        islem: "onaybekleyen",
        firsatId: 0,
      });
      setOnayBekleyenMisafirler(response.data); // Adjust based on how your API structures the response
      console.log("onaybekleyenler:", response.data);
    } catch (error) {
      console.error("Error fetching guests:", error);
      // Handle the error appropriately
    } finally {
      setIsLoading(false);
    }
  };
  const handleOnaylaClick = (misafirId: number) => {
    setSelectedMisafirId(misafirId);
    console.log("misafirId:", misafirId);
    setIsModalOpen(true);
  };

  const onaylaMisafiri = async () => {
    if (selectedMisafirId === null) return;
    try {
      setIsLoading(true);
      const response = await MisafirIslemeriApi.LoyaltyMisafirIslemleri({
        otelGuid,
        islem: "onayla",
        firsatId: 0,
        misafirID: selectedMisafirId
      });
      console.log("onaylama response:", response);
      OnayBekleyen();
    } catch (error) {
      console.error("Error onaylama:", error);
      // Handle the error appropriately
    } finally {
      setIsLoading(false);
    }
    setIsModalOpen(false); // İşlem tamamlandıktan sonra modalı kapat
  };
  // Bootstrap tablosu için JSX dönüşü
  return (
<div className="p-3">

    <div className="r-shadow-sm rounded">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="p-2">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Ad</th>
              <th>Soyad</th>
              <th>Misafir Kartı</th>
              <th>Email</th>
              <th>İşlemler</th>{" "}
              {/* Örnek sütun başlıkları, gerçek veri yapınıza göre değiştirilebilir */}
            </tr>
          </thead>
          <tbody>
            {onayBekleyenMisafirler.map((misafir, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{misafir.misafirAdi}</td>
                <td>{misafir.misafirSoyadi}</td>
                <td>{misafir.misafirKarti}</td>
                <td>{misafir.misafirMail}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => handleOnaylaClick(misafir.misafirID)}
                  >
                    Onayla
                  </button>
                  <button className="btn btn-danger ms-3">Reddet</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="medium"
        header={<div>Onaylama</div>}
        footer={
          <div>
            <button className="btn btn-success" onClick={onaylaMisafiri}>
              Evet, Onayla
            </button>
            <button
              className="btn btn-light ms-3"
              onClick={() => setIsModalOpen(false)}
            >
              İptal
            </button>
          </div>
        }
      >
        Bu misafiri onaylamak istediğinize emin misiniz?
      </Modal>
    </div>
    </div>

  );
};

export default OnayBekleyenMisafirler;
