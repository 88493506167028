// types.d.ts (veya herhangi bir .ts dosyasında)
export interface KontratGirisleriType {
    db_Id: number;
    Id: number;
    Kodu: string;
    Adi: string;
    Sifresi: string;
    Ulkesi: string;
    Tel: string;
    Fax: string;
    Adres1: string;
    Adres2: string;
    Adres3: string;
    Ilce: string;
    Il: string;
    Resmiad: string;
    Vergidaire: string;
    Vergino: string;
    Bevel: string;
    Grubu: string;
    Kurkodu: string;
    Ulkekodu: string;
    Musteritipi: string;
    Pansiyon: string;
    Odemesekli: string;
    Pazarkodu: string;
    Muhasebe: string;
    Ozelindirim: number;
    Pasif: boolean;
    Bavel: boolean;
    Sorumlu: string;
    Eposta: string;
    Zip: string;
    Banka: string;
    Hesap: string;
    Gsm: string;
    Sirket: number;
    Kulanici: string;
    Free_yas_bas: number;
    Free_yas_bit: number;
    Cocuk_yas_bas: number;
    Cocuk_yas_bit: number;
    Yetiskin_yas: number;
    Rengi: string;
    Carsaf_deg: number;
    Havlu_deg: number;
    Minibar_deg: number;
    Manuel_yok_10: boolean;
    Sorumlu_mail: string;
    Sorumlu_tel: string;
    Satis_mail: string;
    Satis_adsoy: string;
    Satis_tel: string;
    Stop_mail: string;
    Stop_adsoy: string;
    Stop_tel: string;
    Rez_mail: string;
    Rez_adsoy: string;
    Rez_tel: string;
    Muh_mail: string;
    Muh_adsoy: string;
    Muh_tel: string;
    E_fatura_eh: string;
    E_fatura_senaryo: string;
    Doviz_Sekli: string;
    Forecast_grubu: string;
    Kontenjan_grubu: string;
    Vade_gun: number;
    Banka_sube: string;
    Banka_swift_kod: string;
    Banka_iban: string;
    Bavel_ekle_10: number;
    Bavel_ekle_yuzde: number;
    Ekdosya_10: boolean;
    Msh_10: number;
    Online_odeme_yuzde: number;
    Online_odemedep: string;
    nta_Rcitbl_10: number;
    Devrepans_ayir_10: number;
    Bilet_kontrol_10: number;
    Fatura_aciklama_no: number;
    Forecast_iptalgunsay: number;
    Resmiad_kullan_10: number;
    Pansiyon_ayir_10: number;
    Muslst_cikmasin_10: number;
    Polis_cikmasin_10: number;
    Kon_kurusyuvarla_10: number;
    Source: string;
    Tip: number;
    Diplomat_10: number;
    Devre_extraayir_10: number;
    Vatno: string;
    Konver123: number;
    Tr_sirket: number;
    Acenta_sirket_10: number;
    Pansiyon_ozelkod: string;
    Ustgrup: string;
    Tahsilat_yuzde: number;
    Cout_otofat_10: number;
    Web_10: number;
    Bavelkur_ne10: number;
    Adres_ulkekod: string;
    Adsoyad_10: number;
    Chanel_no: number;
    Havayolu_10: number;
    ac_Havayolu_10: number;
    ac_Gunluk_aylik: string;
}

export const KontratGirisleriData: KontratGirisleriType = {
    db_Id: 0,
    Id: 0,
    Kodu: "",
    Adi: "",
    Sifresi: "",
    Ulkesi: "",
    Tel: "",
    Fax: "",
    Adres1: "",
    Adres2: "",
    Adres3: "",
    Ilce: "",
    Il: "",
    Resmiad: "",
    Vergidaire: "",
    Vergino: "",
    Bevel: "",
    Grubu: "",
    Kurkodu: "",
    Ulkekodu: "",
    Musteritipi: "",
    Pansiyon: "",
    Odemesekli: "",
    Pazarkodu: "",
    Muhasebe: "",
    Ozelindirim: 0,
    Pasif: false,
    Bavel: false,
    Sorumlu: "",
    Eposta: "",
    Zip: "",
    Banka: "",
    Hesap: "",
    Gsm: "",
    Sirket: 0,
    Kulanici: "",
    Free_yas_bas: 0,
    Free_yas_bit: 0,
    Cocuk_yas_bas: 0,
    Cocuk_yas_bit: 0,
    Yetiskin_yas: 0,
    Rengi: "",
    Carsaf_deg: 0,
    Havlu_deg: 0,
    Minibar_deg: 0,
    Manuel_yok_10: false,
    Sorumlu_mail: "",
    Sorumlu_tel: "",
    Satis_mail: "",
    Satis_adsoy: "",
    Satis_tel: "",
    Stop_mail: "",
    Stop_adsoy: "",
    Stop_tel: "",
    Rez_mail: "",
    Rez_adsoy: "",
    Rez_tel: "",
    Muh_mail: "",
    Muh_adsoy: "",
    Muh_tel: "",
    E_fatura_eh: "",
    E_fatura_senaryo: "",
    Doviz_Sekli: "",
    Forecast_grubu: "",
    Kontenjan_grubu: "",
    Vade_gun: 0,
    Banka_sube: "",
    Banka_swift_kod: "",
    Banka_iban: "",
    Bavel_ekle_10: 0,
    Bavel_ekle_yuzde: 0,
    Ekdosya_10: false,
    Msh_10: 0,
    Online_odeme_yuzde: 0,
    Online_odemedep: "",
    nta_Rcitbl_10: 0,
    Devrepans_ayir_10: 0,
    Bilet_kontrol_10: 0,
    Fatura_aciklama_no: 0,
    Forecast_iptalgunsay: 0,
    Resmiad_kullan_10: 0,
    Pansiyon_ayir_10: 0,
    Muslst_cikmasin_10: 0,
    Polis_cikmasin_10: 0,
    Kon_kurusyuvarla_10: 0,
    Source: "",
    Tip: 0,
    Diplomat_10: 0,
    Devre_extraayir_10: 0,
    Vatno: "",
    Konver123: 0,
    Tr_sirket: 0,
    Acenta_sirket_10: 0,
    Pansiyon_ozelkod: "",
    Ustgrup: "",
    Tahsilat_yuzde: 0,
    Cout_otofat_10: 0,
    Web_10: 0,
    Bavelkur_ne10: 0,
    Adres_ulkekod: "",
    Adsoyad_10: 0,
    Chanel_no: 0,
    Havayolu_10: 0,
    ac_Havayolu_10: 0,
    ac_Gunluk_aylik: "",
};

export const yeniNesne:KontratGirisleriType = {
    db_Id: 9,
    Id: 0,
    Kodu: "ahmet",
    Adi: "ahmet acenta",
    Sifresi: "onlineşi",
    Ulkesi: "almanya",
    Tel: "tel numarası",
    Fax: "fax no",
    Adres1: "adres1",
    Adres2: "adres2",
    Adres3: "adres3",
    Ilce: "il/ilçe/ülke",
    Il: "",
    Resmiad: "resmiadı",
    Vergidaire: "vergi dairesi",
    Vergino: "vergi no",
    Bevel: "bavel kodu",
    Grubu: "005",
    Kurkodu: "006",
    Ulkekodu: "003",
    Musteritipi: "002",
    Pansiyon: "B",
    Odemesekli: "20",
    Pazarkodu: "001",
    Muhasebe: "muhasebe kodu",
    Ozelindirim: 10,
    Pasif: false,
    Bavel: true,
    Sorumlu: "ad soyad",
    Eposta: "",
    Zip: "zip kodu",
    Banka: "banka",
    Hesap: "hesap",
    Gsm: "gsm",
    Sirket: 1,
    Kulanici: "",
    Free_yas_bas: 1,
    Free_yas_bit: 1,
    Cocuk_yas_bas: 1,
    Cocuk_yas_bit: 1,
    Yetiskin_yas: 12,
    Rengi: "",
    Carsaf_deg: 1,
    Havlu_deg: 1,
    Minibar_deg: 1,
    Manuel_yok_10: true,
    Sorumlu_mail: "iletişim mail",
    Sorumlu_tel: "telefon",
    Satis_mail: "",
    Satis_adsoy: "",
    Satis_tel: "",
    Stop_mail: "",
    Stop_adsoy: "",
    Stop_tel: "",
    Rez_mail: "e-mail",
    Rez_adsoy: "",
    Rez_tel: "",
    Muh_mail: "",
    Muh_adsoy: "",
    Muh_tel: "",
    E_fatura_eh: "A",
    E_fatura_senaryo: "İ",
    Doviz_Sekli: "DS",
    Forecast_grubu: "9999",
    Kontenjan_grubu: "",
    Vade_gun: 1,
    Banka_sube: "şube",
    Banka_swift_kod: "swift kodu",
    Banka_iban: "iban",
    Bavel_ekle_10: 1,
    Bavel_ekle_yuzde: 12,
    Ekdosya_10: true,
    Msh_10: 1,
    Online_odeme_yuzde: 1,
    Online_odemedep: "400",
    nta_Rcitbl_10: 1,
    Devrepans_ayir_10: 1,
    Bilet_kontrol_10: 1,
    Fatura_aciklama_no: 1,
    Forecast_iptalgunsay: 1,
    Resmiad_kullan_10: 1,
    Pansiyon_ayir_10: 1,
    Muslst_cikmasin_10: 1,
    Polis_cikmasin_10: 1,
    Kon_kurusyuvarla_10: 1,
    Source: "ALAK",
    Tip: 1,
    Diplomat_10: 1,
    Devre_extraayir_10: 1,
    Vatno: "vat no",
    Konver123: 1,
    Tr_sirket: 1,
    Acenta_sirket_10: 0,
    Pansiyon_ozelkod: "",
    Ustgrup: "",
    Tahsilat_yuzde: 1,
    Cout_otofat_10: 1,
    Web_10: 1,
    Bavelkur_ne10: 1,
    Adres_ulkekod: "002",
    Adsoyad_10: 1,
    Chanel_no: 1,
    Havayolu_10: 1,
    ac_Havayolu_10: 1,
    ac_Gunluk_aylik:"",
};
