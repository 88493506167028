import React from "react";
import { IconType } from "react-icons";
import "./InfoPanel.css";
import { FaInfoCircle } from "react-icons/fa";
import { Header } from "@progress/kendo-react-dateinputs";

interface InfoItem {
  id: number;
  title: string;
  description: string;
  Icon: IconType;
}

interface InfoPanelProps {
  items: InfoItem[];
  header: string;
}

const InfoPanel: React.FC<InfoPanelProps> = ({ items,header }) => {
  return (
    <div className="infoPanelContainer " >
      <h1 className="infoPanelTitle">
        <FaInfoCircle size={20} color="#255588"></FaInfoCircle>{" "}
        <span className="ms-2 "></span> {header}</h1>
      <div className="infoPanel">
        {items.map(({ id, title, description, Icon }) => {
          const firstWord = description.split("=")[0];
          const restOfDescription = description.substr(firstWord.length);
          return (
            <div key={id} className="item">
              {/* <Icon className="icon"/> */}
              <div className="w-100">
                {/* <h2 className="title">{title}</h2> */}
                <p className="description text-start m-0 p-0">
                  <strong className="fw-bold">{firstWord}</strong>
                  {restOfDescription}
                </p>{" "}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InfoPanel;
