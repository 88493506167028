import React, { useEffect, useState } from "react";
import axios from "axios";
import { LoyaltyTanimlamalarApi } from "../LoyaltyTanimlamalarApi";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GrUpdate } from "react-icons/gr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faCreditCard,
  faInfoCircle,
  faPlus,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";
import { CiDiscount1 } from "react-icons/ci";
import { GiChoice } from "react-icons/gi";
import { TfiSplitVAlt } from "react-icons/tfi";
import "./FirsatIslemleri1.css";
import { useAlert } from "../../../../Modal/Alert/AlertContext";
import DynamicTable from "../../../../../Components/DinamikTable/DynamicTable";
import { Accordion, Card } from "react-bootstrap";
import InfoPanel from "../../../../../Components/InfoPanel/InfoPanel";
import { FaAd } from "react-icons/fa";

interface KartFirsatBilgisi {
  kartID: string;
  firsatTuru: string;
  deger: any;
}

interface FirsatEklemeModeli {
  otelGuid: string;
  firsatAdi: string;
  kartFirsatBilgileri: KartFirsatBilgisi[];
}
interface Kart {
  kartAdi: string;
  kartID: number;
  otelGuid: string;
  kartRengi: string;
}
interface Firsat {
  firsatAdi: string;
  firsatID: number;
  kartAdi: string;
  kartID: number;
  otelGuid: string;
  puan: string | {};
  puanMi: boolean;
  ucretsizMi: boolean;
  yuzde: string | {};
  yuzdeMi: boolean;
}

interface KartDetay {
  kartAdi: string;
  puan: string | {};
  ucretsizMi: boolean;
  yuzde: string | {};
}
interface FirsatDuzenleprops {
  FirsatDetay: [] | any;
}

// Üst bileşen

const FirsatIslemleri1: React.FC<{ otelGuid: string }> = ({ otelGuid }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [firsatAdi, setFirsatAdi] = useState("");
  const [secilenKartID, setSecilenKartID] = useState("");
  const [firsatTuru, setFirsatTuru] = useState("");
  const [deger, setDeger] = useState(0);
  const [kartFirsatBilgileri, setKartFirsatBilgileri] = useState<
    KartFirsatBilgisi[]
  >([]);

  const [kartlar, setKartlar] = useState<Kart[]>([]);
  const { showAlert } = useAlert(); // useAlert hook'unu kullan
  const [secilenKart, setSecilenKart] = useState({ text: "", value: "" });
  //fırsat detay bilgileri
  const [childData, setChildData] = useState<any>([]);
  const [firsatListesiYenilensinMi, setFirsatListesiYenilensinMi] =
    useState(false);

  const handleChildData = (data: any) => {
    setChildData(data);
  };

  useEffect(() => {
    if (childData && childData.length > 0) {
      // Fırsat adını ayarla (Tüm fırsatlar için aynı olduğu varsayılıyor)
      setFirsatAdi(childData[0].firsatAdi);

      // Kart fırsat bilgilerini ayarla
      const kartFirsatBilgileriDizisi = childData.map((item: any) => {
        let firsatTuru = "";
        let deger = "";

        if (item.yuzdeMi) {
          firsatTuru = "Yuzde";
          deger = item.yuzde;
        } else if (item.puanMi) {
          firsatTuru = "Puan";
          deger = item.puan;
        } else if (item.ucretsizMi) {
          firsatTuru = "Ucretsiz";
          deger = "0"; // Ücretsiz için değer genellikle 0 veya boş bırakılabilir.
        }

        return {
          kartID: item.kartID.toString(), // kartID'yi string olarak dönüştür
          firsatTuru,
          deger,
        };
      });

      setKartFirsatBilgileri(kartFirsatBilgileriDizisi);

      // Not: setSecilenKartID veya diğer ilgili state'lerinizi burada ayarlayabilirsiniz.
      // Örneğin, eğer bir 'secilenKart' ayarlamanız gerekiyorsa, bu kısmı da buraya ekleyebilirsiniz.
    }
  }, [childData]); // childData değiştiğinde bu effect tetiklenir.

  //fırsat detay bilgileri

  useEffect(() => {
    kartlariGetir();
  }, [otelGuid]);

  useEffect(() => {
    console.log("seçili kartın value değeri", secilenKart.value);
    setSecilenKartID(secilenKart.value);
  }, [secilenKart]);

  const firsatEkle = () => {
    // Yeni fırsatın eklenip eklenmeyeceğini kontrol et
    const kartZatenVar = kartFirsatBilgileri.some(
      (firsat) => firsat.kartID === secilenKartID
    );
    if (firsatAdi === "") {
      showAlert("warning", "Fırsat adı girmelisiniz"); // Başarı uyarısı göster
      return;
    }
    if (secilenKartID === "") {
      showAlert("warning", "Kart seçmelisiniz"); // Başarı uyarısı göster
      return;
    }
    if (firsatTuru === "") {
      showAlert("warning", "Fırsat türü seçmelisiniz"); // Başarı uyarısı göster
      return;
    }
    if (firsatTuru !== "Ucretsiz" && deger === 0) {
      showAlert("warning", "Değer girmelisiniz"); // Başarı uyarısı göster
      return;
    }
    if (!kartZatenVar) {
      const yeniFirsat: KartFirsatBilgisi = {
        kartID: secilenKartID,
        firsatTuru,
        deger: deger.toString(), // Sayıyı stringe çevir
      };
      setKartFirsatBilgileri([...kartFirsatBilgileri, yeniFirsat]);
      setSecilenKart({ text: "", value: "" });
      setSecilenKartID("");
      setFirsatTuru("");
      setDeger(0);
    } else {
      showAlert(
        "warning",
        "Bu kart için zaten bir fırsat bilgisi ekleniş eklenmiş."
      ); // Başarı uyarısı göster
    }
  };
  const firsatiKaydet = async () => {
    if (isDisabled) return; // Eğer buton zaten devre dışı ise, işlemi yapma
    setIsDisabled(true); // Butonu devre dışı bırak
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);
    const model: FirsatEklemeModeli = {
      otelGuid,
      firsatAdi,
      kartFirsatBilgileri,
    };
    console.log("model", model);
    if (kartFirsatBilgileri.length === 0) {
      showAlert("warning", "Fırsat eklemelisiniz"); // Başarı uyarısı göster
      return;
    }
    if (firsatAdi === "") {
      showAlert("warning", "Fırsat adı girmelisiniz"); // Başarı uyarısı göster
      return;
    }
    try {
      await axios.post(
        "https://api.rmosloyalty.com/api/FirsatIslemleri/eklev2",
        model
      );
      showAlert("success", "Fırsat başarıyla kaydedildi!"); // Başarı uyarısı göster
      setFirsatAdi("");
      setKartFirsatBilgileri([]);
      setFirsatListesiYenilensinMi(!firsatListesiYenilensinMi);
    } catch (error) {
      console.error("Fırsat kaydedilirken bir hata oluştu", error);
      alert("Fırsat kaydedilirken bir hata oluştu");
      showAlert("error", "Fırsat kaydedilirken bir hata oluştu"); // Başarı uyarısı göster
    }

  };
  const kartlariGetir = async () => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyKartIslemleri({
        otelGuid,
        islem: "listele",
      });
      setKartlar(response.data);
      showAlert("success", "Kartlar başarıyla listelendi"); // Başarı uyarısı göster
      console.log("kart istek");
    } catch (error) {
      console.error("Kartlar listelenirken bir hata oluştu", error);
      showAlert("error", "Kartlar listelenirken bir hata oluştu");
    }
  };
  const firsatSil = (index: any) => {
    const yeniFirsatlar = [...kartFirsatBilgileri];
    yeniFirsatlar.splice(index, 1);
    setKartFirsatBilgileri(yeniFirsatlar);
  };
  const handleFirsatTuruChange = (value: any) => {
    setFirsatTuru(value);
  };
  const items = [
    {
      id: 1,
      title: "Ekleme",
      description: "	Fırsat = Fırsatınızın adını temsil eder kullanıcı arayüzünde yayınlanır Kart = Belirlediğiniz fırsata , tanımladığınız kartlardan hangisini atayacağınızı belirler",
      Icon: FaAd,
    },
    {
      id: 2,
      title: "Node.js",
      description: "	Fırsat Tipi = Seçtiğiniz kart'ın fırsatınızı Puanlı , Yüzdeli ya da ücretsiz kullanacağını belirler",
      Icon: FaAd,
    },
    {
      id: 3,
      title: "Node.js",
      description: "	Değer = Eğer Fırsatınızın seçtiğiniz kart tipinde puanlı kullanılmasını istediyseniz fırsatın kaç puanla kullanılabileceğini belirler EğerFırsatınızın seçtiğiniz kart tipinde Yüzdeli kullanılmasını istediyseniz fırsatın, belirlediğiniz kart'a yüzde kaç indirim uygulayacağını belirler.  ",
      Icon: FaAd,
    },
  ];
  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-5 d-flex align-items-center p-1 ">
                <label className=" ">
                  <CiDiscount1 size={25} />
                  <span className="ms-2 r-fs-16 mt-1">Fırsat</span>
                </label>
              </div>
              <div className="col-7 p-1">
                <Input
                  name="kartAdi"
                  className="r-input-sm"
                  value={firsatAdi}
                  onChange={(e) => setFirsatAdi(e.value)}
                />
              </div>
              <div className="col-5 d-flex align-items-center p-1 ">
                <label className=" r-fs-16">
                  <FontAwesomeIcon icon={faCreditCard} className="fs-6" />
                  <span className="ms-2 "></span>Kart Seçiniz
                </label>
              </div>
              <div className="col-7 p-1">
                <DropDownList
                  data={kartlar.map((kart) => ({
                    text: kart.kartAdi,
                    value: kart.kartID.toString(), // kart ID'yi string olarak ayarla
                  }))}
                  value={secilenKart}
                  onChange={(e) => setSecilenKart(e.target.value)} // Doğrudan e.value kullan
                  textField="text"
                  dataItemKey="value"
                  defaultItem={{ text: "Kart Seçiniz", value: "" }}
                  className="r-input-sm"
                />
              </div>
              <div className="col-5 d-flex align-items-center p-1 ">
                <label className=" r-fs-16">
                  <GiChoice />
                  <span className="ms-2 "></span>Fırsat Tipi
                </label>
              </div>
              <div className="col-7 d-flex align-items-center p-1">
                <div className="d-flex justify-content-between w-100">
                  <div>
                    <RadioButton
                      name="firsatTuru"
                      value="Puan"
                      label="Puan"
                      checked={firsatTuru === "Puan"}
                      onChange={() => handleFirsatTuruChange("Puan")}
                    />
                  </div>
                  <div>
                    <RadioButton
                      name="firsatTuru"
                      value="Yuzde"
                      label="Yüzde"
                      checked={firsatTuru === "Yuzde"}
                      onChange={() => handleFirsatTuruChange("Yuzde")}
                    />
                  </div>
                  <div>
                    <RadioButton
                      name="firsatTuru"
                      value="Ucretsiz"
                      label="Ücretsiz"
                      checked={firsatTuru === "Ucretsiz"}
                      onChange={() => handleFirsatTuruChange("Ucretsiz")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-5 d-flex align-items-center p-1">
                <label className=" r-fs-16">
                  <TfiSplitVAlt />
                  <span className="ms-2 "></span>Değer
                </label>
              </div>
              <div className="col-7 p-1 d-flex align-items-center">
                <NumericTextBox
                  value={deger}
                  onChange={(e) => setDeger(e.value || 0)}
                  disabled={firsatTuru === "Ucretsiz"}
                  className="r-input-sm "
                />
                <Button
                  className="ms-2 r-buton-success  "
                  style={{ height: "25px", width: "45px" }}
                  onClick={firsatEkle}
                >
                  <FontAwesomeIcon className="mt-1" icon={faPlus} />
                </Button>
              </div>
              <div className="col-12 p-1 d-flex justify-content-between">
                <div className="a"></div>
                <div className="a"></div>
                <div className="a"></div>

                {/* <Button onClick={firsatiKaydet}>Kaydet</Button> */}
              </div>
              <div className="col-12 d-flex p-0 m-0">
                <div className="d-flex flex-wrap  ">
                  {kartFirsatBilgileri.map((firsat, index) => (
                    <div key={index} className="firsat-karti m-2 ">
                      <div className="kart-adi">
                        {kartlar.find(
                          (kart) => kart.kartID.toString() === firsat.kartID
                        )?.kartAdi || "Kart bulunamadı"}
                      </div>
                      <div className="firsat-turu">
                        {firsat.firsatTuru === "Yuzde"
                          ? `%${firsat.deger} İndirim`
                          : firsat.firsatTuru === "Ucretsiz"
                          ? "ücretsiz"
                          : firsat.firsatTuru === "Puan"
                          ? `${firsat.deger} ${firsat.firsatTuru}`
                          : `${firsat.deger}`}
                      </div>
                      <div className="kart-footer w-100 p-0 m-0">
                        <Button
                          className="sil-butonu w-100"
                          onClick={() => firsatSil(index)}
                        >
                          Sil
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 d-flex justify-content-end p-1 m-0">
                <Button
                  className=" mt-4 r-buton-danger d-flex align-items-center"
                  onClick={() => {
                    setFirsatAdi("");
                    setKartFirsatBilgileri([]);
                  }}
                >
                  <FontAwesomeIcon icon={faClose} />
                  <span className="ms-3">Vazgeç</span>
                </Button>
                <span className="ms-3"></span>
                <Button
                  className=" mt-4 r-buton-success d-flex align-items-center"
                  onClick={firsatiKaydet}
                  disabled={isDisabled} // isDisabled true ise butonu devre dışı bırak
                >
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="ms-3">Tamamla</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="col-6">

                <InfoPanel items={items} header="Fırsat Ekleme"/>

            {/* <div className="info-panel ">
              <div className="">
                <div className="info-panel__title r-fs-16 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#255588"
                    className="fs-4 mt-"
                  />
                  <span className="ms-4"></span>
                  Fırsat İşlemleri{" "}
                </div>
              </div>
              <div className="info-panel__content">
                <div className="info-panel__item bg-light rounded shadow-sm">
                  <p className="p-0 m-0">
                    Hesaplama türü{" "}
                    <span className="fw-bold text-danger">0</span> ise Kalışa
                    göre hesaplama
                  </p>
                </div>
                <div className="info-panel__item bg-light rounded shadow-sm">
                  <p className="p-0 m-0">
                    Hesaplama türü{" "}
                    <span className="fw-bold text-danger">1</span> ise Gelişe
                    göre hesaplama
                  </p>
                </div>
                <div className="info-panel__item bg-light rounded shadow-sm">
                  <p className="p-0 m-0">
                    Hesaplama türü{" "}
                    <span className="fw-bold text-danger">2</span> ise Kalış ve
                    gelişe göre hesaplama
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div></div>
      <div className="container">
        <div className="row  overflow-auto">
          <FirsatListele
            otelGuid={otelGuid}
            onChildData={handleChildData}
            yenile={firsatListesiYenilensinMi}
          />
        </div>
      </div>
    </>
  );
};

export default FirsatIslemleri1;

const FirsatListele: React.FC<{
  otelGuid: string;
  onChildData: (data: any[]) => void;
  yenile: boolean;
}> = ({ otelGuid, onChildData, yenile }) => {
  const [firsatlar, setFirsatlar] = useState<Firsat[]>([]);

  useEffect(() => {
    fetchFirsatListele();
  }, [otelGuid, yenile]); // `yenile` prop'unu bağımlılık listesine ekleyin
  const fetchFirsatListele = async () => {
    try {
      const response = await axios.get(
        `https://api.rmosloyalty.com/api/FirsatIslemleri/listelev2?otelGuid=${otelGuid}`
      );
      setFirsatlar(response.data);
      // console.log("fırsatlar", response.data);
    } catch (error) {
      console.error("Fırsatlar listelenirken bir hata oluştu", error);
    }
  };

  const gruplanmisFirsatlar = firsatlar.reduce<Record<string, KartDetay[]>>(
    (acc, current) => {
      const { firsatAdi, kartAdi, puan, ucretsizMi, yuzde } = current;
      if (!acc[firsatAdi]) {
        acc[firsatAdi] = [];
      }
      acc[firsatAdi].push({ kartAdi, puan, ucretsizMi, yuzde });
      return acc;
    },
    {}
  );
  const handleFirsatGuncelle = (firsatAdi: string) => {
    console.log("fırsatlar", firsatlar);
    const firsat = firsatlar.filter((f) => f.firsatAdi === firsatAdi);
    console.log("firsat", firsat);
    onChildData(firsat);
  };
  const FirsatSil = async (firsatID: number) => {
    try {
      await axios.post(
        `https://api.rmosloyalty.com/api/FirsatIslemleri/FirsatSilV2?firsatId=${firsatID}`
      );
      console.log("fırsat silme isteği");
      // Fırsatlar yenilensin
      fetchFirsatListele();
    } catch (error) {
      console.error("Fırsat silinirken bir hata oluştu", error);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="border-top mb-4 w-100  mt-4 ">
          <span></span>
        </div>
      </div>
      <h3 className="text-center mb-2" style={{ letterSpacing: "2px" }}>
        {" "}
        Tanımlanmış Fırsatlar
      </h3>

      <Accordion defaultActiveKey="">
        {Object.entries(gruplanmisFirsatlar).map(
          ([firsatAdi, detaylar], index) => (
            <Accordion.Item eventKey={String(index)} key={index}>
              <Accordion.Header className="accordion-header-bold">
                {firsatAdi}
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  {detaylar.map((detay, detayIndex) => (
                    <div className="col-md-4" key={detayIndex}>
                      <Card
                        className="h-100"
                        style={{ borderColor: "#255588" }}
                      >
                        <Card.Body>
                          <Card.Title>{detay.kartAdi}</Card.Title>
                          {typeof detay.puan === "string" && (
                            <Card.Text>{detay.puan} Puan</Card.Text>
                          )}
                          {detay.ucretsizMi !== undefined && (
                            <Card.Text>
                              {" "}
                              {detay.ucretsizMi ? "Ücretsiz" : ""}
                            </Card.Text>
                          )}
                          {typeof detay.yuzde === "string" && (
                            <Card.Text>%{detay.yuzde} Indirim</Card.Text>
                          )}
                        </Card.Body>
                        <Card.Footer
                          style={{ background: "#255588" }}
                        ></Card.Footer>
                      </Card>
                    </div>
                  ))}
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    className="r-buton-danger"
                    onClick={() =>
                      FirsatSil(
                        firsatlar.find((f) => f.firsatAdi === firsatAdi)
                          ?.firsatID || 0
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faClose} />
                    <span className="ms-3"></span>
                    Sil
                  </Button>
                  <span className="ms-3"></span>
                  <Button
                    className="r-buton-orange"
                    onClick={() => handleFirsatGuncelle(firsatAdi)}
                  >
                    <GrUpdate />
                    <span className="ms-3"></span>
                    Güncelle
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )
        )}
      </Accordion>
    </div>
  );
};
// const FirsatListele : React.FC<{otelGuid: string}> = ({otelGuid}) => {
//   const [firsatlar, setFirsatlar] = useState<any[]>([]);

//   useEffect(() => {
//     FetchfirsatListele();
//   }
//   , [otelGuid]);

//   const FetchfirsatListele = async () => {
//     try {
//       const response = await axios.get(`https://api.rmosloyalty.com/api/FirsatIslemleri/listelev2?otelGuid=${otelGuid}`)
//       const filteredData = response.data.map((guest:any) => ({
//         firsatAdi: guest.firsatAdi,
//       }));

//       setFirsatlar(filteredData);
//       console.log("fırsatlar", response.data);
//     } catch (error) {
//       console.error("Fırsatlar listelenirken bir hata oluştu", error);
//     }
//   }
//   const columnNamesMap1 = {
//     firsatAdi: "FIRSATLAR",

//   };

//   return (
//     <div>
//                   <DynamicTable data={firsatlar}   columnNamesMap={columnNamesMap1} />

//       <button onClick={FetchfirsatListele}>fısatlistee</button>
//       {firsatlar.map((firsat, index) => (
//         <div key={index}>
//           <span>{firsat.firsatAdi} - </span>
//           <span>{firsat.otelGuid} - </span>
//           <span>{firsat.firsatID}</span>
//           </div>
//       ))}
//     </div>
//   );
// }
