// Alert.tsx
import React from "react";
import "./Alert.scss";
import { AlertProps } from "./AlertTypes";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { GoInfo } from "react-icons/go";
import { IoWarningOutline } from "react-icons/io5";
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import { IoClose } from "react-icons/io5";

const Alert: React.FC<AlertProps> = ({ type, message, onClose, className }) => {
  const getIcon = (type: string) => {
    switch (type) {
      case "success":
        return <IoMdCheckmarkCircleOutline />;
      case "info":
        return <GoInfo />;
      case "warning":
        return <IoWarningOutline />;
      case "error":
        return <MdOutlineReportGmailerrorred />;
      default:
        return null;
    }
  };

  return (
    <div className={`alert alert-${type} ${className}`}>
      <span className="icon">{getIcon(type)}</span>
      <span className="message">{message}</span>
      {onClose && (
        <button className="d-flex align-items-center justify-content-center" onClick={onClose}>
          <IoClose />
        </button>
      )}
    </div>
  );
};

export default Alert;
