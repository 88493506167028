import React, { useState, useEffect } from 'react';
import './ContextMenu.scss';

const useContextMenu = (menuItems:any) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [targetData, setTargetData] = useState(null); // Tıklanan öğenin verilerini tutmak için state

  const handleContextMenu = (event:any, data:any) => {
    event.preventDefault();
    setPosition({ x: event.pageX, y: event.pageY });
    setMenuVisible(true);
    setTargetData(data); // Tıklanan öğenin verilerini set ediyoruz
  };

  const handleClick = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event:any) => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    };

    if (menuVisible) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [menuVisible]);

  const renderContextMenu = () => {
    if (!menuVisible) return null;

    return (
      <ul className="context-menu " style={{ top: position.y, left: position.x }}>
        {menuItems.map((item:any, index:any) => (
          <li key={index} onClick={() => { item.onClick(targetData); setMenuVisible(false); }}>
            {item.icon && <span className="context-menu-icon">{item.icon}</span>}
            {item.label}
          </li>
        ))}
      </ul>
    );
  };

  return { handleContextMenu, handleClick, renderContextMenu };
};

export default useContextMenu;
