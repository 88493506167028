import React, { useEffect, useState } from "react";
import LoyaltyPuanlamaTabPanel from "./LoyaltyOtelPuanlamaIslemleri";
import { useOtelGuid } from "../../../../../Context/LocalStrogeContext/otelGuidContext";
import axios from "axios";
import { format } from "path";
import { LuCalendarSearch } from "react-icons/lu";
import { GiAtSea } from "react-icons/gi";
import { TiThSmallOutline } from "react-icons/ti";
import { Button } from "@progress/kendo-react-buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
const apiUrl = process.env.REACT_APP_LOYALTY_URL;

interface Oda {
  odaId: number;
  otelGuid: string;
  onbOdaId: number;
  onbOdaKod: string;
  onbOdaAd: string;
}
interface PeriyotSezon {
  periyotSezonId: number;
  periyotSezonAdi: string;
  periyotBaslangicTarihi: string;
  periyotBitisTarihi: string;
  periyotOtelGuid: string;
}
interface PeriyotPuan {
  puanlarID: number;
  puanlarSezonID: number;
  puanlarOdaKod: string;
  puanlarPuan: number;
  puanlarOtelGuid: string;
}

interface Puan {
  PuanlarID: number;
  puanlarSezonID: number;
  puanlarOdaKod: string;
  puanlarPuan: number;
  puanlarOtelGuid: string;
}

interface Props {
  otelguid: string;
}
interface GirilenPuanlar {
  [key: string]: string; // `odaKod-sezonId` formatında anahtar ve puan değeri string olarak
}

export const GelismisPuanlamaAyarlaritab: React.FC = () => {
  const { otelGuid, setOtelGuid } = useOtelGuid(); // Hook'u burada kullan

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <LoyaltyPuanlamaTabPanel otelGuid={otelGuid} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const GelismisPuanlamaAyarlari: React.FC<Props> = ({ otelguid }) => {
  const [showSezonEkleModal, setShowSezonEkleModal] = useState(false);
  const [odalar, setOdalar] = useState<Oda[]>([]);
  const [sezonlar, setSezonlar] = useState<PeriyotSezon[]>([]);
  const [puanlar, setPuanlar] = useState<Puan[]>([]);
  const { otelGuid } = useOtelGuid(); // Hook'u burada kullan
  const [secilenYil, setSecilenYil] = useState<number>(
    new Date().getFullYear()
  );
  const [filtrelenmisSezonlar, setFiltrelenmisSezonlar] = useState<
    PeriyotSezon[]
  >([]);
  const [girilenPuanlar, setGirilenPuanlar] = useState<GirilenPuanlar>({});

  const puanGirisiDegisti = (
    odaKod: string,
    sezonId: number,
    girilenPuan: string
  ) => {
    const yeniPuanlar: GirilenPuanlar = {
      ...girilenPuanlar,
      [`${odaKod}-${sezonId}`]: girilenPuan,
    };
    setGirilenPuanlar(yeniPuanlar);
  };
  const sezonEklendiCallback = () => {
    odaCek();
    sezonCek();
    PuanCek();
  };
  const puanlariGonder = async () => {
    // Tip güvenliği için burada daha fazla kontrol ekleyebilirsiniz
    for (const key in girilenPuanlar) {
      const [odaKod, sezonId] = key.split("-");
      const puan = girilenPuanlar[key];
      const data: PeriyotPuan = {
        puanlarID: 0,
        puanlarSezonID: parseInt(sezonId, 10),
        puanlarOdaKod: odaKod,
        puanlarPuan: parseInt(puan, 10),
        puanlarOtelGuid: otelGuid,
      };

      try {
        await GelismisApi.postPeriyotPuan(data); // API çağrısını varsayılan olarak tanımladığınız metod
        console.log("Puan başarıyla gönderildi:", data);
      } catch (error) {
        console.error("Puan gönderilirken hata oluştu:", error);
      }
    }
  };

  useEffect(() => {
    setOdalar([]);
    setSezonlar([]);
    setPuanlar([]);
    odaCek();
    sezonCek();
    PuanCek();
    console.log("odalar sezonlar,puanlar", odalar, sezonlar, puanlar);
  }, [otelGuid]); // Bağımlılık olarak otelGuid kullan
  useEffect(() => {
    // secilenYil değiştiğinde, sezonları filtrele
    filtreleSezonlar();
  }, [secilenYil, sezonlar]);
  const odaCek = async () => {
    try {
      const data = {};
      const response = await GelismisApi.LoyaltyOtelOdaCek(otelGuid);
      setOdalar(response);
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu", error);
    }
  };
  const sezonCek = async () => {
    try {
      const data = {};
      const response = await GelismisApi.LoyaltyOtelSezonCek(otelGuid);
      setSezonlar(response);
      setFiltrelenmisSezonlar(response); // İlk yüklemede tüm sezonları göster
      console.log("sezonlar", response);
      console.log("filtrelenmisSezonlar", filtrelenmisSezonlar);
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu", error);
    }
  };
  const PuanCek = async () => {
    try {
      const data = {};
      const response = await GelismisApi.LoyaltyOtelPuanCek(otelGuid);
      setPuanlar(response);
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu", error);
    }
  };
  const getPuanForOdaAndSezon = (odaKod: string, sezonId: number) => {
    const puan = puanlar.find(
      (p) => p.puanlarOdaKod === odaKod && p.puanlarSezonID === sezonId
    );
    return puan ? puan.puanlarPuan : "N/A";
  };

  const renderTable = () => {
    return (
      <div className="container-fluid mt-3">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Oda Adı</th>
                {filtrelenmisSezonlar.map((sezon) => (
                  <th key={sezon.periyotSezonId} scope="col">
                    <div className="d-flex flex-wrap">
                      {sezon.periyotSezonAdi}{" "}
                      {new Date(
                        sezon.periyotBaslangicTarihi
                      ).toLocaleDateString()}{" "}
                      -{" "}
                      {new Date(sezon.periyotBitisTarihi).toLocaleDateString()}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {odalar.map((oda, odaIndex) => (
                <tr key={oda.odaId}>
                  <td>{oda.onbOdaAd}</td>
                  {filtrelenmisSezonlar.map((sezon, sezonIndex) => {
                    const puanKey = `${oda.onbOdaKod}-${sezon.periyotSezonId}`;
                    return (
                      <td key={puanKey}>
                        <input
                          id={puanKey}
                          type="number"
                          className="form-control"
                          value={
                            girilenPuanlar[puanKey] ??
                            getPuanForOdaAndSezon(
                              oda.onbOdaKod,
                              sezon.periyotSezonId
                            ).toString()
                          }
                          onChange={(e) =>
                            puanGirisiDegisti(
                              oda.onbOdaKod,
                              sezon.periyotSezonId,
                              e.target.value
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyDown(e, odaIndex, sezonIndex)
                          }
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <button className="btn btn-primary" onClick={puanlariGonder}>
          Puanları Gönder
        </button> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <Button className=" r-buton-success " onClick={puanlariGonder}>
                <FontAwesomeIcon icon={faCheck} />
                <span className="ms-3">Tamamla</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    currentOdaIndex: number,
    currentSezonIndex: number
  ) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Enter'ın varsayılan davranışını engelle

      const nextSezonIndex =
        (currentSezonIndex + 1) % filtrelenmisSezonlar.length;
      const nextOdaIndex =
        currentSezonIndex + 1 >= filtrelenmisSezonlar.length
          ? currentOdaIndex + 1
          : currentOdaIndex;

      if (nextOdaIndex < odalar.length) {
        const nextOda = odalar[nextOdaIndex];
        const nextSezon = filtrelenmisSezonlar[nextSezonIndex];
        const nextInputId = `${nextOda.onbOdaKod}-${nextSezon.periyotSezonId}`;
        document.getElementById(nextInputId)?.focus();
      }
    }
  };

  const tümünüGöster = () => {
    setFiltrelenmisSezonlar(sezonlar); // Filtrelenmiş sezonları, tüm sezonlarla aynı yap
  };

  const filtreleSezonlar = () => {
    const filteredSezonlar = sezonlar.filter((sezon: PeriyotSezon) => {
      const baslangicYili = new Date(
        sezon.periyotBaslangicTarihi
      ).getFullYear();
      const bitisYili = new Date(sezon.periyotBitisTarihi).getFullYear();
      return secilenYil >= baslangicYili && secilenYil <= bitisYili;
    });
    setFiltrelenmisSezonlar(filteredSezonlar);
  };
  const yilSecici = () => {
    const yil = new Date().getFullYear();
    const yillar = Array.from(new Array(20), (val, index) => yil + index);

    return (
      <div className="form-group ">
        <select
          className="form-control"
          value={secilenYil}
          onChange={(e) => setSecilenYil(Number(e.target.value))}
        >
          {yillar.map((yil) => (
            <option key={yil} value={yil}>
              {yil}
            </option>
          ))}
        </select>
      </div>
    );
  };
  const toggleSezonEkleModal = () => {
    setShowSezonEkleModal(!showSezonEkleModal);
  };

  return (
    <div className="mt-4">
      {/* Tümünü Göster butonu */}
      {showSezonEkleModal && (
        <SezonEkle onSezonEklendi={sezonEklendiCallback} />
      )}{" "}
      <div className="row">
        <div className="col-4 d-flex">
          <div className="div w-25 d-flex align-items-center">
            <span style={{ marginRight: "10px" }}>Yıl seç</span>
            {yilSecici()}
          </div>
          <button className="btn btn-light ms-4" onClick={tümünüGöster}>
            <TiThSmallOutline style={{ marginRight: "10px" }} />
            Tümünü Göster
          </button>
          <button className="btn btn-light ms-4" onClick={toggleSezonEkleModal}>
            {" "}
            <GiAtSea size={15} style={{ marginRight: "10px" }} />
            Sezon Ekle
          </button>
        </div>
      </div>
      {renderTable()}
    </div>
  );
};
interface SezonEkleProps {
  onSezonEklendi: () => void;
}
export const SezonEkle: React.FC<SezonEkleProps> = ({ onSezonEklendi }) => {
  const { otelGuid } = useOtelGuid(); // Hook'u burada kullan

  const [formData, setFormData] = useState<PeriyotSezon>({
    periyotSezonId: 0,
    periyotSezonAdi: "",
    periyotBaslangicTarihi: "",
    periyotBitisTarihi: "",
    periyotOtelGuid: otelGuid,
  });
  useEffect(() => {
    setFormData({ ...formData, periyotOtelGuid: otelGuid });
  }, [otelGuid]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("formdata", formData);
    try {
      const response = await GelismisApi.postPeriyotSezon(formData);
      alert("Form başarıyla gönderildi!");
      onSezonEklendi(); // Prop olarak alınan callback fonksiyonunu çağır
      setFormData({
        periyotSezonId: 0,
        periyotSezonAdi: "",
        periyotBaslangicTarihi: "",
        periyotBitisTarihi: "",
        periyotOtelGuid: otelGuid,
      }); // Formu temizle
    } catch (error) {
      console.error("Form gönderimi sırasında bir hata oluştu:", error);
      alert("Form gönderimi sırasında bir hata oluştu.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid mt-3">
      <div className="row">
        <div className="col-6">
          <h3>Sezon Ekle</h3>
        </div>
      </div>
      <div className="row mb-3 align-items-center">
        <div className="col-sm-2 d-flex ">
          <label htmlFor="periyotSezonAdi" className="col-form-label">
            <GiAtSea size={15} style={{ marginRight: "10px" }} />
            Sezon Adı:
          </label>
        </div>
        <div className="col-sm-3">
          <input
            type="text"
            id="periyotSezonAdi"
            name="periyotSezonAdi"
            className="form-control"
            value={formData.periyotSezonAdi}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-sm-2 d-flex">
          <label htmlFor="periyotBaslangicTarihi" className="col-form-label">
            <LuCalendarSearch size={15} style={{ marginRight: "10px" }} />
            Başlangıç Tarihi:
          </label>
        </div>
        <div className="col-sm-3">
          <input
            type="date"
            id="periyotBaslangicTarihi"
            name="periyotBaslangicTarihi"
            className="form-control"
            value={formData.periyotBaslangicTarihi}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-sm-2 d-flex">
          <label htmlFor="periyotBitisTarihi" className="col-form-label">
            <LuCalendarSearch size={15} style={{ marginRight: "10px" }} />
            Bitiş Tarihi:
          </label>
        </div>
        <div className="col-sm-3">
          <input
            type="date"
            id="periyotBitisTarihi"
            name="periyotBitisTarihi"
            className="form-control"
            value={formData.periyotBitisTarihi}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-5 d-flex justify-content-end">
          {/* <button type="submit" className="btn btn-primary">
            Gönder
          </button> */}
          <Button className=" r-buton-success " type="submit">
            <FontAwesomeIcon icon={faCheck} />

            <span className="ms-3">Sezon Ekle</span>
          </Button>
        </div>
      </div>
    </form>
  );
};
export const PuanEkle: React.FC = () => {
  const [odalar, setOdalar] = useState<any | null>([]);
  const [sezonlar, setSezonlar] = useState<any | null>([]);
  const [selectedOda, setSelectedOda] = useState("");
  const [selectedSezon, setSelectedSezon] = useState(0);
  const [puan, setPuan] = useState(0);
  const { otelGuid } = useOtelGuid(); // Hook'u burada kullan

  useEffect(() => {
    setOdalar([]);
    setSezonlar([]);
    const odaCek = async () => {
      try {
        const data = {};
        const response = await GelismisApi.LoyaltyOtelOdaCek(otelGuid);
        setOdalar(response);
      } catch (error) {
        console.error("API isteği sırasında bir hata oluştu", error);
      }
    };
    const sezonCek = async () => {
      try {
        const data = {};
        const response = await GelismisApi.LoyaltyOtelSezonCek(otelGuid);
        setSezonlar(response);
      } catch (error) {
        console.error("API isteği sırasında bir hata oluştu", error);
      }
    };
    sezonCek();
    odaCek();
  }, [otelGuid]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      puanlarID: 0,
      puanlarSezonID: selectedSezon,
      puanlarOdaKod: selectedOda,
      puanlarPuan: puan,
      puanlarOtelGuid: otelGuid,
    };
    console.log("data", data);
    await GelismisApi.postPeriyotPuan(data);
    // Form gönderildikten sonra yapılacak işlemler (örneğin, formu temizleme)
  };

  return (
    <div>
      <select
        onChange={(e) => setSelectedOda(e.target.value)}
        value={selectedOda}
      >
        {odalar &&
          odalar.map((oda: any) => (
            <option key={oda.onbOdaId} value={oda.onbOdaKod}>
              {oda.onbOdaAd}
            </option>
          ))}
      </select>

      <select
        onChange={(e) => setSelectedSezon(Number(e.target.value))}
        value={selectedSezon}
      >
        {sezonlar &&
          sezonlar.map((sezon: any) => (
            <option key={sezon.periyotSezonId} value={sezon.periyotSezonId}>
              {sezon.periyotSezonAdi}
            </option>
          ))}
      </select>

      <form onSubmit={handleSubmit}>
        <input
          type="number"
          value={puan}
          onChange={(e) => setPuan(Number(e.target.value))}
        />
        <button type="submit">Puan Gönder</button>
      </form>
    </div>
  );
};
const GelismisApi = {
  LoyaltyOtelOdaCek: async (otelGuid: any) => {
    const response = await axios.get(
      `${apiUrl}/api/GelismisParametreler/GetPeriyotOdalar?otelGuid=${otelGuid}`
      // `${apiUrl}/api/GelismisParametreler/GetPeriyotOdalar?otelGuid=1`,
    );
    return response.data;
  },
  LoyaltyOtelSezonCek: async (otelGuid: any) => {
    const response = await axios.get(
      `${apiUrl}/api/GelismisParametreler/PeriyotSezonlariListele?otelGuid=${otelGuid}`
      // `${apiUrl}/api/GelismisParametreler/PeriyotSezonlariListele?otelGuid=1`,
    );
    return response.data;
  },
  LoyaltyOtelPuanCek: async (otelGuid: any) => {
    const response = await axios.get(
      `${apiUrl}/api/GelismisParametreler/PeriyotPuanListele?PotelGuid=${otelGuid}`
      // `${apiUrl}/api/GelismisParametreler/PeriyotPuanListele?otelGuid=1`,
    );
    return response.data;
  },
  postPeriyotSezon: async (data: PeriyotSezon) => {
    console.log("postPeriyotSezon", data);
    try {
      const response = await axios.post(
        `${apiUrl}/api/GelismisParametreler/AddPeriyotSezon`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Periyot Sezon POST isteğinde hata:", error);
      throw error;
    }
  },
  postPeriyotPuan: async (data: PeriyotPuan) => {
    console.log("postPeriyotSezon", data);
    try {
      const response = await axios.post(
        `${apiUrl}/api/GelismisParametreler/periyotpuanekle`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Periyot Sezon POST isteğinde hata:", error);
      throw error;
    }
  },
};
