// import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
// import Alert from './Alert';
// import { AlertType } from './AlertTypes';

// interface AlertItem {
//   id: number;
//   type: AlertType;
//   message: string;
//   isExiting: boolean;
// }

// interface AlertContextType {
//   showAlert: (type: AlertType, message: string) => void;
// }

// const AlertContext = createContext<AlertContextType | undefined>(undefined);

// interface AlertProviderProps {
//   children: ReactNode;
// }

// export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
//   const [alerts, setAlerts] = useState<AlertItem[]>([]);

//   const removeAlert = (id: number) => {
//     // İlk olarak alert'i çıkış animasyonu için işaretleyin
//     setAlerts((currentAlerts) =>
//       currentAlerts.map((alert) =>
//         alert.id === id ? { ...alert, isExiting: true } : alert,
//       ),
//     );

//     // CSS animasyonunuzun süresine uygun bir şekilde bekleyin ve sonra alert'i tamamen kaldırın
//     setTimeout(() => {
//       setAlerts((currentAlerts) =>
//         currentAlerts.filter((alert) => alert.id !== id),
//       );
//     }, 500); // Bu değer CSS animasyon sürenizle eşleşmelidir
//   };

//   const showAlert = (type: AlertType, message: string) => {
//     const id = new Date().getTime(); // Benzersiz bir ID oluşturun
//     setAlerts((prevAlerts) => [...prevAlerts, { id, type, message, isExiting: false }]);
    
//     // Alert'i otomatik olarak kaldırmak için zamanlayıcı ayarlayın
//     setTimeout(() => removeAlert(id), 3000); // Bu süre alert gösterim sürenizdir
//   };

//   const contextValue = { showAlert };

//   return (
//     <AlertContext.Provider value={contextValue}>
//       {children}
//       <div className="alert-container">
//         {alerts.map((alert) => (
//           <Alert
//             key={alert.id}
//             type={alert.type}
//             message={alert.message}
//             onClose={() => removeAlert(alert.id)}
//             className={alert.isExiting ? 'exit' : ''}
//           />
//         ))}
//       </div>
//     </AlertContext.Provider>
//   );
// };

// export const useAlert = () => {
//   const context = useContext(AlertContext);
//   if (context === undefined) {
//     throw new Error('useAlert must be used within an AlertProvider');
//   }
//   return context;
// };

import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react';
import Alert from './Alert';
import { AlertType } from './AlertTypes';

interface AlertItem {
  id: number;
  type: AlertType;
  message: string;
  isExiting: boolean;
}

interface AlertContextType {
  showAlert: (type: AlertType, message: string) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertItem[]>([]);

  const removeAlert = useCallback((id: number) => {
    setAlerts((currentAlerts) =>
      currentAlerts.map((alert) =>
        alert.id === id ? { ...alert, isExiting: true } : alert,
      ),
    );

    setTimeout(() => {
      setAlerts((currentAlerts) =>
        currentAlerts.filter((alert) => alert.id !== id),
      );
    }, 500); // Bu değer CSS animasyon sürenizle eşleşmelidir
  }, []);

  const showAlert = useCallback((type: AlertType, message: string) => {
    const id = new Date().getTime();
    setAlerts((prevAlerts) => [...prevAlerts, { id, type, message, isExiting: false }]);

    const timerId = setTimeout(() => removeAlert(id), 3000);
    return () => clearTimeout(timerId); // Cleanup function
  }, [removeAlert]);

  useEffect(() => {
    const timers = alerts.map((alert) =>
      setTimeout(() => removeAlert(alert.id), 3000 + 500) // 3000ms gösterim + 500ms çıkış animasyonu
    );

    return () => {
      timers.forEach(clearTimeout); // Cleanup all timers
    };
  }, [alerts, removeAlert]);

useEffect(() => {
  const alertContainer = document.querySelector('.alert-container');

  if (alerts.length === 0) {
    // Alert listesi boşsa ve element varsa, 'hidden' sınıfını ekleyin
    alertContainer?.classList.add('hidden');
  } else {
    // Alert listesi boş değilse ve element varsa, 'hidden' sınıfını çıkarın
    alertContainer?.classList.remove('hidden');
  }

  // Alert'lerin kaldırılması için zamanlayıcılar ayarlayın
  const timers = alerts.map(alert => 
    setTimeout(() => {
      removeAlert(alert.id);
    }, 3000)
  );

  // Component unmount olursa zamanlayıcıları temizleyin
  return () => timers.forEach(timer => clearTimeout(timer));
}, [alerts]);

  
  const contextValue = { showAlert };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      <div className={`alert-container ${alerts.length === 0 ? 'hidden' : ''}`}>
        {alerts.map((alert) => (
          <Alert
            key={alert.id}
            type={alert.type}
            message={alert.message}
            onClose={() => removeAlert(alert.id)}
            className={alert.isExiting ? 'exit' : ''}
          />
        ))}
      </div>
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};
