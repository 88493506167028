import React, { createContext, useState, useEffect, ReactNode } from "react";

interface LanguageContextType {
  language: string;
  setLanguage: (language: string) => void;
  translations: any;
}

export const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // Başlangıçta localStorage'dan dil bilgisini al
  const [language, setLanguage] = useState(
    localStorage.getItem("Language") || "en-us"
  );
  const [translations, setTranslations] = useState<any>({});

  // Dil değiştirme fonksiyonu güncelleniyor
  const changeLanguage = (newLanguage: string) => {
    localStorage.setItem("Language", newLanguage);
    setLanguage(newLanguage);
  };

  useEffect(() => {
    // Her dil değişikliğinde, ilgili dil dosyasını yükle
    console.log("Language changed: /public/Translate/", language);
    import(`/public/Translate/${language}.json`)
      .then((module) => {
        setTranslations(module.default);
      })
      .catch((error) => {
        console.error("Error loading translation file:", error);
      });
  }, [language]); // Dependency olarak language'ı izle
  useEffect(() => {
    fetch(`/Translate/${language}.json`)
      .then((response) => response.json())
      .then((data) => setTranslations(data))
      .catch((error) =>
        console.error("Error loading translation file:", error)
      );
  }, [language]);
  return (
    <LanguageContext.Provider
      value={{ language, setLanguage: changeLanguage, translations }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
