import { RadioGroup, TextAreaHandle } from "@progress/kendo-react-inputs";
import Modal from "../../../../Modal/Modal/Modal";
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { TextArea } from "@progress/kendo-react-inputs";
import RmosGrid from "../../../../../Components/DinamikRmosGrid/RmosGrid";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";
import { useEffect, useState } from "react";
import axios from "axios";

export interface NotesModalProps {
  isOpen: boolean;
  onClose: () => void;
  data?: any;
}

const comboboxdata = [
  "overbook",
  "kategori overbook",
  "upgrade",
  "upsale",
  "OutOfOrder",
  "G.Manager",
  "Other",
];

const group_1 = [
  { label: "Rezervasyon", value: "Rezervation" },
  { label: "Onburo", value: "FrontOffice" },
  { label: "HK", value: "HouseKeeping" },
];

const group_2 = [
  { label: "CRM", value: "CRM" },
  { label: "DevreMulk", value: "DevreMulk" },
  { label: "Oda Mesajı", value: "RoomMessage" },
];

const group_3 = [
  { label: "Time Share", value: "Time Share" },
  { label: "Online(Web)", value: "Web" },
  { label: "Konum Değişikliği", value: "LocationChange" },
];

const group_4 = [
  { label: "İptal Rezervasyon", value: "CancelRezervation" },
  { label: "Rmos Document", value: "Rmos Document" },
  { label: "Fatura", value: "Bill" },
];

const group_5 = [
  { label: "Muhasebe Notu", value: "AccountingNote" },
  { label: "Acente Konum No", value: "AgencyLocationNo" },
  { label: "Check/Out Ozel", value: "CheckoutSpecial" },
];

const NotesModal: React.FC<NotesModalProps> = ({ isOpen, onClose, data }) => {
  const { dbId } = useDbId();
  const [description, setDescription] = useState("");
  const [reason, setReason] = useState<any>();
  const [crmtext, setCrmtext] = useState<any>();

  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  const [body, setBody] = useState({
    db_Id: dbId,
    notlar_Rez_id: 218,
    xtip: 1,
  });

  const [bodyApproved, setBodyApproved] = useState({
    db_Id: dbId,
    Id: 0,
    Previl_id: data.Previl_id,
    Rez_id: data.RezId,
    Room_id: 0,
    Tbl_id: 0,
    Neresi: "H",
    Aciklama: "",
    Kulanici: "rmos",
    Yil_kodu: 0,
    Kumhrk_id: 0,
    Kayitno: "",
    Sira: 0,
  });

  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const handleAddButton = async () => {
    const lastbody = {
      ...bodyApproved,
      Aciklama: description,
    };
    console.log("Data is ", data);
    console.log(lastbody);

    try {
      const response = axios
        .post("https://frontapi.rmosweb.com/api/Notlar/Ekle", lastbody, {
          headers,
        })
        .then((response) => {
          console.log("POST request successful:", response.data);
        })
        .catch((error) => {
          console.error("Error during POST request:", error);
        });
    } catch (err) {
      console.log("An error occured in NotesModal.tsx");
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="extra-large"
        header={<h4 className="m-0 p-0">Notlar</h4>}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "0.5rem",
                justifyContent: "right",
              }}
            >
              <Button>Sil</Button>
              <Button onClick={handleAddButton}>Onay</Button>
            </div>
          </div>
        }
      >
        <div style={{ height: "50%", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <RadioGroup
              data={group_1}
              value={selectedValue}
              onChange={handleChange}
            />
            <RadioGroup
              data={group_2}
              value={selectedValue}
              onChange={handleChange}
            />
            <RadioGroup
              data={group_3}
              value={selectedValue}
              onChange={handleChange}
            />
            <RadioGroup
              data={group_4}
              value={selectedValue}
              onChange={handleChange}
            />
            <RadioGroup
              data={group_5}
              value={selectedValue}
              onChange={handleChange}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <p style={{ fontWeight: "bold", marginTop: "1rem" }}>Rez No:</p>
              <TextBox
                style={{ marginLeft: "1rem", width: "20%" }}
                disabled={true}
                value={data.RezId}
              />
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "1rem",
                  marginLeft: "0.5rem",
                }}
              >
                Nedenler:
              </p>
              <ComboBox
                style={{ marginLeft: "1rem", width: "40%" }}
                data={comboboxdata}
                value={reason}
                onChange={(e: any) => {
                  setReason(e.target.value);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <p style={{ fontWeight: "bold", marginTop: "1rem" }}>Üye:</p>
              <TextBox
                style={{ marginLeft: "1rem", width: "60%" }}
                disabled={true}
                value={data.Previl_id}
              />
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "1rem",
                  marginLeft: "0.5rem",
                }}
              >
                CRM:
              </p>
              <TextBox
                style={{ marginLeft: "1rem", width: "40%" }}
                value={crmtext}
                onChange={(e: any) => {
                  setCrmtext(e.target.value);
                }}
              />
            </div>
          </div>
          <TextArea
            rows={5}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value as string);
            }}
          />
          {/* <RmosGrid
            apiUrl="https://frontapi.rmosweb.com/api/Procedure/StpNotlar_Bul"
            gridKey="AddNoteCustomer"
            requestBody={body}
            height={"45vh"}
          /> */}
        </div>
      </Modal>
    </div>
  );
};

export default NotesModal;
