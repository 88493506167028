import React, { useEffect, useState } from "react";
import Modal from "../../Pages/Modal/Modal/Modal";
import UstMenuBar from "../Basliklar/UstMenuBar";
import { Input } from "reactstrap";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Card, CardHeader, Tab, Tabs } from "react-bootstrap";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import GeneralButton from "../Buttons/FloatActionButton/GeneralButton";
import {
  faBroom,
  faCamera,
  faCancel,
  faEye,
  faFileInvoiceDollar,
  faFilePdf,
  faHeartbeat,
  faPaperclip,
  faRefresh,
  faSave,
  faScaleUnbalanced,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Person } from "./PersonModel";
import axios from "axios";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const gradientStart = "#1E88E5"; // Sitenizin mavi tonu
const gradientEnd = "#2196F3";
const hoverGradientStart = "#1976D2";
const hoverGradientEnd = "#1E88E5";

interface BerbaerindekilerModalProps {
  masterId: number;
  isOpen: boolean;
  onClose: () => void;
}

const belgeTurleri = ["A", "E", "I", "K", "N", "P"];
const cinsiyetler = ["E", "K"];
const medeniHaller = ["B", "E"];

const BerbaerindekilerModal: React.FC<BerbaerindekilerModalProps> = ({
  masterId,
  isOpen,
  onClose,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabsData, setTabsData] = useState<Person[]>([]);
  const [fetchError, setFetchError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      handleOpenModal();
      fetchData(masterId);
    } else {
      handleCloseModal();
    }
  }, [isOpen, masterId]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    fetchData(masterId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  const fetchData = async (masterId: number) => {
    try {
      const dbId = localStorage.getItem("selectedDbId"); // Local Storage'den db_Id alınması
      const token = localStorage.getItem("token"); // Local Storage'den token alınması

      if (!dbId) {
        throw new Error("db_Id is not set in localStorage");
      }

      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Procedure/StpBeraber",
        {
          db_Id: parseInt(dbId, 10),
          xRez_Sirket: 1,
          xtip: 1,
          rez_Master_id: masterId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      console.log("Beraberindekiler API response:", data); // API cevabının konsola yazdırılması

      if (data.isSucceded) {
        const formattedData = data.value.map((person: Person) => ({
          ...person,
          dogum_Tarihi_1: person.dogum_Tarihi_1
            ? new Date(person.dogum_Tarihi_1)
            : null,
          gecerlilik_1: person.gecerlilik_1
            ? new Date(person.gecerlilik_1)
            : null,
          evlilik_1: person.evlilik_1 ? new Date(person.evlilik_1) : null,
        }));
        setTabsData(formattedData);
        setFetchError(null);
      } else {
        setFetchError("API request was not successful");
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        console.error("Fetch error:", error);
        setFetchError(
          error.response
            ? `Failed to fetch data: ${error.response.status} ${error.response.statusText}`
            : "Failed to fetch data"
        );
      } else {
        console.error("An unknown error occurred:", error);
      }
    }
  };

  const handleInputChange = <K extends keyof Person>(
    index: number,
    field: K,
    value: Person[K]
  ) => {
    const newTabsData = [...tabsData];
    newTabsData[index][field] = value;
    setTabsData(newTabsData);
  };

  const handleSave = async () => {
    const dbId = localStorage.getItem("selectedDbId"); // Local Storage'den db_Id alınması
    const token = localStorage.getItem("token"); // Local Storage'den token alınması
  
    if (!dbId) {
      console.error("db_Id is not set in localStorage");
      return;
    }
  
    try {
      for (const person of tabsData) {
        const payload = {
          db_Id: parseInt(dbId, 10),
          Id: person.previl_No_1 === 0 ? 0 : person.previl_No_1,
          Turu: person.belge_Turu_1,
          No: person.belge_Turu_1,
          Verilis: new Date().toISOString(),
          Dogum: person.dogum_Tarihi_1 ? person.dogum_Tarihi_1.toISOString() : null,
          Evlilik: person.evlilik_1 ? person.evlilik_1.toISOString() : null,
          Dogumyeri: person.dogumyeri_1,
          Baba: person.baba_1,
          Ana: person.ana_1,
          Adres1: person.kimlik_Adres1,
          Adres2: person.kimlik_Adres2,
          Adres3: person.kimlik_Adres3,
          Il: person.il_1,
          Ilce: person.ilce_1,
          Posta: person.kimlik_Posta,
          Medeni: person.meden_1,
          Cinsiyet: person.cinsiyet_1,
          Plakano: person.plaka_1,
          Tc: person.rez_Tc_NO_1,
          Soyad: person.soy_1,
          Ad: person.adi_1,
          Mail: person.mail_1,
          Tel: person.telefon_1,
          Ulke: person.ulke_1,
          Firma: person.kimlik_Firma,
          Adresilce: person.kimlik_Adresilce,
          Adresil: person.kimlik_Adresil,
          Vergidaire: person.kimlik_Vergidaire,
          Vergino: person.kimlik_Vergino,
          Kart: person.kart_1,
          Gecerlilik_tarihi: person.gecerlilik_1 ? person.gecerlilik_1.toISOString() : null,
          Fat_Adres1: person.fat_Adres1,
          Fat_Adres2: person.fat_Adres2,
          Fat_ilce: person.kimlik_Fat_ilce,
          Fat_il: person.kimlik_Fat_il,
          Fat_posta: person.kimlik_Fat_posta,
          Hes_kodu: person.hes_1,
        };
  
        const apiUrl = person.previl_No_1 === 0 
          ? "https://frontapi.rmosweb.com/api/Previl/Ekle" 
          : "https://frontapi.rmosweb.com/api/Previl/UpdatePrevil";
  
        console.log("Gönderilen istek:", {
          url: apiUrl,
          payload: payload,
        });
  
        const response = await axios.post(apiUrl, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
        console.log("API response:", response.data);
        toast.success(person.previl_No_1 === 0 ? "Ekleme başarılı!" : "Güncelleme başarılı!");
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        console.error("API error:", error);
        toast.error("İşlem başarısız oldu!");
      } else {
        console.error("An unknown error occurred:", error);
        toast.error("Bilinmeyen bir hata oluştu!");
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        size="large"
        header={<h2>Beraberindekiler</h2>}
        footer={
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-3"></div>
                <div className="col-md-3">
                  {" "}
                  <GeneralButton
                    text="OCR Getir"
                    icon={faTimesCircle}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={console.log}
                    width="100%"
                  />
                </div>
                <div className="col-md-3">
                  <GeneralButton
                    text="Kaydet"
                    icon={faSave}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={handleSave}
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </>
        }
      >
        <>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <UstMenuBar>
                  <div className="w-100 d-flex  justify-content-between align-items-center">
                    <div className="div w-10">Oda</div>
                    <div className="div w-20 ms-1">
                      <Input />
                    </div>

                    <div className="div w-10">Kayıt No</div>
                    <div className="div w-20 ms-1">
                      <Input />
                    </div>

                    <div className="text-start  w-20 ms-1">
                      <Checkbox label={"Jandarma Oto Kayıt"} color="red" />
                    </div>
                  </div>
                </UstMenuBar>
                {/* Buraya bir tabs açmak istiyorum */}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <Tabs
                        defaultActiveKey={tabsData[0]?.adi_1}
                        id="dynamic-tab-example"
                      >
                        {tabsData.map((person, index) => (
                          <Tab
                            eventKey={person.adi_1}
                            title={person.adi_1}
                            key={index}
                          >
                            <div className="container fluid">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Adı
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Adı"
                                      value={person.adi_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "adi_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Soyadı
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Soy Ad"
                                      value={person.soy_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "soy_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Ülkesi
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.ulke_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "ulke_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Cinsiyeti
                                    </div>
                                    <ComboBox
                                      className="w-60"
                                      data={cinsiyetler}
                                      value={person.cinsiyet_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "cinsiyet_1",
                                          e.target.value
                                        )
                                      }
                                    ></ComboBox>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Doğum Tarihi
                                    </div>
                                    <DatePicker
                                      className="w-60"
                                      format="dd/MM/yyyy"
                                      value={
                                        person.dogum_Tarihi_1
                                          ? new Date(person.dogum_Tarihi_1)
                                          : null
                                      }
                                      onChange={(e) => {
                                        const newTabsData = [...tabsData];
                                        newTabsData[index].dogum_Tarihi_1 =
                                          e.target.value;
                                        setTabsData(newTabsData);
                                      }}
                                    ></DatePicker>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Belge No
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.belge_No_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "belge_No_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Uyruğu
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.uyruk_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "uyruk_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Belge Türü
                                    </div>
                                    <ComboBox
                                      className="w-60"
                                      data={belgeTurleri}
                                      value={person.belge_Turu_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "belge_Turu_1",
                                          e.target.value
                                        )
                                      }
                                    ></ComboBox>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      TC Kimlik No
                                    </div>
                                    <Input
                                      className="w-50"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.rez_Tc_NO_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "rez_Tc_NO_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                    <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md w-10">
                                      <FontAwesomeIcon icon={faSearch} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Anne Adı
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.ana_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "ana_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Baba Adı
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.baba_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "baba_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Plaka
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.plaka_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "plaka_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Rezervasyon No
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.rez_No_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "rez_No_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      CRM No
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.previl_No_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "previl_No_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      İl
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.il_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "il_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      İlçe
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.ilce_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "ilce_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Geçerlilik Tarihi
                                    </div>
                                    <DatePicker
                                      className="w-60"
                                      format={"dd/MM/yyyy"}
                                      // placeholder="Aidat"
                                      value={
                                        person.gecerlilik_1
                                          ? new Date(person.gecerlilik_1)
                                          : null
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "gecerlilik_1",
                                          e.target.value
                                        )
                                      }
                                    ></DatePicker>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Evlilik Tarihi
                                    </div>
                                    <DatePicker
                                      className="w-60"
                                      format={"dd/MM/yyyy"}
                                      // placeholder="Aidat"
                                      value={
                                        person.evlilik_1
                                          ? new Date(person.evlilik_1)
                                          : null
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "evlilik_1",
                                          e.target.value
                                        )
                                      }
                                    ></DatePicker>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Kart No
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      value={person.kart_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "kart_1",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Doğum Yeri
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.dogumyeri_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "dogumyeri_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Hes Kodu
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.hes_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "hes_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Medeni Hali
                                    </div>
                                    <ComboBox
                                      className="w-60"
                                      data={medeniHaller}
                                      value={person.meden_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "meden_1",
                                          e.target.value
                                        )
                                      }
                                    ></ComboBox>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Telefon
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.telefon_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "telefon_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      E-Mail
                                    </div>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.mail_1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "mail_1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container-fluid mt-1">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-20 d-flex justify-content-start align-items-center">
                                      Adres 1
                                    </div>
                                    <Input
                                      className="w-80"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.kimlik_Adres1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "kimlik_Adres1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-20 d-flex justify-content-start align-items-center">
                                      Adres 2
                                    </div>
                                    <Input
                                      className="w-80"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.kimlik_Adres2}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "kimlik_Adres2",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-20 d-flex justify-content-start align-items-center">
                                      Adres 3
                                    </div>
                                    <Input
                                      className="w-80"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.kimlik_Adres3}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "kimlik_Adres3",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-20 d-flex justify-content-start align-items-center">
                                      Firma Bul
                                    </div>
                                    <Input
                                      className="w-30"
                                      type="text"
                                      // placeholder="Aidat"
                                    ></Input>
                                    <div className=" w-20 d-flex justify-content-start align-items-center">
                                      Firma Seç
                                    </div>
                                    <div className="div-w-30">
                                      <ComboBox />
                                    </div>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-20 d-flex justify-content-start align-items-center">
                                      Firma
                                    </div>
                                    <Input
                                      className="w-80"
                                      type="text"
                                      value={person.kimlik_Firma}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "kimlik_Firma",
                                          e.target.value
                                        )
                                      }
                                      // placeholder="Aidat"
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-20 d-flex justify-content-start align-items-center">
                                      Fatura Adres 1
                                    </div>
                                    <Input
                                      className="w-80"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.fat_Adres1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "fat_Adres1",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                  <div className="w-100 d-flex mt-1">
                                    <div className=" w-20 d-flex justify-content-start align-items-center">
                                      Fatura Adres 2
                                    </div>
                                    <Input
                                      className="w-80"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.fat_Adres2}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "fat_Adres2",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                </div>
                                <div className="container-fluid mt-1">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="w-100 d-flex mt-1">
                                        <div className=" w-20 d-flex justify-content-start align-items-center">
                                          İl
                                        </div>
                                        <Input
                                          className="w-80"
                                          type="text"
                                          // placeholder="Aidat"
                                          value={person.kimlik_Fat_il}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "kimlik_Fat_il",
                                              e.target.value
                                            )
                                          }
                                        ></Input>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="w-100 d-flex mt-1">
                                        <div className=" w-20 d-flex justify-content-start align-items-center">
                                          İlçe
                                        </div>
                                        <Input
                                          className="w-80"
                                          type="text"
                                          // placeholder="Aidat"
                                          value={person.kimlik_Fat_ilce}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "kimlik_Fat_ilce",
                                              e.target.value
                                            )
                                          }
                                        ></Input>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="w-100 d-flex mt-1">
                                        <div className="w-20 d-flex justify-content-start align-items-center">
                                          Posta
                                        </div>
                                        <Input
                                          className="w-80"
                                          type="text"
                                          // placeholder="Aidat"
                                          value={person.kimlik_Fat_posta}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "kimlik_Fat_posta",
                                              e.target.value
                                            )
                                          }
                                        ></Input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container-fluid mt-1">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="w-100 d-flex mt-1">
                                    <div className="w-20 d-flex justify-content-start align-items-center">
                                      Vergi D.
                                    </div>
                                    <Input
                                      className="w-80"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.kimlik_Vergidaire}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "kimlik_Vergidaire",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="w-100 d-flex mt-1">
                                    <div className="w-20 d-flex justify-content-start align-items-center">
                                      V. No
                                    </div>
                                    <Input
                                      className="w-80"
                                      type="text"
                                      // placeholder="Aidat"
                                      value={person.kimlik_Vergino}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "kimlik_Vergino",
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        ))}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};
export default BerbaerindekilerModal;
