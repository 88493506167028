import { TextBox } from "@progress/kendo-react-inputs";
import { Card } from "react-bootstrap";
import { TextArea } from "@progress/kendo-react-inputs";
import RmosGrid from "../../../../../Components/DinamikRmosGrid/RmosGrid";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import BlackListButtons from "../../Modal/BlacklListModal/BlackListButtons";
import "./BlackList.css";

import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisTitle,
  ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";

export interface BlackListProps {
  rowData?: any;
}

interface Agency {
  Id: number;
  Kodu: string;
  Adi: string;
  Sinif: string;
  db_Id: number;
}

const BlackList: React.FC<BlackListProps> = ({ rowData }) => {
  const token = localStorage.getItem("token");
  const { dbId } = useDbId();

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  const bodyforgrid = {
    db_Id: 9,
    Adi: "ALL?",
  };

  const [countries, setCountries] = useState<any[]>([]);
  const xmlbody = {
    db_Id: dbId,
    Sinif: "115",
    Kod: "",
    Tip: 888,
  };

  const [agencies, setAgencies] = useState<Agency[]>([]);
  const agencybody = {
    db_Id: dbId,
    Kod: "ALL",
  };

  useEffect(() => {
    try {
      const response = axios
        .post("https://frontapi.rmosweb.com/api/Kodlar/Getir_Kod", xmlbody, {
          headers,
        })
        .then((response) => {
          console.log("Countries must be here: ", response.data.value);
          setCountries(response.data.value);
        })
        .catch((error) => {
          console.error("Error during POST request:", error);
        });
    } catch (err) {
      console.log("This is self written error.");
    }

    try {
      const response = axios
        .post("https://frontapi.rmosweb.com/api/Acenta/Getir_Kod", agencybody, {
          headers,
        })
        .then((response) => {
          setAgencies(response.data.value);
        })
        .catch((error) => {
          console.error("Error during POST request:", error);
        });
    } catch (err) {
      console.log("This is self written error.");
    }
  }, []);

  const nameDefault = rowData && rowData.Adi ? rowData.Adi : "";

  const surnameDefault = rowData && rowData.Soyadı ? rowData.Soyadı : "";

  const birthDefault = new Date(
    rowData && rowData["Doğum Tarihi"] ? rowData["Doğum Tarihi"] : "2024"
  );

  const kimlikNoDefault =
    rowData && rowData["Kimlik No"] ? rowData["Kimlik No"] : "";

  const [name, setName] = useState<any>();
  const [surname, setSurname] = useState<any>();
  const [birth, setBirth] = useState<any>();
  const [identityNo, setIdentityNo] = useState<any>();
  const [tcNo, setTcNo] = useState<any>();
  const [countryXml, setCountryXml] = useState<any>();
  const [agency, setAgency] = useState<any>();
  const [description, setDescription] = useState<any>();

  const nameRef = useRef<any>();
  const surnameRef = useRef<any>();
  const birthRef = useRef<any>();
  const identityNoRef = useRef<any>();
  const tcNoRef = useRef<any>();
  const countryXmlRef = useRef<any>();
  const agencyRef = useRef<any>();
  const descriptionRef = useRef<any>();

  const approveHandler = async () => {
    const realbody = {
      db_Id: dbId,
      Id: 0,
      Adi: name,
      Soy: surname,
      Aciklama: description,
      Tcno: tcNo,
      Kimlik_no: identityNo,
      Dogum_tarihi: birth,
      Sistem_grubu: "",
      Otel_kodu: "001",
      Ulke_xml: countryXml,
      Acenta: agency,
    };

    try {
      const response = axios
        .post("https://frontapi.rmosweb.com/api/Kara/Ekle", realbody, {
          headers,
        })
        .then((response) => {
          console.log("POST request successful:", response.data);
        })
        .catch((error) => {
          console.error("Error during POST request:", error);
        });
    } catch (err) {
      console.log("This is self written error.");
    }

    cleanDataHandler();
  };

  const formRef = useRef<any>();
  const cleanDataHandler = () => {  
    formRef.current.reset();

    
    
  };

  //Fields must match with data names -> field = country.(name)
  const countrycolumns = [
    { field: "Ad", header: "Ülke", width: "150px" },
    { field: "Kod", header: "XML", width: "80px" },
  ];

  const agencycolumns = [
    { field: "Kodu", header: "Kodu", width: "100px" },
    { field: "Adi", header: "Adi", width: "350px" },
  ];

  //Printing statements

  const [heightprint, setHeightprint] = useState<any>();

  const printHandler = () => {
    window.print();
  };

  const [firstSeries, secondSeries, thirdSeries, fourthSeries] = [
    [100, 123, 234, 343],
    [120, 67, 231, 196],
    [45, 124, 189, 143],
    [87, 154, 210, 215],
  ];
  const categories = ["Q1", "Q2", "Q3", "Q4"];

  return (
    <form ref={formRef}>
      <div className="unprintable">
        <BlackListButtons
          approve={approveHandler}
          print={printHandler}
          clean={cleanDataHandler}
        />
        <div style={{ display: "flex" }}>
          <Card style={{ padding: "0.2rem", height: "25%", width: "50%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.25rem",
                marginTop: "0.25rem",
              }}
            >
              <h6
                style={{
                  fontWeight: "bold",
                  marginRight: "1rem",
                  paddingTop: "0.2rem",
                  width: "50%",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                Adı
              </h6>

              <TextBox defaultValue={nameDefault} ref={nameRef}  />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.25rem",
                justifyContent: "space-between",
              }}
            >
              <h6
                style={{
                  fontWeight: "bold",
                  marginRight: "1rem",
                  paddingTop: "0.5rem",
                  width: "50%",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                Soyadı
              </h6>
              <TextBox defaultValue={surnameDefault} ref={surnameRef} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.25rem",
              }}
            >
              <h6
                style={{
                  fontWeight: "bold",
                  marginRight: "1rem",
                  paddingTop: "0.5rem",
                  width: "50%",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                Doğum Tarihi
              </h6>
              <DatePicker defaultValue={birthDefault} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.25rem",
              }}
            >
              <h6
                style={{
                  fontWeight: "bold",
                  marginRight: "1rem",
                  paddingTop: "0.5rem",
                  width: "50%",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                Kimlik No
              </h6>
              <TextBox defaultValue={kimlikNoDefault} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.25rem",
              }}
            >
              <h6
                style={{
                  fontWeight: "bold",
                  marginRight: "1rem",
                  paddingTop: "0.5rem",
                  width: "50%",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                TC No
              </h6>
              <TextBox value={tcNo} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.25rem",
              }}
            >
              <h6
                style={{
                  fontWeight: "bold",
                  marginRight: "1rem",
                  paddingTop: "0.5rem",
                  width: "50%",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                Ülke Xml
              </h6>
              <MultiColumnComboBox
                columns={countrycolumns}
                data={countries.map((country) => ({
                  Ad: country.Ad,
                  Kod: country.Kod,
                }))}
                textField={"Ad"}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.25rem",
              }}
            >
              <h6
                style={{
                  fontWeight: "bold",
                  marginRight: "1rem",
                  paddingTop: "0.5rem",
                  width: "50%",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                Acente
              </h6>
              <MultiColumnComboBox
                columns={agencycolumns}
                data={agencies
                  .filter((agency) => agency.Adi != null)
                  .map((agency) => ({
                    Kodu: agency.Kodu,
                    Adi: agency.Adi,
                  }))}
                textField={"Adi"}
              />
            </div>
          </Card>
          <div style={{ width: "100%" }}>
            <TextArea rows={11} value={description} />
          </div>
        </div>
      </div>

      <div className="printable">
        <div className="printable-menu">
          <h2 style={{ verticalAlign: "sup" }}>Blacklist Raporu</h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <h6>Otel Adı: </h6>
                <h6>Seven For Life </h6>
              </div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <h6>Kullanıcı:</h6>
                <h6>Eren </h6>
              </div>
            </div>

            <div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <h6>Tarih: </h6>
                <h6>30.06.2024</h6>
              </div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <h6>Zaman: </h6>
                <h6>19.00 </h6>
              </div>
            </div>
          </div>
        </div>

        <RmosGrid
          apiUrl="https://frontapi.rmosweb.com/api/Kara/Getir_Kod"
          gridKey="BlackListFetch"
          requestBody={bodyforgrid}
          height={"45vh"}
        />

        <Card className="printable-charts">
          <Chart renderAs="svg">
            <ChartTitle text="Units sold" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem categories={categories}>
                <ChartCategoryAxisTitle text="Months" />
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartSeries>
              <ChartSeriesItem
                type="bar"
                gap={2}
                spacing={0.25}
                data={firstSeries}
              />

              <ChartSeriesItem type="bar" data={secondSeries} />
              <ChartSeriesItem type="bar" data={thirdSeries} />
              <ChartSeriesItem type="bar" data={fourthSeries} />
            </ChartSeries>
          </Chart>
        </Card>
      </div>
    </form>
  );
};

export default BlackList;
