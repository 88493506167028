import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.scss';
import logo from '../../Images/RmosWebLogo6.svg';
interface LoginProps {
  setIsAuthenticated: (value: boolean) => void;
}

function Login({ setIsAuthenticated }: LoginProps) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const Navigate = useNavigate();

  const getToken = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/security/createToken`, {
        userName,
        password,
      });
      return response.data;
    } catch (error) {
      console.error('Error in getting token:', error);
      throw error;
    }
  };

  const loginUser = async (token: any) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Users/LoginByToken`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      console.log('Login response:', response.data);
      localStorage.setItem('token', token);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('user', JSON.stringify(response.data));
      setIsAuthenticated(true);
  
      // Token ve isAuthenticated yerel depolamada kaydedildiğinden emin olun
      if (localStorage.getItem('token') && localStorage.getItem('isAuthenticated') === 'true') {
        // Yerel depolamada gerekli veriler varsa, kullanıcıyı anasayfaya yönlendir
        Navigate('/Dashboard'); // İstenilen yönlendirme adresi buraya girilebilir
      }
    } catch (error) {
      console.error('Login failed:', error);
      // Hata işleme
    }
  };
  useEffect(() => {
    // Yerel depolamadaki kimlik doğrulama durumunu kontrol edin
    const storedAuth = localStorage.getItem('isAuthenticated');
    if (storedAuth === 'true'&& localStorage.getItem('token')) {
      Navigate("/Dashboard");
    }
  }, []);
  

  const handleLogin = async () => {
    try {
      const token = await getToken();
      console.log('Token:', token);
      if (token) {
        await loginUser(token);
      }
    } catch (error) {
      // Hata işleme
    }
  };

  return (
    <div className="login-container">
      <img src={logo} className='mb-5'  alt="" />
      {/* <h2 className="login-title">Login</h2> */}
      <input
        className="login-input"
        type="text"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        placeholder="Username"
      />
      <input
        className="login-input"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button className="login-button" onClick={handleLogin}>Giriş yap</button>
    </div>
  );
}

export default Login;
