import BackDeneme from "../../../BackDeneme/BackDeneme";
import FrontDeneme from "../../FrontDeneme/FrontDeneme";
import HesapPlani from "../../Menuler/BackOffice/Muhasebe/HesapPlani/HesapPlani";
import { GelismisPuanlamaAyarlaritab } from "../../Menuler/Loyalty/LoyaltyTanimlamalar/LoyaltyOtelPuanlamaIslemleri/GelismisPuanlamaAyarlari";
import LoyaltyTanimlamalar from "../../Menuler/Loyalty/LoyaltyTanimlamalar/LoyaltyTanimlamalar";
import MisafirIslemleriMenu from "../../Menuler/Loyalty/MisafirIslemleri/MisafirIslemleriMenu";
import KontratGirisleri from "../../Menuler/Onburo/Satis Ve Acenta Islemleri/Acenta Islemleri/Kontrat Girisleri/KontratGirisleri";
import Aktiviteler from "../../Menuler/Onburo/Satis Ve Acenta Islemleri/Acenta Islemleri/Aktiviteler/Aktiviteler";
import Kartex from "../../Menuler/Onburo/Satis Ve Acenta Islemleri/Satış Raporları/Kartex";
import RezervasyonGirisi from "../../Menuler/Onburo/Rezervasyon/Rezervasyon Işlemleri/Rezarvasyon Girişi/RezervasyonGirisi";
import MisafirListesi from "../../Menuler/Onburo/Resepsiyon/Misafir Listesi/MisafirListesi";
import RoomRack from "../../Menuler/Onburo/Resepsiyon/Room Rack/RoomRack";
import GunlukIslemler from "../../Menuler/Onburo/Resepsiyon/Gunluk Islemler/GunlukIslemler";
import FrontDeneme2 from "../../FrontDeneme/FrontDeneme2";
import WalkIn from "../../Menuler/Onburo/Resepsiyon/Walk In/WalkIn";
import RezervasyonRaporu1 from "../../Menuler/Onburo/Rezervasyon/Rezervasyon Işlemleri/Rezervasyon Raporu/RezervasyonRaporu1";
import HesapBakma from "../../Menuler/Onburo/On Kasa/On Kasa Islemleri/Hesap Bakma/HesapBakma";
import RoomPosting from "../../Menuler/Onburo/Geceİslemleri/RoomPosting/RoomPosting";
import BlackList from "../../Menuler/Onburo/Resepsiyon/BlackList/BlackList";
import QuotaEntrance from "../../Menuler/Onburo/Rezervasyon/Kontenjan Giriş/QuotaEntrance";
import OdaTanimlama from "../../Menuler/Onburo/Tanımlamalar/OdaTanimlama";
import FaturaKontrolSistemi from "../../Menuler/Onburo/On Kasa/Fatura Islemleri/FaturaKontrolSistemi";
import FolioFaturasi from "../../Menuler/Onburo/On Kasa/Fatura İşlemleri/FolioFaturasi/FolioFaturasi";

export const MenuItemsComponents = {
  FrontComponent: () => <FrontDeneme />,
  BackComponent: () => <BackDeneme />,
  PosComponent: () => <FrontDeneme />,
  BackHesapPlani: () => <HesapPlani />,
  LoyaltyTanimlamalar: () => <LoyaltyTanimlamalar />,
  MisafirIslemleri: () => <MisafirIslemleriMenu />,
  GelismisPuanlamaAyari: () => <GelismisPuanlamaAyarlaritab />,
  KontratGirisleri: () => <KontratGirisleri />,
  Aktiviteler: () => <Aktiviteler />,
  Kartex: () => <Kartex />,
  RezervasyonGirisi: () => <RezervasyonGirisi />,
  MisafirListesi: () => <MisafirListesi />,
  RoomRack: () => <RoomRack />,
  GunlukIslemler: () => <GunlukIslemler />,
  FrontDeneme2: () => <FrontDeneme2 />,
  WalkIn: () => <WalkIn />,
  RezervasyonRaporu1: () => <RezervasyonRaporu1 />,
  HesapBakma: () => <HesapBakma />,
  RoomPosting: () => <RoomPosting />,
  BlackList: () => <BlackList />,
  KontenjanGiris: () => <QuotaEntrance />,
  OdaTanimlama: () => <OdaTanimlama />,
  FaturaKontrolSistemi: () => <FaturaKontrolSistemi />,
  FolioFaturasi: () => <FolioFaturasi />,
};
