// FirsatEkleForm.tsx
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  DropdownButton,
  Dropdown,
  Tabs,
  Tab,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import { LoyaltyTanimlamalarApi } from "../LoyaltyTanimlamalarApi";
import useAlert from "../../../../Modal/Alert/useAlert";
import e from "express";
import { MdDelete, MdEditNotifications } from "react-icons/md";
import useContextMenu from "../../../../Modal/SagTikMenu/ContextMenu";
import Modal from "../../../../Modal/Modal/Modal";
import FirsatIslemleri1 from "./FirsatIslemleri1";

interface Firsat {
  firsatID: number;
  otelGuid: string;
  firsatAdi: string;
  firsatAciklamasi: string;
  firsatPuani: number;
  firsatKartAdi: string;
}
const LoyaltyFirsatTabPanel: React.FC<{ otelGuid: string }> = ({
  otelGuid,
}) => {
  const [activeTab, setActiveTab] = useState<string>("FirsatDuzenle1");

  return (
    <Tabs activeKey={activeTab} onSelect={(k) => k && setActiveTab(k)}>
      {/* <Tab eventKey="FirsatEkle" title="Fırsat Ekle">
        <FirsatIslemleriForm otelGuid={otelGuid} />
      </Tab>
      <Tab eventKey="FirsatDuzenle" title="Fırsat Düzenle">
        <FirsatListesi otelGuid={otelGuid} />
      </Tab> */}
      <Tab eventKey="FirsatDuzenle1" title="Fırsat İşlemleri">
        <FirsatIslemleri1 otelGuid={otelGuid} />
      </Tab>
    </Tabs>
  );
};
interface Kart {
  kartAdi: string;
  kartID: number;
}
const FirsatIslemleriForm: React.FC<{ otelGuid: string }> = ({ otelGuid }) => {
  const [kartListesi, setKartListesi] = useState<Kart[]>([]);
  const [selectedKart, setSelectedKart] = useState("");
  const [firsatAdi, setFirsatAdi] = useState("");
  const [firsatAciklamasi, setFirsatAciklamasi] = useState("");
  const [firsatPuani, setFirsatPuani] = useState("");
  const { showAlert, AlertComponents } = useAlert();

  useEffect(() => {
    getKartlar();
  }, [otelGuid]);

  // async function getKartlar() {
  //   console.log("Kartlar listeleniyor...");
  //   try {
  //     const response = await LoyaltyTanimlamalarApi.LoyaltyKartIslemleri({
  //       otelGuid,
  //       islem: "listele",
  //     });
  //     setKartListesi(response.data || []);
  //   } catch (error) {
  //     showAlert("error", "Kart listesi alınırken bir hata oluştu.");
  //   }
  // }
  const getKartlar = async () => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyKartIslemleri({
        otelGuid,
        islem: "listele",
      });
      setKartListesi(response.data);
    } catch (error) {
      console.error("Kartlar listelenirken bir hata oluştu", error);
      showAlert("error", "Kartlar listelenirken bir hata oluştu");
    }
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyFirsatIslemleri({
        otelGuid,
        firsatAdi,
        firsatAciklamasi,
        firsatPuani: Number(firsatPuani),
        firsatKartAdi: selectedKart,
        islem: "ekle",
      });
      if (response.status === 200) {
        showAlert("success", "Fırsat ekleme işlemi başarılı");
        setFirsatAdi("");
        setFirsatAciklamasi("");
        setFirsatPuani("");
      } else {
        showAlert("error", "Fırsat ekleme işlemi başarısız");
      }
    } catch (error) {
      showAlert("error", "Fırsat ekleme işlemi sırasında bir hata oluştu.");
    }
  };

  return (
    <>
      {AlertComponents}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>Kart Seçimi</FormLabel>
          <DropdownButton
            id="dropdown-item-button"
            title={selectedKart || "Kart Seçin"}
            onSelect={(e: string | null) => e && setSelectedKart(e)}
          >
            {kartListesi.map((kart, index) => (
              <Dropdown.Item key={index} eventKey={kart.kartAdi}>
                {kart.kartAdi}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </FormGroup>
        <FormGroup>
          <FormLabel>Fırsat Adı</FormLabel>
          <FormControl
            type="text"
            value={firsatAdi}
            onChange={(e) => setFirsatAdi(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Fırsat Açıklaması</FormLabel>
          <FormControl
            type="text"
            value={firsatAciklamasi}
            onChange={(e) => setFirsatAciklamasi(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Fırsat Puanı</FormLabel>
          <FormControl
            type="number"
            value={firsatPuani}
            onChange={(e) => setFirsatPuani(e.target.value)}
          />
        </FormGroup>
        <Button variant="primary" type="submit">
          Fırsat Ekle
        </Button>
      </Form>
    </>
  );
};

const FirsatListesi: React.FC<{ otelGuid: string }> = ({ otelGuid }) => {
  const [kartListesi, setKartListesi] = useState<any[]>([]);
  const [firsatListesi, setFirsatListesi] = useState<Firsat[]>([]);
  const [gruplanmisFirsatlar, setGruplanmisFirsatlar] = useState<
    Record<string, Firsat[]>
  >({});
  const { showAlert, AlertComponents } = useAlert();
  const [selectedFirsatId, setSelectedFirsatId] = useState<number | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    firsatID: 0,
    firsatAdi: "",
    firsatAciklamasi: "",
    firsatPuani: 0,
    firsatKartAdi: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteFirsatID, setDeleteFirsatID] = useState(null);
  const [selectedKart, setSelectedKart] = useState<string>(""); // Kart tipi güncelleme için kullanılacak
  const menuItems = [
    {
      label: "Düzenle",
      icon: <MdEditNotifications />,
      onClick: () => {
        openEditModalWithFirsatData(editFormData); // Ensure you have the selectedFirsatData available
      },
    },
    {
      label: "Sil",
      icon: <MdDelete />,
      onClick: () => openDeleteModal(selectedFirsatId, editFormData),
    },
    // Diğer menü öğeleri...
  ];
  const { handleContextMenu, handleClick, renderContextMenu } =
    useContextMenu(menuItems);

  useEffect(() => {
    if (kartListesi.length > 0) {
      setSelectedKart(kartListesi[0].kartID);
    }
  }, [kartListesi]); // Bağımlılıklar dizisinde kartlar var, bu yüzden kartlar değiştiğinde bu useEffect çalışır
  useEffect(() => {
    fetchFirsatlar();
    getKartlar();
  }, [otelGuid]);

  useEffect(() => {
    const gruplar = firsatListesi.reduce(
      (acc: Record<string, Firsat[]>, firsat) => {
        if (!acc[firsat.firsatKartAdi]) {
          acc[firsat.firsatKartAdi] = [];
        }
        acc[firsat.firsatKartAdi].push(firsat);
        return acc;
      },
      {}
    );
    setGruplanmisFirsatlar(gruplar);
    console.log("Gruplanmış fırsatlar:", gruplar);
  }, [firsatListesi]);
  const getKartlar = async () => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyKartIslemleri({
        otelGuid,
        islem: "listele",
      });
      setKartListesi(response.data);
    } catch (error) {
      console.error("Kartlar listelenirken bir hata oluştu", error);
      showAlert("error", "Kartlar listelenirken bir hata oluştu");
    }
  };
  const fetchFirsatlar = async () => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyFirsatIslemleri({
        otelGuid,
        islem: "listele",
      });
      setFirsatListesi(response.data || []);
      console.log("Fırsatlar:", response.data);
    } catch (error) {
      showAlert("error", "Fırsat listesi alınırken bir hata oluştu.");
    }
  };
  const handleContextMenuWithId =
    (firsatId: number) => (event: React.MouseEvent) => {
      event.preventDefault(); // Context menüsünün tarayıcı tarafından otomatik olarak açılmasını engelleyin.
      const selectedFirsatData = firsatListesi.find(
        (firsat) => firsat.firsatID === firsatId
      );
      if (selectedFirsatData) {
        setSelectedFirsatId(firsatId); // Seçilen fırsatın ID'sini kaydedin
        setEditFormData({
          // Seçilen fırsatın bilgilerini edit form data'sına aktarın
          firsatID: selectedFirsatData.firsatID,
          firsatAdi: selectedFirsatData.firsatAdi,
          firsatAciklamasi: selectedFirsatData.firsatAciklamasi,
          firsatPuani: selectedFirsatData.firsatPuani,
          firsatKartAdi: selectedFirsatData.firsatKartAdi,
        });
        // handleContextMenu(event); // Orijinal context menü işleyicisini çağırın
      }
    };
  const openEditModalWithFirsatData = (firsatData: any) => {
    setEditFormData(firsatData);
    setIsEditModalOpen(true);
  };
  const openDeleteModal = (firsatID: any, firsatdata: any) => {
    setDeleteFirsatID(firsatID);
    setEditFormData(firsatdata);
    setIsDeleteModalOpen(true);
  };
  const updateFirsat = async () => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyFirsatIslemleri({
        firsatID: editFormData.firsatID,
        firsatAdi: editFormData.firsatAdi,
        firsatAciklamasi: editFormData.firsatAciklamasi,
        firsatPuani: editFormData.firsatPuani,
        firsatKartAdi: editFormData.firsatKartAdi,
        islem: "guncelle",
      });
      if (response.status === 200) {
        showAlert("success", "Fırsat güncelleme işlemi başarılı");
        setIsEditModalOpen(false);
        fetchFirsatlar();
      } else {
        showAlert("error", "Fırsat güncelleme işlemi başarısız");
      }
    } catch (error) {
      showAlert("error", "Fırsat güncelleme işlemi sırasında bir hata oluştu.");
    }
  };
  const deleteFirsat = async () => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyFirsatIslemleri({
        firsatID: editFormData.firsatID,
        islem: "sil",
      });
      if (response.status === 200) {
        showAlert("success", "Fırsat silme işlemi başarılı");
        setIsDeleteModalOpen(false);
        fetchFirsatlar();
      } else {
        showAlert("error", "Fırsat silme işlemi başarısız");
      }
    } catch (error) {
      showAlert("error", "Fırsat silme işlemi sırasında bir hata oluştu.");
    }
  };
  return (
    <>
      {AlertComponents}
      <Accordion defaultActiveKey="">
        {Object.entries(gruplanmisFirsatlar).map(
          ([kartAdi, firsatlar], index) => (
            <Accordion.Item eventKey={String(index)} key={index}>
              <Accordion.Header>{kartAdi}</Accordion.Header>
              <Accordion.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Fırsat Adı</th>
                      <th>Fırsat Açıklaması</th>
                      <th>Fırsat Puanı</th>
                    </tr>
                  </thead>
                  <tbody>
                    {firsatlar.map((firsat) => (
                      <tr
                        key={firsat.firsatID}
                        onContextMenu={handleContextMenuWithId(firsat.firsatID)}
                      >
                        <td>{firsat.firsatID}</td>
                        <td>{firsat.firsatAdi}</td>
                        <td>{firsat.firsatAciklamasi}</td>
                        <td>{firsat.firsatPuani}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          )
        )}
      </Accordion>
      {renderContextMenu()}
      {isEditModalOpen && (
        <Modal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          size="medium" 
          header={<div>Edit Opportunity</div>}
          footer={
            <div className="modal-footer">
              {" "}
              <button
                type="button"
                className="btn btn-primary"
                onClick={updateFirsat}
              >
                Düzenle
              </button>
              {/* <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setIsEditModalOpen(false)}
              >
                Cancel
              </button> */}
            </div>
          }
        >
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label htmlFor="firsatAdi">Opportunity Name:</label>
                <input
                  type="text"
                  className="form-control" // Bootstrap form-control sınıfı
                  id="firsatAdi"
                  value={editFormData.firsatAdi}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      firsatAdi: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="firsatAciklamasi">Description:</label>
                <textarea
                  className="form-control" // Bootstrap form-control sınıfı
                  id="firsatAciklamasi"
                  value={editFormData.firsatAciklamasi}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      firsatAciklamasi: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="firsatPuani">Points:</label>
                <input
                  type="number"
                  className="form-control" // Bootstrap form-control sınıfı
                  id="firsatPuani"
                  value={editFormData.firsatPuani}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      firsatPuani: Number(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="firsatKartAdi">Card Name:</label>
                <select
                  className="form-control" // Bootstrap form-control sınıfı
                  id="firsatKartAdi"
                  value={editFormData.firsatKartAdi}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      firsatKartAdi: e.target.value,
                    })
                  }
                >
                  {kartListesi.map((kart, index) => (
                    <option key={index} value={kart.kartAdi}>
                      {kart.kartAdi}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          size="medium" // Bootstrap'te küçük modal boyutu için 'sm' kullanılır
          header={<div>Delete Opportunity</div>}
          footer={
            <div className="modal-footer">
              {" "}
              // Bootstrap modal footer sınıfı
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteFirsat}
              >
                Confirm Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          }
        >
          <div className="modal-body">
            <p>Are you sure you want to delete this opportunity?</p>
            <p>Opportunity ID: {deleteFirsatID}</p>
            <p>Opportunity Name: {editFormData.firsatAdi}</p>
          </div>
        </Modal>
      )}
    </>
  );
};
export default LoyaltyFirsatTabPanel;
