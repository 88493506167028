import React, { useEffect } from "react";
import { MisafirIslemeriApi } from "../MisafirIslemleriApi/MisafirIslemleriApi";
import DynamicTable from "../../../../../Components/DinamikTable/DynamicTable";

const FirsatKullanimListesi: React.FC<{ otelGuid: string }> = ({
  otelGuid,
}) => {
  const [firsatKullanimListesi, setFirsatKullanimListesi] = React.useState<
    any[]
  >([]);

  const getFirsatKullanimListesi = async () => {
    try {
      const response = await MisafirIslemeriApi.LoyaltyMisafirIslemleri({
        islem: "firsatkullanimlistele",
        otelGuid: otelGuid,
      });
      if (response.status === 200) {
        const filteredData = response.data.map((guest: any) => {
          // Tarih ve saat değerlerini kontrol et
          const tanimlanmaTarihiStr = guest.tanımlanmaTarihi
            ? new Date(guest.tanımlanmaTarihi).toLocaleDateString("tr-TR")
            : "";
          const kullanimTarihiStr = guest.kullanımTarihi
            ? new Date(guest.kullanımTarihi).toLocaleDateString("tr-TR") +
              " " +
              new Date(guest.kullanımTarihi).toLocaleTimeString("tr-TR")
            : "";

          return {
            misafirID: guest.firsatID,
            misafirAdi: guest.misafirAdi + " " + guest.misafirSoyadi,
            misafirFirsat: guest.firsatAdi,
            misafirFirsatTipi: guest.kullanımDetayı,
            misafirTanimlanmaTarihi: tanimlanmaTarihiStr,
            misafirKullanimTarihi: kullanimTarihiStr,
          };
        });
        setFirsatKullanimListesi(filteredData);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFirsatKullanimListesi();
    console.log("FirsatKullanimListesi otelguid değişiklik algılandı ");
  }, [otelGuid]);
  const columnNamesMap1 = {
    misafirID: "#",
    misafirAdi: "Misafir",
    misafirFirsat: "Firsat",
    misafirFirsatTipi: "Firsat Tipi",
    misafirTanimlanmaTarihi: " Tanımlama Tarihi",
    misafirKullanimTarihi: " Kullanım Tarihi",
  };
  return (
    // <div>
    //     <table>
    //         <thead>
    //             <tr>
    //                 <th>Misafir</th>
    //                 <th>Firsat</th>
    //                 <th>Firsat Tipi</th>
    //                 <th>Tanımlama Tarihi</th>
    //                 <th>Kullanım Tarihi</th>
    //             </tr>
    //         </thead>
    //         <tbody>
    //             {firsatKullanimListesi.map((firsat, index) => (
    //                 <tr key={index}>
    //                     <td>{firsat.misafirAdi} {firsat.misafirSoyadi}</td>
    //                     <td>{firsat.firsatAdi}</td>
    //                     <td>{firsat.kullanımDetayı}</td>
    //                     <td>{firsat.tanımlanmaTarihi}</td>
    //                     <td>{firsat.kullanımTarihi}</td>
    //                 </tr>
    //             ))}
    //         </tbody>
    //     </table>
    // </div>
    <>
      <div className="container-fluid">
        <div className="row p-2">
          <div className="col-12 r-shadow-sm rounded">
            <DynamicTable
              data={firsatKullanimListesi}
              columnNamesMap={columnNamesMap1}
            //   hiddenColumns={["misafirID"]} // Örnek olarak bu sütunlar başlangıçta görünür olacak
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default FirsatKullanimListesi;
