// LoyaltyTanimlamalarApi.tsx
import axios from "axios";

const apiUrl = process.env.REACT_APP_LOYALTY_URL;

interface MisafirIslemleriRequest {
  misafirID?: number;
  otelGuid?: string;
  misafirMail?: string;
  misafirDTarihi?: string;
  misafirAdi?: string;
  misafirSoyadi?: string;
  misafirGelisSayisi?: number;
  misafirGecelemeSayisi?: number;
  misafirPuan?: number;
  misafirKarti?: string;
  onay?: boolean;
  islem:
    | "listele"
    | "onaybekleyen"
    | "onayla"
    | "firsattanimla"
    | "firsattanimlamaiptal"
    | "puanguncelle"
    | "firsatkullanimlistele"
    | "kartguncelle";
  firsatId?: number | 0;
  hrkID?: number;
  kartID?: number;
  islemTarihi?: string | "";
}
export const MisafirIslemeriApi = {
  LoyaltyMisafirIslemleri: async (data: MisafirIslemleriRequest) => {
    const { islem, firsatId, ...requestData } = data; // Destructure the islem property and capture the rest
    const firsatIdValue = firsatId !== undefined ? firsatId : 0; // If firsatId is undefined, set it to 0
    const url = `${apiUrl}/api/MisafirIslemleri?islem=${data.islem}&firsatId=${firsatIdValue}`;
    let response;
    switch (data.islem) {
      case "listele":
        // Assuming listele only needs otelGuid, and misafirID as a filter (if present)
        response = await axios.post(url, { otelGuid: data.otelGuid });
        break;
      case "onaybekleyen":
        // Assuming this action might require specific request structure or filters
        response = await axios.post(url, requestData);
        break;
      case "onayla":
        // Assuming onayla might need the entire request data
        response = await axios.post(url, requestData);
        break;
      case "firsattanimla":
        // Assuming firsattanimla might need the entire request data
        response = await axios.post(url, {
          otelguid: data.otelGuid,
          misafirID: data.misafirID,
          islemTarihi: data.islemTarihi,
        });
        break;
      case "firsattanimlamaiptal":
        response = await axios.post(url, {
          hrkID: data.hrkID,
          misafirID: data.misafirID,
        });
        break;
      case "puanguncelle":
        response = await axios.post(url, {
          misafirID: data.misafirID,
          otelGuid: data.otelGuid,
          misafirKarti: data.misafirKarti,
        });
        break;
      case "firsatkullanimlistele":
        response = await axios.post(url, {
          otelguid: data.otelGuid,
        });
        break;
      case "kartguncelle":
        response = await axios.post(url, {
          misafirID: data.misafirID,
          misafirKarti: data.misafirKarti,
          otelGuid: data.otelGuid,
        });
        break;
      default:
        throw new Error("Geçersiz işlem türü");
    }
    return { status: response.status, data: response.data };
  },
};
