// Örnek Prototip

import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { RadioButton } from "@progress/kendo-react-inputs";
import {
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { Tab, Tabs } from "react-bootstrap";

const initialDataState = {
  take: 10,
  skip: 0,
  group: [],
  filter: null,
};
const Aktiviteler: React.FC = () => {
  const [data, setData] = React.useState<any>(AktivitelerData);
  const [value, setValue] = React.useState(new Date());
  const [dataState, setDataState] = React.useState<any>(initialDataState);
  const [value1, setValue1] = React.useState<string[]>(["Armas", "Hane"]);

  const onGridDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const onChange = (event: MultiSelectChangeEvent) => {
    setValue1([...event.value]);
  };
  const changeDate = (event: DatePickerChangeEvent) => {
    if (event.value !== null) {
      setValue(event.value);
    }
  };

  const processedData = process(data, dataState);
  

  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-12 bg-white shadow-lg rounded p-3">
            <div className="w-100">
              <div className="row">
                <div className="col-md-3 ">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center">
                      <label className="w-50 text-start">
                        Başlangıç Tarihi:
                      </label>
                      <DatePicker value={value} onChange={changeDate} />
                    </div>
                    <div className="d-flex align-items-center ">
                      <label className="w-50 text-start">Bitiş Tarihi:</label>
                      <DatePicker value={value} onChange={changeDate} />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center">
                      <label className="w-50 text-start">Acenta</label>
                      <MultiSelect
                        data={sports}
                        onChange={onChange}
                        value={value1}
                        placeholder="Please select ..."
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <label className="w-50 text-start">Departman</label>
                      <MultiSelect
                        data={sports}
                        onChange={onChange}
                        value={value1}
                        placeholder="Please select ..."
                      />
                    </div>

                  </div>
                </div>
                <div className="col-md-2">
                  <div className="border">
                    <div className="w-100">
                      <p>filtre</p>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start  justify-contet-start">
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Sadece Kontenjan Olanlar Çıksın"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="border">
                    <div className="w-100">
                      <p>Sonuç Durum</p>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start  justify-contet-start">
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Devam Ediyor"
                        />
                      </div>
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Sonuçlandı"
                        />
                      </div>

                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Hepsi"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="border">
                    <div className="w-100">
                      <p>Tipi</p>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start  justify-contet-start">
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Aktivite"
                        />
                      </div>
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Görev"
                        />
                      </div>

                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Hepsi"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 bg-white shadow-lg rounded p-3 mt-4">
            <Tabs defaultActiveKey="Kartex" id="uncontrolled-tab-example">
              <Tab eventKey="Kartex" title="Kartex Detay">
                <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="KTO" title="Kartex Özet">
                <Grid
                  data={process(AktivitelerData1, dataState)}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="KTR" title="Kontenjan Rapoları">
                <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="KTG" title="Kartex Grafikler">
                <Grid
                  data={process(AktivitelerData1, dataState)}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="ATFP" title="Acenta Tablo Fiyat Performansı">
                <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="YGAD" title="Yıllık Gerçekleşen Acenta Dağılımı">
                <Grid
                  data={process(AktivitelerData1, dataState)}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
export default Aktiviteler;
const sports = [
  "Armas Luxury Resort",
  "Hane Garden Hotel",
  "Kervansaray Hotel",
  "Kemer Holiday Club",
  "Kemer Resort Hotel",
  "Turan Prince World",
  "Golden Lotus Hotel",
];
const AktivitelerData: any[] = [
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
];


const AktivitelerData1: any[] = [
    {
        AktiviteAdi: "aktivite",
        FirmaAdi: "Acenta Adı",
        Kontak: "pazar",
        Iletişim: "grup",
        BaslangicTarihi: "konaklama",
        BİtisTarihi: "kur",
        PersonelAdsoyad: "tarih",
      },
      {
        AktiviteAdi: "aktivite",
        FirmaAdi: "Acenta Adı",
        Kontak: "pazar",
        Iletişim: "grup",
        BaslangicTarihi: "konaklama",
        BİtisTarihi: "kur",
        PersonelAdsoyad: "tarih",
      },
      {
        AktiviteAdi: "aktivite",
        FirmaAdi: "Acenta Adı",
        Kontak: "pazar",
        Iletişim: "grup",
        BaslangicTarihi: "konaklama",
        BİtisTarihi: "kur",
        PersonelAdsoyad: "tarih",
      },
      {
        AktiviteAdi: "aktivite",
        FirmaAdi: "Acenta Adı",
        Kontak: "pazar",
        Iletişim: "grup",
        BaslangicTarihi: "konaklama",
        BİtisTarihi: "kur",
        PersonelAdsoyad: "tarih",
      },
      {
        AktiviteAdi: "aktivite",
        FirmaAdi: "Acenta Adı",
        Kontak: "pazar",
        Iletişim: "grup",
        BaslangicTarihi: "konaklama",
        BİtisTarihi: "kur",
        PersonelAdsoyad: "tarih",
      },
      {
        AktiviteAdi: "aktivite",
        FirmaAdi: "Acenta Adı",
        Kontak: "pazar",
        Iletişim: "grup",
        BaslangicTarihi: "konaklama",
        BİtisTarihi: "kur",
        PersonelAdsoyad: "tarih",
      },
      {
        AktiviteAdi: "aktivite",
        FirmaAdi: "Acenta Adı",
        Kontak: "pazar",
        Iletişim: "grup",
        BaslangicTarihi: "konaklama",
        BİtisTarihi: "kur",
        PersonelAdsoyad: "tarih",
      },
      {
        AktiviteAdi: "aktivite",
        FirmaAdi: "Acenta Adı",
        Kontak: "pazar",
        Iletişim: "grup",
        BaslangicTarihi: "konaklama",
        BİtisTarihi: "kur",
        PersonelAdsoyad: "tarih",
      },
    {
      AktiviteAdi: "aktivite",
      FirmaAdi: "Acenta Adı",
      Kontak: "pazar",
      Iletişim: "grup",
      BaslangicTarihi: "konaklama",
      BİtisTarihi: "kur",
      PersonelAdsoyad: "tarih",
    },
    {
      AktiviteAdi: "aktivite",
      FirmaAdi: "Acenta Adı",
      Kontak: "pazar",
      Iletişim: "grup",
      BaslangicTarihi: "konaklama",
      BİtisTarihi: "kur",
      PersonelAdsoyad: "tarih",
    },
    {
      AktiviteAdi: "aktivite",
      FirmaAdi: "Acenta Adı",
      Kontak: "pazar",
      Iletişim: "grup",
      BaslangicTarihi: "konaklama",
      BİtisTarihi: "kur",
      PersonelAdsoyad: "tarih",
    },
    {
      AktiviteAdi: "aktivite",
      FirmaAdi: "Acenta Adı",
      Kontak: "pazar",
      Iletişim: "grup",
      BaslangicTarihi: "konaklama",
      BİtisTarihi: "kur",
      PersonelAdsoyad: "tarih",
    },
    {
      AktiviteAdi: "aktivite",
      FirmaAdi: "Acenta Adı",
      Kontak: "pazar",
      Iletişim: "grup",
      BaslangicTarihi: "konaklama",
      BİtisTarihi: "kur",
      PersonelAdsoyad: "tarih",
    },
    {
      AktiviteAdi: "aktivite",
      FirmaAdi: "Acenta Adı",
      Kontak: "pazar",
      Iletişim: "grup",
      BaslangicTarihi: "konaklama",
      BİtisTarihi: "kur",
      PersonelAdsoyad: "tarih",
    },
    {
      AktiviteAdi: "aktivite",
      FirmaAdi: "Acenta Adı",
      Kontak: "pazar",
      Iletişim: "grup",
      BaslangicTarihi: "konaklama",
      BİtisTarihi: "kur",
      PersonelAdsoyad: "tarih",
    },
    {
      AktiviteAdi: "aktivite",
      FirmaAdi: "Acenta Adı",
      Kontak: "pazar",
      Iletişim: "grup",
      BaslangicTarihi: "konaklama",
      BİtisTarihi: "kur",
      PersonelAdsoyad: "tarih",
    },
  ];
  