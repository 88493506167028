// import React, { useEffect, useState } from "react";
// import "./Footer.css";
// import Loader from "react-js-loader";
// import DropdownDilButton from "../../Components/DropdownDil/TranslateButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faArrowUp,
//   faArrowDown,
//   faWifi,
//   faPhone,
//   faHeadset,
//   faCloud
// } from "@fortawesome/free-solid-svg-icons";
// import { HotelComboBox } from "../Header/Header";

// function Footer() {
//   // State to control database status
//   const [dbStatus, setDbStatus] = useState(1); // Assuming default status is 1
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [phoneNumbertext, setPhoneNumbertext] = useState("");
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [speedTestResult, setSpeedTestResult] = useState({
//     downloadSpeed: 0,
//     uploadSpeed: 0,
//     ping: 0,
//   });
//   const [isLoading, setIsLoading] = useState(true);

//   // Determine Loader color and text based on dbStatus
//   let loaderColor, statusText;
//   if (dbStatus === 1) {
//     loaderColor = "#a3b18a"; // Green for active
//     statusText = "Veritabanı Bağlantıları";
//   } else if (dbStatus === 2) {
//     loaderColor = "#e63946"; // Red for inactive
//     statusText = "Veritabanı Bağlantıları";
//   }

//   const performSpeedTest = () => {
//     setIsLoading(true);
//     fetch("http://localhost:3001/speedtest")
//       .then((response) => response.json())
//       .then((data) => {
//         setSpeedTestResult({
//           downloadSpeed: data.downloadSpeed,
//           uploadSpeed: data.uploadSpeed,
//           ping: data.ping,
//         });
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         console.error("Hız testi sırasında bir hata oluştu:", error);
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     // performSpeedTest();
//     // Function to update phone number based on time
//     const updatePhoneNumber = () => {
//       const currentTime = new Date();
//       const currentHour = currentTime.getHours();
//       const currentMinutes = currentTime.getMinutes();

//       // Between 9:00 and 18:30
//       if (
//         (currentHour >= 9 && currentHour < 18) ||
//         (currentHour === 18 && currentMinutes <= 30)
//       ) {
//         setPhoneNumber("0 (242) 227 87 31");
//         setPhoneNumbertext("Merkez Ofis");
//       } else {
//         // Other times (before 9:00 and after 18:30)
//         setPhoneNumber("0 (555) 998 6175");
//         setPhoneNumbertext("Nöbetçi");
//       }
//     };

//     // Update phone number on component mount and every minute
//     updatePhoneNumber();
//     const interval = setInterval(updatePhoneNumber, 60000);

//     // Cleanup interval on component unmount
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="footer h-100">
//       <div className="container-fluid h-100">
//         <div className="row h-100">
//           <div className="col-sm-2 d-flex h-100 footer-sm justify-content-center">

//             {/* <Loader type="bubble-ping" bgColor={loaderColor} size={35}  /> */}
//             <div className="d-flex w-75 justify-content-center align-items-center">
//               <p className="p-0 m-0 w-100"> <HotelComboBox /></p>
//             </div>
//           </div>
//           <div className="col-sm-2">
//             <div className="d-flex justify-content-center align-items-center">
//               <FontAwesomeIcon
//                 icon={faCloud}
//                 color="#a3b18a"
//                 style={{ marginRight: "5px" }}
//               />
//               <span>Yedekleme Sistemi</span>
//             </div>
//           </div>
//           <div className="col-sm-2">
//             <div className="d-flex justify-content-center align-items-center">
//               <DropdownDilButton />
//             </div>
//           </div>
//           <div className="col-sm-2">
//             <div className="d-flex justify-content-center align-items-center">
//               {isLoading ? (
//                 <div onClick={performSpeedTest} style={{ cursor: "pointer" }}>
//                   Hız testi yapılıyor...
//                 </div>
//               ) : (
//                 <div className="">
//                   <div className="d-flex ">
//                     <div className="m-1 ">
//                       <FontAwesomeIcon icon={faArrowDown} color="#a3b18a" />{" "}
//                       {speedTestResult.downloadSpeed < 100
//                         ? speedTestResult.downloadSpeed.toFixed(1)
//                         : speedTestResult.downloadSpeed.toFixed(0)}{" "}
//                       Mbps
//                     </div>
//                     <div className="m-1">
//                       <FontAwesomeIcon icon={faArrowUp} color="#e63946" />{" "}
//                       {speedTestResult.uploadSpeed < 100
//                         ? speedTestResult.uploadSpeed.toFixed(1)
//                         : speedTestResult.uploadSpeed.toFixed(0)}{" "}
//                       Mbps
//                     </div>
//                     <div className="m-1">
//                       <FontAwesomeIcon icon={faWifi} color="#a3b18a" />{" "}
//                       {speedTestResult.ping < 100
//                         ? speedTestResult.ping.toFixed(1)
//                         : speedTestResult.ping.toFixed(0)}{" "}
//                       ms
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//           <div className="col-sm-2">
//             <div className="d-flex justify-content-center align-items-center">
//               <FontAwesomeIcon
//                 icon={faHeadset}
//                 color="#a3b18a"
//                 style={{ marginRight: "5px" }}
//               />
//               <span>Destek</span>
//             </div>
//           </div>
//           <div className="col-sm-2">
//             <div className="d-flex justify-content-center align-items-center">
//               <FontAwesomeIcon
//                 icon={faPhone}
//                 style={{
//                   marginRight: "5px",
//                 }}
//                 color="#a3b18a"
//               />{" "}
//               {phoneNumbertext}: {phoneNumber}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Footer;

import React, { useEffect, useState } from "react";
import "./Footer.css";
import Loader from "react-js-loader";
import DropdownDilButton from "../../Components/DropdownDil/TranslateButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faWifi,
  faPhone,
  faHeadset,
  faCloud,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { HotelComboBox } from "../Header/Header";
import { useDbId } from "../../Context/LocalStrogeContext/OnburoDbContext";
import { Input } from "reactstrap";
import { TextArea } from "@progress/kendo-react-inputs";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ComboBox } from "@progress/kendo-react-dropdowns";


function Footer() {
  const [dbStatus, setDbStatus] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumbertext, setPhoneNumbertext] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [speedTestResult, setSpeedTestResult] = useState({
    downloadSpeed: 0,
    uploadSpeed: 0,
    ping: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { otelAdi, dbId } = useDbId();
  const [isim, setIsim] = useState("");
  const [iletisim, setIletisim] = useState("");
  const [departman, setDepartman] = useState("");
  const [aciklama, setAciklama] = useState("");

  const departmanListesi = [
    "MUHASEBE", "ÖNBÜRO", "SATIN ALMA", "İNSAN KAYNAKLARI", "HK", "BİLGİ İŞLEM", "GUEST RELATION", "KREDİLER", "REZERVASYON", "YÖNETİM"
  ];

  let loaderColor, statusText;
  if (dbStatus === 1) {
    loaderColor = "#a3b18a";
    statusText = "Veritabanı Bağlantıları";
  } else if (dbStatus === 2) {
    loaderColor = "#e63946";
    statusText = "Veritabanı Bağlantıları";
  }

  const performSpeedTest = () => {
    setIsLoading(true);
    fetch("http://localhost:3001/speedtest")
      .then((response) => response.json())
      .then((data) => {
        setSpeedTestResult({
          downloadSpeed: data.downloadSpeed,
          uploadSpeed: data.uploadSpeed,
          ping: data.ping,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Hız testi sırasında bir hata oluştu:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const updatePhoneNumber = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();

      if (
        (currentHour >= 9 && currentHour < 18) ||
        (currentHour === 18 && currentMinutes <= 30)
      ) {
        setPhoneNumber("0 (242) 227 87 31");
        setPhoneNumbertext("Merkez Ofis");
      } else {
        setPhoneNumber("0 (555) 998 6175");
        setPhoneNumbertext("Nöbetçi");
      }
    };

    updatePhoneNumber();
    const interval = setInterval(updatePhoneNumber, 60000);

    return () => clearInterval(interval);
  }, []);

  const CagriEkleIstek = () => {
    const token = localStorage.getItem("token");

    if (!isim || !iletisim || !departman || !aciklama) {
      toast.error("Lütfen tüm alanları doldurun!");
      return;
    }

    const requestBody = {
      isletmeAdi: otelAdi,
      isim: isim,
      iletisim: iletisim,
      departman: departman,
      aciklama: aciklama,
    };

    fetch("https://service.rmosweb.com/api/Cagri/CagriEkle", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("İşlem başarılı!");
        temizle();
      })
      .catch((error) => {
        toast.error("İşlem başarısız!");
        temizle();
      });
  };

  const temizle = () => {
    setIsim("");
    setIletisim("");
    setDepartman("");
    setAciklama("");
  }

  return (
    <div className="footer h-100 position-relative">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-sm-2 d-flex h-100 footer-sm justify-content-center">
            <div className="d-flex w-75 justify-content-center align-items-center">
              <p className="p-0 m-0 w-100">
                {" "}
                <HotelComboBox />
                <ToastContainer
                        position="top-right"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
              </p>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={faCloud}
                color="#a3b18a"
                style={{ marginRight: "5px" }}
              />
              <span>Yedekleme Sistemi</span>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              <DropdownDilButton />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              {isLoading ? (
                <div onClick={performSpeedTest} style={{ cursor: "pointer" }}>
                  Hız testi yapılıyor...
                </div>
              ) : (
                <div className="">
                  <div className="d-flex ">
                    <div className="m-1 ">
                      <FontAwesomeIcon icon={faArrowDown} color="#a3b18a" />{" "}
                      {speedTestResult.downloadSpeed < 100
                        ? speedTestResult.downloadSpeed.toFixed(1)
                        : speedTestResult.downloadSpeed.toFixed(0)}{" "}
                      Mbps
                    </div>
                    <div className="m-1">
                      <FontAwesomeIcon icon={faArrowUp} color="#e63946" />{" "}
                      {speedTestResult.uploadSpeed < 100
                        ? speedTestResult.uploadSpeed.toFixed(1)
                        : speedTestResult.uploadSpeed.toFixed(0)}{" "}
                      Mbps
                    </div>
                    <div className="m-1">
                      <FontAwesomeIcon icon={faWifi} color="#a3b18a" />{" "}
                      {speedTestResult.ping < 100
                        ? speedTestResult.ping.toFixed(1)
                        : speedTestResult.ping.toFixed(0)}{" "}
                      ms
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setIsFormOpen(!isFormOpen)}
                style={{ cursor: "pointer" }}
                className={`toggle-button ${isFormOpen ? "active" : ""}`}
              >
                <FontAwesomeIcon
                  icon={faHeadset}
                  color="#a3b18a"
                  style={{ marginRight: "5px" }}
                />
                <span>Destek Talebi Aç</span>
                <FontAwesomeIcon
                  icon={isFormOpen ? faChevronUp : faChevronDown}
                  color={isFormOpen ? "#2c3e50" : "#a3b18a"}
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  marginRight: "5px",
                }}
                color="#a3b18a"
              />{" "}
              {phoneNumbertext}: {phoneNumber}
            </div>
          </div>
        </div>
        <div className="support-form-container">
          <div className={`support-form ${isFormOpen ? "open" : ""}`}>
            <div className="form-title">Yeni Destek Talebi</div>
            <div className="form-group">
              <Input
                type="text"
                className="form-control uppercase"
                placeholder="İsim"
                value={isim}
                onChange={(e) => setIsim(e.target.value)}
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                className="form-control"
                placeholder="Telefon Numarası"
                value={iletisim}
                onChange={(e) => setIletisim(e.target.value)}
              />
            </div>
            <div className="form-group">
              <ComboBox
                data={departmanListesi}
                className="form-control uppercase"
                placeholder="Departman"
                value={departman}
                onChange={(e) => setDepartman(e.target.value)}
              />
            </div>
            <div className="form-group">
              <TextArea
                className="form-control textarea"
                placeholder="Açıklama"
                rows={5}
                value={aciklama}
                onChange={(e) => setAciklama(e.target.value as any)}
              />
            </div>
            <div className="form-group d-flex justify-content-between">
              <button className="btn btn-primary" onClick={CagriEkleIstek}>
                Gönder
              </button>
              <button className="btn btn-secondary">Bekleyen Talepler</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
