import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import {
  saveIcon,
  printIcon,
  pencilIcon,
  fileTxtIcon,
} from "@progress/kendo-svg-icons";
import { Card } from "react-bootstrap";
import "./BlackListButtons.css";

import { Tooltip } from "@progress/kendo-react-tooltip";
import { Ripple } from "@progress/kendo-react-ripple";

interface BlackListButtonsProps {
  approve: any;
  print: any;
  clean: any;
}

const BlackListButtons: React.FC<BlackListButtonsProps> = ({
  approve,
  print,
  clean,
}) => {
  const approveHandler = async () => {
    approve();
  };

  const printHandler = () => {
    print();
  };

  const cleanHandler = () => {
    clean();
  };

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "right",
     
      }}
    >
      <Tooltip anchorElement="target" position="bottom">
        <Ripple className="ripple">
          <Button
            svgIcon={pencilIcon}
            className="blacklist-button"
            title="Düzenle"
            onClick={cleanHandler}
          />

          <Button
            svgIcon={fileTxtIcon}
            className="blacklist-button"
            title="Xml"
          />

          <Button
            svgIcon={printIcon}
            className="blacklist-button"
            fillMode={null}
            title="Yazdır"
            onClick={printHandler}
          />
          <Button
            onClick={approveHandler}
            svgIcon={saveIcon}
            className="blacklist-button"
            title="Kaydet"
          />
        </Ripple>
      </Tooltip>
    </Card>
  );
};

export default BlackListButtons;
