// import React, { useEffect, useMemo, useState } from 'react';
// import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';

// interface DataItem {
//     [key: string]: any;
// }

// interface KendoGridComponentProps {
//     data: DataItem[];
// }

// const KendoGridComponent: React.FC<KendoGridComponentProps> = ({ data }) => {
//     const [page, setPage] = useState({
//         skip: 0,
//         take: 40,
//     });
//     const [filterText, setFilterText] = useState('');
//     const [filteredData, setFilteredData] = useState<DataItem[]>([]);

//     useEffect(() => {
//         // Eğer filtre metni boşsa, direkt olarak gelen data'yı kullan
//         if (!filterText) {
//             setFilteredData(data);
//         } else {
//             // Filtre metni varsa, filtreyi tekrar uygula
//             applyFilter();
//         }
//     }, [data, filterText]);

//     const handlePageChange = (event: GridPageChangeEvent) => {
//         setPage({
//             skip: event.page.skip,
//             take: event.page.take,
//         });
//     };

//     const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         setFilterText(event.target.value);
//     };

//     const applyFilter = () => {
//       if (!filterText) {
//           setFilteredData(data);
//       } else {
//           const lowercasedFilter = filterText.toLowerCase();
//           const filtered = data.filter(item =>
//               Object.keys(item).some(key =>
//                   (item[key] ? item[key].toString() : "").toLowerCase().includes(lowercasedFilter)
//               )
//           );
//           setFilteredData(filtered);
//       }
//       setPage({ skip: 0, take: 40 }); // Filtre uygulandığında sayfayı sıfırla
//     };

//     const columns = useMemo(() => {
//       if (data.length === 0) {
//         return [];
//       }

//       const keys = Object.keys(data[0]);
//       const maxContentLengths = keys.map(key => {
//         const maxDataLength = Math.max(...data.map(item => (item[key] ? item[key].toString().length : 0)));
//         const columnHeaderLength = key.length;
//         return Math.max(maxDataLength, columnHeaderLength);
//       });

//       return keys.map((key, index) => {
//         const baseWidth = 50;
//         const characterWidth = data.every(item => !isNaN(Number(item[key]))) ? 7 : 10;
//         const padding = 20;
//         const maxLength = maxContentLengths[index];
//         const idealWidth = Math.min(Math.max(baseWidth, (maxLength * characterWidth) + padding), 300);

//         return (
//           <Column
//             field={key}
//             title={key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}
//             key={key}
//             width={idealWidth}
//           />
//         );
//       });
//     }, [data]);

//     return (
//         <div>
//             <input
//                 type="text"
//                 value={filterText}
//                 onChange={handleFilterChange}
//                 placeholder="Filtrele..."
//             />
//             <button onClick={applyFilter}>Ara</button>
//             <Grid

//                 data={filteredData.slice(page.skip, page.skip + page.take)}
//                 skip={page.skip}
//                 take={page.take}
//                 total={filteredData.length}
//                 pageable={true}
//                 onPageChange={handlePageChange}
//                 style={{ height: '100%' }}
//                 filterable={true}
//             >
//               {columns}
//             </Grid>
//         </div>
//     );
// }

// export default KendoGridComponent;

import React, { ReactNode, useEffect, useMemo, useState } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridCellProps,
  GridKeyDownEvent,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { act } from "react-dom/test-utils";
import { Checkbox, Switch } from "@progress/kendo-react-inputs";

interface KendoGridComponentProps {
  toolbarContent?: ReactNode | string; // Bu, sayfa için özel içerik olacak
  data1: any[];
}
interface searchText {
  text: string;
}
// isActive alanı için özel hücre render fonksiyonu
const ActiveCell = (props: GridCellProps) => (
  <td
    className={`text-align-center ${
      props?.dataItem[props.field || ""] ? "green-background" : ""
    }`}
  >
    <input
      type="checkbox"
      id="singleMode"
      className="k-checkbox k-checkbox-md k-rounded-md "
      checked={props.dataItem[props.field || ""]}
      disabled
    />
  </td>
);
const KendoGridComponent: React.FC<KendoGridComponentProps> = ({
  toolbarContent,
  data1,
}) => {
  const [gridState, setGridState] = React.useState<any>({
    skip: 0,
    take: 30,
    sort: [],
    group: [],
    filter: null,
  });
  const [filterable, setFilterable] = useState(true);
  const [filteredData, setFilteredData] = useState(data1); // Filtrelenmiş veri için yeni bir state
  const [liveSearch, setLiveSearch] = useState(true); // Canlı arama için yeni bir state
  const [searchText, setSearchText] = useState<searchText>({ text: "" });
  const [denemetext, setDenemetext] = useState<string>("");

  useEffect(() => {
    setFilteredData(data1);
  }, [data1]); // data1 değiştiğinde filtrelenmiş veriyi güncelle
  useEffect(() => {
    if (liveSearch && searchText.text.trim()) {
      handleSearch();
    }
  }, [searchText, liveSearch]); // searchText değiştiğinde ve liveSearch açıksa otomatik arama yap

  const handlePageChange = (e: any) => {
    setGridState({
      ...gridState,
      skip: e.page.skip,
      take: e.page.take,
    });
  };

  const handleSortChange = (e: any) => {
    setGridState({
      ...gridState,
      sort: e.sort,
    });
  };

  const handleFilterChange = (e: any) => {
    setGridState({
      ...gridState,
      filter: e.filter,
    });
  };
  const columns = useMemo(() => {
    if (data1.length === 0) {
      return [];
    }

    const keys = Object.keys(data1[0]);
    const maxContentLengths = keys.map((key) => {
      const maxDataLength = Math.max(
        ...data1.map((item) => (item[key] ? item[key].toString().length : 0))
      );
      const columnHeaderLength = key.length;
      return Math.max(maxDataLength, columnHeaderLength);
    });

    return keys.map((key, index) => {
      const baseWidth = 50;
      const characterWidth = data1.every((item) => !isNaN(Number(item[key])))
        ? 7
        : 10;
      const padding = 20;
      const maxLength = maxContentLengths[index];
      const idealWidth = Math.min(
        Math.max(baseWidth, maxLength * characterWidth + padding),
        300
      );

      return (
        <Column
          field={key}
          title={key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}
          key={key}
          width={idealWidth}
        />
      );
    });
  }, [data1]);
  const processedData = process(filteredData, gridState);

  const toggleFilter = () => {
    setFilterable(!filterable);
  };

  const handleSearch = () => {
    if (!searchText.text.trim()) {
      // searchText boş ise tüm veriyi göster
      setFilteredData(data1);
    } else {
      const lowercasedFilter = searchText.text.toLowerCase();
      const filtered = data1.filter((item) =>
        Object.keys(item).some(
          (key) =>
            item[key] &&
            item[key].toString().toLowerCase().includes(lowercasedFilter)
        )
      );
      setFilteredData(filtered);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText({ text: e.target.value });
  };
  const deneme = () => {
    let a = document.getElementById("deneme") as HTMLInputElement;
    setDenemetext(a.value);
    setSearchText({ text: a.value });
    console.log("denemetext", a.value);
  };

  return (
    <div>
      <div className="w-100 d-flex justify-content-between">
        <div>
          <input
            type="text"
            value={searchText.text}
            onChange={handleInputChange}
            placeholder="Ara..."
          />
          <input type="text" id="deneme" />
          <button onClick={deneme}>butonna</button>
        </div>
        <div>
          <label>
            <Checkbox
              checked={filterable}
              onChange={toggleFilter}
              size="medium"
              label={"Filtrelemeyi Aç"}
            />
          </label>
        </div>
        <div> {toolbarContent}</div>
      </div>{" "}
      <Grid
        data={processedData}
        pageable={true} /* Sayfalama özelliğini etkinleştirir */
        sortable={true} /* Sıralama özelliğini etkinleştirir */
        filterable={filterable}
        onPageChange={
          handlePageChange
        } /* Sayfa değişikliği işleyicisini belirtir */
        onSortChange={
          handleSortChange
        } /* Sıralama değişikliği işleyicisini belirtir */
        onFilterChange={
          handleFilterChange
        } /* Filtre değişikliği işleyicisini belirtir */
        skip={gridState.skip} /* Atlanacak veri sayısını belirtir */
        take={gridState.take} /* Alınacak veri sayısını belirtir */
        sort={gridState.sort} /* Sıralama durumunu belirtir */
        filter={gridState.filter} /* Filtre durumunu belirtir */
        resizable={true}
        reorderable={true}
      >
        {columns}
      </Grid>
    </div>
  );
};

export default KendoGridComponent;
