import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  Checkbox,
  Input,
  NumericTextBox,
  TextArea,
} from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const initialDataState = {
  take: 10,
  skip: 0,
  group: [],
  filter: null,
};

const GunlukIslemler: React.FC = () => {
  const [data, setData] = React.useState<any>(GunlukIslemlerData);

  const [dataState, setDataState] = React.useState<any>(initialDataState);
  const onGridDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };
  const processedData = process(data, dataState);
  return (
    <>
      <div className="container-fluid my-2">
        <div className="row">
          <div className="col-md-12">
            <Tabs
              defaultActiveKey="CheckInOlacaklar"
              id="uncontrolled-tab-example"
            >
              <Tab eventKey="CheckInOlacaklar" title="Check-In Olacaklar">
                <div className="row">
                  <div className="col-md-12">
                    <Grid
                      data={processedData}
                      pageable={true}
                      pageSize={dataState.take}
                      total={data.length}
                      groupable={true}
                      filterable={true}
                      sortable={true}
                      onDataStateChange={onGridDataStateChange}
                      {...dataState}
                      reorderable={true}
                      resizable={true}
                      style={{ height: "400px" }}
                    >
                      <Column field="AktiviteAdi" title="Aktivite Adı" />
                      <Column field="FirmaAdi" title="Firma Adı" />
                      <Column field="Kontak" title="Kontak" />
                      <Column field="Iletişim" title="İletişim" />
                      <Column
                        field="BaslangicTarihi"
                        title="Başlangıç Tarihi"
                      />
                      <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                      <Column
                        field="PersonelAdsoyad"
                        title="Personel Ad Soyad"
                      />
                    </Grid>
                  </div>
                </div>

                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-between gap-2">
                        <div className="d-flex justify-content-center align-items-center">
                          Notlar :
                        </div>
                        <div>
                          <TextArea />
                        </div>
                        <div>
                          <TextArea />
                        </div>
                        <div>
                          <TextArea />
                        </div>
                        <div>
                          <TextArea />
                        </div>
                        <div>
                          <TextArea />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <Tabs
                    defaultActiveKey="GenelIslemler"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="GenelIslemler" title="Genel İşlemler">
                      <div className="container-fluid my-2">
                        <div className="row -0 p-0">
                          <div className="col-md-3">
                            <div className="row -0 p-0">
                              <div className="col-md-6 m-0 p-0">
                                <div className="container-fluid m-0 p-0">
                                  <div className="row m-0 p-0">
                                    <div className="m-0 p-0 gap-1 d-flex flex-column">
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Rez No
                                        </span>
                                        <Input
                                          className="w-60"
                                          type="text"
                                          placeholder="Rez No"
                                        ></Input>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Oda No
                                        </span>
                                        <Input
                                          className="w-60"
                                          type="text"
                                          placeholder="Oda No"
                                        ></Input>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Giriş / Çıkış
                                        </span>
                                        <DatePicker
                                          className="w-60"
                                          defaultValue={new Date()}
                                        />
                                      </div>
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Adı
                                        </span>
                                        <Input
                                          className="w-60"
                                          type="text"
                                          placeholder="Adı"
                                        ></Input>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Soyadı
                                        </span>
                                        <Input
                                          className="w-60"
                                          type="text"
                                          placeholder="Soyadı"
                                        ></Input>
                                      </div>
                                      <div className=" gap-2 d-flex flex-column">
                                        <div className="w-100 d-flex">
                                          <div className=" w-40 d-flex justify-content-start align-items-center">
                                            Kur Kodu
                                          </div>
                                          <div className="w-60 ">
                                            <DropDownList
                                              data={["TL", "Euro", "TRY"]}
                                              defaultValue="TL"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className=" gap-2 d-flex flex-column">
                                        <div className="w-100 d-flex">
                                          <div className=" w-40 d-flex justify-content-start align-items-center">
                                            Pansiyon
                                          </div>
                                          <div className="w-60 ">
                                            <DropDownList
                                              data={["pazar", "pazartesi"]}
                                              defaultValue="pazar"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* div-6 */}
                              </div>
                              <div className="col-md-6 m-0 p-0 gap-1 d-flex flex-column">
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Acenta
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["Acenta", "pazartesi"]}
                                        defaultValue="Acenta"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Konum
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["Konum", "pazartesi"]}
                                        defaultValue="Konum"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start ms-1">
                                  <NumericTextBox className="w-40"></NumericTextBox>
                                  <DatePicker
                                    className="w-60"
                                    defaultValue={new Date()}
                                  />
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Ödeme
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["Ödeme", "pazartesi"]}
                                        defaultValue="Ödeme"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      O/M
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["O", "pazartesi"]}
                                        defaultValue="O"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      B. Yatak
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["Yatak", "pazartesi"]}
                                        defaultValue="Yatak"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start ms-1">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Yaşı
                                  </span>
                                  <Input
                                    className="w-60"
                                    type="text"
                                    placeholder="Yaşı"
                                  ></Input>
                                </div>
                                {/* div-6 */}
                              </div>
                              <div className="col-md-8 m-0 p-0 gap-1 d-flex flex-column">
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-15 d-flex justify-content-start align-items-center">
                                    Y/Ç/Free
                                  </span>
                                  <NumericTextBox className="w-25"></NumericTextBox>
                                  <NumericTextBox className="w-25"></NumericTextBox>
                                  <NumericTextBox className="w-25"></NumericTextBox>
                                  <NumericTextBox className="w-25"></NumericTextBox>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Bilet Tarihi
                                  </span>
                                  <DatePicker
                                    className="w-60"
                                    defaultValue={new Date()}
                                  />
                                </div>
                                <div className=" gap-2 d-flex flex-column">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Ülke
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={[
                                          "TURKIYE",
                                          "INGILTERE",
                                          "ITALYA",
                                        ]}
                                        defaultValue="TURKIYE"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    İrtibat Tel
                                  </span>
                                  <Input
                                    className="w-60"
                                    type="text"
                                    placeholder="İrtibat Tel"
                                  ></Input>
                                </div>
                              </div>
                              <div className="col-md-4 m-0 p-0 gap-1 d-flex flex-column">
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      0 - 2
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["1", "2", "3"]}
                                        defaultValue="1"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      3 - 6
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["3", "4", "5"]}
                                        defaultValue="3"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      7 - 10
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["7", "8", "9"]}
                                        defaultValue="9"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      11 - 14
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["11", "12", "13"]}
                                        defaultValue="1"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* div-4 */}
                              </div>
                            </div>
                            {/* div-3 */}
                          </div>
                          <div className="col-md-2">
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Kur (G/A)
                              </div>
                              <div className="d-flex">
                                <DropDownList
                                  data={["G", "A"]}
                                  defaultValue="G"
                                />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>

                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Top. Fiyat
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Oda
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Kahvaltı
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Öğle
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Akşam
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                İçecek
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Yiyecek
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Ek Yatak
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className="d-flex">
                                <DropDownList
                                  data={["7", "8", "9"]}
                                  defaultValue="0"
                                />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Kapora
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 m-0 p-0">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-md-6 m-0 p-0">
                                  <div className=" d-flex flex-column">
                                    <div className="w-100 d-flex">
                                      <div className="w-40 d-flex justify-content-start align-items-center">
                                        Doğ. Tarihi
                                      </div>
                                      <div className="w-60 ">
                                        <DatePicker defaultValue={new Date()} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Tel
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Tel No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Gsm
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Gsm No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      TC No
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="TC No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Baba Adı
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Baba Adı"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Ana Adı
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Ana Adı"
                                    ></Input>
                                  </div>
                                  {/* BUraya birşey eklenecek */}
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Ana Adı
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Ana Adı"
                                    ></Input>
                                  </div>
                                  {/* BUraya birşey eklenecek */}
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Belge No
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Belge No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      İl/İlçe
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="İl/İlçe"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="col-md-6 m-0 p-0">
                                  <div className=" d-flex flex-column">
                                    <div className="w-100 d-flex">
                                      <div className="w-40 ms-1 d-flex justify-content-start align-items-center">
                                        Cinsiyet
                                      </div>
                                      <div className="w-60 ">
                                        <DropDownList
                                          data={["Erkek", "Kadın"]}
                                          defaultValue="Erkek"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      İl
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="İl"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      İlçe
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="İlçe"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Mahalle
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Mahalle"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Cilt / Aile
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Baba Adı"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Sıra
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Ana Adı"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Verildiği Yer
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Belge No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Uyruk
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="İl/İlçe"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <Input
                                      className="w-100"
                                      type="text"
                                      placeholder="İl/İlçe"
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-md-12 p-0 m-0">
                                  <div className="d-flex align-items-center justify-content-Start mt-1 p-0 m-0">
                                    <Input
                                      className="w-100"
                                      type="text"
                                      placeholder="Not 1"
                                    ></Input>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1 p-0 m-0">
                                    <Input
                                      className="w-100"
                                      type="text"
                                      placeholder="Not 2"
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 p-0 m-0">
                            <div className="d-flex align-items-center justify-content-Start ms-2">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                Kart No
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="Kart No"
                              ></Input>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                İnd.T.G.S
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="İnd.T.G.S"
                              ></Input>
                            </div>
                            <div className="d-flex flex-column ms-2 mt-1">
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Dep.Ind
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={["TL", "Euro", "TRY"]}
                                    defaultValue="TL"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                Ext.Ind
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="İndirim"
                              ></Input>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                Gel.Kap.
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="Kapora"
                              ></Input>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                Ace.Krd.
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="Kredi"
                              ></Input>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <TextArea
                                style={{
                                  width: "400px",
                                  height: "167px",
                                  padding: "10px",
                                  fontSize: "14px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  resize: "none",
                                }}
                                placeholder="Metin girin..."
                              />
                            </div>
                          </div>
                          <div className="col-md-3 m-0 p-0">
                            <div className="container-fluid">
                              <div className="rowm-0 p-0">
                                <div className="col-md-12 m-0 p-0">
                                  <div className="text-start">
                                    <Checkbox
                                      label={"Beraberindekiler Girilebilsin !"}
                                      color="red"
                                    />
                                  </div>
                                  <Grid
                                    data={processedData}
                                    pageable={true}
                                    pageSize={dataState.take}
                                    total={data.length}
                                    groupable={true}
                                    filterable={true}
                                    sortable={true}
                                    onDataStateChange={onGridDataStateChange}
                                    {...dataState}
                                    reorderable={true}
                                    resizable={true}
                                    style={{ height: "300px" }}
                                  >
                                    <Column field="AktiviteAdi" title="Adı" />
                                    <Column field="FirmaAdi" title="Soyadı" />
                                    <Column field="Iletişim" title="Yaşı" />
                                    <Column
                                      field="Kontak"
                                      title="Doğum Tarihi"
                                    />
                                  </Grid>
                                  <div className="div w-100 mt-1">
                                    <TextArea
                                      style={{
                                        // width: "350px",
                                        height: "50px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        resize: "none",
                                      }}
                                      placeholder="Metin girin..."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* div-3 */}
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="AracKrediKartiBilgileriDigerIslemler"
                      title="Araç Kredi Kartı Bilgileri Diğer İşlemler"
                    >
                      <div className="container-fluid my-2 m-0 p-0">
                        <div className="row m-o p-0">
                          <div className="col-md-12 d-flex">
                            <div className="col-md-4">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="d-flex align-items-center justify-content-Start">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Kiralama
                                    </span>
                                    <Input
                                      className="w-70"
                                      type="text"
                                      placeholder="Kiralama"
                                    ></Input>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Devre Pansiyon
                                    </span>
                                    <Input
                                      className="w-35"
                                      type="text"
                                      placeholder="DP"
                                    ></Input>
                                    <Input
                                      className="w-35 ms-1"
                                      type="text"
                                      placeholder="DP"
                                    ></Input>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Kalan Kiralama
                                    </span>
                                    <Input
                                      className="w-70"
                                      type="text"
                                      placeholder="Kalan Kiralama"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="border">
                                    <p>Araç Bilgileri</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Araç No
                                    </span>
                                    <Input
                                      className="w-35"
                                      type="text"
                                      placeholder="Araç No"
                                    ></Input>
                                    <Input
                                      className="w-35 ms-1"
                                      type="text"
                                      placeholder="Araç No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Plaka
                                    </span>
                                    <Input
                                      className="w-70"
                                      type="text"
                                      placeholder="Plaka"
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <Grid
                                data={processedData}
                                pageable={true}
                                pageSize={dataState.take}
                                total={data.length}
                                groupable={true}
                                filterable={true}
                                sortable={true}
                                onDataStateChange={onGridDataStateChange}
                                {...dataState}
                                reorderable={true}
                                resizable={true}
                                style={{ height: "350px" }}
                              >
                                <Column field="AktiviteAdi" title="Dep" />
                                <Column field="FirmaAdi" title="Departman" />
                                <Column field="Kontak" title="Tutar" />
                              </Grid>
                            </div>
                            <div className="col-md-4">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="d-flex align-items-center justify-content-Start">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            K.K. No
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="K.K. No"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            K.K. Son Kul.
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="K.K. Son Kullanma"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            SW2
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="SW2"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Limit Tutar
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Limit Tutar"
                                          ></Input>
                                        </div>
                                        <div className=" gap-2 d-flex flex-column mt-1">
                                          <div className="w-100 d-flex">
                                            <div className=" w-40 d-flex justify-content-start align-items-center">
                                              Limit Uyarı
                                            </div>
                                            <div className="w-60 ">
                                              <DropDownList
                                                data={["H", "E"]}
                                                defaultValue="H"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className=" gap-2 d-flex flex-column mt-1">
                                          <div className="w-100 d-flex">
                                            <div className=" w-40 d-flex justify-content-start align-items-center">
                                              D. Şekli
                                            </div>
                                            <div className="w-60 ">
                                              <DropDownList
                                                data={["H", "E"]}
                                                defaultValue="DA"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Alış Tarihi
                                          </span>
                                          <DatePicker
                                            className="w-60"
                                            defaultValue={new Date()}
                                          />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Blokaj
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Blokaj"
                                          ></Input>
                                        </div>
                                        <span className="w-100 d-flex justify-content-start align-items-center mt-1">
                                          Çıkışta Iade Edilecek
                                          Malzemeler/Kartlar
                                        </span>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Breakdown Fark
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Breakdown Fark"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Ac.Ozel Post.
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Ac.Ozel Post."
                                          ></Input>
                                        </div>
                                      </div>
                                      <div className="col-md-6 m-0 p-0">
                                        <div className="d-flex align-items-center justify-content-Start">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Net Tutar
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Net Tutar"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Rez.Folio Kapora
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Rez.Folio Kapora"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Kalan Tutar
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Kalan Tutar"
                                          ></Input>
                                        </div>
                                        <div className="text-start mt-1">
                                          <Checkbox
                                            label={"Diplomat veya Ateşe"}
                                            color="red"
                                          />
                                        </div>
                                        <div className="text-start mt-1">
                                          <Checkbox
                                            label={"Birleşen Beraber Oda"}
                                            color="red"
                                          />
                                        </div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <Input
                                          className="w-100"
                                          type="text"
                                          placeholder="Çıkışta Iade Edilecek Malzemeler/Kartlar"
                                        ></Input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Tab>
              <Tab eventKey="CheckInOlanlar" title="Check-In Olanlar">
                <div className="row">
                  <div className="col-md-12">
                    <Grid
                      data={processedData}
                      pageable={true}
                      pageSize={dataState.take}
                      total={data.length}
                      groupable={true}
                      filterable={true}
                      sortable={true}
                      onDataStateChange={onGridDataStateChange}
                      {...dataState}
                      reorderable={true}
                      resizable={true}
                      style={{ height: "400px" }}
                    >
                      <Column field="AktiviteAdi" title="Aktivite Adı" />
                      <Column field="FirmaAdi" title="Firma Adı" />
                      <Column field="Kontak" title="Kontak" />
                      <Column field="Iletişim" title="İletişim" />
                      <Column
                        field="BaslangicTarihi"
                        title="Başlangıç Tarihi"
                      />
                      <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                      <Column
                        field="PersonelAdsoyad"
                        title="Personel Ad Soyad"
                      />
                    </Grid>
                  </div>
                </div>

                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-between gap-2">
                        <div className="d-flex justify-content-center align-items-center">
                          Notlar :
                        </div>
                        <div>
                          <TextArea />
                        </div>
                        <div>
                          <TextArea />
                        </div>
                        <div>
                          <TextArea />
                        </div>
                        <div>
                          <TextArea />
                        </div>
                        <div>
                          <TextArea />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <Tabs
                    defaultActiveKey="GenelIslemler"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="GenelIslemler" title="Genel İşlemler">
                      <div className="container-fluid my-2">
                        <div className="row -0 p-0">
                          <div className="col-md-3">
                            <div className="row -0 p-0">
                              <div className="col-md-6 m-0 p-0">
                                <div className="container-fluid m-0 p-0">
                                  <div className="row m-0 p-0">
                                    <div className="m-0 p-0 gap-1 d-flex flex-column">
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Rez No
                                        </span>
                                        <Input
                                          className="w-60"
                                          type="text"
                                          placeholder="Rez No"
                                        ></Input>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Oda No
                                        </span>
                                        <Input
                                          className="w-60"
                                          type="text"
                                          placeholder="Oda No"
                                        ></Input>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Giriş / Çıkış
                                        </span>
                                        <DatePicker
                                          className="w-60"
                                          defaultValue={new Date()}
                                        />
                                      </div>
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Adı
                                        </span>
                                        <Input
                                          className="w-60"
                                          type="text"
                                          placeholder="Adı"
                                        ></Input>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-Start">
                                        <span className="w-40 d-flex justify-content-start align-items-center">
                                          Soyadı
                                        </span>
                                        <Input
                                          className="w-60"
                                          type="text"
                                          placeholder="Soyadı"
                                        ></Input>
                                      </div>
                                      <div className=" gap-2 d-flex flex-column">
                                        <div className="w-100 d-flex">
                                          <div className=" w-40 d-flex justify-content-start align-items-center">
                                            Kur Kodu
                                          </div>
                                          <div className="w-60 ">
                                            <DropDownList
                                              data={["TL", "Euro", "TRY"]}
                                              defaultValue="TL"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className=" gap-2 d-flex flex-column">
                                        <div className="w-100 d-flex">
                                          <div className=" w-40 d-flex justify-content-start align-items-center">
                                            Pansiyon
                                          </div>
                                          <div className="w-60 ">
                                            <DropDownList
                                              data={["pazar", "pazartesi"]}
                                              defaultValue="pazar"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* div-6 */}
                              </div>
                              <div className="col-md-6 m-0 p-0 gap-1 d-flex flex-column">
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Acenta
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["Acenta", "pazartesi"]}
                                        defaultValue="Acenta"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Konum
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["Konum", "pazartesi"]}
                                        defaultValue="Konum"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start ms-1">
                                  <NumericTextBox className="w-40"></NumericTextBox>
                                  <DatePicker
                                    className="w-60"
                                    defaultValue={new Date()}
                                  />
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Ödeme
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["Ödeme", "pazartesi"]}
                                        defaultValue="Ödeme"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      O/M
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["O", "pazartesi"]}
                                        defaultValue="O"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      B. Yatak
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["Yatak", "pazartesi"]}
                                        defaultValue="Yatak"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start ms-1">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Yaşı
                                  </span>
                                  <Input
                                    className="w-60"
                                    type="text"
                                    placeholder="Yaşı"
                                  ></Input>
                                </div>
                                {/* div-6 */}
                              </div>
                              <div className="col-md-8 m-0 p-0 gap-1 d-flex flex-column">
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-15 d-flex justify-content-start align-items-center">
                                    Y/Ç/Free
                                  </span>
                                  <NumericTextBox className="w-25"></NumericTextBox>
                                  <NumericTextBox className="w-25"></NumericTextBox>
                                  <NumericTextBox className="w-25"></NumericTextBox>
                                  <NumericTextBox className="w-25"></NumericTextBox>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Bilet Tarihi
                                  </span>
                                  <DatePicker
                                    className="w-60"
                                    defaultValue={new Date()}
                                  />
                                </div>
                                <div className=" gap-2 d-flex flex-column">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Ülke
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={[
                                          "TURKIYE",
                                          "INGILTERE",
                                          "ITALYA",
                                        ]}
                                        defaultValue="TURKIYE"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    İrtibat Tel
                                  </span>
                                  <Input
                                    className="w-60"
                                    type="text"
                                    placeholder="İrtibat Tel"
                                  ></Input>
                                </div>
                              </div>
                              <div className="col-md-4 m-0 p-0 gap-1 d-flex flex-column">
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      0 - 2
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["1", "2", "3"]}
                                        defaultValue="1"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      3 - 6
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["3", "4", "5"]}
                                        defaultValue="3"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      7 - 10
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["7", "8", "9"]}
                                        defaultValue="9"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" gap-2 d-flex flex-column ms-1">
                                  <div className="w-100 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      11 - 14
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={["11", "12", "13"]}
                                        defaultValue="1"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* div-4 */}
                              </div>
                            </div>
                            {/* div-3 */}
                          </div>
                          <div className="col-md-2">
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Kur (G/A)
                              </div>
                              <div className="d-flex">
                                <DropDownList
                                  data={["G", "A"]}
                                  defaultValue="G"
                                />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>

                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Top. Fiyat
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Oda
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Kahvaltı
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Öğle
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Akşam
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                İçecek
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Yiyecek
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Ek Yatak
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className="d-flex">
                                <DropDownList
                                  data={["7", "8", "9"]}
                                  defaultValue="0"
                                />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-between mt-1">
                              <div className=" w-60 d-flex justify-content-start align-items-center">
                                Kapora
                              </div>
                              <div className="d-flex">
                                <Input type="text" placeholder="00000" />
                                <Input
                                  className="ms-1"
                                  type="text"
                                  placeholder="00000"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 m-0 p-0">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-md-6 m-0 p-0">
                                  <div className=" d-flex flex-column">
                                    <div className="w-100 d-flex">
                                      <div className="w-40 d-flex justify-content-start align-items-center">
                                        Doğ. Tarihi
                                      </div>
                                      <div className="w-60 ">
                                        <DatePicker defaultValue={new Date()} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Tel
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Tel No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Gsm
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Gsm No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      TC No
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="TC No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Baba Adı
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Baba Adı"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Ana Adı
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Ana Adı"
                                    ></Input>
                                  </div>
                                  {/* BUraya birşey eklenecek */}
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Ana Adı
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Ana Adı"
                                    ></Input>
                                  </div>
                                  {/* BUraya birşey eklenecek */}
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Belge No
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Belge No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      İl/İlçe
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="İl/İlçe"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="col-md-6 m-0 p-0">
                                  <div className=" d-flex flex-column">
                                    <div className="w-100 d-flex">
                                      <div className="w-40 ms-1 d-flex justify-content-start align-items-center">
                                        Cinsiyet
                                      </div>
                                      <div className="w-60 ">
                                        <DropDownList
                                          data={["Erkek", "Kadın"]}
                                          defaultValue="Erkek"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      İl
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="İl"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      İlçe
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="İlçe"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Mahalle
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Mahalle"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Cilt / Aile
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Baba Adı"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Sıra
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Ana Adı"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Verildiği Yer
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="Belge No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <span className="w-40 d-flex justify-content-start align-items-center">
                                      Uyruk
                                    </span>
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="İl/İlçe"
                                    ></Input>
                                  </div>
                                  <div className="d-flex ms-1 mt-1 align-items-center justify-content-Start">
                                    <Input
                                      className="w-100"
                                      type="text"
                                      placeholder="İl/İlçe"
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-md-12 p-0 m-0">
                                  <div className="d-flex align-items-center justify-content-Start mt-1 p-0 m-0">
                                    <Input
                                      className="w-100"
                                      type="text"
                                      placeholder="Not 1"
                                    ></Input>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1 p-0 m-0">
                                    <Input
                                      className="w-100"
                                      type="text"
                                      placeholder="Not 2"
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 p-0 m-0">
                            <div className="d-flex align-items-center justify-content-Start ms-2">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                Kart No
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="Kart No"
                              ></Input>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                İnd.T.G.S
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="İnd.T.G.S"
                              ></Input>
                            </div>
                            <div className="d-flex flex-column ms-2 mt-1">
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Dep.Ind
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={["TL", "Euro", "TRY"]}
                                    defaultValue="TL"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                Ext.Ind
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="İndirim"
                              ></Input>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                Gel.Kap.
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="Kapora"
                              ></Input>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <span className="w-40 d-flex justify-content-start align-items-center">
                                Ace.Krd.
                              </span>
                              <Input
                                className="w-60"
                                type="text"
                                placeholder="Kredi"
                              ></Input>
                            </div>
                            <div className="d-flex align-items-center justify-content-Start ms-2 mt-1">
                              <TextArea
                                style={{
                                  width: "400px",
                                  height: "167px",
                                  padding: "10px",
                                  fontSize: "14px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  resize: "none",
                                }}
                                placeholder="Metin girin..."
                              />
                            </div>
                          </div>
                          <div className="col-md-3 m-0 p-0">
                            <div className="container-fluid">
                              <div className="rowm-0 p-0">
                                <div className="col-md-12 m-0 p-0">
                                  <div className="text-start">
                                    <Checkbox
                                      label={"Beraberindekiler Girilebilsin !"}
                                      color="red"
                                    />
                                  </div>
                                  <Grid
                                    data={processedData}
                                    pageable={true}
                                    pageSize={dataState.take}
                                    total={data.length}
                                    groupable={true}
                                    filterable={true}
                                    sortable={true}
                                    onDataStateChange={onGridDataStateChange}
                                    {...dataState}
                                    reorderable={true}
                                    resizable={true}
                                    style={{ height: "300px" }}
                                  >
                                    <Column field="AktiviteAdi" title="Adı" />
                                    <Column field="FirmaAdi" title="Soyadı" />
                                    <Column field="Iletişim" title="Yaşı" />
                                    <Column
                                      field="Kontak"
                                      title="Doğum Tarihi"
                                    />
                                  </Grid>
                                  <div className="div w-100 mt-1">
                                    <TextArea
                                      style={{
                                        // width: "350px",
                                        height: "50px",
                                        padding: "10px",
                                        fontSize: "14px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        resize: "none",
                                      }}
                                      placeholder="Metin girin..."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* div-3 */}
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="AracKrediKartiBilgileriDigerIslemler"
                      title="Araç Kredi Kartı Bilgileri Diğer İşlemler"
                    >
                      <div className="container-fluid my-2 m-0 p-0">
                        <div className="row m-o p-0">
                          <div className="col-md-12 d-flex">
                            <div className="col-md-4">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="d-flex align-items-center justify-content-Start">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Kiralama
                                    </span>
                                    <Input
                                      className="w-70"
                                      type="text"
                                      placeholder="Kiralama"
                                    ></Input>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Devre Pansiyon
                                    </span>
                                    <Input
                                      className="w-35"
                                      type="text"
                                      placeholder="DP"
                                    ></Input>
                                    <Input
                                      className="w-35 ms-1"
                                      type="text"
                                      placeholder="DP"
                                    ></Input>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Kalan Kiralama
                                    </span>
                                    <Input
                                      className="w-70"
                                      type="text"
                                      placeholder="Kalan Kiralama"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="border">
                                    <p>Araç Bilgileri</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Araç No
                                    </span>
                                    <Input
                                      className="w-35"
                                      type="text"
                                      placeholder="Araç No"
                                    ></Input>
                                    <Input
                                      className="w-35 ms-1"
                                      type="text"
                                      placeholder="Araç No"
                                    ></Input>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-Start mt-1">
                                    <span className="w-30 d-flex justify-content-start align-items-center">
                                      Plaka
                                    </span>
                                    <Input
                                      className="w-70"
                                      type="text"
                                      placeholder="Plaka"
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <Grid
                                data={processedData}
                                pageable={true}
                                pageSize={dataState.take}
                                total={data.length}
                                groupable={true}
                                filterable={true}
                                sortable={true}
                                onDataStateChange={onGridDataStateChange}
                                {...dataState}
                                reorderable={true}
                                resizable={true}
                                style={{ height: "350px" }}
                              >
                                <Column field="AktiviteAdi" title="Dep" />
                                <Column field="FirmaAdi" title="Departman" />
                                <Column field="Kontak" title="Tutar" />
                              </Grid>
                            </div>
                            <div className="col-md-4">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="d-flex align-items-center justify-content-Start">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            K.K. No
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="K.K. No"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            K.K. Son Kul.
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="K.K. Son Kullanma"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            SW2
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="SW2"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Limit Tutar
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Limit Tutar"
                                          ></Input>
                                        </div>
                                        <div className=" gap-2 d-flex flex-column mt-1">
                                          <div className="w-100 d-flex">
                                            <div className=" w-40 d-flex justify-content-start align-items-center">
                                              Limit Uyarı
                                            </div>
                                            <div className="w-60 ">
                                              <DropDownList
                                                data={["H", "E"]}
                                                defaultValue="H"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className=" gap-2 d-flex flex-column mt-1">
                                          <div className="w-100 d-flex">
                                            <div className=" w-40 d-flex justify-content-start align-items-center">
                                              D. Şekli
                                            </div>
                                            <div className="w-60 ">
                                              <DropDownList
                                                data={["H", "E"]}
                                                defaultValue="DA"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Alış Tarihi
                                          </span>
                                          <DatePicker
                                            className="w-60"
                                            defaultValue={new Date()}
                                          />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Blokaj
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Blokaj"
                                          ></Input>
                                        </div>
                                        <span className="w-100 d-flex justify-content-start align-items-center mt-1">
                                          Çıkışta Iade Edilecek
                                          Malzemeler/Kartlar
                                        </span>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Breakdown Fark
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Breakdown Fark"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Ac.Ozel Post.
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Ac.Ozel Post."
                                          ></Input>
                                        </div>
                                      </div>
                                      <div className="col-md-6 m-0 p-0">
                                        <div className="d-flex align-items-center justify-content-Start">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Net Tutar
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Net Tutar"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Rez.Folio Kapora
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Rez.Folio Kapora"
                                          ></Input>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-Start mt-1">
                                          <span className="w-40 d-flex justify-content-start align-items-center">
                                            Kalan Tutar
                                          </span>
                                          <Input
                                            className="w-60"
                                            type="text"
                                            placeholder="Kalan Tutar"
                                          ></Input>
                                        </div>
                                        <div className="text-start mt-1">
                                          <Checkbox
                                            label={"Diplomat veya Ateşe"}
                                            color="red"
                                          />
                                        </div>
                                        <div className="text-start mt-1">
                                          <Checkbox
                                            label={"Birleşen Beraber Oda"}
                                            color="red"
                                          />
                                        </div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <div className="div">-</div>
                                        <Input
                                          className="w-100"
                                          type="text"
                                          placeholder="Çıkışta Iade Edilecek Malzemeler/Kartlar"
                                        ></Input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Tab>
              <Tab
                eventKey="BeklemeyeAlinanlar"
                title="Beklemeye Alınanlar(Waiting)"
              >
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
              </Tab>
              <Tab eventKey="Icerdekiler" title="İçerdekiler">
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
              </Tab>
              <Tab eventKey="CheckOutOlacaklar" title="Check-Out Olacaklar">
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
              </Tab>
              <Tab eventKey="CheckOutOlanlar" title="Check-Out Olanlar">
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
              </Tab>
              <Tab eventKey="OdaDegisimleri" title="Oda Değişimleri">
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
              </Tab>
              <Tab eventKey="BosOdalar" title="Boş Odalar">
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
                {/* Yapılacak */}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default GunlukIslemler;

const GunlukIslemlerData: any[] = [
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
];
