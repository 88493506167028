import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';

interface OtelGuidContextType {
  otelGuid: string;
  setOtelGuid: (guid: string) => void;
}

// Context'i oluştur
const OtelGuidContext = createContext<OtelGuidContextType | undefined>(undefined);

// Provider component'i
export const OtelGuidProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [otelGuid, setOtelGuid] = useState(localStorage.getItem('selectedHotelGuid') || '');

  useEffect(() => {
    const handleOtelGuidChange = () => {
      const newOtelGuid = localStorage.getItem('selectedHotelGuid') || '';
      setOtelGuid(newOtelGuid);
      console.log('otelGuid değişti:', newOtelGuid);
    };

    // localStorage'daki değişiklikleri dinle
    window.addEventListener('otelGuidChanged', handleOtelGuidChange);

    return () => {
      window.removeEventListener('otelGuidChanged', handleOtelGuidChange);
    };
  }, []);

  return (
    <OtelGuidContext.Provider value={{ otelGuid, setOtelGuid }}>
      {children}
    </OtelGuidContext.Provider>
  );
};

export const useOtelGuid = () => {
  const context = useContext(OtelGuidContext);
  if (context === undefined) {
    throw new Error('useOtelGuid must be used within a OtelGuidProvider');
  }
  return context;
};
