import React, { useEffect, useState } from "react";
import UstMenuBar from "../../../../../../Components/Basliklar/UstMenuBar";
import { Card, CardBody, CardHeader, Tab, Tabs } from "react-bootstrap";
import { Input, RadioButton, TextArea } from "@progress/kendo-react-inputs";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import GeneralButton from "../../../../../../Components/Buttons/FloatActionButton/GeneralButton";
import {
  faEraser,
  faFileInvoice,
  faHourglassHalf,
  faPlus,
  faPlusCircle,
  faPrint,
  faSave,
  faSearch,
  faSignOutAlt,
  faSync,
  faTimes,
  faTrash,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import { process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import axios from "axios";
import { Spinner } from "reactstrap";
import "./HesapBakma.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDbId } from "../../../../../../Context/LocalStrogeContext/OnburoDbContext";

const initialDataState = {
  // take: 10,
  skip: 0,
  group: [],
  filter: null,
};

const gradientStart = "#769CC0";
const gradientEnd = "#7BADDB";
const hoverGradientStart = "#A0522D";
const hoverGradientEnd = "#B87333";

interface Kod {
  Id: number;
  Kod: string;
  Ad: string;
  Sinif: string;
  db_Id: number;
}

// Toast bildirim fonksiyonu
const showToast = (message: string) => {
  toast(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
// Toast bildirimlerini kullanmak için bu fonksiyonu App bileşeninizin üst seviyesinde çağırmanız gerekiyor
// toast.configure();

const HesapBakma: React.FC = () => {
  // üst menü stateleri
  const [selectedFolioTipi, setSelectedFolioTipi] = useState("R");
  const [selectedVeriTipi, setSelectedVeriTipi] = useState("I");
  // üst menü stateleri

  // grid stateleri
  const [data, setData] = useState<any[]>([]);
  const [dataState, setDataState] = useState<any>(initialDataState);
  const [isLoading, setLoading] = useState(false);
  const [folioIsLoading, setFolioLoading] = useState(false);
  const [odaNo, setOdaNo] = useState("");
  const [folioData, setFolioData] = useState<any[]>([]);
  const onGridDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };
  const processedData = process(data, dataState);
  const processedFolioData = process(folioData, dataState);
  // grid stateleri

  // bakiye bilgileri stateleri
  const [borcTL, setBorcTL] = useState("0.00");
  const [formattedBorcTL, setFormattedBorcTL] = useState("0.00");
  const [alacakTL, setAlacakTL] = useState("0.00");
  const [formattedAlacakTL, setFormattedAlacakTL] = useState("0.00");
  const [bakiyeTL, setBakiyeTL] = useState("0.00");
  const [formattedBakiyeTL, setFormattedBakiyeTL] = useState("0.00");
  const [dovizBorc, setDovizBorc] = useState("0.00");
  const [formattedDovizBorc, setFormattedDovizBorc] = useState("0.00");
  const [dovizAlacak, setDovizAlacak] = useState("0.00");
  const [formattedDovizAlacak, setFormattedDovizAlacak] = useState("0.00");
  const [dovizBakiye, setDovizBakiye] = useState("0.00");
  const [formattedDovizBakiye, setFormattedDovizBakiye] = useState("0.00");
  const {otelAdi,dbId}=useDbId();
  // bakiye bilgileri stateleri

  useEffect(() => {
    const formattedBorcTL = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(borcTL.replace(",", ".")));

    setFormattedBorcTL(formattedBorcTL);
  }, [borcTL]);

  useEffect(() => {
    const formattedAlacakTL = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(alacakTL.replace(",", ".")));

    setFormattedAlacakTL(formattedAlacakTL);
  }, [alacakTL]);
  useEffect(() => {
    const formattedBakiyeTL = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(bakiyeTL.replace(",", ".")));

    setFormattedBakiyeTL(formattedBakiyeTL);
  }, [bakiyeTL]);
  useEffect(() => {
    const formattedDovizBorc = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(dovizBorc.replace(",", ".")));

    setFormattedDovizBorc(formattedDovizBorc);
  }, [dovizBorc]);
  useEffect(() => {
    const formattedDovizAlacak = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(dovizAlacak.replace(",", ".")));

    setFormattedDovizAlacak(formattedDovizAlacak);
  }, [dovizAlacak]);
  useEffect(() => {
    const formattedDovizBakiye = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(dovizBakiye.replace(",", ".")));

    setFormattedDovizBakiye(formattedDovizBakiye);
  }, [dovizBakiye]);

  useEffect(() => {
    const totalBorc = folioData.reduce((acc: number, item: any) => {
      const borc = parseFloat(item.Borç);
      return acc + (isNaN(borc) ? 0 : borc);
    }, 0);
    setBorcTL(totalBorc.toFixed(2).replace(".", ","));

    const totalAlacak = folioData.reduce((acc: number, item: any) => {
      const alacak = parseFloat(item.Alacak);
      return acc + (isNaN(alacak) ? 0 : alacak);
    }, 0);
    setAlacakTL(totalAlacak.toFixed(2).replace(".", ","));

    const totalDovizBorc = folioData.reduce((acc: number, item: any) => {
      const dovizBorc = parseFloat(item.P_Borç);
      return acc + (isNaN(dovizBorc) ? 0 : dovizBorc);
    }, 0);
    setDovizBorc(totalDovizBorc.toFixed(2).replace(".", ","));

    const totalDovizAlacak = folioData.reduce((acc: number, item: any) => {
      const dovizAlacak = parseFloat(item.P_Alacak);
      return acc + (isNaN(dovizAlacak) ? 0 : dovizAlacak);
    }, 0);
    setDovizAlacak(totalDovizAlacak.toFixed(2).replace(".", ","));
  }, [folioData]);

  useEffect(() => {
    const borc = parseFloat(borcTL.replace(",", "."));
    const alacak = parseFloat(alacakTL.replace(",", "."));
    const bakiye = borc - alacak;
    setBakiyeTL(bakiye.toFixed(2).replace(".", ","));

    const dovizBorcValue = parseFloat(dovizBorc.replace(",", "."));
    const dovizAlacakValue = parseFloat(dovizAlacak.replace(",", "."));
    const dovizBakiyeValue = dovizBorcValue - dovizAlacakValue;
    setDovizBakiye(dovizBakiyeValue.toFixed(2).replace(".", ","));
  }, [borcTL, alacakTL, dovizBorc, dovizAlacak]);

  const OdaNoFolioCagir = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      temizle2();
      setLoading(true);
      const db_Id = localStorage.getItem("selectedDbId");
      const token = localStorage.getItem("token");
      const requestBody = {
        db_Id: db_Id ? parseInt(db_Id) : null,
        Tip: 5,
        R_I_H: selectedVeriTipi,
        Odano: odaNo,
      };

      try {
        const response = await axios.post(
          "https://frontapi.rmosweb.com/api/Rez/Getir_Kod",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = response.data;
        console.log("API response:", result);
        if (result.isSucceded) {
          setData(result.value);
        } else {
          console.error("API response error:", result.message);
        }
      } catch (error) {
        console.error("API request error:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  const [MasterIdRefresh, setMasterIdRefresh] = useState(0);
  const FolioNoDetayListele = async (event: GridRowClickEvent) => {
    setFolioLoading(true);
    const db_Id = localStorage.getItem("selectedDbId");
    const token = localStorage.getItem("token");

    const masterId = event.dataItem.Master_id;
    setMasterIdRefresh(masterId);
    const girisTarihi = event.dataItem["Giriş Tarihi"];
    const odaNumarasi = event.dataItem.Odano;

    const requestBody = {
      db_Id: db_Id ? parseInt(db_Id) : null,
      xKumhrk_Re: selectedFolioTipi,
      xKumhrk_Rez_id: masterId,
      xtip: 16,
    };

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Procedure/StpKumhrk_Bul",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = response.data;
      if (result.isSucceded) {
        setFolioData(result.value);
        setRezId(masterId);
        setFolioGirisTarihi(girisTarihi);
        setOdaNo(odaNumarasi);
        // console.log("Giriş Tarihi:", girisTarihi)
      } else {
        console.error("API response error:", result.message);
      }
    } catch (error) {
      console.error("API request error:", error);
    } finally {
      setFolioLoading(false);
    }
  };

  const fetchFolioDetailsWithMasterIdRefresh = async (
    MasterIdRefresh: number
  ) => {
    setFolioLoading(true);
    const db_Id = localStorage.getItem("selectedDbId");
    const token = localStorage.getItem("token");

    const requestBody = {
      db_Id: db_Id ? parseInt(db_Id) : null,
      xKumhrk_Re: "T",
      xKumhrk_Rez_id: MasterIdRefresh,
      xtip: 16,
    };

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Procedure/StpKumhrk_Bul",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = response.data;
      if (result.isSucceded) {
        setFolioData(result.value);
        // Diğer gerekli state güncellemelerini yapabilirsiniz
      } else {
        console.error("API response error:", result.message);
      }
    } catch (error) {
      console.error("API request error:", error);
    } finally {
      setFolioLoading(false);
    }
  };

  // input stateleri
  const [depAdi, setDepAdi] = useState("");
  const [depKodu, setDepKodu] = useState("");
  const [borc, setBorc] = useState("");
  const [alacak, setAlacak] = useState("");
  const [kur, setKur] = useState<number>(0);
  const [secilenKurKodu, setSecilenKurKodu] = useState("");
  const [RezId, setRezId] = useState("");
  const [kurAdi, setKurAdi] = useState("");
  const [dovizTutar, setDovizTutar] = useState<number>(0);
  const [R_E, setR_E] = useState("");
  const [Fat_Bölüm, setFat_Bölüm] = useState("");
  const [Çekno, setÇekno] = useState("");
  const [Aciklama, setAciklama] = useState("");
  const [KartNo, setKartNo] = useState("");
  const [odemeTipi, setOdemeTipi] = useState("");
  const [merkezExchange, setMerkezExchange] = useState("");
  const [ga, setGA] = useState("");
  const [onBuroCalismaTarihi, setOnBuroCalismaTarihi] = useState<Date | null>(
    null
  );
  const [folioGirisTarihi, setFolioGirisTarihi] = useState("");
  // input stateleri

  const handleEkle = async () => {
    const dbId = localStorage.getItem("selectedDbId");
    const token = localStorage.getItem("token");

    const requestBody = {
      db_Id: dbId ? parseInt(dbId) : null,
      Id: 0,
      Tarih: onBuroCalismaTarihi,
      Rez_id: RezId,
      Oda: odaNo,
      Dep_kodu: depKodu,
      Me: merkezExchange,
      Tipi: odemeTipi,
      Doviz_kodu: "002",
      Kur: kur,
      Doviz_tutar: dovizTutar,
      Tutar: borc,
      Def_doviz: 2500,
      Odenmez: 0,
      Eski_folio: 0,
      Kartno: KartNo,
      Kart_id: 0,
      Cekno: Çekno,
      Aciklama: Aciklama,
      Safe_no: "",
      Nakit_kredi: "",
      Posting_kodu: "",
      Pos_no: 0,
      Fatura_eh: "",
      Fatura_Id: 0,
      Fatura_no: 0,
      Rezkira_id: 0,
      Kulanici_id: 0,
      Kulanici_kodu: "",
      Sirket: 0,
      Otel: "",
      Yil_kodu: 0,
      Tel_id: 0,
      Foltr_eh: "",
      Eski_oda: "",
      Sistem_unv: "",
      Fatura_kesildi_eh: "",
      Fatura_bolum: 0,
      Pm_eh: "",
      Yazarkasa_10: 0,
      Gunluk_aylik: ga,
      ver_oran: 0,
      ver: 0,
      ver_matrah: 0,
      Kdvyuzde: 0,
    };

    console.log("Gönderilen istek body:", requestBody);

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Kumhrk/Ekle",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // İşlem başarılı olduğunda toast bildirimi göster
      showToast("İşlem tamamlandı");
      fetchFolioDetailsWithMasterIdRefresh(MasterIdRefresh);
      temizle();

      console.log("API response:", response.data);
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  const fetchDonBuroCalismaTarihi = async () => {
    const dbId = localStorage.getItem("selectedDbId");
    const requestBody = {
      db_Id: dbId ? parseInt(dbId) : 9,
      Kod: 1,
      tip: 1,
    };

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Fishrk/Getir_Kod",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.isSucceded) {
        const curtar = new Date(response.data.value.Curtar);
        setOnBuroCalismaTarihi(curtar);
        console.log("Veri çekme başarılı:", response.data.value.Curtar);
      } else {
        console.error("Veri çekme hatası:", response.data.message);
      }
    } catch (error) {
      console.error("API isteği hatası:", error);
    }
  };

  const [departmanlar, setDepartmanlar] = useState<Kod[]>([]);
  const [kurKodlari, setKurKodlari] = useState<Kod[]>([]);

  const fetchKodlar = async () => {
    const dbId = localStorage.getItem("selectedDbId");
    const token = localStorage.getItem("token");

    const requestBody = {
      db_Id: dbId ? parseInt(dbId) : null,
      Sinif: "01,02",
      Kod: "",
      Tip: 888,
    };

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Kodlar/Getir_Kod",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.isSucceded) {
        const kodlar = response.data.value;
        const departmanlar = kodlar.filter((kod: any) => kod.Sinif === "01");
        const kurKodlari = kodlar.filter((kod: any) => kod.Sinif === "02");
        setDepartmanlar(departmanlar);
        setKurKodlari(kurKodlari);
      } else {
        console.error("API response error:", response.data.message);
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  useEffect(() => {
    fetchDonBuroCalismaTarihi();
    fetchKodlar();
  }, []);

  // ga değişikliği fonksiyonu
  const handleGAChange = (event: any) => {
    const selectedValue = event.value;
    let gaKodu = "";

    switch (selectedValue) {
      case "Günlük":
        gaKodu = "G";
        setGA(gaKodu);
        break;
      case "Aylık":
        gaKodu = "A";
        setGA(gaKodu);
        break;
      case "Kendi Kuru":
        gaKodu = "K";
        setGA(gaKodu);
        break;
      case "Rez,Alış":
        gaKodu = "R";
        setGA(gaKodu);
        break;
      case "Özel":
        gaKodu = "O";
        setGA(gaKodu);
        break;
      case "Daily":
        gaKodu = "D";
        setGA(gaKodu);
        break;
      default:
        break;
    }

    setGA(gaKodu);
  };

  // Merkez/Exchange değişikliği fonksiyonu
  const handleMerkezExchangeChange = (event: any) => {
    const selectedValue = event.value;
    let merkezExchangeKodu = "";

    switch (selectedValue) {
      case "Merkez":
        merkezExchangeKodu = "M";
        setMerkezExchange(merkezExchangeKodu);
        break;
      case "Exchange":
        merkezExchangeKodu = "E";
        setMerkezExchange(merkezExchangeKodu);
        break;
      case "Ozel":
        merkezExchangeKodu = "O";
        setMerkezExchange(merkezExchangeKodu);
        break;

      default:
        break;
    }

    setMerkezExchange(merkezExchangeKodu);
  };

  // Ödeme Tipi değişikliği fonksiyonu
  const handleOdemeTipiChange = (event: any) => {
    const selectedValue = event.value;
    let odemeKodu = "";

    switch (selectedValue) {
      case "Satış":
        odemeKodu = "S";
        break;
      case "İndirim":
        odemeKodu = "Z";
        break;
      case "Correction (+)":
        odemeKodu = "C+";
        break;
      case "Correction (-)":
        odemeKodu = "C-";
        break;
      case "Late Charge":
        odemeKodu = "L";
        break;
      case "Nakit":
        odemeKodu = "N";
        break;
      case "Havale":
        odemeKodu = "H";
        break;
      case "Kart":
        odemeKodu = "K";
        break;
      default:
        break;
    }

    setOdemeTipi(odemeKodu);
  };

  const FolioToInput = (event: GridRowClickEvent) => {
    const item = event.dataItem;
    setDepAdi(item["Dep_Adı"] || "");
    setBorc(item["Borç"] || "");
    setAlacak(item["Alacak"] || "");
    setKur(item["Kur"] || "");
    setRezId(item["RezId"] || "");
    setKurAdi(item["KurAdı"] || "");
    setDovizTutar(item["Doviz_Tutar"] || "");
    setDepKodu(item["Dep_Kodu"] || "");
    setR_E(item["R/E"] || "");
    setFat_Bölüm(item["Fat_Bölüm"] || "");
    setÇekno(item["Çekno"] || "");
    setKartNo(item["Kart_Numarası"] || "");
  };

  const TlBorcFooterCell = (props: any) => {
    const total = props.data.reduce((acc: number, item: any) => {
      const borc = parseFloat(item.Borç);
      return acc + (isNaN(borc) ? 0 : borc);
    }, 0);

    const formattedTotal = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(total);

    return (
      <td style={{ textAlign: "center" }}>
        <strong style={{ color: "#218c74" }}>{formattedTotal} ₺</strong>
      </td>
    );
  };
  const TlAlacakFooterCell = (props: any) => {
    const total = props.data.reduce((acc: number, item: any) => {
      const alacak = parseFloat(item.Alacak);
      return acc + (isNaN(alacak) ? 0 : alacak);
    }, 0);

    const formattedTotal = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(total);

    return (
      <td style={{ textAlign: "center" }}>
        <strong style={{ color: "#cd6133" }}>{formattedTotal} ₺</strong>
      </td>
    );
  };
  const DovizBorcFooterCell = (props: any) => {
    const total = props.data.reduce((acc: number, item: any) => {
      const dovizBorc = parseFloat(item.P_Borç);
      return acc + (isNaN(dovizBorc) ? 0 : dovizBorc);
    }, 0);

    const formattedTotal = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(total);

    return (
      <td style={{ textAlign: "center" }}>
        <strong style={{ color: "#218c74" }}>{formattedTotal}</strong>
      </td>
    );
  };
  const DovizAlacakFooterCell = (props: any) => {
    const total = props.data.reduce((acc: number, item: any) => {
      const dovizAlacak = parseFloat(item.P_Alacak);
      return acc + (isNaN(dovizAlacak) ? 0 : dovizAlacak);
    }, 0);

    const formattedTotal = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(total);

    return (
      <td style={{ textAlign: "center" }}>
        <strong style={{ color: "#cd6133" }}>{formattedTotal}</strong>
      </td>
    );
  };

  // herşeyi temizleme işlemleri
  const temizle = () => {
    setOdaNo("");
    setRezId("");
    setDepAdi("");
    setDepKodu("");
    setBorc("");
    setAlacak("");
    setKur(0);
    setKurAdi("");
    setDovizTutar(0);
    setR_E("");
    setFat_Bölüm("");
    setÇekno("");
    setKartNo("");
    setOdemeTipi("");
    setMerkezExchange("");
    setGA("");
    // setOnBuroCalismaTarihi(null);
    // setData([]);
    setFolioData([]);
    setBorcTL("0.00");
    setAlacakTL("0.00");
    setBakiyeTL("0.00");
    setDovizBorc("0.00");
    setDovizAlacak("0.00");
    setDovizBakiye("0.00");
    setOdemeTipi("");
    setMerkezExchange("");
    setGA("");
    setAciklama("");
  };

  const temizle2 = () => {
    // setOdaNo("");
    setRezId("");
    setDepAdi("");
    setDepKodu("");
    setBorc("");
    setAlacak("");
    setKur(0);
    setKurAdi("");
    setDovizTutar(0);
    setR_E("");
    setFat_Bölüm("");
    setÇekno("");
    setKartNo("");
    setOdemeTipi("");
    setMerkezExchange("");
    setGA("");
    // setOnBuroCalismaTarihi(null);
    setData([]);
    setFolioData([]);
    setBorcTL("0.00");
    setAlacakTL("0.00");
    setBakiyeTL("0.00");
    setDovizBorc("0.00");
    setDovizAlacak("0.00");
    setDovizBakiye("0.00");
    setOdemeTipi("");
    setMerkezExchange("");
    setGA("");
    setAciklama("");
  };

  const formatDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  // useEffect(() => {
  //     temizle();
  // },);

  // herşeyi temizleme işlemleri
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 m-0 p-0">
            <UstMenuBar>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-3 d-flex justify-content-center align-items-center">
                    <RadioButton
                      label={"Inhouse"}
                      checked={selectedVeriTipi === "I"}
                      onChange={() => {
                        setSelectedVeriTipi("I");
                      }}
                    />

                    <RadioButton
                      className="ms-2"
                      label={"History"}
                      checked={selectedVeriTipi === "H"}
                      onChange={() => {
                        setSelectedVeriTipi("H");
                      }}
                      // disabled={true}
                    />
                  </div>
                  <div className="col-md-3 col-md-3 d-flex justify-content-center align-items-center">
                    <RadioButton
                      label={"Room"}
                      checked={selectedFolioTipi === "R"}
                      onChange={() => {
                        setSelectedFolioTipi("R");
                      }}
                    />

                    <RadioButton
                      className="ms-2"
                      label={"Extra"}
                      checked={selectedFolioTipi === "E"}
                      onChange={() => {
                        setSelectedFolioTipi("E");
                      }}
                    />

                    <RadioButton
                      className="ms-2"
                      label={"All"}
                      checked={selectedFolioTipi === "T"}
                      onChange={() => {
                        setSelectedFolioTipi("T");
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-md-3 d-flex justify-content-center align-items-center">
                    <div className="btn-group" role="group">
                      {Array.from({ length: 5 }, (_, i) => (
                        <button key={i} className="btn btn-primary">
                          <i className="fas fa-user"></i> {i + 1}.kişi
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3 d-flex justify-content-center align-items-center">
                    <div className="row">
                      <div className="div w-40 d-flex justify-content-center align-items-center">
                        Tek Folio Arama
                      </div>
                      <Input className="w-60" placeholder="Folio No Giriniz" />
                    </div>
                  </div>
                </div>
              </div>
            </UstMenuBar>
          </div>
        </div>
        <div className="row">
          <UstMenuBar>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-1">
                  <GeneralButton
                    text="Yenile"
                    icon={faSync}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="Sil"
                    icon={faTrash}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="Temizle"
                    icon={faEraser}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={temizle}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="Print"
                    icon={faPrint}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="C-Out"
                    icon={faSignOutAlt}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="Fatura"
                    icon={faFileInvoice}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="İşlemler"
                    icon={faPlus}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="Formlar"
                    icon={faSearch}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="Kapat"
                    icon={faTimes}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="Aç"
                    icon={faUnlockAlt}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="Pdf"
                    icon={faHourglassHalf}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
                <div className="col-md-1">
                  <GeneralButton
                    text="Kaydet"
                    icon={faSave}
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                    hoverGradientStart={hoverGradientStart}
                    hoverGradientEnd={hoverGradientEnd}
                    onClick={() => {}}
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </UstMenuBar>
        </div>
        <div className="row">
          <div className="col-md-12 m-0 p-0">
            <Tabs defaultActiveKey="Genel" id="uncontrolled-tab-example">
              <Tab eventKey="Genel" title="Genel">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 d-flex align-items-center p-0 m-0">
                      <div className="container-fluid m-0 p-0">
                        <div className="row">
                          <div className="col-md-3 mt-1">
                            <Card>
                              <Card.Header>
                                <Card.Title>Genel Bilgiler</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className=" d-flex flex-column">
                                      <div className="w-100 d-flex  justify-content-between align-items-center">
                                        <div className="div w-20 text-start">
                                          Oda No
                                        </div>
                                        <div className="div w-40">
                                          <Input
                                            value={odaNo}
                                            onChange={(e) =>
                                              setOdaNo(e.target.value as string)
                                            }
                                            onKeyDown={OdaNoFolioCagir}
                                          />
                                        </div>
                                        <div className="div w-40 ms-1">
                                          <Input value={RezId} />
                                        </div>
                                      </div>
                                      <div className="w-100 d-flex  justify-content-between align-items-center mt-1 ">
                                        <div className=" w-20  text-start">
                                          Departman
                                        </div>
                                        <div className=" w-20">
                                          <Input value={depKodu} />
                                        </div>
                                        <div className=" w-60 ms-1">
                                          <ComboBox
                                            placeholder="Departman Seçin"
                                            value={depAdi}
                                            data={departmanlar.map(
                                              (dep: any) => dep.Ad
                                            )}
                                            onChange={(e) => {
                                              const selectedDep =
                                                departmanlar.find(
                                                  (dep: any) =>
                                                    dep.Ad === e.value
                                                );
                                              if (selectedDep) {
                                                setDepAdi(selectedDep.Ad);
                                                setDepKodu(selectedDep.Kod);
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                        <div className="div w-20  text-start">
                                          Kart No
                                        </div>
                                        <div className="div w-80">
                                          <Input
                                            value={KartNo}
                                            onChange={(e) =>
                                              setKartNo(
                                                e.target.value as string
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                        <div className="div w-20  text-start">
                                          Ödeme Tipi
                                        </div>
                                        <div className="div w-30">
                                          <ComboBox
                                            data={[
                                              "Satış",
                                              "İndirim",
                                              "Correction (+)",
                                              "Correction (-)",
                                              "Late Charge",
                                              "Nakit",
                                              "Havale",
                                              "Kart",
                                            ]}
                                            onChange={handleOdemeTipiChange}
                                          />
                                        </div>
                                        <div className="div w-20  text-start ms-1">
                                          G/A
                                        </div>
                                        <div className="div w-30">
                                          <ComboBox
                                            data={[
                                              "Günlük",
                                              "Aylık",
                                              "Kendi Kuru",
                                              "Rez,Alış",
                                              "Özel",
                                              "Daily",
                                            ]}
                                            onChange={handleGAChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                        <div className="div w-20  text-start">
                                          Merkez/Ec
                                        </div>
                                        <div className="div w-30">
                                          <ComboBox
                                            data={[
                                              "Merkez",
                                              "Exchange",
                                              "Ozel",
                                            ]}
                                            onChange={
                                              handleMerkezExchangeChange
                                            }
                                          />
                                        </div>
                                        <div className=" w-20  text-start ms-1">
                                          Geliş Say
                                        </div>
                                        <div className=" w-30">
                                          <Input />
                                        </div>
                                      </div>
                                      <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                        <div className="div w-20  text-start">
                                          Bölüm
                                        </div>
                                        <div className="div w-80">
                                          <ComboBox value={Fat_Bölüm} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                          <div className="col-md-2 mt-1 m-0 p-0">
                            <Card>
                              <Card.Header>
                                <Card.Title>Kur Bilgileri</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <div className="w-100 d-flex  justify-content-between align-items-center">
                                  <div className="div w-30  text-start ms-1">
                                    Döviz
                                  </div>
                                  <div className="div w-70">
                                    <ComboBox
                                      data={kurKodlari.map(
                                        (kur: any) => kur.Ad
                                      )}
                                      value={kurAdi}
                                      onChange={async (e) => {
                                        const selectedKur = kurKodlari.find(
                                          (kur: any) => kur.Ad === e.value
                                        );
                                        if (selectedKur) {
                                          setSecilenKurKodu(selectedKur.Kod);
                                          setKurAdi(selectedKur.Ad);

                                          const db_Id =
                                            localStorage.getItem(
                                              "selectedDbId"
                                            );
                                          const token =
                                            localStorage.getItem("token");

                                          const requestBody = {
                                            db_Id: db_Id
                                              ? parseInt(db_Id)
                                              : null,
                                            xRez_Sirket: 1,
                                            xtip: 1,
                                            kur_Gunluk_Aylik: ga,
                                            giristar:
                                              formatDate(folioGirisTarihi), // Tarih formatını dönüştürme
                                            me: merkezExchange,
                                            da: "DA",
                                            nereden: "KUM",
                                            kur_Kodu: selectedKur.Kod,
                                            dep_Kodu: depKodu,
                                          };

                                          console.log(
                                            "API request body:",
                                            requestBody
                                          ); // İstek body’sini konsola yazdırıyoruz

                                          try {
                                            const response = await axios.post(
                                              "https://frontapi.rmosweb.com/api/Procedure/StpKur_getir",
                                              requestBody,
                                              {
                                                headers: {
                                                  "Content-Type":
                                                    "application/json",
                                                  Authorization: `Bearer ${token}`,
                                                },
                                              }
                                            );

                                            console.log(
                                              "API response:",
                                              response.data
                                            ); // API yanıtını konsola yazdırıyoruz

                                            const result = response.data;
                                            if (result.isSucceded) {
                                              const kurDegeri = parseFloat(
                                                result.value[0].Kur
                                              );
                                              setKur(kurDegeri); // Kur inputunu güncelle
                                              const borcDegeri =
                                                dovizTutar * kurDegeri;
                                              setBorc(borcDegeri.toFixed(2)); // Borç inputunu güncelle
                                            } else {
                                              console.error(
                                                "API response error:",
                                                result.message
                                              );
                                            }
                                          } catch (error) {
                                            console.error(
                                              "API request error:",
                                              error
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    Dv Tutar
                                  </div>
                                  <div className="div w-70">
                                    <Input
                                      value={dovizTutar}
                                      onChange={(e) =>
                                        setDovizTutar(
                                          parseFloat(e.target.value as any)
                                        )
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          const borcDegeri = dovizTutar * kur;
                                          setBorc(borcDegeri.toFixed(2));
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    Kur
                                  </div>
                                  <div className="div w-70">
                                    <Input value={kur} />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    TL Karşılık
                                  </div>
                                  <div className="div w-70">
                                    <Input
                                      value={borc}
                                      onChange={(e) =>
                                        setBorc(e.target.value as any)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    Çek No
                                  </div>
                                  <div className="div w-70">
                                    <Input
                                      value={Çekno}
                                      onChange={(e) =>
                                        setÇekno(e.target.value as any)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-100">
                                    <Input
                                      value={Aciklama}
                                      onChange={(e) =>
                                        setAciklama(e.target.value as any)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-md-12 p-0 m-0">
                                      <GeneralButton
                                        text="Ekle"
                                        height="25px"
                                        icon={faPlusCircle}
                                        gradientStart="#1B5E20"
                                        gradientEnd="#388E3C"
                                        hoverGradientStart="#2E7D32"
                                        hoverGradientEnd="#43A047"
                                        onClick={handleEkle}
                                        width="100%"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                          <div className="col-md-2 mt-1">
                            <Card>
                              <Card.Header>
                                <Card.Title>Bakiye Bilgileri</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <div className="w-100 d-flex  justify-content-between align-items-center">
                                  <div className="div w-30  text-start ms-1">
                                    Borç TL
                                  </div>
                                  <div className="div w-70">
                                    <Input value={formattedBorcTL} />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    Alacak
                                  </div>
                                  <div className="div w-70">
                                    <Input value={formattedAlacakTL} />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    Bakiye TL
                                  </div>
                                  <div className="div w-70">
                                    <Input
                                      value={formattedBakiyeTL}
                                      style={{
                                        backgroundColor: "#eb3b5a",
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    Dv Borç
                                  </div>
                                  <div className="div w-70">
                                    <Input value={formattedDovizBorc} />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    Dv Alacak
                                  </div>
                                  <div className="div w-70">
                                    <Input value={formattedDovizAlacak} />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    Dv Bakiye
                                  </div>
                                  <div className="div w-70">
                                    <Input
                                      value={formattedDovizBakiye}
                                      style={{
                                        backgroundColor: "#a5d8ff",
                                        fontWeight: "bold",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-30  text-start ms-1">
                                    Parite
                                  </div>
                                  <div className="div w-70">
                                    <ComboBox
                                      data={kurKodlari.map(
                                        (kur: any) => kur.Ad
                                      )}
                                      onChange={async (e) => {
                                        const selectedKur = kurKodlari.find(
                                          (kur: any) => kur.Ad === e.value
                                        );
                                        if (selectedKur) {
                                          setKurAdi(selectedKur.Ad);
                                          const dbId =
                                            localStorage.getItem(
                                              "selectedDbId"
                                            );
                                          const token =
                                            localStorage.getItem("token");

                                          const requestBody = {
                                            db_Id: dbId ? parseInt(dbId) : 9,
                                            xKumhrk_Re: "T",
                                            xKumhrk_Rez_id: RezId,
                                            xtip: 16,
                                            parite_Doviz_Kodu: selectedKur.Kod,
                                          };

                                          setFolioLoading(true);
                                          try {
                                            const response = await axios.post(
                                              "https://frontapi.rmosweb.com/api/Procedure/StpKumhrk_Bul",
                                              requestBody,
                                              {
                                                headers: {
                                                  "Content-Type":
                                                    "application/json",
                                                  Authorization: `Bearer ${token}`,
                                                },
                                              }
                                            );

                                            if (response.data.isSucceded) {
                                              setFolioData(response.data.value);
                                            } else {
                                              console.error(
                                                "API response error:",
                                                response.data.message
                                              );
                                            }
                                          } catch (error) {
                                            console.error(
                                              "API request error:",
                                              error
                                            );
                                          } finally {
                                            setFolioLoading(false);
                                          }
                                        }
                                      }}
                                      value={kurAdi}
                                    />
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                          <div className="col-md-5 m-0 p-0 mt-1">
                            <Card>
                              <CardHeader>
                                <Card.Title>Folio Bilgileri</Card.Title>
                              </CardHeader>
                              <CardBody>
                                {isLoading ? (
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: "200px" }}
                                  >
                                    <Spinner
                                      style={{ width: "3rem", height: "3rem" }}
                                    />
                                  </div>
                                ) : (
                                  <Grid
                                    data={processedData}
                                    pageable={false}
                                    pageSize={dataState.take}
                                    total={data.length}
                                    groupable={false}
                                    filterable={false}
                                    sortable={true}
                                    onDataStateChange={onGridDataStateChange}
                                    {...dataState}
                                    reorderable={true}
                                    resizable={true}
                                    style={{ height: "200px" }}
                                    onRowClick={FolioNoDetayListele}
                                  >
                                    <Column
                                      field="M/D"
                                      title="M/D/E"
                                      className="text-center"
                                    />
                                    <Column
                                      field="Master_id"
                                      title="Folio"
                                      className="text-center"
                                    />
                                    <Column field="Adı" title="Adı" />
                                    <Column field="Soyadı" title="Soyadı" />
                                    <Column
                                      field="Giriş Tarihi"
                                      title="Giriş Tarihi"
                                      className="text-center"
                                    />
                                    <Column
                                      field="Çıkış Tarihi"
                                      title="Çıkış Tarihi"
                                      className="text-center"
                                    />
                                    <Column
                                      field="Acenta"
                                      title="Acenta"
                                      className="text-center"
                                    />
                                    {/* <Column field="Odano" title="Oda No" />
                  <Column field="Voucher" title="Voucher" /> */}
                                  </Grid>
                                )}
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Card className="mt-1">
                  <CardHeader>
                    <Card.Title>Folio</Card.Title>
                  </CardHeader>
                  <CardBody>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12 p-0 m-0">
                          {folioIsLoading ? (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "350px" }}
                            >
                              <Spinner
                                style={{ width: "3rem", height: "3rem" }}
                              />
                            </div>
                          ) : (
                            <Grid
                              data={processedFolioData}
                              pageable={false}
                              pageSize={dataState.take}
                              total={folioData.length}
                              groupable={false}
                              filterable={false}
                              sortable={true}
                              onDataStateChange={onGridDataStateChange}
                              {...dataState}
                              reorderable={true}
                              resizable={true}
                              style={{ height: "350px" }}
                              onRowClick={FolioToInput}
                            >
                              <Column
                                field="Tarih"
                                title="Tarih"
                                className="text-center"
                                footerCell={() => (
                                  <td style={{ textAlign: "center" }}>
                                    <strong
                                      style={{ color: "#218c74" }}
                                    >{`${folioData.length}`}</strong>
                                  </td>
                                )}
                              />
                              <Column field="Dep_Adı" title="Departman" />
                              <Column
                                field="BA"
                                title="BA"
                                className="text-center"
                              />
                              <Column
                                field="Borç"
                                title="Borç"
                                footerCell={() => (
                                  <TlBorcFooterCell data={folioData} />
                                )}
                                className="text-center"
                              />
                              <Column
                                field="Alacak"
                                title="Alacak"
                                footerCell={() => (
                                  <TlAlacakFooterCell data={folioData} />
                                )}
                                className="text-center"
                              />
                              <Column
                                field="Bakiye"
                                title="Bakiye"
                                className="text-center"
                              />
                              <Column
                                field="Çekno"
                                title="Çek No"
                                className="text-center"
                              />
                              <Column
                                field="Kur"
                                title="Kur"
                                className="text-center"
                              />
                              <Column
                                field="Açıklama"
                                title="Açıklama"
                                className="text-center"
                              />
                              <Column
                                field="R/E"
                                title="R/E"
                                className="text-center"
                              />
                              <Column
                                field="F_Bölüm"
                                title="F. Bölüm"
                                className="text-center"
                              />
                              <Column
                                field="KurAdı"
                                title="Kur Adı"
                                className="text-center"
                              />
                              <Column
                                field="P_Borç"
                                title="Doviz Borç"
                                footerCell={() => (
                                  <DovizBorcFooterCell data={folioData} />
                                )}
                                className="text-center"
                              />
                              <Column
                                field="P_Alacak"
                                title="Doviz Alacak"
                                footerCell={() => (
                                  <DovizAlacakFooterCell data={folioData} />
                                )}
                                className="text-center"
                              />

                              <Column
                                field="P_Bakiye"
                                title="Doviz Bakiye"
                                className="text-center"
                              />

                              {/* <Column field="Dep.Kodu" title="Dep. Kodu" /> */}
                              {/* <Column field="Doviz Tutar" title="Doviz Tutar" />
                        <Column field="P.Doviz Adi" title="P. Doviz Adi" />
                        <Column field="P.Borç" title="P. Borç" />
                        <Column field="P.Alacak" title="P. Alacak" />
                        <Column field="P.Bakiye" title="P. Bakiye" />
                        <Column field="P.Kur" title="P. Kur" /> */}
                              {/* <Column field="sec" title="Sec" /> */}
                              {/* <Column field="Id" title="ID" /> */}
                              {/* <Column field="Kulanıcı" title="Kullanıcı" /> */}
                              {/* <Column field="Sistem" title="Sistem" /> */}
                              {/* <Column field="F(E/H)" title="F(E/H)" /> */}
                              {/* <Column field="FaturaNo" title="Fatura No" /> */}
                              {/* <Column field="Kart Numarası" title="Kart Numarası" /> */}
                              {/* <Column field="Eski Folio" title="Eski Folio" /> */}
                              {/* <Column field="Zaman" title="Zaman" /> */}
                              {/* <Column field="Tel.Açıklama" title="Tel. Açıklama" /> */}
                              {/* <Column field="TelNo" title="Tel No" /> */}
                              {/* <Column field="Def.Borç" title="Def. Borç" /> */}
                              {/* <Column field="Def.Alacak" title="Def. Alacak" />
                        <Column field="Def.Bakiye" title="Def. Bakiye" /> */}
                              {/* <Column field="Post.Kodu" title="Post Kodu" /> */}
                              {/* <Column field="FBD" title="FBD" /> */}
                              {/* <Column field="Ödenmez" title="Ödenmez" /> */}
                              {/* <Column field="Oda Tutar" title="Oda Tutar" /> */}
                              {/* <Column field="Extra Tutar" title="Extra Tutar" /> */}
                              {/* <Column field="Kur(GAY)" title="Kur(GAY)" /> */}
                              {/* <Column field="Edevlet(EH)" title="Edevlet(EH)" /> */}
                              {/* <Column field="KartNo" title="Kart No" /> */}
                              {/* <Column field="Istenen" title="Istenen" /> */}
                              {/* <Column field="Verilen" title="Verilen" /> */}
                              {/* <Column field="Roda" title="Roda" /> */}
                              {/* <Column field="Radi" title="Radi" /> */}
                              {/* <Column field="Rsoyadi" title="Rsoyadi" /> */}
                              {/* <Column field="Eski Oda" title="Eski Oda" /> */}
                              {/* <Column field="RezId" title="RezId" /> */}
                              {/* <Column field="OdaNo" title="Oda No" /> */}
                            </Grid>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 mt-1 m-0 p-0">
                      <Card>
                        <Card.Header>
                          <Card.Title>Notlar</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <div className="div w-100 mt-1">
                            <TextArea />
                          </div>
                          <div className="div w-100 mt-1">
                            <TextArea
                              style={{
                                backgroundColor: "rgba(255, 255, 204, 0.9)",
                              }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Ozet" title="Özet">
                <div className="container-fluid m-0 p-0 mt-1">
                  <div className="row">
                    <div className="col-md-4">
                      <Card>
                        <CardHeader>
                          <Card.Title>Özet 1</Card.Title>
                        </CardHeader>
                        <CardBody>
                          <Grid
                            data={processedData}
                            pageable={true}
                            pageSize={dataState.take}
                            total={data.length}
                            groupable={false}
                            filterable={false}
                            sortable={true}
                            onDataStateChange={onGridDataStateChange}
                            {...dataState}
                            reorderable={true}
                            resizable={true}
                            style={{ height: "500px" }}
                          >
                            <Column field="AktiviteAdi" title="Aktivite Adı" />
                            <Column field="FirmaAdi" title="Firma Adı" />
                            <Column field="Kontak" title="Kontak" />
                            <Column field="Iletişim" title="İletişim" />
                            <Column
                              field="BaslangicTarihi"
                              title="Başlangıç Tarihi"
                            />
                            <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                            <Column
                              field="PersonelAdsoyad"
                              title="Personel Ad Soyad"
                            />
                          </Grid>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-md-4">
                      <Card>
                        <CardHeader>
                          <Card.Title>Özet 2</Card.Title>
                        </CardHeader>
                        <CardBody>
                          <Grid
                            data={processedData}
                            pageable={true}
                            pageSize={dataState.take}
                            total={data.length}
                            groupable={false}
                            filterable={false}
                            sortable={true}
                            onDataStateChange={onGridDataStateChange}
                            {...dataState}
                            reorderable={true}
                            resizable={true}
                            style={{ height: "500px" }}
                          >
                            <Column field="AktiviteAdi" title="Aktivite Adı" />
                            <Column field="FirmaAdi" title="Firma Adı" />
                            <Column field="Kontak" title="Kontak" />
                            <Column field="Iletişim" title="İletişim" />
                            <Column
                              field="BaslangicTarihi"
                              title="Başlangıç Tarihi"
                            />
                            <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                            <Column
                              field="PersonelAdsoyad"
                              title="Personel Ad Soyad"
                            />
                          </Grid>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-md-4">
                      <Card>
                        <CardHeader>
                          <Card.Title>Özet 3</Card.Title>
                        </CardHeader>
                        <CardBody>
                          <Grid
                            data={processedData}
                            pageable={true}
                            pageSize={dataState.take}
                            total={data.length}
                            groupable={false}
                            filterable={false}
                            sortable={true}
                            onDataStateChange={onGridDataStateChange}
                            {...dataState}
                            reorderable={true}
                            resizable={true}
                            style={{ height: "500px" }}
                          >
                            <Column field="AktiviteAdi" title="Aktivite Adı" />
                            <Column field="FirmaAdi" title="Firma Adı" />
                            <Column field="Kontak" title="Kontak" />
                            <Column field="Iletişim" title="İletişim" />
                            <Column
                              field="BaslangicTarihi"
                              title="Başlangıç Tarihi"
                            />
                            <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                            <Column
                              field="PersonelAdsoyad"
                              title="Personel Ad Soyad"
                            />
                          </Grid>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="KartHesaplari" title="Kart Hesapları">
                Kart Hesapları
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
export default HesapBakma;
