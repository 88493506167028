import React, { useState } from "react";
import LoyaltyMisafirTabPanel from "./MisafirIslemleri/MisafirIslemleri";
import { useOtelGuid } from "../../../../Context/LocalStrogeContext/otelGuidContext";

export default function MisafirIslemleriMenu() {
    const { otelGuid, setOtelGuid } = useOtelGuid();
    

  return (
    <div className="contianer">
        {/* secili otel guid : {otelGuid} */}
      <div className="row">
        <div className="col-12 ">
          <LoyaltyMisafirTabPanel otelGuid={otelGuid} />
        </div>
      </div>
    </div>
  );
}
