import React, { useEffect, useState, KeyboardEvent } from "react";
import { backApi } from "./HesapPlaniApi";
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridCellProps,
  GridRowProps,
  GridRow,
  GridHandle,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process } from "@progress/kendo-data-query";
import { on } from "events";
import Modal from "../../../../Modal/Modal/Modal";
import {
  MuhPlanDetay,
  HesapPlaniEkleProps,
  MuhPlanDetayListe,
} from "./HesapPlaniType";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import {
  faCheck,
  faClose,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Tab, Tabs } from "react-bootstrap";
import { Button } from "@progress/kendo-react-buttons";
import { FaBeer } from "react-icons/fa";
import InfoPanel from "../../../../../Components/InfoPanel/InfoPanel";
import { group } from "console";
interface ApiResponse {
  data: string;
}
interface searchText {
  text: string;
}
const customRowRender = (
  row: React.ReactElement<HTMLTableRowElement>,
  rowProps: GridRowProps
) => {
  let backgroundColor = "";
  let textColor = "black"; // default text color

  const rowValue = rowProps.dataItem["Grup"]; // Directly access the 'Grup' field

  switch (rowValue) {
    case 0:
      backgroundColor = "#A9A9A9"; // Dark gray
      break;
    case 1:
      backgroundColor = "#D3D3D3"; // Light gray
      break;
    case 2:
      backgroundColor = "#f1f1f1"; // White
      break;
    default:
      backgroundColor = ""; // Default or transparent color
      break;
  }

  // Set text color to black for 'Ana' and 'Ara'
  if (rowValue === 0 || rowValue === 1) {
    textColor = "black";
  }

  // Return cloned element with directly applied styles
  return React.cloneElement(row, {
    style: {
      ...row.props.style,
      backgroundColor,
      color: textColor,
      fontWeight: rowValue === 0 || rowValue === 1 ? "bold" : "normal",
    }, // Merging existing styles with new background color and text color
  });
};
// Fonksiyon, Grup alanının sayısal değerlerini alır ve bunları uygun metinlere dönüştürür
const mapGrupValueToText = (value: number): string => {
  switch (value) {
    case 0:
      return "Ana";
    case 1:
      return "Ara";
    case 2:
      return "Alt";
    default:
      return "";
  }
};
const ActiveCell = (props: GridCellProps) => (
  <td
    className={`text-align-center ${
      props.dataItem[props.field || ""] ? "green-background" : ""
    }`}
  >
    <input
      type="checkbox"
      id="singleMode"
      className="k-checkbox k-checkbox-md k-rounded-md "
      checked={props.dataItem[props.field || ""]}
      disabled
    />
  </td>
);

const HesapPlani: React.FC = () => {
  const [dataState, setDataState] = useState<any>({
    skip: 0,
    take: 50,
    filter: null,
    sort: [],
    group: [],
  });
  const [muhPlanListesi, setMuhPlanListesi] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  // Seçilen satırın verisini tutacak yeni state
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [muhPlanDetay, setMuhPlanDetay] = useState<MuhPlanDetay[]>([]);
  const [isLoading, setIsLoading] = useState(false); // Yüklenme durumunu takip edecek yeni state
  const [responseTime, setResponseTime] = useState<string | null>(null);
  const [dataProcessingTime, setDataProcessingTime] = useState("");
  const [apiResponseTimes, setApiResponseTimes] = useState<number[]>([]);
  const [dataProcessingTimes, setDataProcessingTimes] = useState<number[]>([]);
  const [averageResponseTimes, setAverageResponseTimes] = useState<number[]>(
    []
  );
  const [averageDataProcessingTimes, setAverageDataProcessingTimes] = useState<
    number[]
  >([]);
  const [filteredData, setFilteredData] = useState<MuhPlanDetay[]>([]);
  const [searchText, setSearchText] = useState<searchText>({ text: "" });
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (searchText.text.trim()) {
      handleSearch();
    }
  }, [searchText]); // searchText değiştiğinde ve liveSearch açıksa otomatik arama yap

  const handleFilterChange = (event: any) => {
    setDataState({
      ...dataState,
      filter: event.filter,
    });
  };

  const handleSortChange = (e: any) => {
    setDataState({
      ...dataState,
      sort: e.sort,
    });
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const muhPlanListesiCek = async () => {
    const startTime = performance.now(); // İstek başlangıç zamanı
    setIsLoading(true);
    const response = await backApi.MuhPlanGetir();
    const endTime = performance.now(); // İstek bitiş zamanı

    if (response && response.isSucceded) {
      setFilteredData(response.value);
      setMuhPlanListesi(response.value);
    }
    else {
      setFilteredData([{
        Kod: "123",
        Ad: "Test Adı",
        Grup: "0",
        Dovizli: true,
        dovizkodu: "USD",
        borc: 1000,
        alacak: 500,
        bakiye: 500,
        d_Borc: 2000,
        d_Alacak: 1000,
        d_Bakiye: 1000,
        Muhcari: 3000,
        Vade: 30,
        Iskonto: 10,
        Resmiad: "Resmi Ad",
        Vergidaire: "Vergi Dairesi",
        Vergino: "1234567890",
        Adres1: "Adres 1",
        Adres2: "Adres 2",
        Ilce: "Ilce",
        Il: "Il",
        Posta: "12345",
        Tel1: "1234567890",
        Tel2: "0987654321",
        Csm1: "Csm1",
        Csm2: "Csm2",
        Fax: "1234567890",
        Eposta: "test@test.com",
        Web: "www.test.com",
        Yetkili: "Yetkili",
        Banka1: "Banka1",
        Banka2: "Banka2",
        Banka3: "Banka3",
        Hesap1: "Hesap1",
        Hesap2: "Hesap2",
        Hesap3: "Hesap3",
        Iban1: "TR12345678901234567890",
        Iban2: "TR09876543210987654321",
        Iban3: "TR12345678901234567890",
        Nakitkod: "Nakit Kod",
        Efatura: true,
        Senaryo: "Senaryo",
        Yan_Borc: "100",
        Yan_Alacak: "50",
        Onaysiz: false,
        Sube: "Sube",
        Saticino: "123",
        Avanshesap: "Avans Hesap",
        Avansoran: 10,
        TcKimlik: "12345678901",
        Kayittarih: "2022-01-01",
        Kurfarki: true,
        Ulke: "Türkiye",
        Halsis_Ulke: "Türkiye",
        Halsis_Il: "İstanbul",
        Halsis_Ilce: "Kadıköy",
        Halsis_Belde: "Belde",
        Halsis_Sifat: "Sıfat",
        Halsis_Isyerituru: "İşyeri Türü",
        Halsis_Depo: "Depo",
        Halsis_Sube: "Şube",
        Halsis_Halici: "Halici",
        Etiket: "Etiket",
        Babs: true,
        Calismasekli: "Çalışma Şekli",
        Not: "Not",
        Soyad: "Soyad",
        Mersisno: "1234567890",
        Sicilno: "123456",
        Malverenno: "123456",
        Alternatif1: "Alternatif1",
        Alternatif2: "Alternatif2",
        Alternatif3: "Alternatif3",
        cari_banka_sube_kodu: "123",
        cari_banka_sube_adi: "Şube Adı",
        cari_banka_hesap_adi: "Hesap Adı",
        EpostaMutabakat: "mutabakat@test.com",
        EpostaSatinalma: "satinalma@test.com",
        MasrafZorunlu: true,
        LogoVade: "30",
        Kamu: true,
        EpostaMutabakatHesap: "mutabakathesap@test.com",
        RiskLimit: 10000,
        Kdv: 18,
        Ticimax: true,
        LogoHesap: "Logo Hesap",
        KarsiHesap: "Karşı Hesap",
        LogoId: "123",
        EntegrasyonKodu: "123",
        Yurtdisi: false
    }])}
    setIsLoading(false);

    const durationInSeconds = (endTime - startTime) / 1000;
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    setResponseTime(`${minutes} dakika : ${seconds} saniye`);
  };

  useEffect(() => {
    muhPlanListesiCek();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="div">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <div className="loader mx-auto"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    ); // Spinner göster
  }

  const handlePageChange = (event: GridPageChangeEvent) => {
    setDataState({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  // Çift tıklanan satırın verisini alacak fonksiyon
  const onRowDoubleClick = (event: any) => {
    setSelectedRow(event.dataItem);

    // kod alanını bir değişkene ata
    const kod = event.dataItem.Kod;
    openModal();

    // Seçilen satırın verisini bir alert ile göster
    // alert(`Seçilen satır: ${JSON.stringify(kod)}`);
  };

  const cellWithConditionalStyling = (props: any) => {
    let style = {};
    // Bu örnekte, değer direkt olarak true ile karşılaştırılıyor
    if (props.dataItem[props.field] === true) {
      style = { backgroundColor: "#00800080" }; // Değeri true olan hücreler için yeşil arkaplan
    }

    return <td style={style}>{props.dataItem[props.field].toString()}</td>;
  };
  const ModalAc = () => {
    openModal();
  };
  // Özelleştirilmiş hücre bileşeni
  const handleSearch = () => {
    if (!searchText.text.trim()) {
      // searchText boş ise tüm veriyi göster
      setFilteredData(muhPlanListesi);
    } else {
      const lowercasedFilter = searchText.text.toLowerCase();
      const filtered = muhPlanListesi.filter((item) =>
        Object.keys(item).some(
          (key) =>
            item[key] &&
            item[key].toString().toLowerCase().includes(lowercasedFilter)
        )
      );
      setFilteredData(filtered);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText({ text: e.target.value });
  };

  const processedData = process(filteredData, dataState);

  const veriara = () => {
    let a = document.getElementById("veriara") as HTMLInputElement;
    if (a.value === "") {
      console.log("a yok");
      setFilteredData(muhPlanListesi);
    }
    setSearchText({ text: a.value });
    console.log(a.value);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      veriara(); // Enter tuşuna basıldığında veriAra fonksiyonunu çağır
    }
  };
  const toggleFilter = () => {
    setIsDisabled(!isDisabled);
  };

  
  return (
    <div className="container-fluid">
      <div className="row">
        <div>{dataProcessingTime}</div>

        {/* <input type="text" value={searchText.text} onChange={handleInputChange} placeholder="Ara..." /> */}
        <div></div>
        <div className="col-12">
          <h1>Hesap Planı</h1>
        </div>
        <button onClick={ModalAc}>Ekle</button>
        <HesapPlaniEkle
          isOpen={isModalOpen}
          onClose={closeModal}
          hesapKodu={selectedRow?.Kod}
        />
        <div className="col-12 p-2 r-shadow-sm mt-2">
          <div className="col-12 d-flex flex-end">
            <div className="col-12 mt-2 d-flex align-items-center ">
              <div className="col-2">
                <label className="fw-bold">Kayıt Sayısı:</label>
                <span className="ms-2"></span>
                <DropDownList
                  data={[10, 20, 50, 100]}
                  value={pageSize}
                  onChange={(e) => {
                    const newPageSize = e.target.value;
                    setDataState({ ...dataState, take: newPageSize });
                    setPageSize(newPageSize);
                  }}
                  className="w-25 mb-2"
                />
              </div>
            </div>
          </div>
          <div>API Yanıt Süresi: {responseTime}</div>
          <Grid
            style={{ height: "600px", width: "100%" }}
            data={processedData.data}
            skip={dataState.skip}
            take={dataState.take}
            total={muhPlanListesi.length}
            onPageChange={handlePageChange}
            resizable={true}
            filterable={isDisabled}
            onSortChange={handleSortChange}
            onRowDoubleClick={onRowDoubleClick}
            rowRender={customRowRender}
            onFilterChange={handleFilterChange}
            filter={dataState.filter}
            pageable={{
              buttonCount: 4,
              pageSizes: [50, 70, 100, 500],
              pageSizeValue: pageSizeValue,
            }}
            reorderable={true}
            // onGroupChange={handleGroupChange}
            // groupable={true}
          >
            <GridToolbar>
              <input className="k-textbox k-input k-input-md k-input-solid k-rounded-md" type="text" id="veriara" onKeyDown={handleKeyDown} />
              <button className="k-button k-button-md k-button-solid k-button-solid-base k-icon-button k-input-button" onClick={veriara}>veri ara</button>
              <label>
                <Checkbox
                  checked={isDisabled}
                  onChange={toggleFilter}
                  size="medium"
                  label={"Filtrelemeyi Aç"}
                />
              </label>
            </GridToolbar>
            <Column field="Kod" title="Plan Kodu" width={100} />
            <Column field="Ad" title="Hesap Adı" width={500} />
            <Column
              field="Grup"
              title="Grup"
              width={100}
              cell={(props) => (
                <td>{mapGrupValueToText(props.dataItem.Grup)}</td>
              )}
            />
            <Column
              field="Dovizli"
              title="Dövizli Hesap"
              width="100px"
              cell={ActiveCell}
            />
            <Column field="Dovizkodu" title="Döviz Kodu" width="100px" />
            <Column
              field="borc"
              title="Borç"
              width="100px"
              cell={(props) => (
                <td style={{ textAlign: "right" }}>{props.dataItem.borc}</td>
              )}
            />
            <Column
              field="alacak"
              title="Alacak"
              width="100px"
              cell={(props) => (
                <td style={{ textAlign: "right" }}>{props.dataItem.alacak}</td>
              )}
            />
            <Column
              field="bakiye"
              title="Bakiye"
              width="100px"
              cell={(props) => (
                <td style={{ textAlign: "right" }}>{props.dataItem.bakiye}</td>
              )}
            />
            <Column
              field="d_Borc"
              title="Döviz Borç"
              width="100px"
              cell={(props) => (
                <td style={{ textAlign: "right" }}>{props.dataItem.d_Borc}</td>
              )}
            />
            <Column
              field="d_Alacak"
              title="Döviz Alacak"
              width="100px"
              cell={(props) => (
                <td style={{ textAlign: "right" }}>
                  {props.dataItem.d_Alacak}
                </td>
              )}
            />
            <Column
              field="d_Bakiye"
              title="Döviz Bakiye"
              width="100px"
              cell={(props) => (
                <td style={{ textAlign: "right" }}>
                  {props.dataItem.d_Bakiye}
                </td>
              )}
            />

            <Column
              field="Muhcari"
              title="Muhasebe \ Cari"
              width="100px"
              cell={(props) => (
                <td style={{ textAlign: "right" }}>{props.dataItem.Muhcari}</td>
              )}
            />
            <Column
              field="Vade"
              title="Vade"
              width="100px"
              cell={(props) => (
                <td style={{ textAlign: "right" }}>{props.dataItem.Vade}</td>
              )}
            />
            <Column
              field="Iskonto"
              title="İskonto"
              width="100px"
              cell={(props) => (
                <td style={{ textAlign: "right" }}>{props.dataItem.Iskonto}</td>
              )}
            />
            <Column field="Resmiad" title="Resmi Ad" width="100px" />
            <Column field="Vergidaire" title="Vergi Dairesi" width="100px" />
            <Column field="Vergino" title="Vergi No" width="100px" />
            <Column field="Adres1" title="Adres1" width="100px" />
            <Column field="Adres2" title="Adres2" width="100px" />
            <Column field="Ilce" title="İlçe" width="100px" />
            <Column field="Il" title="İl" width="100px" />
            <Column field="Posta" title="Posta Kodu" width="100px" />
            <Column field="Tel1" title="İletişim 1" width="100px" />
            <Column field="Tel2" title="İletişim 2" width="100px" />
            <Column field="Csm1" title="GSM 1" width="100px" />
            <Column field="Csm2" title="GSM 2" width="100px" />
            <Column field="Fax" title="Fax" width="100px" />
            <Column field="Eposta" title="E-Posta" width="100px" />
            <Column field="Web" title="Web Sitesi" width="100px" />
            <Column field="Yetkili" title="Yetkili" width="100px" />
            <Column field="Banka1" title="Banka 1" width="100px" />
            <Column field="Banka2" title="Banka 2" width="100px" />
            <Column field="Banka3" title="Banka 3" width="100px" />
            <Column field="Hesap1" title="Hesap 1" width="100px" />
            <Column field="Hesap2" title="Hesap 2" width="100px" />
            <Column field="Hesap3" title="Hesap 3" width="100px" />
            <Column field="Iban1" title="Iban 1" width="100px" />
            <Column field="Iban2" title="Iban 2" width="100px" />
            <Column field="Iban3" title="Iban 3" width="100px" />
            <Column field="Nakitkod" title="nakit kod" width="100px" />
            <Column
              field="Efatura"
              title="efatura"
              width="100px"
              cell={ActiveCell}
            />
            <Column field="Senaryo" title="senaryo" width="100px" />
            <Column field="Yan_Borc" title="yan borç" width="100px" />
            <Column field="Yan_Alacak" title="yan alacak" width="100px" />
            <Column
              field="Onaysiz"
              title="onaysız"
              width="100px"
              cell={ActiveCell}
            />
            <Column field="Sube" title="şube" width="100px" />
            <Column field="Saticino" title="satıcı no" width="100px" />
            <Column field="Avanshesap" title="avans hesap" width="100px" />
            <Column field="Avansoran" title="avans oran" width="100px" />
            <Column field="Kilit" title="kilit" width="100px" />
            <Column field="TcKimlik" title="tc kimlik" width="100px" />
            <Column field="Kayittarih" title="kayıt tarih" width="400px" />
            <Column
              field="Kurfarki"
              title="kur farkı"
              width="100px"
              cell={ActiveCell}
            />
            <Column field="Ulke" title="ülke" width="100px" />
            <Column field="Halsis_Ulke" title="halsis ülke" width="100px" />
            <Column field="Halsis_Il" title="halsis il" width="100px" />
            <Column field="Halsis_Ilce" title="halsis ilçe" width="100px" />
            <Column field="Halsis_Belde" title="halsis belde" width="100px" />
            <Column field="Halsis_Sifat" title="halsis şifat" width="100px" />
            <Column
              field="Halsis_Isyerituru"
              title="halsis işyeri türü"
              width="100px"
            />
            <Column field="Halsis_Depo" title="halsis depo" width="100px" />
            <Column field="Halsis_Sube" title="halsis şube" width="100px" />
            <Column field="Halsis_Halici" title="halsis halici" width="100px" />
            <Column field="Etiket" title="etiket" width="100px" />
            <Column field="Babs" title="babs" cell={ActiveCell} />
            <Column field="Calismasekli" title="çalışma şekli" width="100px" />
            <Column field="Not" title="not" width="100px" />
            <Column field="Soyad" title="soyad" width="100px" />
            <Column field="Mersisno" title="mersis no" width="100px" />
            <Column field="Sicilno" title="sicil no" width="100px" />
            <Column field="Malverenno" title="malveren no" width="100px" />
            <Column field="Alternatif1" title="alternatif1" width="100px" />
            <Column field="Alternatif2" title="alternatif2" width="100px" />
            <Column field="Alternatif3" title="alternatif3" width="100px" />
            <Column
              field="cari_banka_sube_kodu"
              title="cari banka şube kodu"
              width="100px"
            />
            <Column
              field="cari_banka_sube_adi"
              title="cari banka şube adı"
              width="100px"
            />
            <Column
              field="cari_banka_hesap_adi"
              title="cari banka hesap adı"
              width="100px"
            />
            <Column
              field="EpostaMutabakat"
              title="eposta mutabakat"
              width="100px"
            />
            <Column
              field="EpostaSatinalma"
              title="eposta satınalma"
              width="100px"
            />
            <Column
              field="MasrafZorunlu"
              title="masraf zorunlu"
              width="100px"
              cell={ActiveCell}
            />
            <Column field="LogoVade" title="logo vade" width="100px" />
            <Column field="Kamu" title="kamu" width="100px" cell={ActiveCell} />
            <Column
              field="EpostaMutabakatHesap"
              title="eposta mutabakat hesap"
              width="100px"
            />
            <Column field="RiskLimit" title="risk limit" width="100px" />
            <Column field="Kdv" title="kdv" width="100px" />
            <Column field="Ticimax" title="ticimax" width="100px" />
            <Column field="LogoHesap" title="logo hesap" width="100px" />
            <Column field="KarsiHesap" title="karşı hesap" width="100px" />
            <Column field="LogoId" title="logo id" width="100px" />
            <Column
              field="EntegrasyonKodu"
              title="entegrasyon kodu"
              width="100px"
            />
            <Column
              field="Yurtdisi"
              title="yurtdışı"
              width="100px"
              cell={ActiveCell}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

const HesapPlaniEkle: React.FC<HesapPlaniEkleProps> = ({
  isOpen,
  onClose,
  hesapKodu,
}) => {
  const [muhPlanDetayListe, setMuhPlanDetayListe] = useState<
    MuhPlanDetayListe[]
  >([
    {
      Kod: "1000100102",
      Ad: "TL Kasası Nakit VICTORY RESORT",
      Grup: 2, //hesap türü 0ana 1 ara 2 alt 0 ve 1 den 1 tane olmalı 2 den birden fazla olabilir
      Dovizli: false,
      Dovizkodu: "",
      Muhcari: 0,
      Vade: 0,
      Iskonto: 0,
      Resmiad: "",
      Vergidaire: "",
      Vergino: "",
      Adres1: "",
      Adres2: "",
      Ilce: "",
      Il: "",
      Posta: "",
      Tel1: "",
      Tel2: "",
      Gsm1: "",
      Gsm2: "",
      Fax: "",
      Eposta: "",
      Web: "",
      Yetkili: "",
      Banka1: "",
      Banka2: "",
      Banka3: "",
      Hesap1: "",
      Hesap2: "",
      Hesap3: "",
      Iban1: "",
      Iban2: "",
      Iban3: "",
      Nakitkod: "",
      Efatura: false,
      Senaryo: "",
      YansitmaBorc: "",
      YansitmaAlacak: "",
      Onaysiz: false,
      Sube: "",
      Saticino: "",
      Avanshesap: "",
      Avansoran: 0,
      TcKimlik: "",
      Kurfarki: false,
      Ulke: "Türkiye",
      Etiket: "",
      Babs: false,
      Calismasekli: "",
      Not: "",
      Soyad: "",
      Mersisno: "",
      Sicilno: "",
      Alternatif1: "100.01.001",
      Alternatif2: "",
      Alternatif3: "",
      CariBankaSubeKodu: "",
      CariBankaSubeAdi: null,
      CariBankaHesapAdi: null,
      EpostaMutabakat: "",
      EpostaSatinalma: "",
      MasrafZorunlu: false,
      LogoVade: "",
      Kamu: false,
      EpostaMutabakatHesap: "",
      RiskLimit: 0,
      Kdv: 0,
      LogoHesap: "",
      KarsiHesap: "",
      EntegrasyonKodu: "",
      Yurtdisi: false,
    },
  ]);
  const [kayitDurum, setKayitDurum] = useState("Kayıtlı");
  const [checked, setChecked] = useState(false);
  // Modalın açık/kapalı durumunu yönetmek için yeni bir state eklenir
  const [modalOpen, setModalOpen] = useState(false);
  const muhPlanDetayCek = async (kod: string) => {
    try {
      const response = await backApi.MuhPlanDetayGetir(kod);
      if (response && response.isSucceded) {
        setMuhPlanDetayListe([response.value]); // Tek bir objeyi diziye çevir
        setModalOpen(true);
        console.log("muh plan detay başarılıcer", response.value);
      }
    } catch (error) {
      console.error("API isteğinde bir hata oluştu", error);
    }
  };
  useEffect(() => {
    setModalOpen(isOpen); // isOpen prop'unun değişikliklerine bağlı olarak modalı açıp kapar
  }, [isOpen]); // Bu useEffect yalnızca isOpen değiştiğinde çalışır

  useEffect(() => {
    if (hesapKodu) {
      muhPlanDetayCek(hesapKodu);
    }
  }, [hesapKodu]);

  // Modalı kapatma işlemi için mevcut onClose fonksiyonunu genişlet
  const handleClose = () => {
    onClose();
    // Modalı programatik olarak kapattığımızda, state'i de güncelleyerek kapalı duruma getiririz
    setModalOpen(false);
  };
  const handleInputChange = (index: number, field: string, newValue: any) => {
    const updatedList = muhPlanDetayListe.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: newValue }; // Alan adını dinamik olarak değiştirme
      }
      return item;
    });

    setMuhPlanDetayListe(updatedList);
  };
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  const infoItems = [
    {
      id: 1,
      title: "Örnek Başlık 1",
      description: "Açıklama=bu bir örnek açıklamadır.",
      Icon: FaBeer, // React icons'tan bir örnek simge
    },
    // Diğer öğeler...
  ];
  return (
    <>
      <Modal
        isOpen={modalOpen}
        onClose={handleClose}
        size="full-screen"
        header={<div className="fs-5">Hesap Kart - Ekleme</div>}
        footer={
          <>
            <div className="container-fluid p-0 m-0">
              <div className="row m-0 p-0">
                <div className="col-12 d-flex gap-5">
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">Onaysız</span>
                  </div>
                  {/* kilitli */}
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">Kilitli</span>
                  </div>
                  {/* kur farkı uygulama */}
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">
                      Kur Farkı Uygula
                    </span>
                  </div>
                  {/*babs dahil  */}
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">
                      BABS Dahil
                    </span>
                  </div>
                  {/* masraf merkezi zorunlu */}
                  <div className="d-flex align-items-center ">
                    <Checkbox

                     />
                    <span className="ms-2  mt-1 p-0 text-black">
                      Masraf Merkezi Zorunlu
                    </span>
                  </div>
                  {/* yurt dışı firması */}
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">
                      Yurt Dışı Firması
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Button
              className=" ms-3 r-buton-danger d-flex align-items-center"
              // onClick={() => {
              //   setFirsatAdi("");
              //   setKartFirsatBilgileri([]);
              // }}
            >
              <FontAwesomeIcon icon={faClose} />
              <span className="ms-3">Sil</span>
            </Button>
            <Button
              className="ms-3 r-buton-success d-flex align-items-center"
              // onClick={firsatiKaydet}
              // disabled={isDisabled} // isDisabled true ise butonu devre dışı bırak
            >
              <FontAwesomeIcon icon={faCheck} />
              <span className="ms-3">Tamamla</span>
            </Button>
          </>
        }
      >
        <div className="container-fluid m-0 p-3">
          <div className="container-fluid m-0 p-0">
            {muhPlanDetayListe.length > 0 ? (
              <div className="row m-0 p-0">
                {muhPlanDetayListe.map((detay, index) => (
                  <div key={index} className="row">
                    <div className="col-4">
                      <Card>
                        <Card.Header>
                          <Card.Title>Hesap Kartı</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <div className="row">
                            {/* <div className="col-6">
                          <div>Adı: {detay.Ad}</div>
                          <div>Kodu: {detay.Kod}</div>
                        </div> */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Kayıt durum
                              </label>
                            </div>
                            <div className="col-9">
                              {/* <Input
                                name="kartAdi"
                                className="r-input-sm"
                                value={detay.Ad || ""}
                                onChange={(e) =>
                                  handleInputChange(index, e.value)
                                } // Kendo Input için `e.value` kullanılır.
                              />*/}
                              <Input
                                type="text"
                                value={kayitDurum}
                                className="r-input-sm"
                                disabled
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Hesap Kodu
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Kod"
                                value={detay.Kod || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Kod", e.value)
                                }
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Hesap Adı
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Ad"
                                value={detay.Ad || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Ad", e.value)
                                }
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Grup
                              </label>
                            </div>
                            <div className="col-9">
                              <DropDownList
                                data={[
                                  { text: "Ana Grup", value: "0" },
                                  { text: "Ara Grup", value: "1" },
                                  { text: "Alt Grup", value: "2" },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Grup",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Hesap türü Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm"
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Dövizli
                              </label>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                              <Checkbox />
                              <DropDownList
                                data={[
                                  { text: "Evet", value: true },
                                  { text: "Hayır", value: false },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Dovizli",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Dövizli Hesap Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm ms-3"
                              />
                            </div>
                            {/* çalışma şekli muhasebe cari */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Muhasebe Cari
                              </label>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                              <DropDownList
                                data={[
                                  { text: "Muhasebe", value: 0 },
                                  { text: "Cari", value: 1 },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Muhcari",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Muhasebe Cari Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm ms-3"
                              />
                            </div>
                            {/* çalışma şekli abc cdf*/}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Çalışma Şekli
                              </label>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                              <DropDownList
                                data={[
                                  { text: "ABC", value: "ABC" },
                                  { text: "CDF", value: "CDF" },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Calismasekli",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Çalışma Şekli Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm ms-3"
                              />
                            </div>
                            {/*nakit alım kodları 001 002  */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Nakit Akım Kodu
                              </label>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                              <DropDownList
                                data={[
                                  { text: "001", value: "001" },
                                  { text: "002", value: "002" },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Nakitkod",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Nakit Kod Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm ms-3"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3 mt-4"
                      >
                        <Tab eventKey="home" title="Muhasebe bilgileri">
                          <Card>
                            <Card.Header>
                              <Card.Title>Muhasebe Bilgileri</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="row">
                                {/* vergi dairesi */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Vergi Dairesi
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Vergidaire"
                                    value={detay.Vergidaire || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Vergidaire",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* vergi no */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Vergi No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Vergino"
                                    value={detay.Vergino || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Vergino",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* tc kimlik no  */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> TC Kimlik No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="TcKimlik"
                                    value={detay.TcKimlik || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "TcKimlik",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* mersis no  */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Mersis No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Mersisno"
                                    value={detay.Mersisno || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Mersisno",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* ticaret sicil no */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Ticaret Sicil
                                    No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Sicilno"
                                    value={detay.Sicilno || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Sicilno",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* karşı hesap */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Karşı Hesap
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="KarsiHesap"
                                    value={detay.KarsiHesap || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "KarsiHesap",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* logo hesap */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Logo Hesap
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="LogoHesap"
                                    value={detay.LogoHesap || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "LogoHesap",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* vade */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Vade
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Vade"
                                    value={detay.Vade || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Vade", e.value)
                                    }
                                  />
                                </div>
                                {/*vade logo */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Logo Vade
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="LogoVade"
                                    value={detay.LogoVade || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "LogoVade",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* iskonto */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> İskonto
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Iskonto"
                                    value={detay.Iskonto || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Iskonto",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* kdv */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> KDV
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Kdv"
                                    value={detay.Kdv || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Kdv", e.value)
                                    }
                                  />
                                </div>
                                {/* yetkili */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Yetkili
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Yetkili"
                                    value={detay.Yetkili || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Yetkili",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* yansıt borç alacak buradna yan yana 2 tane input koyalım */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Yansıtma
                                  </label>
                                </div>
                                {/* bu şekilde değil col-9 içerisinde 2 tane input olacak */}
                                <div className="col-9 d-flex align-items-center">
                                  <Input
                                    name="YansitmaBorc"
                                    value={detay.YansitmaBorc || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "YansitmaBorc",
                                        e.value
                                      )
                                    }
                                  />
                                  <Input
                                    name="YansitmaAlacak"
                                    value={detay.YansitmaAlacak || ""}
                                    className="r-input-sm ms-3"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "YansitmaAlacak",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* e fatura burada inputun sol tarafına bir check ekle sonra yanına bir input daha ekle oonunda sağına check ekle  */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> E-Fatura
                                  </label>
                                </div>
                                <div className="col-9 d-flex align-items-center">
                                  <Checkbox />
                                  {/* input yerine dropdown */}
                                  <DropDownList
                                    data={[
                                      { text: "Evet", value: true },
                                      { text: "Hayır", value: false },
                                    ]}
                                    // value={secilenKart}
                                    value="sdfdsf"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Efatura",
                                        e.target.value
                                      )
                                    }
                                    textField="text"
                                    dataItemKey="value"
                                    defaultItem={{
                                      text: "E-Fatura Seçiniz",
                                      value: "",
                                    }}
                                    className="r-input-sm ms-3"
                                  />
                                  <Input
                                    name="YansitmaAlacak"
                                    value={detay.YansitmaAlacak || ""}
                                    className="r-input-sm ms-3"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "YansitmaAlacak",
                                        e.value
                                      )
                                    }
                                  />
                                  <Checkbox className="ms-2" />
                                  <span className="ms-2">kamu</span>
                                </div>
                                {/* şirket şuba burasıda yine col-3 ile olucak */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Şirket Şube
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Sube"
                                    value={detay.Sube || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Sube", e.value)
                                    }
                                  />
                                </div>
                                {/* satici no */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Satıcı No
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Saticino"
                                    value={detay.Saticino || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Saticino",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* acans hesabıda col-3 ile olucak yine  */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Avans Hesap
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Avanshesap"
                                    value={detay.Avanshesap || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Avanshesap",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* avans oran */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Avans Oran
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Avansoran"
                                    value={detay.Avansoran || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Avansoran",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* risk limit */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Risk Limit
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="RiskLimit"
                                    value={detay.RiskLimit || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "RiskLimit",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Tab>
                        <Tab eventKey="profile" title="Banka Bilgileri">
                          <Card>
                            <Card.Header>
                              <Card.Title>Banka Bilgileri</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="row">
                                {/* banka 1 */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Banka 1
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Banka1"
                                    value={detay.Banka1 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Banka1",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* şube kodu */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Şube Kodu
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="CariBankaSubeKodu"
                                    value={detay.CariBankaSubeKodu || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "CariBankaSubeKodu",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* şube adı  */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Şube Adı
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="CariBankaSubeAdi"
                                    value={detay.CariBankaSubeAdi || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "CariBankaSubeAdi",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* hesap No */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Hesap No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="CariBankaHesapAdi"
                                    value={detay.CariBankaHesapAdi || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "CariBankaHesapAdi",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* banka hesap adı */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> B Hesap Adı
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Banka2"
                                    value={detay.Banka2 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Banka2",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* ıban */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> IBAN
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Iban1"
                                    value={detay.Iban1 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Iban1", e.value)
                                    }
                                  />
                                </div>
                                {/* banka2 */}
                                <div className="col-3 d-flex align-items-center p-1 mt-4">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Banka 2
                                  </label>
                                </div>
                                <div className="col-9 mt-4">
                                  <Input
                                    name="Banka2"
                                    value={detay.Banka2 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Banka2",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* hesap no */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Hesap No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Hesap2"
                                    value={detay.Hesap2 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Hesap2",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* ıban */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> IBAN
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Iban2"
                                    value={detay.Iban2 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Iban2", e.value)
                                    }
                                  />
                                </div>
                                {/* banka3 */}
                                <div className="col-3 d-flex align-items-center p-1 mt-4">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Banka 3
                                  </label>
                                </div>
                                <div className="col-9 mt-4">
                                  <Input
                                    name="Banka3"
                                    value={detay.Banka3 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Banka3",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* hesap no */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Hesap No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Hesap3"
                                    value={detay.Hesap3 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Hesap3",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* ıban */}
                                <div className="col-3 d-flex align-items-center p-1">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> IBAN
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Iban3"
                                    value={detay.Iban3 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Iban3", e.value)
                                    }
                                  />
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Tab>
                      </Tabs>
                    </div>
                    <div className="col-4">
                      <Card>
                        <Card.Header>
                          <Card.Title>İletişim Bilgileri</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <div className="row">
                            {/* resmi ad */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Resmi Ad
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Resmiad"
                                value={detay.Resmiad || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Resmiad", e.value)
                                }
                              />
                            </div>

                            {/* soyad */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Soyad
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Soyad"
                                value={detay.Soyad || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Soyad", e.value)
                                }
                              />
                            </div>
                            {/* adres1 */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Adres 1
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Adres1"
                                value={detay.Adres1 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Adres1", e.value)
                                }
                              />
                            </div>
                            {/* adres 2  */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Adres 2
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Adres2"
                                value={detay.Adres2 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Adres2", e.value)
                                }
                              />
                            </div>
                            {/* ilce */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> İlçe
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Ilce"
                                value={detay.Ilce || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Ilce", e.value)
                                }
                              />
                            </div>
                            {/* il */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> İl
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Il"
                                value={detay.Il || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Il", e.value)
                                }
                              />
                            </div>
                            {/* ülke */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Ülke
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Ulke"
                                value={detay.Ulke || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Ulke", e.value)
                                }
                              />
                            </div>
                            {/* posta kodu */}
                            <div className="col-3 d-flex align-items-center p-1">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Posta Kodu
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Postakodu"
                                // value={detay.Postakodu || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Postakodu", e.value)
                                }
                              />
                            </div>
                            {/* telefon 1 */}
                            <div className="col-3 d-flex align-items-center p-1 mt-3">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Telefon 1
                              </label>
                            </div>
                            <div className="col-9 mt-3">
                              <Input
                                name="Telefon1"
                                // value={detay.Telefon1 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Telefon1", e.value)
                                }
                              />
                            </div>
                            {/* gsm1 */}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> GSM 1
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Gsm1"
                                // value={detay.Gsm1 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Gsm1", e.value)
                                }
                              />
                            </div>
                            {/* fax */}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Fax
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Fax"
                                // value={detay.Fax || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Fax", e.value)
                                }
                              />
                            </div>
                            {/* e posta  */}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> E-Posta
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Eposta"
                                // value={detay.Eposta || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Eposta", e.value)
                                }
                              />
                            </div>
                            {/* eposta muta babs */}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> E-Posta Muhasebe
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="EpostaMuhasebe"
                                // value={detay.Eposta || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "EpostaMuhasebe",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            {/* eposta muta hesap */}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> E-Posta Hesap
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="EpostaHesap"
                                // value={detay.Eposta || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "EpostaHesap",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            {/* eposta satın alma*/}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> E-Posta Satın
                                Alma
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="EpostaSatinalma"
                                // value={detay.Eposta || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "EpostaSatinalma",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            {/* WEB */}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Web
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Web"
                                // value={detay.Web || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Web", e.value)
                                }
                              />
                            </div>
                            {/* NOT */}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Not
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Not"
                                // value={detay.Not || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Not", e.value)
                                }
                              />
                            </div>
                            {/* ALTERNATİF 1 2 3 */}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Alternatif 1
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Alternatif1"
                                // value={detay.Alternatif1 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Alternatif1",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Alternatif 2
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Alternatif2"
                                // value={detay.Alternatif2 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Alternatif2",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Alternatif 3
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Alternatif3"
                                // value={detay.Alternatif3 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Alternatif3",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            {/* ENTEGRASYON KODU */}
                            <div className="col-3 d-flex align-items-center p-1 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Entegrasyon Kodu
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="EntegrasyonKodu"
                                // value={detay.EntegrasyonKodu || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "EntegrasyonKodu",
                                    e.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-4">
                      <InfoPanel items={infoItems} header="Önemli Bilgiler" />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>Veri Yok</div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HesapPlani;
