import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  DropDownList,
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Card } from "react-bootstrap";
import { Checkbox, Input, RadioButton } from "@progress/kendo-react-inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCode,
  faGauge,
  faList,
  faPrint,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../../Modal/Modal/Modal";

const initialDataState = {
  take: 10,
  skip: 0,
  group: [],
  filter: null,
};

const Aktiviteler: React.FC = () => {
  const [data, setData] = React.useState<any>(AktivitelerData);
  const [value, setValue] = React.useState(new Date());
  const [dataState, setDataState] = React.useState<any>(initialDataState);
  const [value1, setValue1] = React.useState<string[]>(["Armas", "Hane"]);
  const [isModalOpen, setModalOpen] = useState(false);

  const changeDate = (event: DatePickerChangeEvent) => {
    if (event.value !== null) {
      setValue(event.value);
    }
  };

  const onGridDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const onChange = (event: MultiSelectChangeEvent) => {
    setValue1([...event.value]);
  };

  const processedData = process(data, dataState);
  const toggleModal = () => setModalOpen(!isModalOpen);

  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <AktiviteGirisi
            openModal={toggleModal}
            data={data}
            isOpen={isModalOpen}
          />
          <div className="col-md-12 bg-white shadow-lg rounded p-3">
            <div className="w-100">
              <div className="row">
                <div className="col-md-3 ">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center">
                      <label className="w-50 text-start">
                        Başlangıç Tarihi:
                      </label>
                      <DatePicker value={value} onChange={changeDate} />
                    </div>
                    <div className="d-flex align-items-center ">
                      <label className="w-50 text-start">Bitiş Tarihi:</label>
                      <DatePicker value={value} onChange={changeDate} />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center">
                      <label className="w-50 text-start">Firmalar</label>
                      <MultiSelect
                        data={sports}
                        onChange={onChange}
                        value={value1}
                        placeholder="Please select ..."
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <label className="w-50 text-start">personel</label>
                      <MultiSelect
                        data={sports}
                        onChange={onChange}
                        value={value1}
                        placeholder="Please select ..."
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <label className="w-50 text-start">Aktiviteler</label>
                      <MultiSelect
                        data={sports}
                        onChange={onChange}
                        value={value1}
                        placeholder="Please select ..."
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="border">
                    <div className="w-100">
                      <p>Şekil Durum</p>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start  justify-contet-start">
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Kesin"
                        />
                      </div>
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Geçici"
                        />
                      </div>

                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Iptal"
                        />
                      </div>

                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Hepsi"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="border">
                    <div className="w-100">
                      <p>Sonuç Durum</p>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start  justify-contet-start">
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Devam Ediyor"
                        />
                      </div>
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Sonuçlandı"
                        />
                      </div>

                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Hepsi"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="border">
                    <div className="w-100">
                      <p>Tipi</p>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start  justify-contet-start">
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Aktivite"
                        />
                      </div>
                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Görev"
                        />
                      </div>

                      <div>
                        <RadioButton
                          className="ms-3"
                          name="group1"
                          value="Firma"
                          label="Hepsi"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100">
              <div className="col-md-12 p-3">
                <div className="d-flex flex-row gap-2">
                  <div className="">
                    <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                      <FontAwesomeIcon icon={faRefresh} /> yenile
                    </div>
                  </div>
                  <div className="">
                    <div
                      className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                      onClick={toggleModal}
                    >
                      <FontAwesomeIcon icon={faAdd} /> Aktivite Ekle Düzelt
                    </div>
                  </div>
                  <div className="">
                    <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                      <FontAwesomeIcon icon={faPrint} /> Yazdır{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 bg-white shadow-lg rounded p-3 mt-4">
            <Grid
              data={processedData}
              pageable={true}
              pageSize={dataState.take}
              total={data.length}
              groupable={true}
              filterable={true}
              sortable={true}
              onDataStateChange={onGridDataStateChange}
              {...dataState}
              reorderable={true}
              resizable={true}
              style={{ height: "500px" }}
            >
              <Column field="AktiviteAdi" title="Aktivite Adı" />
              <Column field="FirmaAdi" title="Firma Adı" />
              <Column field="Kontak" title="Kontak" />
              <Column field="Iletişim" title="İletişim" />
              <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
              <Column field="BİtisTarihi" title="Bitiş Tarihi" />
              <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
export default Aktiviteler;

interface AktiviteProps {
  openModal: () => void; // openModal sadece bir fonksiyon olarak tanımlanmış
  data?: any[]; // data bir any[] tipinde veri dizisi
  isOpen: boolean; // Modalın açık olup olmadığı bilgisini dışarıdan alıyoruz
}

const AktiviteGirisi: React.FC<AktiviteProps> = ({
  openModal,
  data,
  isOpen,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState<any>(data || {});

  useEffect(() => {
    if (isOpen) {
      openModal();
      setFormData(data || {}); // Veri geldiğinde form data güncellenir
      console.log("modal açma tetiklendi");
      setModalOpen(true);
    }
  }, [isOpen, data, openModal]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        setModalOpen(false);
        setFormData({});
      }}
      size="medium"
      header={<div>Aktivite Ekle</div>}
      footer={
            <div className="d-flex flex-row gap-1">
              <div className="">
                <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                  <FontAwesomeIcon icon={faRefresh} /> Kaydet
                </div>
              </div>
              <div className="">
                <div
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                  // onClick={toggleModal}
                >
                  <FontAwesomeIcon icon={faAdd} /> Temizle
                </div>
              </div>
              <div className="">
                <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                  <FontAwesomeIcon icon={faPrint} /> Sil{" "}
                </div>
              </div>
              <div className="">
                <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                  <FontAwesomeIcon icon={faCode} /> İptal{" "}
                </div>
              </div>
            </div>
      }
    >
      <>
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="w-100"></div>
                <div className="w-100 d-flex justify-content-around align-items-start">
                  <div>
                    <RadioButton
                      className="ms-3"
                      name="group1"
                      value="Firma"
                      label="Aktivite"
                    />
                  </div>
                  <div>
                    <RadioButton
                      className="ms-3"
                      name="group1"
                      value="Firma"
                      label="Görev"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row gap-1">
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Şirket Kodu
                </div>
                <div className="col-md-6">
                  <DropDownList data={categories} defaultValue="pazar" />
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Kontak
                </div>
                <div className="col-md-6">
                  <Input type="text" placeholder="Kontak"></Input>
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  İletişim
                </div>
                <div className="col-md-6">
                  <Input type="text" placeholder="İletişim"></Input>
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Düzenleyen Ekip / Personel
                </div>
                <div className="col-md-6">
                  <DropDownList data={categories} defaultValue="pazar" />
                </div>

                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Aktivite Tipi
                </div>
                <div className="col-md-6">
                  <DropDownList data={categories} defaultValue="pazar" />
                </div>

                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Aktivite Tanımı
                </div>
                <div className="col-md-6">
                  <Input type="text" placeholder="Aktivite Tanımı"></Input>
                </div>
                <div className="col-md-12">
                  <div>
                    <div className="w-100"></div>
                    <div className="w-100 d-flex justify-content-around align-items-start">
                      <div>
                        <Checkbox label={"Tam Gün"} color="red" />
                      </div>
                      <div>
                        <Checkbox
                          label={"Kontrol Edilebilir (Saatli)"}
                          color="red"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Başlangıç Tarihi
                </div>
                <div className="col-md-6">
                  <div className="w-100 d-flex gap-1">
                    <div>
                      <DatePicker defaultValue={new Date()} />
                    </div>
                    <div>
                      <Input type="text" placeholder="Saati"></Input>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Bitiş Tarihi
                </div>
                <div className="col-md-6">
                  <div className="w-100 d-flex gap-1">
                    <div>
                      <DatePicker defaultValue={new Date()} />
                    </div>
                    <div>
                      <Input type="text" placeholder="Saati"></Input>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Notlar
                </div>
                <div className="col-md-6">
                  <Input type="text" placeholder="Notlar"></Input>
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Sonuçlanma Tarihi
                </div>
                <div className="col-md-6">
                  <DatePicker defaultValue={new Date()} />
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Sonuçlanma Kodu
                </div>
                <div className="col-md-6">
                  <div className="w-100 d-flex justify-content-between align-items-start">
                    <div>
                      <RadioButton
                        className="ms-3"
                        name="group1"
                        value="Firma"
                        label="Aktivite"
                      />
                    </div>
                    <div>
                      <RadioButton
                        className="ms-3"
                        name="group1"
                        value="Firma"
                        label="Görev"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Sonuç Notu
                </div>
                <div className="col-md-6">
                  <Input type="text" placeholder="Sonuç Notu"></Input>
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  Status
                </div>
                <div className="col-md-6">
                  <DropDownList data={categories} defaultValue="pazar" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};
const categories = ["pazar", "grup", "konaklama", "kur", "tarih"];
const sports = [
  "Armas Luxury Resort",
  "Hane Garden Hotel",
  "Kervansaray Hotel",
  "Kemer Holiday Club",
  "Kemer Resort Hotel",
  "Turan Prince World",
  "Golden Lotus Hotel",
];
const AktivitelerData: any[] = [
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
];
