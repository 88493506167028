import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface GeneralButtonProps {
  text: string;
  icon: any;
  gradientStart: string;
  gradientEnd: string;
  hoverGradientStart: string;
  hoverGradientEnd: string;
  onClick: () => void;
  width?: string;
  height?: string;
}

const GeneralButton: React.FC<GeneralButtonProps> = ({
  text,
  icon,
  gradientStart,
  gradientEnd,
  hoverGradientStart,
  hoverGradientEnd,
  onClick,
  width = 'auto', // Default width
  height = 'auto', // Default height
}) => {
  return (
    <button
      style={{
        backgroundImage: `linear-gradient(45deg, ${gradientStart}, ${gradientEnd})`,
        color: 'white',
        borderRadius: '4px',
        border: 'none',
        padding: '6px 12px',
        cursor: 'pointer',
        margin: '4px 0', // Top ve bottom margin için mt-1 seviyesinde
        boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: 'background-image 0.3s, box-shadow 0.3s',
        width: width,
        height: height,
        minWidth: '100px', // Min width
      }}
      onMouseOver={(e) => {
        const target = e.currentTarget as HTMLButtonElement;
        target.style.backgroundImage = `linear-gradient(45deg, ${hoverGradientStart}, ${hoverGradientEnd})`;
        target.style.boxShadow = '4px 4px 16px rgba(0, 0, 0, 0.3)';
        const icon = target.querySelector('.icon-span') as HTMLSpanElement;
        if (icon) {
          icon.style.animation = 'spin 0.5s linear infinite';
        }
      }}
      onMouseOut={(e) => {
        const target = e.currentTarget as HTMLButtonElement;
        target.style.backgroundImage = `linear-gradient(45deg, ${gradientStart}, ${gradientEnd})`;
        target.style.boxShadow = '4px 4px 12px rgba(0, 0, 0, 0.2)';
        const icon = target.querySelector('.icon-span') as HTMLSpanElement;
        if (icon) {
          icon.style.animation = 'none';
        }
      }}
      onClick={onClick}
    >
      {text}
      <span
        className="icon-span"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '50%',
          width: '24px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '10px',
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </span>
    </button>
  );
};

export default GeneralButton;

// CSS for the spin animation
const style = document.createElement('style');
style.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(style);
