import React, { useEffect, useState } from "react";
import useMenuItems from "../Sidebar/SidebarMenu/MenuItems";
import { Tab, Tabs } from 'react-bootstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import "./Content.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import logo from '../../Images/RmosWebLogo4.svg';
import { Card } from 'react-bootstrap';

type ContentProps = {
  isOpen: boolean;
  selectedMenu: number | null;
};

interface MenuItem {
  id: number;
  title: string;
  icon: React.ElementType;
  Component?: React.FC;
  children?: MenuItem[];
}

const DraggableTab: React.FC<{ id: number; index: number; moveTab: (dragIndex: number, hoverIndex: number) => void; removeTab: (id: number) => void; title: string }> = ({ id, index, moveTab, removeTab, title }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'TAB',
    hover(item: { index: number }) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveTab(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'TAB',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move', display: 'inline-block' }}>
      <span
        onMouseDown={(event) => {
          if (event.button === 1) {
            event.preventDefault();
            removeTab(id);
          }
        }}
      >
        {title}
        <button
          onClick={() => removeTab(id)}
          style={{ marginLeft: '10px', border: 'none', background: 'none' }}
        >
          <FontAwesomeIcon icon={faClose} color="white" />
        </button>
      </span>
    </div>
  );
};

const Content: React.FC<ContentProps> = ({ isOpen, selectedMenu }) => {
  const menuItems = useMenuItems();
  const [activeTabs, setActiveTabs] = useState<number[]>([]);
  const [selectedTab, setSelectedTab] = useState<number | null>(selectedMenu);

  useEffect(() => {
    if (selectedMenu !== null) {
      openTab(selectedMenu);
    }
  }, [selectedMenu]);

  useEffect(() => {
    if (selectedTab !== null && !activeTabs.includes(selectedTab)) {
      const lastTab = activeTabs[activeTabs.length - 1] || null;
      setSelectedTab(lastTab);
    }
  }, [activeTabs, selectedTab]);

  const openTab = (menuId: number) => {
    setActiveTabs(tabs => {
      const newTabs = [...tabs];
      if (!newTabs.includes(menuId)) {
        newTabs.push(menuId);
      }
      return newTabs;
    });
    setSelectedTab(menuId);
  };

  const removeTab = (menuId: number) => {
    setActiveTabs(tabs => {
      const newTabs = tabs.filter(tabId => tabId !== menuId);
      if (selectedTab === menuId) {
        const currentIndex = tabs.indexOf(menuId);
        const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;
        const newSelectedTab = newIndex !== null ? newTabs[newIndex] : null;
        setSelectedTab(newSelectedTab);
      } else {
        setSelectedTab(newTabs.length > 0 ? newTabs[newTabs.length - 1] : null);
      }
      return newTabs;
    });
  };

  const findMenuItem = (items: MenuItem[], selectedId: number): MenuItem | null => {
    for (const item of items) {
      if (item.id === selectedId) {
        return item;
      }
      if (item.children) {
        const found = findMenuItem(item.children, selectedId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const renderTabTitle = (menuItem: MenuItem) => (
    <span
      onMouseDown={(event) => {
        if (event.button === 1) {
          event.preventDefault();
          removeTab(menuItem.id);
        }
      }}
    >
      {menuItem.title}
    </span>
  );

  const renderTabPanel = (selectedId: number) => {
    const menuItem = findMenuItem(menuItems, selectedId);
    if (!menuItem) return null;

    return {
      id: menuItem.id,
      title: renderTabTitle(menuItem),
      Component: menuItem.Component ? <menuItem.Component /> : <div>Lütfen bir menü seçin.</div>
    };
  };

  const moveTab = (dragIndex: number, hoverIndex: number) => {
    setActiveTabs((prevTabs) => {
      const updatedTabs = [...prevTabs];
      const [draggedTab] = updatedTabs.splice(dragIndex, 1);
      updatedTabs.splice(hoverIndex, 0, draggedTab);
      return updatedTabs;
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <main className={`App-content overflow-auto ${isOpen ? "open" : ""}`}>
        {activeTabs.length === 0 ? (
          <div className="text-center mt-5">
            <img src={logo} alt="Logo" className="mb-3" />
            <Card className="text-center mx-auto" style={{ maxWidth: '500px' }}>
              <Card.Header>Hoş Geldiniz!</Card.Header>
              <Card.Body>
                <Card.Title>Açıklama Paneli</Card.Title>
                <Card.Text>
                  Lütfen sol menüden bir öğe seçin. Seçilen öğe burada görünecektir.
                </Card.Text>
                <Card.Text>
                  Orta tıklama (fare tekerleği) ile sekmeleri kapatabilirsiniz.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ) : (
          <Tabs
            activeKey={selectedTab !== null ? selectedTab : 0}
            onSelect={(k) => setSelectedTab(Number(k))}
            id="controlled-tab-example"
          >
            {activeTabs.map((tabId, index) => {
              const menuItem = findMenuItem(menuItems, tabId);
              if (!menuItem) return null;
              return (
                <Tab
                  eventKey={menuItem.id}
                  title={
                    <DraggableTab
                      id={menuItem.id}
                      index={index}
                      moveTab={moveTab}
                      removeTab={removeTab}
                      title={menuItem.title}
                    />
                  }
                  key={menuItem.id}
                >
                  {menuItem.Component ? <menuItem.Component /> : <div>Lütfen bir menü seçin.</div>}
                </Tab>
              );
            })}
          </Tabs>
        )}
      </main>
    </DndProvider>
  );
};

export default Content;
