import React, { useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import "./RezervasyonRaporu1.css";
import {
  FaSignInAlt,
  FaClock,
  FaSignOutAlt,
  FaShoppingCart,
  FaCalendarTimes,
} from "react-icons/fa";
import RmosGrid from "../../../../../../Components/DinamikRmosGrid/RmosGrid";
import { useDbId } from "../../../../../../Context/LocalStrogeContext/OnburoDbContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import postRequest from "../../../../../../Components/PostRequest/PostRequest";
import { Tab, Tabs } from "react-bootstrap";
import RoomChangeModal from "../../../Modal/RoomChangeModal/RoomChangeModal";

const RezervasyonRaporu1: React.FC = () => {
  const apiUrl = "https://frontapi.rmosweb.com/api/Procedure/StpReztara_Giris";
  const cancelUrl = "https://frontapi.rmosweb.com/api/Procedure/StpRoomchange";
  const gridKey = "RezervasyonRaporu1";
  const { dbId } = useDbId();

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const todayDate = formatDate(new Date());

  const initialRequestBody = {
    db_Id: dbId,
    xRez_Id: 0,
    xRez_Master_detay: "M",
    xRez_Sirket: 0,
    xtip: 2,
    xRez_rih: "R",
    xBas_Tar: todayDate,
    xBit_Tar: todayDate,
    xRez_Alis_tarihi: null,
    xRez_Blokeli: 0,
    xRez_Odano: null,
    tek_durum: 0,
    xArama: 1,
    xRez_C_W: "C",
    xIptal_10: 0,
    xFazla_Otel: null,
    xchkFis_Fazla_otel_10: 0,
    xGrup_10: 0,
    odeme_10: 0,
    hkdurum: null,
    online_10: 0,
    ozet_10: 0,
    package_10: 0,
    xAcentalar: null,
    rez_Rep_10: 0,
    rez_Devre_Gelir_10: 0,
  };

  const initialRequestBody1 = {
    db_Id: dbId,
    xRez_Master_detay: "M",
    xtip: 3,
    xRez_rih: "C",
    xBas_Tar: todayDate,
    xBit_Tar: todayDate,
    xArama: 1,
    xIptal_10: 1,
    xRez_C_W: "T",
    xFazla_Otel: null,
    xchkFis_Fazla_otel_10: 0,
    xAcentalar: null,
  };

  const [requestBody, setRequestBody] = useState<any>(initialRequestBody);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<string>("RezervasyonRaporu");

  const handleButtonClick = async (xArama: number) => {
    const updatedRequestBody = {
      ...(activeTab === "IptalRezervasyonRaporu"
        ? {
            ...initialRequestBody1,
            xBas_Tar: startDate ? formatDate(startDate) : "",
            xBit_Tar: endDate ? formatDate(endDate) : "",
            xArama,
            xtip: 3, // Ensure xtip is always 3
          }
        : {
            ...requestBody,
            xArama,
            xBas_Tar: startDate ? formatDate(startDate) : "",
            xBit_Tar: endDate ? formatDate(endDate) : "",
          }),
    };
    setRequestBody(updatedRequestBody);
    try {
      const response = await postRequest(apiUrl, updatedRequestBody);
      console.log("Response:", response);
      // Handle the response data here
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev: any) => ({ ...prev, xRez_C_W: event.target.value }));
  };

  const handleContextMenuClick = (action: string, rowData: any) => {
    console.log(`Action: ${action}, Row Data:`, rowData);
    if (action === "cancel") {
      setSelectedRowData(rowData);
      setShowCancelDialog(true);
    } else if (action === "GeriAl") {
      setSelectedRowData(rowData);
      handleReversalReservation();
    }
  };

  const handleCancelReservation = async () => {
    const token: any = localStorage.getItem("token");
    const cancelRequestBody = {
      db_Id: dbId,
      xtip: 2,
      rez_Master_id: selectedRowData["RezId(M)"],
      eski_Oda: null,
      yeni_Oda: null,
      rez_Birles_oda_10: 0,
      kulaniciid: 0,
    };
    try {
      console.log("Cancel Request Body:", cancelRequestBody);
      const response = await postRequest(cancelUrl, cancelRequestBody, token);
      console.log("Cancel Response:", response);
      setShowCancelDialog(false);
    } catch (error) {
      console.error("Error:", error);
      setShowCancelDialog(false);
    }
  };

  const handleReversalReservation = async () => {
    console.log("Selected Row Data:", selectedRowData);
    const token: any = localStorage.getItem("token");
    const reversalRequestBody = {
      db_Id: dbId,
      xtip: 3,
      rez_Master_id: selectedRowData["RezId(M)"],
      eski_Oda: null,
      yeni_Oda: null,
      rez_Birles_oda_10: 0,
      kulaniciid: 0,
    };
    try {
      console.log("Reversal Request Body:", reversalRequestBody);
      const response = await postRequest(cancelUrl, reversalRequestBody, token);
      console.log("Reversal Response:", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleTabSelect = (key: string | null) => {
    const tabKey = key || "RezervasyonRaporu";
    setActiveTab(tabKey);
    if (tabKey === "IptalRezervasyonRaporu") {
      setRequestBody(initialRequestBody1);
    } else {
      setRequestBody(initialRequestBody);
    }
  };

  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-12 bg-white shadow-lg rounded p-3 d-flex align-items-center justify-content-between">
            <div className="container d-flex align-items-center justify-content-between">
              <div className="date-picker-group">
                <div className="date-picker-item">
                  <label>Başlangıç Tarihi</label>
                  <DatePicker
                    defaultValue={new Date()}
                    format="dd.MM.yyyy"
                    value={startDate}
                    onChange={(e) => setStartDate(e.value)}
                  />
                </div>
                <div className="date-picker-item">
                  <label>Bitiş Tarihi</label>
                  <DatePicker
                    defaultValue={new Date()}
                    format="dd.MM.yyyy"
                    value={endDate}
                    onChange={(e) => setEndDate(e.value)}
                  />
                </div>
              </div>
              <div className="button-group">
                <Button className="btn" onClick={() => handleButtonClick(1)}>
                  <FaSignInAlt className="icon" />
                  Giriş Tarihi
                </Button>
                <Button className="btn" onClick={() => handleButtonClick(5)}>
                  <FaClock className="icon" />
                  Sistem Tarihi
                </Button>
                <Button className="btn" onClick={() => handleButtonClick(2)}>
                  <FaSignOutAlt className="icon" />
                  Çıkış Tarihi
                </Button>
                <Button className="btn" onClick={() => handleButtonClick(3)}>
                  <FaShoppingCart className="icon" />
                  Alış Tarihi
                </Button>
                {activeTab === "IptalRezervasyonRaporu" && (
                  <Button className="btn" onClick={() => handleButtonClick(4)}>
                    <FaCalendarTimes className="icon" />
                    İptal Tarihi
                  </Button>
                )}
                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      name="rezType"
                      value="C"
                      onChange={handleRadioChange}
                      defaultChecked
                    />
                    Kesin
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="rezType"
                      value="T"
                      onChange={handleRadioChange}
                    />
                    Geçici (Tentative)
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 bg-white shadow-lg rounded p-3 mt-4">
            <Tabs
              defaultActiveKey="RezervasyonRaporu"
              id="uncontrolled-tab-example"
              onSelect={handleTabSelect}
            >
              <Tab eventKey="RezervasyonRaporu" title="Rezervasyon Raporu">
                <RmosGrid
                  apiUrl={apiUrl}
                  requestBody={requestBody}
                  gridKey={gridKey}
                  contextMenuItems={[
                    {
                      label: "Rezervasyon İptal Et",
                      action: "cancel",
                      icon: <FontAwesomeIcon icon={faTrash} />,
                    },
                  ]}
                  onContextMenuClick={handleContextMenuClick}
                />
              </Tab>
              <Tab
                eventKey="IptalRezervasyonRaporu"
                title="Iptal Rezervasyon Raporu"
              >
                <RmosGrid
                  apiUrl={apiUrl}
                  requestBody={requestBody}
                  gridKey={gridKey}
                  onContextMenuClick={handleContextMenuClick}
                  contextMenuItems={[
                    {
                      label: "Rezervasyonu Geri Al",
                      action: "GeriAl",
                      icon: <FontAwesomeIcon icon={faTrash} />,
                    },
                  ]}
                />
              </Tab>
              <Tab eventKey="BeklemeWaiting" title="Bekleme / Waiting Raporu">
                <p>Iletişim</p>
              </Tab>
              <Tab eventKey="BlokajDegisimleri" title="Blokaj Değişimleri">
                <p>Iletişim</p>
              </Tab>
              <Tab eventKey="OzetRaporlar" title="Özet Raporlar">
                <p>Iletişim</p>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {showCancelDialog && (
        <Dialog onClose={() => setShowCancelDialog(false)}>
          <p>
            {selectedRowData["RezId(M)"]} Rezervasyonu iptal etmek istediğinize
            emin misiniz?
          </p>
          <DialogActionsBar>
            <Button onClick={handleCancelReservation}>Evet</Button>
            <Button onClick={() => setShowCancelDialog(false)}>Hayır</Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default RezervasyonRaporu1;
