import React, { useEffect, useRef, useState } from "react";
import { Card, Spinner, Tab, Tabs } from "react-bootstrap";
import UstMenuBar from "../../../../../../Components/Basliklar/UstMenuBar";
import {
  Checkbox,
  Input,
  RadioButton,
  RadioButtonChangeEvent,
  TextArea,
  InputChangeEvent,
} from "@progress/kendo-react-inputs";
import {
  ComboBox,
  MultiColumnComboBox,
  MultiColumnComboBoxHandle,
} from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { useDbId } from "../../../../../../Context/LocalStrogeContext/OnburoDbContext";
import axios from "axios";
import GeneralButton from "../../../../../../Components/Buttons/FloatActionButton/GeneralButton";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import UstMenuBar2 from "../../../../../../Components/Basliklar/UstMenuBar2";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FolioFaturasi: React.FC = () => {
  const { dbId } = useDbId();

  const [rihBilgi, setRihBilgi] = useState("I");
  const [odaNo, setOdaNo] = useState("");
  const [faturaId, setFaturaId] = useState<number | null>(null);
  const [faturaData, setFaturaData] = useState<any[]>([]);
  const [manuelGirisData, setManuelGirisData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [faturaSekli, setFaturaSekli] = useState("Kendi");

  const [adres1, setAdres1] = useState("");
  const [adres2, setAdres2] = useState("");
  const [adres3, setAdres3] = useState("");
  const [ilce, setIlce] = useState("");
  const [il, setIl] = useState("");
  const [tcNo, setTcNo] = useState("");
  const [pasaportNo, setPasaportNo] = useState("");
  const [vergiNo, setVergiNo] = useState("");
  const [folioNo, setFolioNo] = useState("");
  const [acenta, setAcenta] = useState("");
  const [kur, setKur] = useState("");
  const [otel, setOtel] = useState("");
  const [dovizKodu, setDovizKodu] = useState("");
  const [buyuk, setBuyuk] = useState("");
  const [kucuk, setKucuk] = useState("");
  const [free, setFree] = useState("");
  const [aciklama, setAciklama] = useState("");
  const [mail, setMail] = useState("");
  const [vergiDairesi, setVergiDairesi] = useState("");
  const [istisnaAciklama, setIstisnaAciklama] = useState("");
  const [sirketAdi, setSirketAdi] = useState("");
  const [adi, setAdi] = useState("");
  const [soyadi, setSoyadi] = useState("");
  const [aciklama2, setAciklama2] = useState("");
  const [CikisTarihi, setCikisTarihi] = useState<Date | null>(null);
  const [GirisTarihi, setGirisTarihi] = useState<Date | null>(null);
  const [ustMenuFaturaTipiSecimi, setUustMenuFaturaTipiSecimi] = useState("R");

  const [tutar, setTutar] = useState(0);
  const [dovizTutar, setDovizTutar] = useState(0);
  const [netKdvsiz, setNetKdvsiz] = useState(0);
  const [konverMatrah, setKonverMatrah] = useState(0);
  const [kdv, setKdv] = useState(0);
  const [konver, setKonver] = useState(0);
  const [kdvsiz1, setKdvsiz1] = useState(0);
  const [kdvsiz2, setKdvsiz2] = useState(0);
  const [kdvsiz3, setKdvsiz3] = useState(0);
  const [kdvsiz4, setKdvsiz4] = useState(0);
  const [kdv1, setKdv1] = useState(0);
  const [kdv2, setKdv2] = useState(0);
  const [kdv3, setKdv3] = useState(0);
  const [kdv4, setKdv4] = useState(0);
  const [kdvoran1, setKdvoran1] = useState(0);
  const [kdvoran2, setKdvoran2] = useState(0);
  const [kdvoran3, setKdvoran3] = useState(0);
  const [kdvoran4, setKdvoran4] = useState(0);

  const [efaturaKodlar, setEfaturaKodlar] = useState<string[]>([]);
  const [selectedEfaturaKod, setSelectedEfaturaKod] = useState<string | null>(
    null
  );
  const [istisnaKodlar, setIstisnaKodlar] = useState<any[]>([]);
  const [selectedIstisnaKod, setSelectedIstisnaKod] = useState<any | null>(
    null
  );
  const [ozelMatrahKodlar, setOzelMatrahKodlar] = useState<any[]>([]);
  const [selectedOzelMatrahKod, setSelectedOzelMatrahKod] = useState<
    any | null
  >(null);
  const [tevkifatKodlar, setTevkifatKodlar] = useState<any[]>([]);
  const [selectedTevkifatKod, setSelectedTevkifatKod] = useState<any | null>(
    null
  );

  const [faturaSenaryo, setFaturaSenaryo] = useState("TEMELFATURA");

  const [xRezID, setXRezID] = useState("");
  const [kumhrkID, setKumhrkID] = useState("");
  const [faturaNo, setFaturaNo] = useState("");
  const [voucher, setVoucher] = useState("");
  const [geceleme, setGeceleme] = useState("");
  const [konaklama, setKonaklama] = useState("");

  const temizle = () => {
    setRihBilgi("I");
    // setOdaNo("");
    setFaturaId(null);
    setFaturaData([]);
    setManuelGirisData([]);
    setLoading(false);
    setFaturaSekli("Kendi");
    setAdres1("");
    setAdres2("");
    setAdres3("");
    setIlce("");
    setIl("");
    setTcNo("");
    setPasaportNo("");
    setVergiNo("");
    setFolioNo("");
    setAcenta("");
    setKur("");
    setOtel("");
    setDovizKodu("");
    setBuyuk("");
    setKucuk("");
    setFree("");
    setAciklama("");
    setMail("");
    setVergiDairesi("");
    setIstisnaAciklama("");
    setSirketAdi("");
    setAdi("");
    setSoyadi("");
    setAciklama2("");
    setCikisTarihi(null);
  };

  const OdaNoFolioCagir = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      temizle();
      setLoading(true);
      const token = localStorage.getItem("token");
      const requestBody = {
        db_Id: dbId,
        Tip: 5,
        R_I_H: rihBilgi,
        Odano: odaNo,
      };

      console.log("İstek gönderiliyor:", requestBody);

      try {
        const response = await axios.post(
          "https://frontapi.rmosweb.com/api/Rez/Getir_Kod",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = response.data;
        console.log("API response:", result);
        if (result.isSucceded) {
          setFaturaId(result.value[0].Id);
          setVoucher(result.value[0].Voucher);
          console.log("Fatura Id:", result.value[0].Id);

          // İkinci API çağrısı
          const secondRequestBody = {
            db_Id: dbId,
            xRez_Id: result.value[0].Id,
            xTip: 20,
            xKumhrk_Re: ustMenuFaturaTipiSecimi,
          };

          console.log("İkinci istek gönderiliyor:", secondRequestBody);

          const secondResponse = await axios.post(
            "https://frontapi.rmosweb.com/api/Procedure/StpFatura_Bul",
            secondRequestBody,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const secondResult = secondResponse.data;
          console.log("Second API response:", secondResult);
          if (secondResult.isSucceded) {
            setFaturaData(secondResult.value);
            setKumhrkID(secondResult.value[0].KumhrkId);
            setFaturaNo(secondResult.value[0]["Fatura No"]);
          } else {
            console.error("İkinci API response error:", secondResult.message);
          }

          // Üçüncü API çağrısı
          const thirdRequestBody = {
            db_Id: dbId,
            xRez_Id: result.value[0].Id,
            xTip: 7,
            xKumhrk_Re: ustMenuFaturaTipiSecimi,
          };

          console.log("Üçüncü istek gönderiliyor:", thirdRequestBody);

          const thirdResponse = await axios.post(
            "https://frontapi.rmosweb.com/api/Procedure/StpFatura_Bul",
            thirdRequestBody,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const thirdResult = thirdResponse.data;
          console.log("Third API response:", thirdResult);
          if (thirdResult.isSucceded) {
            setManuelGirisData(thirdResult.value);
          } else {
            console.error("Üçüncü API response error:", thirdResult.message);
          }

          // Dördüncü API çağrısı
          const fourthRequestBody = {
            db_Id: dbId,
            xtip_Rapor: 36,
            rez_Id: result.value[0].Id,
            Fat_Kendi: faturaSekli,
          };

          console.log("Dördüncü istek gönderiliyor:", fourthRequestBody);

          const fourthResponse = await axios.post(
            "https://frontapi.rmosweb.com/api/Procedure/StpPrevil_Bul",
            fourthRequestBody,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const fourthResult = fourthResponse.data;
          console.log("Fourth API response:", fourthResult);
          if (fourthResult.isSucceded) {
            setSirketAdi(fourthResult.value[0].Şirket_Adi);
            setAdres1(fourthResult.value[0].Adres1);
            setAdres2(fourthResult.value[0].Adres2);
            setAdres3(fourthResult.value[0].Adres3);
            setVergiDairesi(fourthResult.value[0].Vergidaire);
            setVergiNo(fourthResult.value[0].Vergino);
            setIlce(fourthResult.value[0].Ilce);
            setIl(fourthResult.value[0].Il);
            setTcNo(fourthResult.value[0].Tcno);
            setPasaportNo(fourthResult.value[0].Pasaport_no);
            setFolioNo(fourthResult.value[0].Folio);
            setVergiNo(fourthResult.value[0].Vergino);
            setAcenta(fourthResult.value[0].Acenta_Sirket);
            setKur(fourthResult.value[0].Kur);
            setOtel(fourthResult.value[0].Otel);
            setDovizKodu(fourthResult.value[0].Dovizkodu);
            setBuyuk(fourthResult.value[0].Buyuk);
            setKucuk(fourthResult.value[0].Kucuk);
            setFree(fourthResult.value[0].Free);
            setAciklama(fourthResult.value[0].Acik1);
            setMail(fourthResult.value[0].Mail);
            setIstisnaAciklama(fourthResult.value[0].Istisna_aciklama);
            setAdi(fourthResult.value[0].Adi);
            setSoyadi(fourthResult.value[0].Soyadi);
            setAciklama2(fourthResult.value[0].Acik2);
            const apiDate = fourthResult.value[0].Cikis;
            const dateParts = apiDate.split("-");
            const formattedDate1 = new Date(
              `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
            );
            setCikisTarihi(formattedDate1);
            const apiDate2 = fourthResult.value[0].Giris;
            const dateParts2 = apiDate2.split("-");
            const formattedDate2 = new Date(
              `${dateParts2[2]}-${dateParts2[1]}-${dateParts2[0]}`
            );
            setGirisTarihi(formattedDate2);
            setGeceleme(fourthResult.value[0].Geceleme);
            setKonaklama(fourthResult.value[0].Konaklama);
            // setCikisTarihi(fourthResult.value[0].Cikis);
          } else {
            console.error("Dördüncü API response error:", fourthResult.message);
          }
          // Beşinci API çağrısı
          const fifthRequestBody = {
            db_Id: dbId,
            xRez_Id: result.value[0].Id,
            xTip: 32,
            xKumhrk_Re: ustMenuFaturaTipiSecimi,
          };

          console.log("Beşinci istek gönderiliyor:", fifthRequestBody);

          const fifthResponse = await axios.post(
            "https://frontapi.rmosweb.com/api/Procedure/StpFatura_Bul",
            fifthRequestBody,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const fifthResult = fifthResponse.data;
          console.log("Fifth API response:", fifthResult);
          if (fifthResult.isSucceded) {
            setTutar(fifthResult.value[0].Tutar);
            setDovizTutar(fifthResult.value[0].Doviztutar);
            setNetKdvsiz(fifthResult.value[0].Netkdvsiz);
            setKonverMatrah(fifthResult.value[0].Konver_Matrah);
            setKdv(fifthResult.value[0].Kdv);
            setKonver(fifthResult.value[0].Konver);
            setKdvsiz1(fifthResult.value[0].Kdvsiz1);
            setKdvsiz2(fifthResult.value[0].Kdvsiz2);
            setKdvsiz3(fifthResult.value[0].Kdvsiz3);
            setKdvsiz4(fifthResult.value[0].Kdvsiz4);
            setKdv1(fifthResult.value[0].Kdv1);
            setKdv2(fifthResult.value[0].Kdv2);
            setKdv3(fifthResult.value[0].Kdv3);
            setKdv4(fifthResult.value[0].Kdv4);
            setKdvoran1(fifthResult.value[0].Kdvoran1);
            setKdvoran2(fifthResult.value[0].Kdvoran2);
            setKdvoran3(fifthResult.value[0].Kdvoran3);
            setKdvoran4(fifthResult.value[0].Kdvoran4);
          } else {
            console.error("Beşinci API response error:", fifthResult.message);
          }

          // Altıncı API çağrısı
          const sixthRequestBody = {
            db_Id: dbId,
            tip: 0,
          };

          console.log("Altıncı istek gönderiliyor:", sixthRequestBody); // Altıncı isteği konsola yazdırma

          const sixthResponse = await axios.post(
            "https://frontapi.rmosweb.com/api/Procedure/Efatura_Kodlar_Listesi",
            sixthRequestBody,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const sixthResult = sixthResponse.data;
          console.log("Sixth API response:", sixthResult);
          if (sixthResult.isSucceded) {
            setEfaturaKodlar(sixthResult.value.map((item: any) => item.Ad));
          } else {
            console.error("Altıncı API response error:", sixthResult.message);
          }

          // Yedinci API çağrısı
          const seventhRequestBody = {
            db_Id: dbId,
            tip: 1,
          };

          console.log("Yedinci istek gönderiliyor:", seventhRequestBody); // Yedinci isteği konsola yazdırma

          const seventhResponse = await axios.post(
            "https://frontapi.rmosweb.com/api/Procedure/Efatura_Kodlar_Listesi",
            seventhRequestBody,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const seventhResult = seventhResponse.data;
          console.log("Seventh API response:", seventhResult);
          if (seventhResult.isSucceded) {
            setIstisnaKodlar(seventhResult.value);
          } else {
            console.error("Yedinci API response error:", seventhResult.message);
          }
          // Sekizinci API çağrısı
          const eighthRequestBody = {
            db_Id: dbId,
            tip: 2,
          };

          console.log("Sekizinci istek gönderiliyor:", eighthRequestBody); // Sekizinci isteği konsola yazdırma

          const eighthResponse = await axios.post(
            "https://frontapi.rmosweb.com/api/Procedure/Efatura_Kodlar_Listesi",
            eighthRequestBody,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const eighthResult = eighthResponse.data;
          console.log("Eighth API response:", eighthResult);
          if (eighthResult.isSucceded) {
            setOzelMatrahKodlar(eighthResult.value);
          } else {
            console.error(
              "Sekizinci API response error:",
              eighthResult.message
            );
          }

          // Dokuzuncu API çağrısı
          const ninthRequestBody = {
            db_Id: dbId,
            tip: 3,
          };

          console.log("Dokuzuncu istek gönderiliyor:", ninthRequestBody); // Dokuzuncu isteği konsola yazdırma

          const ninthResponse = await axios.post(
            "https://frontapi.rmosweb.com/api/Procedure/Efatura_Kodlar_Listesi",
            ninthRequestBody,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const ninthResult = ninthResponse.data;
          console.log("Ninth API response:", ninthResult);
          if (ninthResult.isSucceded) {
            setTevkifatKodlar(ninthResult.value);
          } else {
            console.error("Dokuzuncu API response error:", ninthResult.message);
          }
        } else {
          console.error("API response error:", result.message);
        }
      } catch (error) {
        console.error("API request error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleREAChange = (event: RadioButtonChangeEvent) => {
    setUustMenuFaturaTipiSecimi(event.value);
    console.log("Fatura Tipi Seçimi:", event.value);
  };
  const handleRIHChange = (event: RadioButtonChangeEvent) => {
    setRihBilgi(event.value);
    console.log("RIH Seçimi", event.value);
  };

  const faturaKaydet = async () => {
    const token = localStorage.getItem("token");

    const requestBody = {
      db_Id: dbId,
      No: faturaNo,
      Acenta_Sirket: acenta,
      Adresdepo: 0,
      Sirketadi: sirketAdi,
      Adi: adi,
      Soyadi: soyadi,
      Tcno: tcNo,
      Kimlik_No: 0,
      Ilce: ilce,
      Il: il,
      Ulke: "",
      Adres1: adres1,
      Adres2: adres2,
      Adres3: adres3,
      Vergidaire: vergiDairesi,
      Vergino: vergiNo,
      Satirsay: 0,
      Oda: odaNo,
      Folio: folioNo,
      Giris: GirisTarihi,
      Cikis: CikisTarihi,
      Muhcari: "string",
      Mahsupno: 0,
      Mahsupsira: 0,
      Kredipesin: "",
      Tarihi: "2024-07-09",
      Kulanici: "RMOS WEB",
      Kdvsiz1: kdvsiz1,
      Kdvsiz2: kdvsiz2,
      Kdvsiz3: kdvsiz3,
      Kdv1: kdv1,
      Kdv2: kdv2,
      Kdv3: kdv3,
      Kdvoran1: kdvoran1,
      Kdvoran2: kdvoran2,
      Kdvoran3: kdvoran3,
      Netkdvsiz: netKdvsiz,
      Kdv: kdv,
      Tutar: tutar,
      Dovizkodu: dovizKodu,
      Doviztutar: dovizTutar,
      iskonto: 0,
      Acik1: aciklama,
      Acik2: aciklama2,
      Alissatis: true,
      Departman: "",
      Tipi: "C_TEK",
      Geceleme: 0,
      Oda_sayisi: 0,
      Kur: kur,
      Sekil_kodu: "",
      Yilkodu: 0,
      Sirket: 0,
      Iskonto_yuzde: 0,
      Iskonto_doviz: 0,
      Iskonto_kdvsiz: 0,
      Net: 0,
      Tutar1: 0,
      Tutar2: 0,
      Tutar3: 0,
      Voucher: "",
      E_eh: "",
      E_fatura_eh: "",
      E_fatura_senaryo: faturaSenaryo,
      Disardan_eh: "",
      Bavel_gitti_10: 0,
      Tevkifat_var_10: 0,
      Tevkifat_oran: 0,
      Tevkifat_kodu: "",
      Tevkifat_kdvtut: 0,
      Otel: "",
      Tevkifat_efatura: "",
      Istisna_Kodu: "",
      Efatura_turu: "",
      Ozelmatrah_kodu: "",
      Iade_corr: 0,
      Edevlet_EH: "",
      Edevlet_Fatno: "",
      Mail: mail,
      Pasaport_no: pasaportNo,
      Kendi: faturaSekli,
      Doviz_tl_10: 0,
      Doviz_kdvsiz: 0,
      Doviz_kdv: 0,
      Istisna_aciklama: "",
      Seri: "",
      Serino1: "",
      Serino2: "",
      Efat_sekil_kod: "",
      Fatfark_beyan_kod: "",
      Fatfark_beyan_acik: "",
      Fatura_aciklama_no: 0,
      Beraber_id: 0,
      Kesim_sekli: "",
      Re: ustMenuFaturaTipiSecimi,
      Konver: 0,
      Konver_oran: 0,
      Konver123: 0,
      TransferNot: "",
      LogoInvoiceNo: 0,
      LogoGUID: "",
      Gunluk_10: 0,
      Konver_matrah: 0,
      Kdvsiz4: kdvsiz4,
      Kdv4: kdv4,
      Kdvoran4: kdvoran4,
      Tutar4: 0,
    };

    const toastId = toast.loading("Fatura oluşturuluyor...");

    try {
      console.log("Fatura Kaydetme İsteği Gönderiliyor:", requestBody);

      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Fat/Ekle",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Fatura Kaydetme Yanıtı:", response.data);

      if (response.data.isSucceded) {
        const [fatid, fatno] = response.data.value.split(',').map(Number);

        const secondRequestBody = {
          db_Id: dbId,
          Fatid: fatid,
          Fatno: fatno,
          Oda: odaNo,
          Folio: folioNo,
          Adi: adi,
          Soyadi: soyadi,
          Giris: GirisTarihi,
          Cikis: CikisTarihi,
          Buyuk: buyuk,
          Kucuk: kucuk,
          Free: free,
          Voucher: voucher,
          Rezno: "",
          Aciklama: aciklama,
          Kdvsiz: netKdvsiz,
          Kdvsiz1: kdvsiz1,
          Kdvsiz2: kdvsiz2,
          Kdv: kdv,
          Kdv1: kdv1,
          Kdv2: kdv2,
          Tutar: tutar,
          Doviz: dovizTutar,
          Dovizkodu: dovizKodu,
          Acenta_Sirket: acenta,
          Uygulanankur: 0,
          Gunlukkur: 0,
          Doviztutar: dovizTutar,
          Kdv_yuzde1: kdvoran1,
          Kdv_yuzde2: kdvoran2,
          Re: ustMenuFaturaTipiSecimi,
          Konaklama: "",
          Geceleme: 0,
          Sirket: 0,
          Kulanici_id: 0,
          Yilkodu: 0,
          Miktar: 0,
          Birim_fiyat: 0,
          E_fatura_eh: "",
          E_fatura_senaryo: faturaSenaryo,
          Bolum: 0,
          Disardan_eh: "",
          Tarih: "2024-07-11",
          Otel: otel,
          Doviz_kdvsiz: 0,
          Doviz_kdv: 0,
          Fatfark_beyan_kod: "",
          Fatfark_beyan_acik: "",
          ikikdv10: 0,
          Sira: 0,
          Konver: konver,
          Konver_oran: 0,
          Kdvsiz3: kdvsiz3,
          Kdv3: kdv3,
          Kdv_yuzde3: kdvoran3,
          Iskonto_yuzde: 0,
          Iskonto: 0,
          Iskonto_doviz: 0,
          Pasaport_no: pasaportNo,
          Konver_matrah: 0,
          Kdvsiz4: kdvsiz4,
          Kdv4: kdv4,
          Kdv_yuzde4: kdvoran4,
        };

        console.log(
          "Fatura HrK Kaydetme İsteği Gönderiliyor:",
          secondRequestBody
        );

        const secondResponse = await axios.post(
          "https://frontapi.rmosweb.com/api/Fathrk/Ekle",
          secondRequestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Fatura HrK Kaydetme Yanıtı:", secondResponse.data);

        if (secondResponse.data.isSucceded) {
          for (let item of manuelGirisData) {
            const thirdRequestBody = {
              db_Id: dbId,
              Id: 0,
              Folio: folioNo,
              Faturano: fatno,
              Fathrkid: fatid,
              Kodu: item.Kodu,
              Adi: item.DepAdi,
              Ba: "B",
              Kdvsiz: item.Kdvsiz,
              Kdv: item.Kdv,
              Kdvorani: item.Kdvorani,
              Tutar: item.Tutar,
              Dovizkod: item.Dovizkod || "",
              Doviztutar: item.Doviztutar,
              Tarih: "2024-07-11",
              Sekil_kodu: "",
              Otelkodu: "string",
              Kulanici_id: 0,
              Yilkodu: 0,
              Sirket: 0,
              Fatura_bolum: 0,
              Doviz_kdvsiz: 0,
              Doviz_kdv: 0,
              Konver_oran: item.Konver_oran,
              Konver: item.Konver,
              Konver_matrah: item.Konver_matrah,
            };

            console.log(
              "Fatura Dep Kaydetme İsteği Gönderiliyor:",
              thirdRequestBody
            );

            const thirdResponse = await axios.post(
              "https://frontapi.rmosweb.com/api/Fatdep/Ekle",
              thirdRequestBody,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            console.log("Fatura Dep Kaydetme Yanıtı:", thirdResponse.data); 
          }
          toast.update(toastId, { render: "Fatura oluşturuldu", type: "success", isLoading: false, autoClose: 5000 });
        } else {
          toast.update(toastId, { render: "Fatura HrK oluşturma başarısız", type: "error", isLoading: false, autoClose: 5000 });
        }
      } else {
        toast.update(toastId, { render: "Fatura oluşturma başarısız", type: "error", isLoading: false, autoClose: 5000 });
      }
    } catch (error) {
      console.error("Fatura Kaydetme İsteği Hatası:", error);
      toast.update(toastId, { render: "Fatura oluşturma başarısız", type: "error", isLoading: false, autoClose: 5000 });
    }
  };


  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
    }).format(value);
  };
  

  return (
    <>
      <ToastContainer />
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-md-12 mt-1">
            <UstMenuBar>
              <div className="container-fluid m-0 p-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="container-fluid align-items-center">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="w-100 d-flex  justify-content-between align-items-center">
                            <div className="w-30">
                              <RadioButton
                                label="Room"
                                value={"R"}
                                checked={ustMenuFaturaTipiSecimi === "R"}
                                onChange={handleREAChange}
                              />
                            </div>
                            <div className="w-30">
                              <RadioButton
                                label="Extra"
                                value={"E"}
                                checked={ustMenuFaturaTipiSecimi === "E"}
                                onChange={handleREAChange}
                              />
                            </div>
                            <div className="w-30">
                              <RadioButton
                                label="All"
                                value={"T"}
                                checked={ustMenuFaturaTipiSecimi === "T"}
                                onChange={handleREAChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="btn-group" role="group">
                              {Array.from({ length: 5 }, (_, i) => (
                                <button key={i} className="btn btn-primary">
                                  <i className="fas fa-user"></i> {i + 1}.kişi
                                </button>
                              ))}
                            </div>
                          </div>
                          {/* bittiği yer */}
                        </div>
                        <div className="col-md-6">
                          <div className="w-100 d-flex justify-content-center align-items-center">
                            <div className="w20">
                              <Checkbox label={"Yazdır"} />
                            </div>
                            <div className="w20 ms-1">
                              <DatePicker />
                            </div>
                            <div className="w20 ms-1">
                              <ComboBox placeholder="Fatura Şekli" />
                            </div>
                            <div className="w20 ms-1">
                              <Input placeholder="Çıkış Tarihi" />
                            </div>
                            <div className="w20 ms-1">
                              <RadioButton label="T.D." />
                              <RadioButton label="E.F." />
                              <RadioButton label="T.E" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="w-100 d-flex justify-content-center align-items-center">
                            <div className="w-50">
                              <RadioButton
                                label="Inhouse"
                                value={"I"}
                                checked={rihBilgi === "I"}
                                onChange={handleRIHChange}
                              />
                            </div>
                            <div className="w-50">
                              <RadioButton
                                label="History"
                                checked={rihBilgi === "H"}
                                value={"H"}
                                onChange={handleRIHChange}
                              />
                            </div>
                            <div className="w-50">
                              <RadioButton
                                label="No Show"
                                checked={rihBilgi === "C"}
                                value={"C"}
                                onChange={handleRIHChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </UstMenuBar>
            {/* <UstMenuBar2/> */}
            {/* ikinci üst bar  */}
            <UstMenuBar>
              <div className="container-fluid m-0 p-0">
                <div className="row">
                  <div className="col-md-12">
                    <GeneralButton
                      text="Kaydet ve Listele"
                      height="25px"
                      icon={faCheckCircle}
                      gradientStart="#1B5E20"
                      gradientEnd="#388E3C"
                      hoverGradientStart="#2E7D32"
                      hoverGradientEnd="#43A047"
                      onClick={faturaKaydet}
                    />
                  </div>
                </div>
              </div>
            </UstMenuBar>
            <Tabs defaultActiveKey="Genel" id="uncontrolled-tab-example">
              <Tab eventKey="Genel" title="Genel">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 mt-1 p-0 m-0">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-7 m-0 p-0">
                            <div className="d-right">
                              <Card>
                                <Card.Header>
                                  <Card.Title>Bölüm 1</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Oda No
                                    </div>
                                    <div className="div w-30">
                                      <Input
                                        value={odaNo}
                                        onChange={(event) =>
                                          setOdaNo(event.target.value as string)
                                        }
                                        onKeyDown={OdaNoFolioCagir}
                                      />
                                    </div>
                                    <div className="div w-25 ms-1">
                                      <ComboBox />
                                    </div>
                                    <div className="div w-25 ms-1">
                                      <ComboBox />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Folio
                                    </div>
                                    <div className="div w-30">
                                      <Input value={folioNo} />
                                    </div>
                                    <div className="div w-25 ms-1">
                                      Tc Kimlik No
                                    </div>
                                    <div className="div w-25 ms-1">
                                      <Input value={tcNo} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Fatura Tarihi
                                    </div>
                                    <div className="div w-30">
                                      <DatePicker
                                        value={CikisTarihi}
                                        onChange={(e) =>
                                          setCikisTarihi(e.value)
                                        }
                                      />
                                    </div>
                                    <div className="div w-25 ms-1">
                                      Pasaport No
                                    </div>
                                    <div className="div w-25 ms-1">
                                      <Input value={pasaportNo} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Adres 1
                                    </div>
                                    <div className="div w-80">
                                      <Input value={adres1} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Adres 2
                                    </div>
                                    <div className="div w-20 ms-1">
                                      <Input value={adres2} />
                                    </div>
                                    <div className="div w-15 ms-1">İlçe</div>
                                    <div className="div w-15 ms-1">
                                      <Input value={ilce} />
                                    </div>
                                    <div className="div w-15 ms-1">İl</div>
                                    <div className="div w-15 ms-1">
                                      <Input value={il} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Vergi Dairesi
                                    </div>
                                    <div className="div w-40">
                                      <Input value={vergiDairesi} />
                                    </div>
                                    <div className="div w-20 ms-1">
                                      Vergi No
                                    </div>
                                    <div className="div w-20 ms-1">
                                      <Input value={vergiNo} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Açıklama 1
                                    </div>
                                    <div className="div w-80">
                                      <TextArea value={aciklama} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Açıklama 2
                                    </div>
                                    <div className="div w-30">
                                      <Input value={aciklama2} />
                                    </div>
                                    <div className="div w-25 ms-1">
                                      İstisna Açık
                                    </div>
                                    <div className="div w-25 ms-1">
                                      <Input value={istisnaAciklama} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Adı / Soyadı
                                    </div>
                                    <div className="div w-40">
                                      <Input value={adi} />
                                    </div>
                                    <div className="div w-40 ms-1">
                                      <Input value={soyadi} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      e-Mail
                                    </div>
                                    <div className="div w-80">
                                      <Input value={mail} />
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                          <div className="col-md-5 p-0 m-0">
                            <div className="px-2">
                              <Card>
                                <Card.Header>
                                  <Card.Title>Bölüm 2</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-50 text-start">
                                      <RadioButton
                                        label="Acenta"
                                        checked={faturaSekli === "Acenta"}
                                        onChange={() => {
                                          setFaturaSekli("Acenta");
                                        }}
                                      />
                                    </div>
                                    <div className="div w-50">
                                      <RadioButton
                                        label="Firma (Şirket)"
                                        checked={faturaSekli === "Firma"}
                                        onChange={() => {
                                          setFaturaSekli("Firma");
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-50 text-start">
                                      <RadioButton
                                        label="Şahıs (Kendi)"
                                        checked={faturaSekli === "Kendi"}
                                        onChange={() => {
                                          setFaturaSekli("Kendi");
                                        }}
                                      />
                                    </div>
                                    <div className="div w-50">
                                      <RadioButton
                                        label="Şahıs (Firma)"
                                        checked={faturaSekli === "Firmasahis"}
                                        onChange={() => {
                                          setFaturaSekli("Firmasahis");
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Ac/Şirket
                                    </div>
                                    <div className="div w-80">
                                      <ComboBox value={acenta} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      CRM (ID)
                                    </div>
                                    <div className="div w-80">
                                      <ComboBox />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Kur/Döviz
                                    </div>
                                    <div className="div w-40">
                                      KDV'siz Matrah
                                    </div>
                                    <div className="div w-40">
                                      <Input value={formatCurrency(netKdvsiz)} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      <Input value={"0,0000"} />
                                    </div>
                                    <div className="div w-40">
                                      Kon.Ver Matrah
                                    </div>
                                    <div className="div w-40">
                                      <Input value={formatCurrency(konverMatrah)} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Kon. Ver.
                                    </div>
                                    <div className="div w-40">KDV (Toplam)</div>
                                    <div className="div w-40">
                                      <Input value={kdv} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      <Input value={"2,00"} />
                                    </div>
                                    <div className="div w-40">
                                      Kon.Ver Tutar
                                    </div>
                                    <div className="div w-40">
                                      <Input value={formatCurrency(konver)} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Toplam
                                    </div>
                                    <div className="div w-80">
                                      <Input value={formatCurrency(tutar)} />
                                    </div>
                                  </div>

                                  <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                    <div className="div w-20 text-start">
                                      Döv. Tutar
                                    </div>
                                    <div className="div w-80">
                                      <Input value={dovizTutar} />
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                          <div className="container fluid">
                            <div className="row">
                              <div className="col-md-12 mt-1 m-0 p-0">
                                <div className="pd-right">
                                  <Card>
                                    <Card.Header>
                                      <Card.Title>Fatura Oluştur</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                      <div className="container-fluid">
                                        <div className="row">
                                          <div className="d-flex flex-column">
                                            <div className="w-100 d-flex  justify-content-between align-items-center">
                                              <div className="div w-20 text-start">
                                                Fat.Şirket Ad
                                              </div>
                                              <div className="div w-80">
                                                <Input value={sirketAdi} />
                                              </div>
                                            </div>

                                            <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                              <div className="div w-20 text-start">
                                                Fatura No
                                              </div>
                                              <div className="div w-40">
                                                <Input value={0} />
                                              </div>
                                              <div className="div w-40">
                                                <Checkbox
                                                  label={
                                                    "Bu Numrayı Sis. Parametresine Taşı"
                                                  }
                                                  color="red"
                                                />
                                              </div>
                                            </div>

                                            <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                              <div className="div w-20 text-start">
                                                Şekil
                                              </div>
                                              <div className="w-30 d-flex align-items-center justify-content-between mt-1">
                                                <div className="div">
                                                  <RadioButton
                                                    label={"Genel"}
                                                  />
                                                </div>
                                                <div className="div">
                                                  <RadioButton
                                                    label={"Günlük"}
                                                  />
                                                </div>
                                                <div className="div">
                                                  <RadioButton
                                                    label={"Pos Kart"}
                                                  />
                                                </div>
                                              </div>
                                              <div className="div w-40">
                                                <ComboBox
                                                  value={0}
                                                  placeholder="Kartlar"
                                                />
                                              </div>
                                            </div>
                                            <div className="container fluid">
                                              <div className="row">
                                                <div className="col-md-12 p-0 m-0 mt-1">
                                                  {loading ? (
                                                    <div
                                                      className="d-flex justify-content-center align-items-center"
                                                      style={{
                                                        height: "200px",
                                                      }}
                                                    >
                                                      <Spinner animation="border" />
                                                    </div>
                                                  ) : (
                                                    <Grid
                                                      data={faturaData}
                                                      style={{
                                                        height: "200px",
                                                        fontSize: "12px",
                                                      }} // Font boyutunu buraya ekliyoruz
                                                    >
                                                      <Column
                                                        field="Tarih"
                                                        title="Tarih"
                                                      />
                                                      <Column
                                                        field="Departman Kodu"
                                                        title="Kodu"
                                                      />
                                                      <Column
                                                        field="Departman Adı"
                                                        title="Departman"
                                                      />
                                                      <Column
                                                        field="Borç"
                                                        title="Borç"
                                                      />
                                                      <Column
                                                        field="Alacak"
                                                        title="Alacak"
                                                      />
                                                      <Column
                                                        field="Kdv Oran"
                                                        title="Kdv %"
                                                      />
                                                      {/* <Column
                                                  field="Çekno"
                                                  title="Çekno"
                                                /> */}
                                                      {/* <Column
                                                  field="Kur"
                                                  title="Kur"
                                                /> */}
                                                      {/* <Column
                                                  field="dBorc"
                                                  title="dBorc"
                                                /> */}
                                                      {/* <Column
                                                  field="Kur Adı"
                                                  title="Kur Adı"
                                                /> */}
                                                      {/* <Column
                                                  field="Aciklama"
                                                  title="Aciklama"
                                                /> */}
                                                      {/* <Column
                                                  field="Re"
                                                  title="Re"
                                                /> */}
                                                      <Column
                                                        field="Fatura No"
                                                        title="Fatura No"
                                                      />
                                                      <Column
                                                        field="Refund"
                                                        title="Refund"
                                                      />
                                                      {/* <Column
                                                  field="Kartno"
                                                  title="Kartno"
                                                /> */}
                                                      {/* <Column
                                                  field="Fatura Düşülsün"
                                                  title="Fatura Düşülsün"
                                                /> */}
                                                      {/* <Column
                                                  field="FaturaId"
                                                  title="FaturaId"
                                                /> */}
                                                      {/* <Column
                                                  field="KumhrkId"
                                                  title="KumhrkId"
                                                /> */}
                                                    </Grid>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1 p-0 m-0">
                      <Tabs
                        defaultActiveKey="ManuelGiris"
                        id="uncontrolled-tab-example"
                      >
                        {/* <Tab eventKey="KdvMatrahlari" title="Kdv Matrahları">
                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">
                              Kdv'siz Matrah (1)
                            </div>
                            <div className="div w-30">
                              <Input disabled={true} />
                            </div>
                            <div className="div w-30 ms-1">
                              <Input value={"0,00"} />
                            </div>
                          </div>

                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">
                              Kdv'siz Matrah (2)
                            </div>
                            <div className="div w-30">
                              <Input disabled={true} />
                            </div>
                            <div className="div w-30 ms-1">
                              <Input value={"0,00"} />
                            </div>
                          </div>

                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">
                              Kdv'siz Matrah (3)
                            </div>
                            <div className="div w-30">
                              <Input disabled={true} />
                            </div>
                            <div className="div w-30 ms-1">
                              <Input value={"0,00"} />
                            </div>
                          </div>

                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">
                              Kdv'siz Matrah (4)
                            </div>
                            <div className="div w-30">
                              <Input disabled={true} />
                            </div>
                            <div className="div w-30 ms-1">
                              <Input value={"0,00"} />
                            </div>
                          </div>

                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">Kdv (1)</div>
                            <div className="div w-30">
                              <Input disabled={true} />
                            </div>
                            <div className="div w-30 ms-1">
                              <Input value={"0,00"} />
                            </div>
                          </div>

                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">Kdv (2)</div>
                            <div className="div w-30">
                              <Input disabled={true} />
                            </div>
                            <div className="div w-30 ms-1">
                              <Input value={"0,00"} />
                            </div>
                          </div>

                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">Kdv (3)</div>
                            <div className="div w-30">
                              <Input disabled={true} />
                            </div>
                            <div className="div w-30 ms-1">
                              <Input value={"0,00"} />
                            </div>
                          </div>

                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">Kdv (4)</div>
                            <div className="div w-30">
                              <Input disabled={true} />
                            </div>
                            <div className="div w-30 ms-1">
                              <Input value={"0,00"} />
                            </div>
                          </div>

                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">
                              <Input value={"0,00"} />
                            </div>
                          </div>

                          <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                            <div className="div w-40 text-start">
                              Döviz Kodu / Adı
                            </div>
                            <div className="div w-30">
                              <Input disabled={true} />
                            </div>
                            <div className="div w-30 ms-1">
                              <Input />
                            </div>
                          </div>
                        </Tab> */}
                        <Tab eventKey="ManuelGiris" title="Manuel Giriş">
                          <div className="container-fluid mt-1 p-0 m-0">
                            <div className="row">
                              <div className="col-md-12">
                                {loading ? (
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: "400px" }}
                                  >
                                    <Spinner animation="border" />
                                  </div>
                                ) : (
                                  <Grid
                                    data={manuelGirisData}
                                    style={{
                                      height: "400px",
                                      fontSize: "12px",
                                    }} // Font boyutunu buraya ekliyoruz
                                  >
                                    <Column field="Kodu" title="Kodu" />
                                    <Column
                                      field="DepAdi"
                                      title="Departman Adı"
                                    />
                                    <Column
                                      field="Kdvorani"
                                      title="Kdv Oranı"
                                    />
                                    <Column
                                      field="Konver_oran"
                                      title="Konver Oranı"
                                    />
                                    <Column field="Tutar" title="Tutar" />
                                    <Column
                                      field="Doviztutar"
                                      title="Döviz Tutarı"
                                    />
                                    <Column field="Kdvsiz" title="Kdvsiz" />
                                    <Column
                                      field="Konver_matrah"
                                      title="Konver Matrah"
                                    />
                                    <Column field="Kdv" title="Kdv" />
                                    <Column field="Konver" title="Konver" />
                                  </Grid>
                                )}
                              </div>
                            </div>
                          </div>
                        </Tab>
                        {/* <Tab
                          eventKey="TevkifatveEkler"
                          title="Tevkifat Ve Ekler"
                        >
                          <div className="container-fluid mt-1 p-0 m-0">
                            <div className="row">
                              <div className="col-md-12">
                                <RmosGrid
                                  apiUrl={""}
                                  requestBody={{}}
                                  gridKey={"denem"}
                                  showFooterCell={true}
                                  footerCalculations={footerCalculations}
                                  height={"30vh"}
                                />
                              </div>
                            </div>
                          </div>
                        </Tab> */}
                      </Tabs>
                      <div className="container-fluid mt-1">
                        <div className="row">
                          <div className="col-md-12 p-0 m-0">
                            <Card>
                              <Card.Header>
                                <Card.Title>E-Fatura Bilgileri</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-20 text-start">
                                    E-Fat.
                                  </div>
                                  <div className="div w-30">
                                    <ComboBox
                                      data={[
                                        "E-Fatura",
                                        "E-Arşiv",
                                        "Kağıt Fatura",
                                        "Diğer",
                                        "Yok",
                                      ]}
                                    />
                                  </div>
                                  <div className="div w-25 ms-1">Senaryo</div>
                                  <div className="div w-25 ms-1">
                                    <ComboBox
                                      data={["TEMELFATURA", "TİCARİFATURA"]}
                                      onChange={(e) =>
                                        setFaturaSenaryo(e.value as any)
                                      }
                                      value={faturaSenaryo}
                                    />
                                  </div>
                                </div>

                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-40 text-start">
                                    <Checkbox label={"Diplomat"} color="red" />
                                  </div>
                                  <div className="div w-60">
                                    <Checkbox
                                      label={"Tevkifatlı Fatura (Kdv)"}
                                      color="red"
                                    />
                                  </div>
                                </div>

                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-40 text-start">
                                    Tev.İndirim.Kdv%
                                  </div>
                                  <div className="div w-30">
                                    <ComboBox data={["5/10", "9/10"]} />
                                  </div>
                                  <div className="div w-30 ms-1">
                                    <Input placeholder="A" value={"A"} />
                                  </div>
                                </div>

                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-50 text-start">
                                    E-Fat Tevkifat Kodu
                                  </div>
                                  <div className="div w-50">
                                    <MultiColumnComboBox
                                      data={tevkifatKodlar}
                                      textField="Ad"
                                      dataItemKey="Kod"
                                      columns={[
                                        { field: "Kod", header: "Kod" },
                                        { field: "Ad", header: "Ad" },
                                        { field: "Oran", header: "Oran" },
                                      ]}
                                      value={selectedTevkifatKod}
                                      onChange={(e) =>
                                        setSelectedTevkifatKod(e.value)
                                      }
                                      placeholder="Tevkifat Kodu Seçin"
                                    />
                                  </div>
                                </div>

                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-50 text-start">
                                    İstisna Kodu (Kdv %0)
                                  </div>
                                  <div className="div w-50">
                                    <MultiColumnComboBox
                                      data={istisnaKodlar}
                                      textField="Ad"
                                      dataItemKey="Kod"
                                      columns={[
                                        { field: "Kod", header: "Kod" },
                                        { field: "Ad", header: "Ad" },
                                      ]}
                                      value={selectedIstisnaKod}
                                      onChange={(e) =>
                                        setSelectedIstisnaKod(e.value)
                                      }
                                      placeholder="İstisna Kodu Seçin"
                                    />
                                  </div>
                                </div>

                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-50 text-start">
                                    E_Fat Özel Matrah
                                  </div>
                                  <div className="div w-50">
                                    <MultiColumnComboBox
                                      data={ozelMatrahKodlar}
                                      textField="Ad"
                                      dataItemKey="Kod"
                                      columns={[
                                        { field: "Kod", header: "Kod" },
                                        { field: "Ad", header: "Ad" },
                                      ]}
                                      value={selectedOzelMatrahKod}
                                      onChange={(e) =>
                                        setSelectedOzelMatrahKod(e.value)
                                      }
                                      placeholder="Özel Matrah Kodu Seçin"
                                    />
                                  </div>
                                </div>

                                <div className="w-100 d-flex  justify-content-between align-items-center mt-1">
                                  <div className="div w-50 text-start">
                                    E-Fat Türü
                                  </div>
                                  <div className="div w-50">
                                    <ComboBox
                                      data={efaturaKodlar}
                                      value={selectedEfaturaKod}
                                      onChange={(e) =>
                                        setSelectedEfaturaKod(e.value)
                                      }
                                      placeholder="E-Fat Türü Seçin"
                                    />
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                          {/* <div className="col-md-8 p-0 m-0"> */}
                          {/* <Card>
                              <Card.Header>
                                <Card.Title>Döküm</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <RmosGrid
                                  apiUrl={""}
                                  requestBody={{a:1}}
                                  gridKey={"denem"}
                                  showFooterCell={true}
                                  footerCalculations={footerCalculations}
                                  height={"30vh"}
                                />
                              </Card.Body>
                            </Card> */}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Faturalar" title="Faturalar">
                <p>Fatura İşlemleri</p>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default FolioFaturasi;
