import { Tooltip } from "@progress/kendo-react-tooltip";
import { Ripple } from "@progress/kendo-react-ripple";
import { printIcon, listUnorderedIcon } from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { Card } from "react-bootstrap";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import RmosGrid from "../../../../../Components/DinamikRmosGrid/RmosGrid";
import { Tab, Tabs } from "react-bootstrap";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRestroom } from "@fortawesome/free-solid-svg-icons";
import FaturaKodUpdateModal from "../../Modal/FaturaKodUpdateModal/FaturaKodUpdateModal";
import { Popup } from "@progress/kendo-react-popup";
import { Checkbox } from "@progress/kendo-react-inputs";

interface Agency {
  Adi: string;
  Kodu: string;
}

interface IBody {
  db_Id: number;
  xFat_Tarihi: string;
  xFat_Islem_tarihi: string;
  xBas_Tar: string;
  xBit_Tar: string;
  xtip: number;
  tahkod1: string;
  xFathrk_Folio: number;
  xFathrk_Fatno: number | null;
  dep1: string;
  xAcentalar: string | null;
}

const contextMenuItems = [
  {
    label: "Fatura Kod Update",
    action: "FaturaKodUpdate",
    icon: <FontAwesomeIcon icon={faRestroom} />,
  },
];

const FaturaKontrolSistemi: React.FC = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  const { dbId } = useDbId();
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [selectedAgencies, setSelectedAgencies] = useState<Agency[]>([]);

  const [veryfirstbody, setFirstBody] = useState<IBody>({
    db_Id: parseInt(dbId),
    xFat_Tarihi: "2026-06-21",
    xFat_Islem_tarihi: "2026-06-21",
    xBas_Tar: "2023-06-01",
    xBit_Tar: "2023-06-01",
    xtip: 2,
    tahkod1: "050",
    xFathrk_Folio: 0,
    xFathrk_Fatno: null,
    dep1: "001",
    xAcentalar: null,
  });

  const [verysecondbody, setSecondBody] = useState<IBody>({
    db_Id: parseInt(dbId),
    xFat_Tarihi: "2026-06-21",
    xFat_Islem_tarihi: "2026-06-21",
    xBas_Tar: "2023-06-01",
    xBit_Tar: "2023-06-01",
    xtip: 1,
    tahkod1: "050",
    xFathrk_Folio: 0,
    xFathrk_Fatno: null,
    dep1: "001",
    xAcentalar: null,
  });

  const anchor = useRef<Button | null>(null);
  const [show, setShow] = useState<boolean>(false);

  const onClick = () => {
    setShow(!show);
  };

  const [agencies, setAgencies] = useState<any[]>([]);
  const agencybody = {
    db_Id: dbId,
    Kod: "ALL",
  };

  useEffect(() => {
    try {
      axios
        .post("https://frontapi.rmosweb.com/api/Acenta/Getir_Kod", agencybody, {
          headers,
        })
        .then((response) => {
          setAgencies(response.data.value);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setFirstBody((prevState) => ({
      ...prevState,
      xBas_Tar: startDate,
      xBit_Tar: endDate,
      xAcentalar:
        selectedAgencies.length == 0
          ? null
          : selectedAgencies.map((agency) => agency.Kodu).join(","),
    }));

    setSecondBody((prevState) => ({
      ...prevState,
      xBas_Tar: startDate,
      xBit_Tar: endDate,
      xAcentalar:
        selectedAgencies.length == 0
          ? null
          : selectedAgencies.map((agency) => agency.Kodu).join(","),
    }));

    console.log("This is my body in BillCheck", veryfirstbody);

    console.log(selectedAgencies.map((agency) => agency.Kodu));
  }, [startDate, endDate, selectedAgencies]);

  const [rowData, setRowData] = useState<any>();
  const [faturaModalOpen, setFaturaModalOpen] = useState<any>(false);

  const handleContextMenuClick = (action: string, rowData: any) => {
    console.log("handleContextMenuClick", action, rowData);
    if (action === "FaturaKodUpdate") {
      setRowData(rowData);
      setFaturaModalOpen(true);
    }
  };

  const faturaModalHandler = () => {
    setFaturaModalOpen(false);
  };

  const isChecked = (agency: Agency) => {
    return selectedAgencies.some((item) => item.Kodu === agency.Kodu);
  };

  const filteredAgencies = agencies
    .filter((agency) => agency.Adi !== null)
    .map((agency) => (
      <div key={agency.Kod} style={{ display: "flex" }}>
        <Checkbox
          onChange={() => {
            handleAgencyChange(agency);
          }}
          checked={isChecked(agency)}
        />
        <p key={agency.Kod}>{agency.Adi}</p>
      </div>
    ));

  const handleAgencyChange = (agency: Agency) => {
    const { Kodu } = agency;
    if (selectedAgencies.includes(agency)) {
      // Remove from checked items
      setSelectedAgencies(
        selectedAgencies.filter((agent) => agent.Kodu !== Kodu)
      );
    } else {
      // Add to checked items
      setSelectedAgencies([...selectedAgencies, agency]);
    }
  };

  return (
    <div>
      <FaturaKodUpdateModal
        isOpen={faturaModalOpen}
        onClose={faturaModalHandler}
        data={rowData}
      />
      <Card>
        <Tooltip anchorElement="target" position="bottom">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                gap: "0.5rem",
                marginTop: "0.2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.2rem",
                }}
              >
                <span style={{ marginTop: "0.5rem" }}>Başlangıç Tarihi</span>
                <div style={{ height: "2.8rem" }}>
                  <DatePicker
                    value={startDate}
                    onChange={(e: any) => setStartDate(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.2rem",
                }}
              >
                <span style={{ marginTop: "0.5rem" }}>Bitiş Tarihi</span>
                <div style={{ height: "2.8rem" }}>
                  <DatePicker
                    value={endDate}
                    onChange={(e: any) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </div>
              </div>
              <Button
                style={{ height: "2.4rem" }}
                onClick={onClick}
                ref={anchor}
              >
                Acenteler
              </Button>
              <Popup
                anchor={anchor.current && anchor.current.element}
                show={show}
                popupClass={"popup-content"}
                style={{ width: "20rem" }}
              >
                <div style={{ padding: "0.5rem" }}>
                  <div>
                    <h5 style={{ textAlign: "center" }}>Acenteler</h5>
                  </div>
                  <div style={{ overflowY: "auto", height: "22rem" }}>
                    {filteredAgencies}
                  </div>
                </div>
              </Popup>
            </div>

            <div>
              <Ripple>
                <div style={{ display: "flex" }}>
                  <Button
                    svgIcon={listUnorderedIcon}
                    className="blacklist-button"
                    title="Fatura"
                  />

                  <Button
                    svgIcon={printIcon}
                    className="blacklist-button"
                    fillMode={null}
                    title="Yazdır"
                  />
                </div>
              </Ripple>
            </div>
          </div>
        </Tooltip>
      </Card>

      <Tabs defaultActiveKey={1} style={{ marginTop: "0.1rem" }}>
        <Tab eventKey={1} title={"Önbüro Faturalar"}>
          <RmosGrid
            apiUrl="https://frontapi.rmosweb.com/api/Procedure/StpFaturabul_1"
            gridKey="FaturaKontrolBul1"
            requestBody={veryfirstbody}
            height={"78vh"}
            contextMenuItems={contextMenuItems}
            onContextMenuClick={handleContextMenuClick}
          />
        </Tab>
        <Tab eventKey={2} title={"Tüm Faturalar"}>
          <RmosGrid
            apiUrl="https://frontapi.rmosweb.com/api/Procedure/StpFaturabul_1"
            gridKey="FaturaKontrolBul2"
            requestBody={verysecondbody}
            height={"78vh"}
            contextMenuItems={contextMenuItems}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default FaturaKontrolSistemi;
