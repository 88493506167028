import { TextBox } from "@progress/kendo-react-inputs";
import { TextArea } from "@progress/kendo-react-inputs";
import RmosGrid from "../../../../../Components/DinamikRmosGrid/RmosGrid";
import { Card } from "react-bootstrap";
import { useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";

const QuotaEntrance: React.FC = () => {
  const { dbId } = useDbId();
  const [agency, setAgency] = useState<string>();
  const [type, setType] = useState<any>();
  const [location, setLocation] = useState<any>();
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [single, setSingle] = useState<any>();
  const [double, setDouble] = useState<any>();
  const [triple, setTriple] = useState<any>();
  const [quadruple, setQuadruple] = useState<any>();
  // const [roomSum, setRoomSum] = useState<any>();
  const [groupAgency, setGroupAgency] = useState<any>();
  const [groupCode, setGroupCode] = useState<any>();
  const [cancelDayCount, setCancelDayCount] = useState<any>();
  const [description, setDescription] = useState<any>();

  const agencycolumns = [
    { field: "Ac_Kodu", header: "Ac_Kodu", width: "150px" },
    { field: "Ac_Adı", header: "Ac_Adı", width: "150px" },
  ];

  const typecolumns = [
    { field: "Kod", header: "Kod", width: "150px" },
    { field: "Ad", header: "Ad", width: "150px" },
  ];

  const locationcolumns = [
    { field: "Kodlar_Kod", header: "Kodlar_Kod", width: "150px" },
    { field: "Kodlar_Ad", header: "Kodlar_Ad", width: "150px" },
  ];

  const groupagencycolumns = [
    { field: "Kod", header: "Kod", width: "150px" },
    { field: "Ad", header: "Ad", width: "150px" },
  ];

  const groupcodecolumns = [
    { field: "Kodlar_Kod", header: "Kodlar_Kod", width: "150px" },
    { field: "Kodlar_Ad", header: "Kodlar_Ad", width: "150px" },
  ];

  return (
    <Card className="mt-1">
      <div style={{ display: "flex", gap: "3rem" }}>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Acente</span>
            <MultiColumnComboBox
              columns={agencycolumns}
              style={{ width: "55%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Tip</span>
            <MultiColumnComboBox
              columns={typecolumns}
              style={{ width: "55%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Konum</span>
            <MultiColumnComboBox
              columns={locationcolumns}
              style={{ width: "55%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Başlangıç Tarihi</span>
            <div style={{ width: "55%" }}>
              <DatePicker
                value={startDate}
                onChange={(e: any) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Bitiş Tarihi</span>
            <div style={{ width: "55%" }}>
              <DatePicker
                value={endDate}
                onChange={(e: any) => {
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Single</span>
            <NumericTextBox
              style={{ width: "55%" }}
              value={single}
              onChange={(e: any) => {
                setSingle(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Double</span>
            <NumericTextBox
              style={{ width: "55%" }}
              value={double}
              onChange={(e: any) => {
                setDouble(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Triple</span>
            <NumericTextBox
              style={{ width: "55%" }}
              value={triple}
              onChange={(e: any) => {
                setTriple(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Quadruple</span>
            <NumericTextBox
              style={{ width: "55%" }}
              value={quadruple}
              onChange={(e: any) => {
                setQuadruple(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Toplam</span>
            <NumericTextBox
              style={{ width: "55%" }}
              disabled
              value={single + double + quadruple + triple}
            />
          </div>
        </div>

        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Grup Acente</span>
            <MultiColumnComboBox
              columns={groupagencycolumns}
              style={{ width: "55%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Grup Kodu</span>
            <MultiColumnComboBox
              columns={groupcodecolumns}
              style={{ width: "55%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>İptal Gün Sayısı</span>
            <NumericTextBox
              style={{ width: "55%" }}
              value={cancelDayCount}
              onChange={(e: any) => {
                setCancelDayCount(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Açıklama</span>
            <TextArea
              value={description}
              onChange={(e: any) => {
                setDescription(e.target.value);
              }}
              style={{ width: "55%" }}
            />
          </div>
        </div>
      </div>

      <RmosGrid apiUrl="" gridKey="" requestBody={{}} height={"62vh"} />
    </Card>
  );
};

export default QuotaEntrance;
