// Örnek Prototip

import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCode,
  faGauge,
  faList,
  faPrint,
  faRefresh,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Checkbox,
  Input,
  InputChangeEvent,
  RadioButton,
} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Card, CardBody, CardHeader, Tab, Tabs } from "react-bootstrap";
import UstMenuBar from "../../../../../../Components/Basliklar/UstMenuBar";
import GeneralButton from "../../../../../../Components/Buttons/FloatActionButton/GeneralButton";

const initialDataState = {
  take: 10,
  skip: 0,
  group: [],
  filter: null,
};
const categories = ["pazar1", "pazar2", "pazar3", "pazar4"];
const ms = ["Mr", "Mrs", "Ms"];

type GuestInputProps = {
  index: number;
};

const GuestInput: React.FC<GuestInputProps> = ({ index }) => (
  <div className="d-flex  gap-1 border p-2">
    <DropDownList data={ms} defaultValue="Mr" />{" "}
    <Input type="text" placeholder="Adı" style={{ marginRight: "5px" }} />
    <Input type="text" placeholder="Soyadı" style={{ marginRight: "5px" }} />
    <DatePicker defaultValue={new Date()} />
    <Input type="text" placeholder="Yaş" style={{ marginRight: "5px" }} />
    <Input type="text" placeholder="Müş. No" style={{ marginRight: "5px" }} />
  </div>
);
const RezervasyonGirisi: React.FC = () => {
  const [data, setData] = React.useState<any>(AktivitelerData);

  const [dataState, setDataState] = React.useState<any>(initialDataState);
  const [guestCount, setGuestCount] = useState<number>(1);
  const [guestInputs, setGuestInputs] = useState<React.ReactElement[]>([
    <GuestInput key={0} index={0} />, // İlk misafir input'unu başlangıçta ekleyin
  ]);
  useEffect(() => {
    const inputs = [];
    for (let i = 0; i < guestCount; i++) {
      inputs.push(<GuestInput key={i} index={i} />);
    }
    setGuestInputs(inputs);
  }, [guestCount]);
  const onGridDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };
  const handleGuestCountChange = (event: InputChangeEvent) => {
    const newGuestCount = parseInt(event.value, 10);
    setGuestCount(newGuestCount);
    const inputs = [];
    for (let i = 0; i < newGuestCount; i++) {
      inputs.push(<GuestInput key={i} index={i} />);
    }
    setGuestInputs(inputs);
  };
  const processedData = process(data, dataState);

  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <UstMenuBar align="right">
            <div>
              <RadioButton
                className="ms-3"
                name="group1"
                value="Firma"
                label="Rezervasyon"
              />
            </div>
            <div>
              <RadioButton
                className="ms-3"
                name="group1"
                value="Firma"
                label="Değişiklik"
              />
            </div>
            <div>
              <RadioButton
                className="ms-3"
                name="group1"
                value="Firma"
                label="Iptal"
              />
            </div>
            <div>
              <RadioButton
                className="ms-3"
                name="group1"
                value="Firma"
                label="Görüntü"
              />
            </div>
            <div className="">
              {/* <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                <FontAwesomeIcon icon={faRefresh} /> Ara
              </div> */}
              <GeneralButton
                text="Ara"
                height="30px"
                icon={faRefresh}
                gradientStart="#2A3747" // Koyu mavi ton
                gradientEnd="#3E4E63" // Orta mavi ton
                hoverGradientStart="#1F2A36" // Daha koyu mavi ton
                hoverGradientEnd="#465A6E" // Açık mavi ton
                onClick={() => {
                  // yazdırma işlemi
                  console.log("Yazdır butonuna tıklandı");
                }}
              />
            </div>
            <div className="">
              {/* <div
                className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                // onClick={toggleModal}
              >
                <FontAwesomeIcon icon={faAdd} /> Boş Odalar
              </div> */}
              <GeneralButton
                text="Boş Odalar"
                height="30px"
                icon={faAdd}
                gradientStart="#2A3747" // Koyu mavi ton
                gradientEnd="#3E4E63" // Orta mavi ton
                hoverGradientStart="#1F2A36" // Daha koyu mavi ton
                hoverGradientEnd="#465A6E" // Açık mavi ton
                onClick={() => {
                  // yazdırma işlemi
                  console.log("Yazdır butonuna tıklandı");
                }}
              />
            </div>
            <div className="">
              {/* <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                <FontAwesomeIcon icon={faPrint} /> oda Izleme
              </div> */}
              <GeneralButton
                text="Oda İzleme"
                height="30px"
                icon={faPrint}
                gradientStart="#2A3747" // Koyu mavi ton
                gradientEnd="#3E4E63" // Orta mavi ton
                hoverGradientStart="#1F2A36" // Daha koyu mavi ton
                hoverGradientEnd="#465A6E" // Açık mavi ton
                onClick={() => {
                  // yazdırma işlemi
                  console.log("Yazdır butonuna tıklandı");
                }}
              />
            </div>
            <div className="">
              {/* <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                <FontAwesomeIcon icon={faCode} /> Pavilion
              </div> */}
              <GeneralButton
                text="Pavillion"
                height="30px"
                icon={faCode}
                gradientStart="#2A3747" // Koyu mavi ton
                gradientEnd="#3E4E63" // Orta mavi ton
                hoverGradientStart="#1F2A36" // Daha koyu mavi ton
                hoverGradientEnd="#465A6E" // Açık mavi ton
                onClick={() => {
                  // yazdırma işlemi
                  console.log("Yazdır butonuna tıklandı");
                }}
              />
            </div>
            <div className="">
              {/* <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                <FontAwesomeIcon icon={faList} /> Çocuk Yaş
              </div> */}
              <GeneralButton
                text="Çocuk Yaş"
                height="30px"
                icon={faList}
                gradientStart="#2A3747" // Koyu mavi ton
                gradientEnd="#3E4E63" // Orta mavi ton
                hoverGradientStart="#1F2A36" // Daha koyu mavi ton
                hoverGradientEnd="#465A6E" // Açık mavi ton
                onClick={() => {
                  // yazdırma işlemi
                  console.log("Yazdır butonuna tıklandı");
                }}
              />
            </div>
            <div className="">
              {/* <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                <FontAwesomeIcon icon={faGauge} /> Muh Kaporalar
              </div> */}
              <GeneralButton
                text="Muh Kaporalar"
                height="30px"
                icon={faGauge}
                gradientStart="#2A3747" // Koyu mavi ton
                gradientEnd="#3E4E63" // Orta mavi ton
                hoverGradientStart="#1F2A36" // Daha koyu mavi ton
                hoverGradientEnd="#465A6E" // Açık mavi ton
                onClick={() => {
                  // yazdırma işlemi
                  console.log("Yazdır butonuna tıklandı");
                }}
              />
            </div>
            <div className="">
              {/* <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                <FontAwesomeIcon icon={faGauge} /> Muh aidat Hesapla{" "}
              </div> */}
              <GeneralButton
                text="Muh Aidat Hesapla"
                height="30px"
                icon={faGauge}
                gradientStart="#2A3747" // Koyu mavi ton
                gradientEnd="#3E4E63" // Orta mavi ton
                hoverGradientStart="#1F2A36" // Daha koyu mavi ton
                hoverGradientEnd="#465A6E" // Açık mavi ton
                onClick={() => {
                  // yazdırma işlemi
                  console.log("Yazdır butonuna tıklandı");
                }}
              />
            </div>
            <div className="">
              {/* <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                <FontAwesomeIcon icon={faGauge} />
                Hesaplat
              </div> */}
              <GeneralButton
                text="Hesaplat"
                height="30px"
                icon={faGauge}
                gradientStart="#2A3747" // Koyu mavi ton
                gradientEnd="#3E4E63" // Orta mavi ton
                hoverGradientStart="#1F2A36" // Daha koyu mavi ton
                hoverGradientEnd="#465A6E" // Açık mavi ton
                onClick={() => {
                  // yazdırma işlemi
                  console.log("Yazdır butonuna tıklandı");
                }}
              />
            </div>
          </UstMenuBar>

          <div className="col-md-12 bg-white shadow-lg rounded p-2 mt-1">
            <div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-3 row">
                    <div className="container-fluid">
                      <div className="row m-0 p-0">
                        <Card>
                          <Card.Header>
                            <Card.Title>Rezervasyon Girişi</Card.Title>
                          </Card.Header>
                          <CardBody>
                            <div className=" gap-2 d-flex flex-column">
                              <div className="d-flex w-100  align-items-center justify-content-Start">
                                <span className="w-40 d-flex justify-content-start align-items-center">
                                  Rez No
                                </span>
                                <Input
                                  className="w-60"
                                  type="text"
                                  defaultValue={"1111111"}
                                ></Input>
                              </div>
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Giriş Tarihi
                                </div>
                                <div className="w-60 ">
                                  <DatePicker defaultValue={new Date()} />
                                </div>
                              </div>
                              <div className="d-flex w-100  align-items-center justify-content-Start">
                                <span className="w-40 d-flex justify-content-start align-items-center">
                                  Geceleme
                                </span>
                                <Input
                                  className="w-60"
                                  type="Number"
                                  defaultValue={"1"}
                                ></Input>
                              </div>
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Çıkış Tarihi
                                </div>
                                <div className="w-60 ">
                                  <DatePicker defaultValue={new Date()} />
                                </div>
                              </div>
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Alış Tarihi
                                </div>
                                <div className="w-60 ">
                                  <DatePicker defaultValue={new Date()} />
                                </div>
                              </div>
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Acenta
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  İstenen
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Verilen
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Yatak Tipi
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Sistem kayıt
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-100 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Voucher
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-100 d-flex flex-wrap ">
                                <div className="w-50 d-flex ">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Konaklama
                                  </div>
                                  <div className="w-60 ">
                                    <DropDownList
                                      data={categories}
                                      defaultValue="pazar"
                                    />{" "}
                                  </div>
                                </div>
                                <div className="w-50 d-flex">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Ülke
                                  </div>
                                  <div className="w-60 ">
                                    <DropDownList
                                      data={categories}
                                      defaultValue="pazar"
                                    />{" "}
                                  </div>
                                </div>
                                <div className="w-50 d-flex mt-2">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Kur Kodu
                                  </div>
                                  <div className="w-60 ">
                                    <DropDownList
                                      data={categories}
                                      defaultValue="pazar"
                                    />{" "}
                                  </div>
                                </div>
                                <div className="w-50 d-flex mt-2">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Kur(G)
                                  </div>
                                  <div className="w-60 ">
                                    <DropDownList
                                      data={categories}
                                      defaultValue="pazar"
                                    />{" "}
                                  </div>
                                </div>
                                <div className="w-50 d-flex mt-2">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Müşt. Tipi
                                  </div>
                                  <div className="w-60 ">
                                    <DropDownList
                                      data={categories}
                                      defaultValue="pazar"
                                    />{" "}
                                  </div>
                                </div>
                                <div className="w-50 d-flex mt-2">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Pazar
                                  </div>
                                  <div className="w-60 ">
                                    <DropDownList
                                      data={categories}
                                      defaultValue="pazar"
                                    />{" "}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-Start">
                                <Input
                                  className=""
                                  type="text"
                                  placeholder="Açıklama"
                                ></Input>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Card>
                      <CardHeader>
                        <Card.Title>Misafir Bilgileri</Card.Title>
                      </CardHeader>
                      <CardBody>
                        <div className="w-100">
                          <div className="col-md-12 p-3">
                            <div className="d-flex flex-row gap-2">
                              <div className="text-start">
                                <span className="">Kişi sayısı</span>
                                <Input
                                  className="w-50 ms-3"
                                  type="Number"
                                  defaultValue={"1"}
                                  value={guestCount}
                                  onChange={handleGuestCountChange}
                                ></Input>
                              </div>
                              <div>
                                <RadioButton
                                  className="ms-3"
                                  name="group1"
                                  value="Firma"
                                  label="Tip0(Default)"
                                />
                              </div>
                              <div>
                                <RadioButton
                                  className="ms-3"
                                  name="group1"
                                  value="Firma"
                                  label="Tip1(Detay)"
                                />
                              </div>
                              <div>
                                <Checkbox
                                  label={"Yaş İndirimi Uygulansın"}
                                  checked={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>{guestInputs}</div>
                        <div className="w-100 mt-2">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="container-fluid ">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Yetişkin
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Çocuk/Free
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Gr/Çık.Saat
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Fiyat Nerden
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Fiyat M/O
                                      </span>
                                      <DropDownList
                                        data={ms}
                                        className="w-60"
                                        defaultValue="Mr"
                                      />{" "}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Fiyat M/O
                                      </span>
                                      <DropDownList
                                        data={ms}
                                        className="w-60"
                                        defaultValue="Mr"
                                      />{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="container-fluid ">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Tablo Kodu
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Dönüş 1
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Dönüş 2
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Günlük Fiyat
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Günlük İndirim
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Günlük Net
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="container-fluid ">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Özel İndirim Kodu
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Özel İndirim Kodu
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        UyguKur
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        UyguKur
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        UyguKur
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Günlük Net
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="number"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card>
                      <CardHeader>
                        <Card.Title>Info</Card.Title>
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="w-100 d-flex flex-wrap ">
                              <div className="w-50 d-flex ">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  bebek
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  0-2
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Doviz Şekli
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  3-6
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Satış Şekli
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  7-10
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Rehber
                                </div>
                                <div className="w-60 ">
                                  <Input
                                    className="w-60"
                                    type="number"
                                    placeholder="Acenta Kodu"
                                  ></Input>
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  11-14
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Blokaj
                                </div>
                                <div className="w-60 ">
                                  <Input
                                    className="w-60"
                                    type="number"
                                    placeholder="Acenta Kodu"
                                  ></Input>
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  İn Oda
                                </div>
                                <div className="w-60 ">
                                  <Input
                                    className="w-60"
                                    type="number"
                                    placeholder="Acenta Kodu"
                                  ></Input>
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Source
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Chanel
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  otel Kodu
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Vip
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Odeme tipi
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Opsiyon
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Ödm.Plan
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                              <div className="w-50 d-flex mt-2">
                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                  Conf/wait
                                </div>
                                <div className="w-60 ">
                                  <DropDownList
                                    data={categories}
                                    defaultValue="pazar"
                                  />{" "}
                                </div>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Plaka
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Rez.Yapam
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        İrtibat Tel
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        İrtibat Ma
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-row justify-content-between">
                                        <div className="text-start">
                                          <Checkbox
                                            label={"kontrol Edildi"}
                                            color="red"
                                          />
                                        </div>
                                        <div className="text-start">
                                          <Checkbox
                                            defaultChecked={true}
                                            label={"Evlilik Yıl Dönümü"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            indirimler
                            <Grid>
                              <Column field="Sec" title="Seç" />
                              <Column field="Adi" title=" Adı" />
                              <Column field="Tutar" title="Tutar" />
                            </Grid>
                            <div className="">
                              <div className="border">
                                <div className="w-100 d-flex flex-column align-items-start  justify-contet-start">
                                  <div>
                                    <RadioButton
                                      className="ms-3"
                                      name="group1"
                                      value="Firma"
                                      label="Günlük"
                                    />
                                  </div>
                                  <div>
                                    <RadioButton
                                      className="ms-3"
                                      name="group1"
                                      value="Firma"
                                      label="Toplam"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Toplam Brüt
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        İndirim Toplamı
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Net Tutar
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Dep (Rez.Folio)
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Acenta Kodu"
                                      ></Input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 bg-white shadow-lg rounded p-3 mt-4">
            <Tabs defaultActiveKey="RZ" id="uncontrolled-tab-example">
              <Tab eventKey="RZ" title="Rezervasyonlar">
                <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="KTO" title="İsim Kontrolü">
                <Grid
                  data={process(AktivitelerData1, dataState)}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="KTR" title="Tablolar İndirimler">
                <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="KTG" title="Otel Durumu">
                <Grid
                  data={process(AktivitelerData1, dataState)}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="ATFP" title="Acenta Bilgileri">
                <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="YGAD" title="Hesaplama">
                <Grid
                  data={process(AktivitelerData1, dataState)}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
              <Tab eventKey="Diger" title="Diğer">
                <Grid
                  data={process(AktivitelerData, dataState)}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
export default RezervasyonGirisi;

const AktivitelerData: any[] = [
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
];

const AktivitelerData1: any[] = [
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
  {
    AktiviteAdi: "aktivite",
    FirmaAdi: "Acenta Adı",
    Kontak: "pazar",
    Iletişim: "grup",
    BaslangicTarihi: "konaklama",
    BİtisTarihi: "kur",
    PersonelAdsoyad: "tarih",
  },
];
