import React, { useState } from "react";
import menuItems from "./SidebarMenu/MenuItems";
import * as Icons from "react-icons/ai";
import "./Sidebar.scss";
import { IconType } from "react-icons";
import useMenuItems from "./SidebarMenu/MenuItems";

type SidebarProps = {
  isOpen: boolean;
  onMenuSelect: (menuId: number) => void;
  toggleSidebar: () => void; // Toggle fonksiyonu için yeni prop tipi
};
interface MenuItem {
  id: number;
  title: string;
  icon: IconType;
  Component?: React.FC;
  children?: MenuItem[];
}

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  onMenuSelect,
  toggleSidebar,
}) => {
  const menuItems = useMenuItems();

  const [openMenus, setOpenMenus] = useState<number[]>([]);

  const toggleMenu = (menuId: number) => {
    setOpenMenus((prevOpenMenus) =>
      prevOpenMenus.includes(menuId)
        ? prevOpenMenus.filter((id) => id !== menuId)
        : [...prevOpenMenus, menuId]
    );
  };

  // toggleSidebar fonksiyonunu tanımlayın.

  // Sidebar'ı açıp kapatan fonksiyon
  const renderMenuItems = (items: MenuItem[], parentTitle?: string) => {
    return items.map((item) => {
      const isMenuOpen = openMenus.includes(item.id);
      const Icon = item.icon;
      const hasChildren = item.children && item.children.length > 0;

      return (
        <div key={item.id} className="">
          <div
            className="menu-item"
            onClick={() =>
              hasChildren ? toggleMenu(item.id) : onMenuSelect(item.id)
            }
          >
            <div className="menu-item-1 w-100">
              {Icon && <Icon className="menu-icon-color-gray" />}
              <span className="menuacik">{item.title}</span>
              {/* burası ok işaretti */}
              {/* {hasChildren && (
              <Icons.AiOutlineDown
                className={`menu-chevron ${isMenuOpen ? "open" : ""}`}
              />
            )} */}
              {/* burası ok işaretti */}
              {hasChildren &&
                (isMenuOpen ? (
                  <Icons.AiOutlineMinus className="menu-chevron" />
                ) : (
                  <Icons.AiOutlinePlus className="menu-chevron" />
                ))}
            </div>
          </div>
          {item.children && isMenuOpen && (
            <div className="sub-menu">{renderMenuItems(item.children)}</div>
          )}
        </div>
      );
    });
  };

  const handleMenuItemClick = (menuId: number) => {
    const selectedItem = menuItems.find((item) => item.id === menuId);
    if (
      selectedItem &&
      (!selectedItem.children || selectedItem.children.length === 0)
    ) {
      onMenuSelect(menuId); // Menü öğesi tıklandığında bu fonksiyon çalışacak
    }
    // Eğer alt menü varsa, sadece aç/kapa işlevi yapacak
    toggleMenu(menuId);
  };

  return (
    <aside className={`App-sidebar  ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        <div
          className={`toggle-icon ${isOpen ? "open" : "closed"}`}
          onClick={toggleSidebar}
        >
          <div className="rotate-icon"></div>
          <div className="rotate-icon1"></div>
          <span className="tooltip-text">
            {isOpen ? "Menüy'ü Kapat" : "Menüy'ü Aç"}
          </span>
        </div>
      </div>
      <div className="menuparent">
        <div className="menu1">{renderMenuItems(menuItems)}</div>
      </div>
    </aside>
  );
};

export default Sidebar;
