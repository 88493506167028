// useAlert.tsx
//Bu hook, Alert bileşenini her türdeki uyarı için kullanmamızı sağlar.
import React, { useState, useEffect } from "react";
import Alert from "./Alert";
import { AlertType } from "./AlertTypes";

const useAlert = () => {
  const [alerts, setAlerts] = useState<
    Array<{ id: number; type: AlertType; message: string; isExiting: boolean }>
  >([]);

  const removeAlert = (id: number) => {
    setAlerts((currentAlerts) =>
      currentAlerts.map((alert) =>
        alert.id === id ? { ...alert, isExiting: true } : alert
      )
    );
    // Çıkış animasyonu süresi kadar bekleyip alert'i kaldır
    setTimeout(() => {
      setAlerts((currentAlerts) =>
        currentAlerts.filter((alert) => alert.id !== id)
      );
    }, 500);
  };

  const showAlert = (type: AlertType, msg: string) => {
    const id = new Date().getTime(); // Basit bir ID oluşturma yöntemi
    setAlerts([...alerts, { id, type, message: msg, isExiting: false }]);

    // Otomatik kapanma için zamanlayıcı ayarla
    setTimeout(() => removeAlert(id), 3000);
  };

  const AlertComponents = (
    <div className="alert-container">
      {alerts.map(alert => (
        <Alert
          key={alert.id}
          type={alert.type}
          message={alert.message}
          onClose={() => removeAlert(alert.id)}
          className={alert.isExiting ? 'exit' : ''}
        />
      ))}
    </div>
  );

  return { showAlert, AlertComponents };
};

export default useAlert;
