import { TextBox } from "@progress/kendo-react-inputs";
import RmosGrid from "../../../../Components/DinamikRmosGrid/RmosGrid";
import { Card, CardBody, CardHeader, Tab, Tabs } from "react-bootstrap";
import { Checkbox } from "@progress/kendo-react-inputs";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Ripple } from "@progress/kendo-react-ripple";
import { Button } from "@progress/kendo-react-buttons";
import { saveIcon, trashIcon } from "@progress/kendo-svg-icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { TextArea } from "@progress/kendo-react-inputs";

const OdaTanimlama: React.FC = () => {
  // Room Props
  const [locationCode, setLocationCode] = useState<any>();
  const [locationName, setLocationName] = useState<any>();
  const [hotelCode, setHotelCode] = useState<any>();
  const [location, setLocation] = useState<any>();
  const [roomNo, setRoomNo] = useState<any>();
  const [roomType, setRoomType] = useState<any>();
  const [squaremeter, setSquaremeter] = useState<any>();
  const [floorNo, setFloorNo] = useState<any>();
  const [bedtype, setBedtype] = useState<any>();
  const [region, setRegion] = useState<any>();
  const [view, setView] = useState<any>();
  const [apart, setApart] = useState<any>();
  const [maid, setMaid] = useState<any>();
  const [definition, setDefinition] = useState<any>();

  // Filters
  const [internet, setInternet] = useState<any>();
  const [phone, setPhone] = useState<any>();
  const [television, setTelevision] = useState<any>();
  const [jakuzi, setJakuzi] = useState<any>();
  const [fullRoom, setFullRoom] = useState<any>();
  const [balcony, setBalcony] = useState<any>();
  const [airconditioner, setAirconditioner] = useState<any>();
  const [cigarette, setCigarette] = useState<any>();
  const [currentSellable, setCurrentSellable] = useState<any>();
  const [operating, setOperating] = useState<any>();
  const [extraBed, setExtraBed] = useState<any>();
  const [virtualRoom, setVirtualRoom] = useState<any>();
  const [isolatedRoom, setIsolatedRoom] = useState<any>();

  const cleanHandler = () => {
    setLocationCode("");
    setLocationName("");
    setHotelCode("");
    setLocation("");
    setRoomNo("");
    setRoomType("");
    setSquaremeter("");
    setFloorNo("");
    setBedtype("");
    setRegion("");
    setView("");
    setApart("");
    setMaid("");
    setDefinition("");
    setInternet(false);
    setPhone(false);
    setTelevision(false);
    setJakuzi(false);
    setFullRoom(false);
    setBalcony(false);
    setAirconditioner(false);
    setCigarette(false);
    setCurrentSellable(false);
    setOperating(false);
    setExtraBed(false);
    setVirtualRoom(false);
    setIsolatedRoom(false);
  };

  //Connection Room
  const [connectionRoom, setConnectionRoom] = useState<any>();

  const [day, setDay] = useState<any>();

  const multicolumns = [
    { field: "Kodlar_Ad", header: "Kodlar_Ad", width: "150px" },
    { field: "Kodlar_Kod", header: "Kodlar_Kod", width: "150px" },
  ];

  const firstradiogroup = [
    { label: "Boş", value: "Boş" },
    { label: "Pazartesi", value: "Pazartesi" },
    { label: "Salı", value: "Salı" },
    { label: "Çarşamba", value: "Çarşamba" },
  ];

  const secondradiogroup = [
    { label: "Perşembe", value: "Perşembe" },
    { label: "Cuma", value: "Cuma" },
    { label: "Cumartesi", value: "Cumartesi" },
    { label: "Pazar", value: "Pazar" },
  ];

  return (
    <>
      <Card>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Tooltip anchorElement="target" position="bottom">
            <Ripple className="ripple">
              <Button
                className="blacklist-button"
                fillMode={null}
                title="Sil"
                svgIcon={trashIcon}
                onClick={cleanHandler}
              />
              <Button
                className="blacklist-button"
                title="Kaydet"
                svgIcon={saveIcon}
              />
            </Ripple>
          </Tooltip>
        </div>
      </Card>
      <Card>
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <div style={{ width: "22.5%" }}>
            <Card>
              <CardHeader>
                <Card.Title>Konum Bilgileri</Card.Title>
              </CardHeader>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    height: "1.6rem",
                    verticalAlign: "middle",
                    marginBottom: "0.4rem",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                    Konum Kodu
                  </span>
                  <TextBox
                    value={locationCode}
                    onChange={(e: any) => {
                      setLocationCode(e.target.value);
                    }}
                    style={{ width: "72.5%" }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    height: "1.6rem",
                    verticalAlign: "middle",
                    marginBottom: "0.4rem",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                    Konum Adı
                  </span>
                  <TextBox
                    value={locationName}
                    onChange={(e: any) => {
                      setLocationName(e.target.value);
                    }}
                    style={{ width: "72.5%" }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    height: "1.6rem",
                    verticalAlign: "middle",
                    marginBottom: "0.4rem",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                    Otel Kodu
                  </span>
                  <TextBox
                    value={hotelCode}
                    onChange={(e: any) => {
                      setHotelCode(e.target.value);
                    }}
                    style={{ width: "72.5%" }}
                  />
                </div>
              </CardBody>
            </Card>
            <RmosGrid apiUrl="" gridKey="OdaTanimlamaGrid1" requestBody={{}} />
          </div>

          <div style={{ width: "77.5%", marginLeft: "0.2rem" }}>
            <Card>
              <Card.Header>
                <Card.Title>Oda Özellikleri</Card.Title>
              </Card.Header>
              <CardBody
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Konum
                    </span>
                    <MultiColumnComboBox
                      columns={multicolumns}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Oda No
                    </span>
                    <TextBox
                      value={roomNo}
                      onChange={(e: any) => {
                        setRoomNo(e.target.value);
                      }}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Daire Tipi
                    </span>
                    <MultiColumnComboBox
                      columns={multicolumns}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Metrekare
                    </span>
                    <TextBox
                      value={squaremeter}
                      onChange={(e: any) => {
                        setSquaremeter(e.target.value);
                      }}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Kat No
                    </span>
                    <TextBox
                      value={floorNo}
                      onChange={(e: any) => {
                        setFloorNo(e.target.value);
                      }}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Yatak Tipi
                    </span>
                    <MultiColumnComboBox
                      columns={multicolumns}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Bölge/Blok
                    </span>
                    <MultiColumnComboBox
                      columns={multicolumns}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Manzara
                    </span>
                    <MultiColumnComboBox
                      columns={multicolumns}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Apart
                    </span>
                    <MultiColumnComboBox
                      columns={multicolumns}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Maid
                    </span>
                    <MultiColumnComboBox
                      columns={multicolumns}
                      style={{ width: "72.5%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "1.6rem",
                      verticalAlign: "middle",
                      marginBottom: "0.4rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "12px", marginTop: "0.2rem" }}>
                      Açıklama
                    </span>
                    <TextArea
                      value={definition}
                      onChange={(e: any) => {
                        setDefinition(e.target.value);
                      }}
                      style={{ width: "72.5%" }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: "15%",
                    marginLeft: "1rem",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={internet}
                      onChange={(e: any) => setInternet(e.target.checked)}
                    />
                    <p>İnternet</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={phone}
                      onChange={(e: any) => {
                        setPhone(e.target.checked);
                      }}
                    />
                    <p>Telefon</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={television}
                      onChange={(e: any) => {
                        setTelevision(e.target.checked);
                      }}
                    />
                    <p>Televizyon</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={jakuzi}
                      onChange={(e: any) => {
                        setJakuzi(e.target.checked);
                      }}
                    />
                    <p>Jakuzi</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={fullRoom}
                      onChange={(e: any) => {
                        setFullRoom(e.target.checked);
                      }}
                    />
                    <p>Tam Daire</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={balcony}
                      onChange={(e: any) => {
                        setBalcony(e.target.checked);
                      }}
                    />
                    <p>Balkon</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={airconditioner}
                      onChange={(e: any) => {
                        setAirconditioner(e.target.checked);
                      }}
                    />
                    <p>Klima</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={cigarette}
                      onChange={(e: any) => {
                        setCigarette(e.target.checked);
                      }}
                    />
                    <p>Sigara</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={currentSellable}
                      onChange={(e: any) => {
                        setCurrentSellable(e.target.checked);
                      }}
                    />
                    <p>Mevcut Satılabilir</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={operating}
                      onChange={(e: any) => {
                        setOperating(e.target.checked);
                      }}
                    />
                    <p>Yönetim</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={extraBed}
                      onChange={(e: any) => {
                        setExtraBed(e.target.checked);
                      }}
                    />
                    <p>Extra Bed Alabilir</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={virtualRoom}
                      onChange={(e: any) => {
                        setVirtualRoom(e.target.checked);
                      }}
                    />
                    <p>Sanal Oda</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox
                      checked={isolatedRoom}
                      onChange={(e: any) => {
                        setIsolatedRoom(e.target.checked);
                      }}
                    />
                    <p>İzole Oda</p>
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      height: "1.5rem",
                      gap: "0.3rem",
                    }}
                  >
                    <Checkbox />
                    <p>Connection Room</p>
                  </div>
                  <div style={{ width: "80%" }}>
                    <TextBox />
                    <TextBox />
                    <h6 style={{ marginTop: "2rem" }}>Sabit Giriş Günü</h6>
                    <div
                      style={{
                        display: "flex",
                        gap: "2rem",
                        justifyContent: "space-around",
                      }}
                    >
                      <RadioGroup
                        data={firstradiogroup}
                        value={day}
                        onChange={(e: any) => {
                          setDay(e.target.value);
                        }}
                      />
                      <RadioGroup
                        data={secondradiogroup}
                        value={day}
                        onChange={(e: any) => {
                          setDay(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: "35%" }}>
                  <RmosGrid
                    apiUrl=""
                    gridKey=""
                    requestBody={{}}
                    height={"22rem"}
                  />
                </div>
              </CardBody>
            </Card>

            <RmosGrid apiUrl="" gridKey="" requestBody={{}} height={"38vh"} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default OdaTanimlama;
