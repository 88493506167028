import React, { useState, useRef, useEffect } from 'react';
import './FAB.css';

interface MenuItem {
  icon: string;
  name: string;
  onClick: () => void;
}

interface FABProps {
  icon: React.ReactNode;
  menuItems: MenuItem[];
}

const FloatingActionButton: React.FC<FABProps> = ({ icon, menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  // containerRef'in tipini HTMLElement | null olarak belirleyin
  const containerRef = useRef<HTMLElement | null>(null);

  const handleItemClick = (itemClick: () => void) => {
    itemClick();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // event.target'ın da bir Element olup olmadığını kontrol edin
      if (containerRef.current && event.target instanceof Element && !containerRef.current.contains(event.target)) {
        setIsOpen(false); // Ref'in dışında bir tıklama varsa menüyü kapat
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  return (
    <div className="fab-container1" ref={containerRef as any}> {/* `as any` kullanarak tip ataması yapın */}
      <button className="fab1" onClick={() => setIsOpen(!isOpen)}>
        {icon}
      </button>
      {isOpen && (
        <div className="dropdown-menu1">
          {menuItems.map((item, index) => (
            <div 
              key={index} 
              className="menu-item1 d-flex" 
              onClick={() => handleItemClick(item.onClick)}
            >
              {item.icon} {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FloatingActionButton;
