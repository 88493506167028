import React, { useState, useEffect } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { LanguageProvider } from "./Context/LanguageContext/LanguageContext";
import Dashboard from "./Dashboard/Dashboard";
import Login from "./Pages/Login/Login";
import { OtelGuidProvider } from "./Context/LocalStrogeContext/otelGuidContext";
import { AlertProvider } from "./Pages/Modal/Alert/AlertContext";
import "@progress/kendo-theme-bootstrap/dist/all.css"; // Bootstrap teması için
import { OnburoDbIdProvider } from "./Context/LocalStrogeContext/OnburoDbContext";
// import '@progress/kendo-theme-default/dist/all.css';  // Default tema için
// import './rmosweb.css'
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Yerel depolamadaki kimlik doğrulama durumunu kontrol edin
    const storedAuth = localStorage.getItem("isAuthenticated");
    if (storedAuth === "true" && localStorage.getItem("token")) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <LanguageProvider>
      <OtelGuidProvider>
        <OnburoDbIdProvider>
          <AlertProvider>
            <Router>
              <div className="">
                <Routes>
                  <Route path="/" element={<Navigate replace to="/Login" />} />
                  <Route
                    path="/Login"
                    element={<Login setIsAuthenticated={setIsAuthenticated} />}
                  />
                  <Route
                    path="/Dashboard"
                    element={
                      isAuthenticated ? (
                        <Dashboard />
                      ) : (
                        <Navigate replace to="/Login" />
                      )
                    }
                  />
                </Routes>
              </div>
            </Router>
          </AlertProvider>
          </OnburoDbIdProvider>
      </OtelGuidProvider>
    </LanguageProvider>
  );
}

export default App;
