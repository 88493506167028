import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { useState } from "react";
import { useEffect } from "react";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";
import Modal from "../../../../Modal/Modal/Modal";
import axios from "axios";
import RmosGrid from "../../../../../Components/DinamikRmosGrid/RmosGrid";

const RoomPostingChild: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [revokeModalOpen, setRevokeModalOpen] = useState(false);
  const [clearModalOpen, setClearModalOpen] = useState(false);
  const [postbackModalOpen, setPostbackModalOpen] = useState(false);

  const token = localStorage.getItem("token");
  const [date, setDate] = useState("");

  const { dbId } = useDbId();

  // If you use useEffect initially to set a variable you need to use one more useEffect to listen to make sure variable is set correctly.
  useEffect(() => {
    axios
      .post(
        "https://frontapi.rmosweb.com/api/Fishrk/Getir_Kod",
        datePostingBody,
        {
          headers,
        }
      )
      .then((response) => {
        setDate(response.data.value.Curtar_Turk);
      })
      .catch((error) => {
        console.error("Error during POST request:", error);
      });
  }, []);

  const formatDate = (date1: string): string => {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const [body, setBody] = useState({
    db_Id: dbId,
    xKumhrk_Tarih: formatDate(date),
    xKumhrk_Sirket: 1,
    xKumhrk_Posting_kodu: "P",
    xKumhrk_Posting_kodu2: "O",
    xtip: 1,
  });

  const [roomPostingBody, setRoomPostingBody] = useState({
    db_Id: dbId,
    tarih: formatDate(date),
    rih: "I",
    eski_Yeni_10: 0,
    xtip: 1,
  });

  const [backroomPostingBody, setBackroomPostingBody] = useState({
    db_Id: dbId,
    xtip: 1,
    tarih: formatDate(date),
    rih: "I",
    eski_Yeni_10: 9,
  });

  const [datePostingBody, setDatePostingBody] = useState({
    db_Id: dbId,
    Kod: 1,
    tip: 1,
  });

  const modalopen = () => {
    setModalOpen(true);
  };
  const revokemodalopen = () => {
    setRevokeModalOpen(true);
  };

  const clearmodalopen = () => {
    setClearModalOpen(true);
  };

  const backroomodalopen = () => {
    setPostbackModalOpen(true);
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  const footerCalculations: {
    field: string;
    type: "sum" | "count" | "average";
  }[] = [{ field: "Tutar", type: "sum" }];

  const RoomPostingListener = () => {
    if (!date) return;

    axios
      .post(
        "https://frontapi.rmosweb.com/api/Procedure/StpPosting",
        roomPostingBody,
        { headers }
      )
      .then((response) => {
        console.log("POST request successful:", response.data);
      })
      .catch((error) => {
        console.error("Error during POST request:", error);
      });

    setModalOpen(false);
  };

  useEffect(() => {
    setBody({
      ...body,
      xKumhrk_Tarih: formatDate(date),
    });
    setRoomPostingBody({
      ...roomPostingBody,
      tarih: formatDate(date),
    });
    setBackroomPostingBody({
      ...backroomPostingBody,
      tarih: formatDate(date),
    });

    console.log("Last Date: " + formatDate(date));
  }, [date]);

  const RoomPostingRevokeListener = () => {
    if (!date) return;

    const updatedRoomPostingBody = {
      ...roomPostingBody,
      xtip: 2,
      tarih: formatDate(date),
    };

    console.log("bodu", updatedRoomPostingBody);
    axios
      .post(
        "https://frontapi.rmosweb.com/api/Procedure/StpPosting",
        updatedRoomPostingBody,
        { headers }
      )
      .then((response) => {
        console.log("POST request successful:", response.data);
      })
      .catch((error) => {
        console.error("Error during POST request:", error);
      });

    setRevokeModalOpen(false);
  };

  const BackroomPostingListener = () => {
    if (!date) return;

    const updatedRoomPostingBody = {
      ...roomPostingBody,
      tarih: formatDate(date),
      xtip: 9,
    };

    console.log("backroom posting body: ", updatedRoomPostingBody);
    axios
      .post(
        "https://frontapi.rmosweb.com/api/Procedure/StpPosting",
        updatedRoomPostingBody,
        { headers }
      )
      .then((response) => {
        console.log("POST request successful:", response.data);
      })
      .catch((error) => {
        console.error("Error during POST request:", error);
      });

    setPostbackModalOpen(false);
  };

  return (
    <div>
      <div style={{ width: "100%", alignItems: "center" }}>
        <div
          style={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              verticalAlign: "middle",
              marginTop: "1.25rem",
              marginRight: "0.5rem",
            }}
          >
            Tarih:
          </p>
          <DatePicker
            disabled
            value={new Date(formatDate(date))}
            format="dd/MM/yyyy"
          />
          <Button onClick={modalopen}>Başlat</Button>
          <Button onClick={revokemodalopen}>Geri Al</Button>
          <Button onClick={clearmodalopen}>Posting Kod Temizle</Button>
          <Button onClick={backroomodalopen}>Eski Tarihe Posting Yap</Button>
        </div>
      </div>
      <div>
        <RmosGrid
          apiUrl="https://frontapi.rmosweb.com/api/Procedure/StpKumhrk_Bul"
          gridKey="RoomPosting"
          requestBody={body}
          showFooterCell={true}
          footerCalculations={footerCalculations}
        />
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        size="small"
        header="Room Posting"
        footer={
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button onClick={() => setModalOpen(false)}>Hayır</Button>
            <Button onClick={RoomPostingListener}>Evet</Button>
          </div>
        }
      >
        <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          Room Posting Yapmak İstediğinize Emin Misiniz?
        </div>
      </Modal>

      <Modal
        isOpen={revokeModalOpen}
        onClose={() => setRevokeModalOpen(false)}
        size="small"
        header="Room Posting"
        footer={
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button onClick={() => setRevokeModalOpen(false)}>Hayır</Button>
            <Button onClick={RoomPostingRevokeListener}>Evet</Button>
          </div>
        }
      >
        <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          Room Posting geri almak istediğinize emin misiniz?
        </div>
      </Modal>

      <Modal
        isOpen={clearModalOpen}
        onClose={() => setClearModalOpen(false)}
        size="small"
        header="Room Posting"
        footer={
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button onClick={() => setClearModalOpen(false)}>Hayır</Button>
            <Button>Evet</Button>
          </div>
        }
      >
        <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          Posting Kodu temizlemek istediğinize emin misiniz?
        </div>
      </Modal>

      <Modal
        isOpen={postbackModalOpen}
        onClose={() => setPostbackModalOpen(false)}
        size="small"
        header="Room Posting"
        footer={
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button onClick={() => setPostbackModalOpen(false)}>Hayır</Button>
            <Button onClick={BackroomPostingListener}>Evet</Button>
          </div>
        }
      >
        <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          Eski tarihe göre posting yapmak istediğinize emin misiniz?
        </div>
      </Modal>
    </div>
  );
};

export default RoomPostingChild;
