// LoyaltyTanimlamalarApi.tsx
import axios from "axios";

  const apiUrl = process.env.REACT_APP_LOYALTY_URL;

interface KartIslemRequest {
  otelGuid?: string;
  kartAdi?: string; // Ekleme ve güncelleme için gereklidir
  kartId?: number; // Silme ve güncelleme için gereklidir
  kartRengi?: string; // Güncelleme için gereklidir
  kartBaslangicPuani?: number; // Ekleme için gereklidir
  kartBitisPuani?: number; // Ekleme için gereklidir
  islem: "ekle" | "sil" | "guncelle" | "listele";
}
interface OtelPuanlamaAyarRequest {
  otelGuid: string;
  otelMaksKartSayisi: number;
  gecelemeKatsayisi: number;
  konaklamaKatsayisi: number;
  hesaplamaTuru: number;
}
interface FirsatIslemleriRequest {
  firsatID?: number;
  otelGuid?: string;
  firsatAdi?: string;
  firsatAciklamasi?: string;
  firsatPuani?: number;
  firsatKartAdi?: string;
  misafirID?: number | 0;
  islem: "ekle" | "sil" | "guncelle" | "listele" | "Hareketlistele";
}

export const LoyaltyTanimlamalarApi = {
  LoyaltyKartIslemleri: async (data: KartIslemRequest) => {
    console.log("Kartlar listeleniyor...");
    const { islem, ...requestData } = data; // Destructure the islem property and capture the rest
    const url = `${apiUrl}/api/KartIslemleri?islem=${data.islem}`;
    let response;
    switch (data.islem) {
      case "ekle":
        response = await axios.post(url, {
          otelguid: data.otelGuid,
          kartadi: data.kartAdi,
          kartrengi: data.kartRengi,
          kartbaslangicpuani: data.kartBaslangicPuani,
          kartbitispuani: data.kartBitisPuani,
        });
        break;
      case "guncelle":
        response = await axios.post(url, data);
        break;
      case "sil":
        response = await axios.post(url, {
          kartID: data.kartId,
          otelGuid: data.otelGuid,
        });
        break;
      case "listele":
        response = await axios.post(url, { otelGuid: data.otelGuid });
        break;
      default:
        throw new Error("Geçersiz işlem türü");
    }
    return { status: response.status, data: response.data };
  },
  LoyaltyOtelPuanlamaAyarlari: async (data: OtelPuanlamaAyarRequest) => {
    const response = await axios.post(
      `${apiUrl}/api/OtelPuanlamaAyarlari`,
      data
    );
    return response.data;
  },
  LoyaltyFirsatIslemleri: async (data: FirsatIslemleriRequest) => {
    const { islem, ...requestData } = data; // Destructure the islem property and capture the rest
    const url = `${apiUrl}/api/FirsatIslemleri?islem=${data.islem}`;
    let response;
    switch (data.islem) {
      case "ekle":
        response = await axios.post(url, {
          otelGuid: data.otelGuid,
          firsatAdi: data.firsatAdi,
          firsatAciklamasi: data.firsatAciklamasi,
          firsatPuani: data.firsatPuani,
          firsatKartAdi: data.firsatKartAdi,
        });
        break;
      case "guncelle":
        response = await axios.post(url, {firsatID:data.firsatID,firsatAdi:data.firsatAdi,firsatAciklamasi:data.firsatAciklamasi,firsatPuani:data.firsatPuani,firsatKartAdi:data.firsatKartAdi});
        break;
      case "sil":
        response = await axios.post(url, { firsatID: data.firsatID },
        );
        break;
      case "listele":
        response = await axios.post(url, { otelGuid: data.otelGuid });
        break;
      case "Hareketlistele":
        response = await axios.post(url, { misafirID: data.misafirID });
        break;
      default:
        throw new Error("Geçersiz işlem türü");
    }
    return { status: response.status, data: response.data };
  },
  LoyaltyPuanlamaCek: async (otelGuid: string) => {
    const response = await axios.get(`${apiUrl}/api/OtelPuanlamaAyarlari/PuanAyarlariListele/${otelGuid}`);
    return response.data;
  },
  
  LoyaltyFirsatCek:async(otelGuid:string)=>{
    // const response = await axios.post(`${apiUrl}/api/FirsatIslemleriController1?islem=listele`,{otelGuid:otelGuid});
    // return response.data;

    try {
      const response = await axios.get(`${apiUrl}/api/FirsatIslemleri/listelev2?otelGuid=${otelGuid}`);
      return { status: response.status, data: response.data };
    }
    catch (error) {
      console.error(error);
    }

  }
};
