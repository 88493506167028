import { Tab, Tabs } from "react-bootstrap";
import RoomPostingChild from "./RoomPostingChild";

const RoomPosting: React.FC = () => {
  return (
    <div>
      <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" className="mt-4">
        <Tab eventKey={1} title="Room Posting">
          <RoomPostingChild />
        </Tab>
        <Tab eventKey={2} title="Özel Toplu Posting İşlemleri">
          Tab 3 content
        </Tab>
      </Tabs>
    </div>
  );
};

export default RoomPosting;
