// KartEkleForm.tsx
import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Tabs,
  Tab,
  Table,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { LoyaltyTanimlamalarApi } from "../LoyaltyTanimlamalarApi";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { MdDelete, MdEditNotifications } from "react-icons/md";
// import useContextMenu from "../../../../Modal/SagTikMenu/ContextMenu";
import Modal from "../../../../Modal/Modal/Modal";
import { LanguageContext } from "../../../../../Context/LanguageContext/LanguageContext";
import { ChromePicker, SketchPicker } from "react-color";
import "./LoyaltyKartIslemleri.scss";
import KartFormDeneme from "../../LoyaltyDeneme";
import {
  ColorPicker,
  Input,
  NumericTextBox,
} from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "@progress/kendo-theme-default/dist/all.css"; // Kendo UI temanızı ekleyin
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  GridColumn as Column,
  GridContextMenuEvent,
} from "@progress/kendo-react-grid";

import {
  faCalendarAlt,
  faCheck,
  faClose,
  faCreditCard,
  faFlagCheckered,
  faPaintBrush,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { ContextMenu, MenuItem } from "@progress/kendo-react-layout";
import { useAlert } from "../../../../Modal/Alert/AlertContext";
interface Kart {
  kartAdi: string;
  kartID: number;
  otelGuid: string;
  kartRengi: string;
  kartBaslangicPuani: number;
  kartBitisPuani: number;
  // Diğer olası alanlar...
}

const LoyaltyKartTabPanel: React.FC<{ otelGuid: string }> = ({ otelGuid }) => {
  const [activeTab, setActiveTab] = useState<string>("kartEkle");
  const [refreshKartList, setRefreshKartList] = useState<boolean>(false);
  const languageContext = useContext(LanguageContext);
  const { translations = {} } = languageContext || {};
  const [selectedKart, setSelectedKart] = useState<Kart | undefined>(undefined);
  const [kartSilindi, setKartSilindi] = useState<boolean>(false); // Yeni state ekleme

  const handleKartSelect = (kart: Kart) => {
    setSelectedKart(kart);
  };

  const handleKartSaved = () => {
    setSelectedKart(undefined); // Kart kaydedildikten sonra seçimi temizle
    // Burada ayrıca kart listesini yenilemek için gerekli işlemleri yapabilirsiniz.
  };
  const handleKartSilindi = () => {
    setKartSilindi(true);
  };

  return (
    <Tabs activeKey={activeTab} onSelect={(k) => k && setActiveTab(k)}>
      <Tab eventKey="kartEkle" title={translations.KartEkle}>
        <LoyaltyKartEkleForm
          otelGuid={otelGuid}
          setRefreshKartList={setRefreshKartList}
          selectedKart={selectedKart}
          kartSilindi={kartSilindi}
          setKartSilindi={setKartSilindi} // Yeni eklenen props
        />
        <div className="mt-3"></div>
        <LoyaltyKartlariListeleForm
          otelGuid={otelGuid}
          refreshKartList={refreshKartList}
          setRefreshKartList={setRefreshKartList}
          onKartSelect={handleKartSelect}
          onKartSilindi={handleKartSilindi} // Callback fonksiyonu prop olarak geçir
        />
      </Tab>
    </Tabs>
  );
};

const LoyaltyKartEkleForm: React.FC<{
  otelGuid: string;
  setRefreshKartList: React.Dispatch<React.SetStateAction<boolean>>;
  selectedKart?: Kart | null;
  kartSilindi: boolean;
  setKartSilindi: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  otelGuid,
  setRefreshKartList,
  selectedKart,
  kartSilindi,
  setKartSilindi,
}) => {
  const [kartAdi, setKartAdi] = useState<string>("");
  const { showAlert } = useAlert(); // useAlert hook'unu kullan
  const [kartBaslangicPuani, setKartBaslangicPuani] = useState<number>(0);
  const [kartBitisPuani, setKartBitisPuani] = useState<number>(0);
  const [kartRengi, setKartRengi] = useState<string>("rgba(237, 126, 50, 1)");
  const [showColorPicker, setShowColorPicker] = useState(false); // State to manage modal visibility
  const clearFormFields = () => {
    setKartAdi("");
    setKartBaslangicPuani(0);
    setKartBitisPuani(0);
    setKartRengi("rgba(237, 126, 50, 1)"); // Varsayılan rengi kullanabilir veya başka bir renk belirleyebilirsiniz.
  };
  useEffect(() => {
    if (kartSilindi) {
      clearFormFields(); // Form alanlarını temizle
      setKartSilindi(false); // State'i tekrar false yap
    }
  }, [kartSilindi]);
  const handleColorPickerOpen = () => {
    setShowColorPicker(true); // Open the modal
  };
  useEffect(() => {
    // Seçilen kart varsa form alanlarını doldur
    if (selectedKart) {
      setKartAdi(selectedKart.kartAdi);
      setKartRengi(selectedKart.kartRengi);
      setKartBaslangicPuani(selectedKart.kartBaslangicPuani);
      setKartBitisPuani(selectedKart.kartBitisPuani);
      setKartRengi(selectedKart.kartRengi);
    }
  }, [selectedKart]);

  const handleColorPickerClose = () => {
    setShowColorPicker(false); // Close the modal
  };

  const handleKartEkle = async () => {
    if (!otelGuid || !kartAdi || !kartRengi) {
      showAlert("warning", "Otel Guid, Kart Adı veya Kart Rengi eksik!"); // Uyarı göster
      return;
    }

    try {
      const result = await LoyaltyTanimlamalarApi.LoyaltyKartIslemleri({
        otelGuid,
        kartAdi,
        kartRengi: kartRengi,
        kartBaslangicPuani, // Include this in the request
        kartBitisPuani, // And this as well
        islem: "ekle",
      });
      console.log(
        "Kart ekleme isteği başarıyla tamamlandı, API'dan gelen yanıt:",
        result.status
      );
      if (result.status === 200) {
        setKartAdi(""); // Kart adını temizle
        setKartBaslangicPuani(0); // Başlangıç puanını sıfırla
        setKartBitisPuani(0); // Bitiş puanını sıfırla
        showAlert("success", "Kart başarıyla eklendi"); // Başarı uyarısı göster
        setRefreshKartList((prev) => !prev); // Kart listesini yeniden yüklemek için state'i güncelle
      }
    } catch (error) {
      console.error("Kart eklenirken bir hata oluştu", error);
      showAlert("error", "Kart eklenirken bir hata oluştu"); // Hata uyarısı göster
    }
  };
  return (
    <>
      {/* <Container className="custom-form-container">
        <Form>
          <Row>
            <Col>
              <label className="k-form-field">
                <span>
                  <i className="fa fa-address-card"></i> Kart Adı
                </span>
                <Input value={kartAdi} onChange={(e) => setKartAdi(e.value)} />
              </label>
            </Col>
            <Col>
              <label className="k-form-field">
                <span>
                  <i className="fa fa-star"></i> Başlangıç Puanı
                </span>
                <NumericTextBox
                  value={kartBaslangicPuani}
                  onChange={(e) => setKartBaslangicPuani(e.value ?? 0)} // e.value null ise 0 kullan
                  />
              </label>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="k-form-field">
                <span>
                  <i className="fa fa-flag"></i> Bitiş Puanı
                </span>
                <NumericTextBox
                  value={kartBitisPuani}
                  onChange={(e) => setKartBitisPuani(e.value ?? 0)} // e.value null ise 0 kullan
                  />
              </label>
            </Col>
            <Col>
              <Button icon="fa fa-palette" onClick={handleColorPickerOpen}>
                Renk Seç
              </Button>
              <p>{`rgba(${kartRengi.r}, ${kartRengi.g}, ${kartRengi.b}, ${kartRengi.a})`}</p>
            </Col>
          </Row>
          <KartFormDeneme />
        </Form>
        <Row>
          <Col>
            {AlertComponents} 
          </Col>
        </Row>
      </Container> */}
      <div className="container mt-2">
        <div className="row">
          <div className="col-8 ">
            <form className="row d-flex">
              <div className="col-6 d-flex align-items-center p-1">
                <label htmlFor="kartAdi " className="r-fs-16 ">
                  <FontAwesomeIcon icon={faCreditCard} />
                  <span className="ms-2"></span> Kart Adı
                </label>
              </div>
              <div className="col-6 p-1">
                <Input
                  name="kartAdi"
                  value={kartAdi}
                  onChange={(e) => setKartAdi(e.value)}
                  className="r-input-sm "
                />
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <label htmlFor="kartBaslangicPuani" className="r-fs-16">
                  <FontAwesomeIcon icon={faStar} />
                  <span className="ms-2"></span> Kart Başlangıç Puanı
                </label>
              </div>
              <div className="col-6 p-1">
                <NumericTextBox
                  name="kartBaslangicPuani"
                  value={kartBaslangicPuani}
                  onChange={(e) => setKartBaslangicPuani(e.value ?? 0)} // e.value null ise 0 kullan
                  className="r-input-sm "
                />
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <label htmlFor="kartBitisPuani" className="r-fs-16">
                  <FontAwesomeIcon icon={faFlagCheckered} />
                  <span className="ms-2"></span> Kart Bitiş Puanı
                </label>
              </div>
              <div className="col-6 p-1">
                <NumericTextBox
                  name="kartBitisPuani"
                  value={kartBitisPuani}
                  onChange={(e) => setKartBitisPuani(e.value ?? 0)} // e.value null ise 0 kullan
                  className="r-input-sm "
                />
              </div>
            </form>
          </div>
          <div className="col-4">
            <div className="col-12 mt-3 d-flex flex-column justify-content-center align-items-center">
              <label className="mb-2 r-fs-16">
                <FontAwesomeIcon icon={faPaintBrush} /> Kart Rengi
              </label>
              <ColorPicker
                className=""
                value={kartRengi}
                onChange={(e) => setKartRengi(e.value)}
              />
            </div>
          </div>
          <div className="col-12 mt-3 d-flex justify-content-end">
            <Button
              className=" r-buton-danger me-3"
              onClick={() => {
                showAlert("info", "Kart ekleme işlemi iptal edildi");
                setKartAdi("");
                setKartBaslangicPuani(0);
                setKartBitisPuani(0);
                setKartRengi("rgba(237, 126, 50, 1)");
              }}
            >
              <FontAwesomeIcon icon={faClose} />
              <span className="ms-2"></span>
              Vazgeç
            </Button>
            <Button className=" r-buton-success " onClick={handleKartEkle}>
              <FontAwesomeIcon icon={faCheck} />

              <span className="ms-3">Tamamla</span>
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12"></div>
        </div>
      </div>
      <Modal
        isOpen={showColorPicker}
        onClose={handleColorPickerClose}
        size="small"
        header={<div>Renk Seçici</div>}
        footer={
          <div className="p-2">
            <button
              onClick={handleColorPickerClose}
              className="btn-primary btn"
            >
              Kapat
            </button>
          </div>
        }
      >
        <SketchPicker
          color={kartRengi}
          onChangeComplete={(color) => setKartRengi(color.rgb as any)}
        />
      </Modal>
    </>
  );
};

const LoyaltyKartlariListeleForm: React.FC<{
  otelGuid: string;
  refreshKartList: boolean;
  setRefreshKartList: React.Dispatch<React.SetStateAction<boolean>>;
  onKartSilindi: () => void; // Callback fonksiyonu için prop tipi
  onKartSelect: (kart: Kart) => void;
}> = ({
  otelGuid,
  refreshKartList,
  setRefreshKartList,
  onKartSelect,
  onKartSilindi,
}) => {
  const [kartlar, setKartlar] = useState<Kart[]>([]);
  const { showAlert } = useAlert(); // useAlert hook'unu kullan
  const [selectedKartId, setSelectedKartId] = useState<number | null>(null);
  const [selectedKart, setSelectedKart] = useState<Kart | null>(null);
  const [selectedKartRengi, setSelectedKartRengi] = useState({
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  });
  const kartlariGetir = async () => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyKartIslemleri({
        otelGuid,
        islem: "listele",
      });
      setKartlar(response.data);
      console.log(kartlar);
    } catch (error) {
      console.error("Kartlar listelenirken bir hata oluştu", error);
      showAlert("error", "Kartlar listelenirken bir hata oluştu");
    }
  };

  useEffect(() => {
    kartlariGetir();
  }, [otelGuid]);
  useEffect(() => {
    // kartlariGetir();
    // Kart listesi yenilendikten sonra, yenileme tetikleyicisini sıfırlayın
    setRefreshKartList(false);
    kartlariGetir();
  }, [otelGuid, refreshKartList]);
  useEffect(() => {
    if (selectedKart && selectedKart.kartRengi) {
      // Initialize the color picker with the card's color
      const color = parseColorFromString(selectedKart.kartRengi);
      setSelectedKartRengi(color);
    }
  }, [selectedKart]);
  function parseColorFromString(colorStr = "rgba(255, 255, 255, 1)") {
    const match = colorStr.match(
      /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(?:\.\d+)?)\)/
    );
    if (!match) {
      console.error(`Invalid color format: ${colorStr}`);
      return { r: 255, g: 255, b: 255, a: 1 }; // Return a default color if the format is invalid
    }
    return {
      r: parseInt(match[1]),
      g: parseInt(match[2]),
      b: parseInt(match[3]),
      a: parseFloat(match[4]),
    };
  }

  const handleUpdateKart = async () => {
    if (selectedKart) {
      try {
        await LoyaltyTanimlamalarApi.LoyaltyKartIslemleri({
          ...selectedKart,
          islem: "guncelle",
          kartRengi: `rgba(${selectedKartRengi.r}, ${selectedKartRengi.g}, ${selectedKartRengi.b}, ${selectedKartRengi.a})`,
        });
        showAlert("success", "Kart başarıyla güncellendi");
        console.log("Kart güncellendi, Kart ID:", selectedKart.kartID);
        setSelectedKart(null);
        // Güncellenmiş kart listesini yeniden yükleme
        kartlariGetir(); // Eğer bu fonksiyonu doğrudan çağırırsanız
      } catch (error) {
        console.error("Kart güncellenirken bir hata oluştu", error);
        showAlert("error", "Kart güncellenirken bir hata oluştu");
      }
    }
  };

  const handleDeleteKart = async () => {
    if (selectedKart) {
      try {
        console.log("Kart silme, Kart ID:", selectedKart.kartID);
        await LoyaltyTanimlamalarApi.LoyaltyKartIslemleri({
          kartId: selectedKart.kartID, // API'nin beklediği 'kartId' parametresi, 'selectedKart.kartID' ile eşleştirildi
          islem: "sil",
          otelGuid: selectedKart.otelGuid,
        });
        setSelectedKart(null);
        // Güncellenmiş kart listesini yeniden yükleme
        kartlariGetir();
        onKartSilindi(); // Üst bileşene kartın silindiğini bildir
        showAlert("success", "Kart başarıyla silindi");
      } catch (error) {
        console.error("Kart silinirken bir hata oluştu", error);
        showAlert("error", "Kart silinirken bir hata oluştu");
      }
    }
  };
  return (
    <>
      {/* <div>
      {kartlar.map((kart) => (
        <div key={kart.kartID}>
          <h5>{kart.kartAdi}</h5>
          <button onClick={() => onKartSelect(kart)}>Güncelle</button>
        </div>
      ))}
    </div> */}
      <div className="border-top mb-4 w-100  mt-4  "></div>
      <h3 className="text-center mb-2" style={{ letterSpacing: "2px" }}>
        {" "}
        Tanımlanmış Kartlar
      </h3>{" "}
      <Grid
        data={kartlar}
        style={{ height: "200px" }}
        onRowClick={(e) => {
          setSelectedKart(e.dataItem);
          setSelectedKartId(e.dataItem.kartID);
          onKartSelect(e.dataItem);
        }}
      >
        <Column field="kartAdi" title="Kart Adı" />
        <Column field="kartBaslangicPuani" title="Başlangıç Puanı" />
        <Column field="kartBitisPuani" title="Bitiş Puanı" />
        <Column field="kartRengi" title="Kart Rengi" />
      </Grid>
      <div className="d-flex mt-3 justify-content-end">
        <Button
          className=" r-buton-danger ms-3"
          onClick={handleDeleteKart}
          disabled={!selectedKart}
        >
          <FontAwesomeIcon icon={faClose} />
          <span className="ms-3">Sil</span>
        </Button>
      </div>
    </>
  );
};

export default LoyaltyKartTabPanel;
