import Modal from "../../../../Modal/Modal/Modal";
import BlackList from "../../Resepsiyon/BlackList/BlackList";

export interface BlackListModalProps {
  isOpen: boolean;
  onClose: () => void;
  data?: any;
}

const BlackListModal: React.FC<BlackListModalProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      header={<h4 className="m-0 p-0">BlackList</h4>}
    >
      <BlackList rowData={data} />
    </Modal>
  );
};

export default BlackListModal;
