import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, FormControl } from "react-bootstrap";
import { LanguageContext } from "../../Context/LanguageContext/LanguageContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';


interface LanguageMapping {
  [key: string]: string; // Bu index imzası, herhangi bir string anahtarın kullanılabileceğini belirtir.
}

const languageMapping: LanguageMapping = {
  en: "English",
  TR: "Türkçe",
  de: "Deutsch",
  fr: "Français",
  es: "Español",
  it: "Italiano",
  pt: "Português",
  ru: "Русский",
  zh: "中文",
  ja: "日本語",
  ko: "한국어",
  ar: "العربية",
  hi: "हिन्दी",
  bn: "বাঙালি",
  id: "Bahasa Indonesia",
  ms: "Bahasa Melayu",
  fa: "فارسی",
  tr: "Türkçe",
  nl: "Nederlands",
  pl: "Polski",
  sv: "Svenska",
  uk: "Українська",
  el: "Ελληνικά",
  hu: "Magyar",
  cs: "Čeština",
  bg: "Български",
  he: "עברית",
  ro: "Română",
  vi: "Tiếng Việt",
  th: "ไทย",
  fi: "Suomi",
  nb: "Norsk",
  da: "Dansk",
  sk: "Slovenčina",
  hr: "Hrvatski",
  ca: "Català",
  lt: "Lietuvių",
  fil: "Filipino",
  et: "Eesti",
  lv: "Latviešu",
  sr: "Српски",
  sl: "Slovenščina",
  is: "Íslenska",
  sw: "Kiswahili",
  tl: "Wikang Tagalog",
  az: "Azərbaycan dili",
  eu: "Euskara",
  ga: "Gaeilge",
  gl: "Galego",
  ht: "Kreyòl ayisyen",
  ur: "اردو",
  mr: "मराठी",
  gu: "ગુજરાતી",
  kn: "ಕನ್ನಡ",
  ta: "தமிழ்",
  te: "తెలుగు",
  ml: "മലയാളം",
  si: "සිංහල",
  lo: "ລາວ",
  my: "ဗမာ",
  km: "ខ្មែរ",
};

function DropdownDilButton() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('LanguageContext içinde çalışmıyor');
  }
  const { setLanguage } = context;
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("Language") || "tr");
  const [filter, setFilter] = useState('');

  const handleLanguageChange = (langCode: string) => {
    localStorage.setItem("Language", langCode);
    setSelectedLanguage(langCode);
    setLanguage(langCode); // Global dil değişikliğini burada uygula
  };

  // Filtreleme için dil seçeneklerini oluştur
  const filteredLanguages = Object.keys(languageMapping)
    .filter(langCode => languageMapping[langCode as keyof LanguageMapping].toLowerCase().includes(filter.toLowerCase()))
    .map(langCode => (
      <Dropdown.Item
        key={langCode}
        onClick={() => handleLanguageChange(langCode)}
      >
        {languageMapping[langCode as keyof LanguageMapping]}
      </Dropdown.Item>
    ));

    return (
      <div>
        <Dropdown drop='up' >
          <Dropdown.Toggle className="text-white dropup btn-sm" variant="" id="dropdown-basic">
            <FontAwesomeIcon icon={faGlobe} className="me-2" /> {/* Adding the FontAwesome icon */}
            Dil Seçimi: {languageMapping[selectedLanguage as keyof LanguageMapping]}
          </Dropdown.Toggle>
          <Dropdown.Menu className="overflow-auto" style={{height:"30vh"}}>
            <FormControl
              autoFocus
              className="mx-3 my-2 w-auto"
              placeholder="Dil ara..."
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            />
            {filteredLanguages}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }


export default DropdownDilButton;