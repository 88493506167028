import React from "react";

interface UstMenuBarProps {
  children: React.ReactNode;
  align?: 'left' | 'right'; // Hizalama parametresi
}

const UstMenuBar: React.FC<UstMenuBarProps> = ({ children, align = 'left' }) => {
  const justifyContent = align === 'left' ? 'justify-content-start' : 'justify-content-end';

  return (
    <div
      className="col-md-12 shadow-lg rounded p-2 mb-3 mt-2"
      style={{
        background: 'rgba(175, 232, 284, 0.1)', // Arkaplan rengi
        border: '1px solid rgba(117, 153, 187, 0.5)' // Opaklığı düşük border rengi
      }}
    >
      <div>
        <div className={`w-100 d-flex align-items-center ${justifyContent} gap-2`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default UstMenuBar;
