import React, { useState, useEffect } from "react";

import { backApi } from "../Menuler/BackOffice/Muhasebe/HesapPlani/HesapPlaniApi";
import KendoGridComponent from "../../Kendo/K-DataGrid";
import FloatingActionButton from "../../Components/Buttons/FloatActionButton/FloatingActionButton";
import { BsLightningFill } from "react-icons/bs";
import InfoPanel from "../../Components/InfoPanel/InfoPanel";
import Modal from "../../Pages/Modal/Modal/Modal";
import { HesapPlaniEkleProps, MuhPlanDetayListe } from "../Menuler/BackOffice/Muhasebe/HesapPlani/HesapPlaniType";
import { FaBeer } from "react-icons/fa";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { Card, Tab, Tabs } from "react-bootstrap";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import DynamicTable from "../../Components/DinamikTable/DynamicTable";

interface ColumnConfig {
  dataField: string;
  caption: string;
}

const FrontDeneme: React.FC = () => {
  const [muhPlanListesi, setMuhPlanListesi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columnsConfig, setColumnsConfig] = useState<ColumnConfig[]>([]);
  const [renderDuration, setRenderDuration] = useState("");
  const [renderStartTime, setRenderStartTime] = useState(0);
  const [renderEndTime, setRenderEndTime] = useState(0);
  const [responseTime, setResponseTime] = useState("");
  const [averageApiResponseTimes, setAverageApiResponseTimes] = useState<
    number[]
  >([]);
  const [averageRenderTimes, setAverageRenderTimes] = useState<number[]>([]);
  const [allApiResponseTimes, setAllApiResponseTimes] = useState<number[]>([]);
  const [allRenderTimes, setAllRenderTimes] = useState<number[]>([]);
  const [totalRenderTime, setTotalRenderTime] = useState<number>(0);
  const [totalApiResponseTime, setTotalApiResponseTime] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
const [addresses, setAddresses] = useState<any>([]); // API'den alınan adres verilerini tutacak state
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const savedConfig = localStorage.getItem("columnsConfig");
    if (savedConfig) {
      setColumnsConfig(JSON.parse(savedConfig));
    }
    muhPlanListesiCek();
  }, []);

  const muhPlanListesiCek = async () => {
    const startTime = performance.now();
    setIsLoading(true);
    const response = await backApi.MuhPlanGetir();
    const endTime = performance.now();
  
    if (response && response.isSucceded) {
      // Verinin ilk 100 öğesini alıyoruz
      // const firstHundredItems = response.value.slice(0, 10);
      setMuhPlanListesi(response.value);
      // console.log(firstHundredItems);
    }
    setIsLoading(false);
  
    const durationInSeconds = (endTime - startTime) / 1000;
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    setResponseTime(`${minutes} dakika : ${seconds} saniye`);
    setRenderStartTime(performance.now()); // Veriler yüklendikten sonra render süresi ölçümü başlar
  };
  

  const saveColumnsConfigToLocalStorage = () => {
    localStorage.setItem("columnsConfig", JSON.stringify(columnsConfig));
    alert("Sütun yapılandırması kaydedildi!");
  };
  // const onCellPrepared = (e: any) => {
  //   if (e.column.dataField === 'bakiye' && e.data && e.value < 0) {
  //     e.cellElement.style.color = 'red';
  //     e.cellElement.style.fontWeight = 'bold';
  //   }
  // };
  //merhaba buraya şimdi kendogrid componentine toolbar propuna buton ekleyeceğim
  //sonrada buraya oluşturduğum html butonun genel tasarımını yazacağız
  //sonra butona basınca sütunları kaydedecek ve localstorage a kaydedecek
  //sonra localstorage dan çekip sütunları oluşturacak
  //sonra sütunları datagrid componentine gönderecek
  //toolbarContent ReactNode bekliyor buna göre kodu düzenlermisin


  const menuItems = [
    { icon: '➕', name: 'Yeni Hesap', onClick: () => openModal() },
    { icon: '🧾', name: 'E-Mükellef', onClick: () => console.log('E-Mükellef') },
    { icon: '🎓', name: 'Eğitim Videosu', onClick: () => console.log('Eğitim Videosu') },
  ];
    //20 tane daha örnek veri yazarmısn
// useEffect(() => {

//   fetchAddresses();
// }
// , []);
//     const fetchAddresses = async () => {
//       try {
//         const response = await axios.get('https://fakerapi.it/api/v1/addresses?_quantity=10000');
//         setAddresses(response.data.data);  // API'nin veri yapısına bağlı olarak değişiklik gerekebilir.
//       } catch (error) {
//         console.error('Veri çekme işlemi sırasında bir hata oluştu:', error);
//       }
//     };
  

  const toolbarpopup = () => {
    return (
      <div>
              <FloatingActionButton icon={<BsLightningFill color="white"/>} menuItems={menuItems} />

      </div>
    );
  };
  const columnNamesMap1 = {
    Kod: "Kod",
    Ad: "Ad",
    Grup: "Grup",
    Dovizli: "Dövizli",
    Dovizkodu: "Döviz Kodu",
    Muhcari: "Muhasebe Cari",
    Vade: "Vade",
    Iskonto: "İskonto",
    Resmiad: "Resmi Ad",
    Vergidaire: "Vergi Dairesi",
    Vergino: "Vergi No",
    Adres1: "Adres 1",
    Adres2: "Adres 2",
    Ilce: "İlçe",
    Il: "İl",
    Posta: "Posta",
    Tel1: "Tel 1",
    Tel2: "Tel 2",
    Gsm1: "Gsm 1",
    Gsm2: "Gsm 2",
    Fax: "Fax",
    Eposta: "E-Posta",
    Web: "Web",
    Yetkili: "Yetkili",
    Banka1: "Banka 1",
    Banka2: "Banka 2",
    Banka3: "Banka 3",
    Hesap1: "Hesap 1",
    Hesap2: "Hesap 2",
    Hesap3: "Hesap 3",
    Iban1: "Iban 1",
    Iban2: "Iban 2",
    Iban3: "Iban 3",
    Nakitkod: "Nakit Kod",
    Efatura: "E-Fatura",
    Senaryo: "Senaryo",
    YansitmaBorc: "Yansıtma Borç",
    YansitmaAlacak: "Yansıtma Alacak",
    Onaysiz: "Onaysız",
    Sube: "Şube",
    Saticino: "Satıcı No",
    Avanshesap: "Avans Hesap",
    Avansoran: "Avans Oran",
    TcKimlik: "Tc Kimlik",
    Kurfarki: "Kur Farkı",
    Ulke: "Ülke",
    Etiket: "Etiket",
    Babs: "Babs"
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="openmodal">
            <button onClick={openModal}>Open Modal</button>
          </div>
          {/* <div>DataGrid Render Süresi: {renderDuration}</div> */}
          <div className="col-12 p-2 r-shadow-sm mt-2">
            {/* <button
              onClick={saveColumnsConfigToLocalStorage}
              style={{ marginBottom: "10px" }}
            >
              Yapılandırmayı Kaydet
            </button> */}
            <KendoGridComponent
              data1={muhPlanListesi}
               toolbarContent={toolbarpopup()}
            />
            {/* <KendoGridComponent data={muhPlanListesi}  />*/}
          <HesapPlaniEkle 
          isOpen={isModalOpen}
          onClose={closeModal}
           hesapKodu={selectedRow?.Kod}
        />
            {/* <DataGrid
              dataSource={muhPlanListesi}
              keyExpr="Kod"
              showBorders={true}
              remoteOperations={true} // Arka uçta sıralama, filtreleme ve sayfalama için
              columnAutoWidth={true} // Sütun genişliğini otomatik olarak ayarla
              allowColumnReordering={true} // Sütunların sırasını değiştirme izni
              allowColumnResizing={true} // Sütun genişliğini değiştirme izni
              rowAlternationEnabled={true} // Satır arka planlarını değiştirme
              // showColumnLines={true} // Sütun çizgilerini göster
              // showRowLines={true} // Satır çizgilerini göster
              onContentReady={onContentReady}
              showColumnHeaders={true} // Sütun başlıklarını göster
              //deafult sturun genisligi
              onOptionChanged={handleOptionsChange}
              columns={columnsConfig.length ? columnsConfig : undefined}
              // onCellPrepared={onCellPrepared}
              loadPanel={{ enabled: true, text: 'Yükleniyor...' }} // Yükleniyor göstergesi etkinleştirildi

              >
                
              <ColumnChooser enabled={true} />
              <ColumnFixing enabled={true} />
              {/* <Scrolling mode="virtual" /> 
              <FilterRow visible={true} />
              <Paging defaultPageSize={15} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[15, 30, 60, 90, 180]}
                showInfo={true}
              />
            </DataGrid> */}
          </div>
        </div>
      </div>
      <div>{responseTime}</div>
    </div>
  );
};

export default FrontDeneme;



const HesapPlaniEkle: React.FC<HesapPlaniEkleProps> = ({
  isOpen,
  onClose,
  hesapKodu,
}) => {
  const [muhPlanDetayListe, setMuhPlanDetayListe] = useState<
    MuhPlanDetayListe[]
  >([
    {
      Kod: "1000100102",
      Ad: "TL Kasası Nakit VICTORY RESORT",
      Grup: 2, //hesap türü 0ana 1 ara 2 alt 0 ve 1 den 1 tane olmalı 2 den birden fazla olabilir
      Dovizli: false,
      Dovizkodu: "",
      Muhcari: 0,
      Vade: 0,
      Iskonto: 0,
      Resmiad: "",
      Vergidaire: "",
      Vergino: "",
      Adres1: "",
      Adres2: "",
      Ilce: "",
      Il: "",
      Posta: "",
      Tel1: "",
      Tel2: "",
      Gsm1: "",
      Gsm2: "",
      Fax: "",
      Eposta: "",
      Web: "",
      Yetkili: "",
      Banka1: "",
      Banka2: "",
      Banka3: "",
      Hesap1: "",
      Hesap2: "",
      Hesap3: "",
      Iban1: "",
      Iban2: "",
      Iban3: "",
      Nakitkod: "",
      Efatura: false,
      Senaryo: "",
      YansitmaBorc: "",
      YansitmaAlacak: "",
      Onaysiz: false,
      Sube: "",
      Saticino: "",
      Avanshesap: "",
      Avansoran: 0,
      TcKimlik: "",
      Kurfarki: false,
      Ulke: "Türkiye",
      Etiket: "",
      Babs: false,
      Calismasekli: "",
      Not: "",
      Soyad: "",
      Mersisno: "",
      Sicilno: "",
      Alternatif1: "100.01.001",
      Alternatif2: "",
      Alternatif3: "",
      CariBankaSubeKodu: "",
      CariBankaSubeAdi: null,
      CariBankaHesapAdi: null,
      EpostaMutabakat: "",
      EpostaSatinalma: "",
      MasrafZorunlu: false,
      LogoVade: "",
      Kamu: false,
      EpostaMutabakatHesap: "",
      RiskLimit: 0,
      Kdv: 0,
      LogoHesap: "",
      KarsiHesap: "",
      EntegrasyonKodu: "",
      Yurtdisi: false,
    },
  ]);
  const [kayitDurum, setKayitDurum] = useState("Kayıtlı");
  const [checked, setChecked] = useState(false);
  // Modalın açık/kapalı durumunu yönetmek için yeni bir state eklenir
  const [modalOpen, setModalOpen] = useState(false);
  const muhPlanDetayCek = async (kod: string) => {
    try {
      const response = await backApi.MuhPlanDetayGetir(kod);
      if (response && response.isSucceded) {
        setMuhPlanDetayListe([response.value]); // Tek bir objeyi diziye çevir
        setModalOpen(true);
        console.log("muh plan detay başarılıcer", response.value);
      }
    } catch (error) {
      console.error("API isteğinde bir hata oluştu", error);
    }
  };
  useEffect(() => {
    setModalOpen(isOpen); // isOpen prop'unun değişikliklerine bağlı olarak modalı açıp kapar
  }, [isOpen]); // Bu useEffect yalnızca isOpen değiştiğinde çalışır

  useEffect(() => {
    if (hesapKodu) {
      muhPlanDetayCek(hesapKodu);
    }
  }, [hesapKodu]);

  // Modalı kapatma işlemi için mevcut onClose fonksiyonunu genişlet
  const handleClose = () => {
    onClose();
    // Modalı programatik olarak kapattığımızda, state'i de güncelleyerek kapalı duruma getiririz
    setModalOpen(false);
  };
  const handleInputChange = (index: number, field: string, newValue: any) => {
    const updatedList = muhPlanDetayListe.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: newValue }; // Alan adını dinamik olarak değiştirme
      }
      return item;
    });

    setMuhPlanDetayListe(updatedList);
  };
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  const infoItems = [
    {
      id: 1,
      title: "Örnek Başlık 1",
      description: "Açıklama=bu bir örnek açıklamadır.",
      Icon: FaBeer, // React icons'tan bir örnek simge
    },
    // Diğer öğeler...
  ];
  return (
    <>
      <Modal
        isOpen={modalOpen}
        onClose={handleClose}
        size="extra-large"
        header={<div className="fs-5">Hesap Kart - Ekleme</div>}
        footer={
          <>
            <div className="container-fluid p-0 m-0">
              <div className="row m-0 p-0">
                <div className="col-12 d-flex gap-5">
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">Onaysız</span>
                  </div>
                  {/* kilitli */}
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">Kilitli</span>
                  </div>
                  {/* kur farkı uygulama */}
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">
                      Kur Farkı Uygula
                    </span>
                  </div>
                  {/*babs dahil  */}
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">
                      BABS Dahil
                    </span>
                  </div>
                  {/* masraf merkezi zorunlu */}
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">
                      Masraf Merkezi Zorunlu
                    </span>
                  </div>
                  {/* yurt dışı firması */}
                  <div className="d-flex align-items-center ">
                    <Checkbox />
                    <span className="ms-2  mt-1 p-0 text-black">
                      Yurt Dışı Firması
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Button
              className=" ms-3 r-buton-danger d-flex align-items-center"
              // onClick={() => {
              //   setFirsatAdi("");
              //   setKartFirsatBilgileri([]);
              // }}
            >
              <FontAwesomeIcon icon={faClose} />
              <span className="ms-3">Sil</span>
            </Button>
            <Button
              className="ms-3 r-buton-success d-flex align-items-center"
              // onClick={firsatiKaydet}
              // disabled={isDisabled} // isDisabled true ise butonu devre dışı bırak
            >
              <FontAwesomeIcon icon={faCheck} />
              <span className="ms-3">Tamamla</span>
            </Button>
          </>
        }
      >
        <div className="container-fluid m-0 p-3">
          <div className="container-fluid m-0 p-0">
            {muhPlanDetayListe.length > 0 ? (
              <div className="row m-0 p-0">
                {muhPlanDetayListe.map((detay, index) => (
                  <div key={index} className="row">
                    <div className="col-6">
                      <Card>
                        <Card.Header>
                          <Card.Title>Hesap Kartı</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <div className="row">
                            {/* <div className="col-6">
                          <div>Adı: {detay.Ad}</div>
                          <div>Kodu: {detay.Kod}</div>
                        </div> */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Kayıt durum
                              </label>
                            </div>
                            <div className="col-9">
                              {/* <Input
                                name="kartAdi"
                                className="r-input-sm"
                                value={detay.Ad || ""}
                                onChange={(e) =>
                                  handleInputChange(index, e.value)
                                } // Kendo Input için `e.value` kullanılır. 
                              />*/}
                              <Input
                                type="text"
                                value={kayitDurum}
                                className="r-input-sm"
                                disabled
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Hesap Kodu
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Kod"
                                value={detay.Kod || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Kod", e.value)
                                }
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Hesap Adı
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Ad"
                                value={detay.Ad || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Ad", e.value)
                                }
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Grup
                              </label>
                            </div>
                            <div className="col-9">
                              <DropDownList
                                data={[
                                  { text: "Ana Grup", value: "0" },
                                  { text: "Ara Grup", value: "1" },
                                  { text: "Alt Grup", value: "2" },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Grup",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Hesap türü Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm"
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Dövizli
                              </label>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                              <Checkbox />
                              <DropDownList
                                data={[
                                  { text: "Evet", value: true },
                                  { text: "Hayır", value: false },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Dovizli",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Dövizli Hesap Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm ms-3"
                              />
                            </div>
                            {/* çalışma şekli muhasebe cari */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Muhasebe Cari
                              </label>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                              <DropDownList
                                data={[
                                  { text: "Muhasebe", value: 0 },
                                  { text: "Cari", value: 1 },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Muhcari",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Muhasebe Cari Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm ms-3"
                              />
                            </div>
                            {/* çalışma şekli abc cdf*/}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Çalışma Şekli
                              </label>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                              <DropDownList
                                data={[
                                  { text: "ABC", value: "ABC" },
                                  { text: "CDF", value: "CDF" },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Calismasekli",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Çalışma Şekli Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm ms-3"
                              />
                            </div>
                            {/*nakit alım kodları 001 002  */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Nakit Akım Kodu
                              </label>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                              <DropDownList
                                data={[
                                  { text: "001", value: "001" },
                                  { text: "002", value: "002" },
                                ]}
                                // value={secilenKart}
                                value="sdfdsf"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Nakitkod",
                                    e.target.value
                                  )
                                }
                                textField="text"
                                dataItemKey="value"
                                defaultItem={{
                                  text: "Nakit Kod Seçiniz",
                                  value: "",
                                }}
                                className="r-input-sm ms-3"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3 mt-4"
                      >
                        <Tab eventKey="home" title="Muhasebe bilgileri">
                          <Card>
                            <Card.Header>
                              <Card.Title>Muhasebe Bilgileri</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="row">
                                {/* vergi dairesi */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Vergi Dairesi
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Vergidaire"
                                    value={detay.Vergidaire || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Vergidaire",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* vergi no */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Vergi No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Vergino"
                                    value={detay.Vergino || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Vergino",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* tc kimlik no  */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> TC Kimlik No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="TcKimlik"
                                    value={detay.TcKimlik || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "TcKimlik",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* mersis no  */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Mersis No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Mersisno"
                                    value={detay.Mersisno || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Mersisno",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* ticaret sicil no */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Ticaret Sicil
                                    No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Sicilno"
                                    value={detay.Sicilno || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Sicilno",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* karşı hesap */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Karşı Hesap
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="KarsiHesap"
                                    value={detay.KarsiHesap || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "KarsiHesap",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* logo hesap */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Logo Hesap
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="LogoHesap"
                                    value={detay.LogoHesap || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "LogoHesap",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* vade */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Vade
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Vade"
                                    value={detay.Vade || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Vade", e.value)
                                    }
                                  />
                                </div>
                                {/*vade logo */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Logo Vade
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="LogoVade"
                                    value={detay.LogoVade || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "LogoVade",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* iskonto */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> İskonto
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Iskonto"
                                    value={detay.Iskonto || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Iskonto",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* kdv */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> KDV
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Kdv"
                                    value={detay.Kdv || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Kdv", e.value)
                                    }
                                  />
                                </div>
                                {/* yetkili */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Yetkili
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Yetkili"
                                    value={detay.Yetkili || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Yetkili",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* yansıt borç alacak buradna yan yana 2 tane input koyalım */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Yansıtma
                                  </label>
                                </div>
                                {/* bu şekilde değil col-9 içerisinde 2 tane input olacak */}
                                <div className="col-9 d-flex align-items-center">
                                  <Input
                                    name="YansitmaBorc"
                                    value={detay.YansitmaBorc || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "YansitmaBorc",
                                        e.value
                                      )
                                    }
                                  />
                                  <Input
                                    name="YansitmaAlacak"
                                    value={detay.YansitmaAlacak || ""}
                                    className="r-input-sm ms-3"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "YansitmaAlacak",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* e fatura burada inputun sol tarafına bir check ekle sonra yanına bir input daha ekle oonunda sağına check ekle  */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> E-Fatura
                                  </label>
                                </div>
                                <div className="col-9 d-flex align-items-center">
                                  <Checkbox />
                                  {/* input yerine dropdown */}
                                  <DropDownList
                                    data={[
                                      { text: "Evet", value: true },
                                      { text: "Hayır", value: false },
                                    ]}
                                    // value={secilenKart}
                                    value="sdfdsf"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Efatura",
                                        e.target.value
                                      )
                                    }
                                    textField="text"
                                    dataItemKey="value"
                                    defaultItem={{
                                      text: "E-Fatura Seçiniz",
                                      value: "",
                                    }}
                                    className="r-input-sm ms-3"
                                  />
                                  <Input
                                    name="YansitmaAlacak"
                                    value={detay.YansitmaAlacak || ""}
                                    className="r-input-sm ms-3"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "YansitmaAlacak",
                                        e.value
                                      )
                                    }
                                  />
                                  <Checkbox className="ms-2" />
                                  <span className="ms-2">kamu</span>
                                </div>
                                {/* şirket şuba burasıda yine col-3 ile olucak */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Şirket Şube
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Sube"
                                    value={detay.Sube || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Sube", e.value)
                                    }
                                  />
                                </div>
                                {/* satici no */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Satıcı No
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Saticino"
                                    value={detay.Saticino || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Saticino",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* acans hesabıda col-3 ile olucak yine  */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Avans Hesap
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Avanshesap"
                                    value={detay.Avanshesap || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Avanshesap",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* avans oran */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Avans Oran
                                  </label>
                                </div>
                                <div className="col-3">
                                  <Input
                                    name="Avansoran"
                                    value={detay.Avansoran || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Avansoran",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* risk limit */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Risk Limit
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="RiskLimit"
                                    value={detay.RiskLimit || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "RiskLimit",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Tab>
                        <Tab eventKey="profile" title="Banka Bilgileri">
                          <Card>
                            <Card.Header>
                              <Card.Title>Banka Bilgileri</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="row">
                                {/* banka 1 */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Banka 1
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Banka1"
                                    value={detay.Banka1 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Banka1",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* şube kodu */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Şube Kodu
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="CariBankaSubeKodu"
                                    value={detay.CariBankaSubeKodu || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "CariBankaSubeKodu",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* şube adı  */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Şube Adı
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="CariBankaSubeAdi"
                                    value={detay.CariBankaSubeAdi || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "CariBankaSubeAdi",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* hesap No */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Hesap No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="CariBankaHesapAdi"
                                    value={detay.CariBankaHesapAdi || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "CariBankaHesapAdi",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* banka hesap adı */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> B Hesap Adı
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Banka2"
                                    value={detay.Banka2 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Banka2",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* ıban */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> IBAN
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Iban1"
                                    value={detay.Iban1 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Iban1", e.value)
                                    }
                                  />
                                </div>
                                {/* banka2 */}
                                <div className="col-3 d-flex align-items-center p-0 mt-4">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Banka 2
                                  </label>
                                </div>
                                <div className="col-9 mt-4">
                                  <Input
                                    name="Banka2"
                                    value={detay.Banka2 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Banka2",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* hesap no */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Hesap No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Hesap2"
                                    value={detay.Hesap2 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Hesap2",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* ıban */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> IBAN
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Iban2"
                                    value={detay.Iban2 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Iban2", e.value)
                                    }
                                  />
                                </div>
                                {/* banka3 */}
                                <div className="col-3 d-flex align-items-center p-0 mt-4">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Banka 3
                                  </label>
                                </div>
                                <div className="col-9 mt-4">
                                  <Input
                                    name="Banka3"
                                    value={detay.Banka3 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Banka3",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* hesap no */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> Hesap No
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Hesap3"
                                    value={detay.Hesap3 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "Hesap3",
                                        e.value
                                      )
                                    }
                                  />
                                </div>
                                {/* ıban */}
                                <div className="col-3 d-flex align-items-center p-0">
                                  <label className="r-fs-16 ">
                                    {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                    <span className="ms-2"></span> IBAN
                                  </label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    name="Iban3"
                                    value={detay.Iban3 || ""}
                                    className="r-input-sm"
                                    onChange={(e) =>
                                      handleInputChange(index, "Iban3", e.value)
                                    }
                                  />
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Tab>
                      </Tabs>
                    </div>
                    <div className="col-6">
                      <Card>
                        <Card.Header>
                          <Card.Title>İletişim Bilgileri</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <div className="row">
                            {/* resmi ad */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Resmi Ad
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Resmiad"
                                value={detay.Resmiad || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Resmiad", e.value)
                                }
                              />
                            </div>

                            {/* soyad */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Soyad
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Soyad"
                                value={detay.Soyad || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Soyad", e.value)
                                }
                              />
                            </div>
                            {/* adres1 */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Adres 1
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Adres1"
                                value={detay.Adres1 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Adres1", e.value)
                                }
                              />
                            </div>
                            {/* adres 2  */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Adres 2
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Adres2"
                                value={detay.Adres2 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Adres2", e.value)
                                }
                              />
                            </div>
                            {/* ilce */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> İlçe
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Ilce"
                                value={detay.Ilce || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Ilce", e.value)
                                }
                              />
                            </div>
                            {/* il */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> İl
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Il"
                                value={detay.Il || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Il", e.value)
                                }
                              />
                            </div>
                            {/* ülke */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Ülke
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Ulke"
                                value={detay.Ulke || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Ulke", e.value)
                                }
                              />
                            </div>
                            {/* posta kodu */}
                            <div className="col-3 d-flex align-items-center p-0">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Posta Kodu
                              </label>
                            </div>
                            <div className="col-9">
                              <Input
                                name="Postakodu"
                                // value={detay.Postakodu || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Postakodu", e.value)
                                }
                              />
                            </div>
                            {/* telefon 1 */}
                            <div className="col-3 d-flex align-items-center p-0 mt-3">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Telefon 1
                              </label>
                            </div>
                            <div className="col-9 mt-3">
                              <Input
                                name="Telefon1"
                                // value={detay.Telefon1 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Telefon1", e.value)
                                }
                              />
                            </div>
                            {/* gsm1 */}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> GSM 1
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Gsm1"
                                // value={detay.Gsm1 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Gsm1", e.value)
                                }
                              />
                            </div>
                            {/* fax */}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Fax
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Fax"
                                // value={detay.Fax || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Fax", e.value)
                                }
                              />
                            </div>
                            {/* e posta  */}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> E-Posta
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Eposta"
                                // value={detay.Eposta || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Eposta", e.value)
                                }
                              />
                            </div>
                            {/* eposta muta babs */}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> E-Posta Muhasebe
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="EpostaMuhasebe"
                                // value={detay.Eposta || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "EpostaMuhasebe",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            {/* eposta muta hesap */}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> E-Posta Hesap
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="EpostaHesap"
                                // value={detay.Eposta || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "EpostaHesap",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            {/* eposta satın alma*/}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> E-Posta Satın
                                Alma
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="EpostaSatinalma"
                                // value={detay.Eposta || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "EpostaSatinalma",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            {/* WEB */}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Web
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Web"
                                // value={detay.Web || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Web", e.value)
                                }
                              />
                            </div>
                            {/* NOT */}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Not
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Not"
                                // value={detay.Not || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(index, "Not", e.value)
                                }
                              />
                            </div>
                            {/* ALTERNATİF 1 2 3 */}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Alternatif 1
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Alternatif1"
                                // value={detay.Alternatif1 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Alternatif1",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Alternatif 2
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Alternatif2"
                                // value={detay.Alternatif2 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Alternatif2",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Alternatif 3
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="Alternatif3"
                                // value={detay.Alternatif3 || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Alternatif3",
                                    e.value
                                  )
                                }
                              />
                            </div>
                            {/* ENTEGRASYON KODU */}
                            <div className="col-3 d-flex align-items-center p-0 ">
                              <label className="r-fs-16 ">
                                {/* <FontAwesomeIcon icon={faCreditCard} /> */}
                                <span className="ms-2"></span> Entegrasyon Kodu
                              </label>
                            </div>
                            <div className="col-9 ">
                              <Input
                                name="EntegrasyonKodu"
                                // value={detay.EntegrasyonKodu || ""}
                                className="r-input-sm"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "EntegrasyonKodu",
                                    e.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>Veri Yok</div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};