import axios from 'axios';

// API adresinizi buraya yazın
const apiUrl = process.env.REACT_APP_Back_API_URL;

// {
//   "db_Id": 7,
//   "tarih1": "2024-02-29T08:22:26.849Z",
//   "tarih2": "2024-02-29T08:22:26.849Z",
//   "yenile": true,
//   "sube": "string",
//   "plan_Muhcari": 0,
//   "kullanici": "string"
// }
const MuhPlanGetir = async () => {
  try {
    // Body'de gönderilecek veriyi hazırla
    const requestBody = {
      db_Id: 7,
      tarih1: "2018-01-01",
      tarih2: "2023-12-31",
      yenile: true,
      sube: "2",
      plan_Muhcari: 0,
      kullanici: "string"
    };

    // Header'ları hazırla
    const headers = {
      'Content-Type': 'application/json',
      // Token'i localStorage'den al ve Bearer token olarak ekle
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    const response = await axios.post(`${apiUrl}/api/Procedure/Muh_Plan_Liste`, requestBody, { headers });
    if (response.status !== 200) {
        console.error('API isteğinde bir hata oluştu', response);
        return null;
        }
        else if (response.status == 200) {
            console.log("muh plan başarılı başarılı");
            return response.data;
        }
    return response.data;
  } catch (error) {
    console.error('API isteğinde bir hata oluştu', error);
    return null;
  }
};
const MuhPlanDetayGetir = async (kod: string) => {
  const requestBody = {
    db_Id: 7,
    Kod: kod,
  };
  const headers = {
    'Content-Type': 'application/json',
    // Token'i localStorage'den al ve Bearer token olarak ekle
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  };

  try {
    const response = await axios.post(`${apiUrl}/api/Muh_Plan/Getir_Kod`, requestBody,{ headers });
    if (response.status !== 200) {
      console.error('API isteğinde bir hata oluştu', response);
      return null;
    }
    console.log("muh plan detay başarılı", response.data);
    return response.data;
  } catch (error) {
    console.error('API isteğinde bir hata oluştu', error);
    return null;
  }
}


// backapi adında bir nesne oluşturup, içine fetchData fonksiyonunu ekliyoruz.
export const backApi = {
    MuhPlanGetir,
    MuhPlanDetayGetir,
};
