import React, { useEffect, useRef, useState } from "react";
import "./KolayErisim.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import leftArrowIcon from "../../../Images/left.svg"; // Yol, projenizin yapısına göre değişiklik gösterebilir
import rightArrowIcon from "../../../Images/right.svg"; // Yol, projenizin yapısına göre değişiklik gösterebilir
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { TbChevronCompactLeft, TbChevronCompactRight } from "react-icons/tb";
import { RiDeleteBin4Fill } from "react-icons/ri";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdOutlineDeleteForever } from "react-icons/md";
import { RiDeleteBack2Fill } from "react-icons/ri";
import { motion } from 'framer-motion';
import useMenuItems from "../../Sidebar/SidebarMenu/MenuItems";
import { IconType } from "react-icons";

interface MenuItemProps {
  id: number;
  Icon: IconType;
  title: string;
  onClick: (menuId: number) => void; // Yeni eklendi

}
// Menü öğesi bileşeni
// const MenuItem = ({ icon, title }: MenuItemProps) => (
//     <div className="menu-item">
//         <div className="icon-round">
//             {icon} {/* İkon burada yer alacak */}
//         </div>
//         <div className="menu-title">{title}</div>
//     </div>
// );
// Ana bileşen
interface UserDetail {
  fldKolayMenu: number;
  // diğer alanlar...
}
interface UserData {
  user: UserDetail;
  hotels: Array<any>; // Daha spesifik bir tip belirtmek isteyebilirsiniz.
  databases: Array<any>; // Daha spesifik bir tip belirtmek isteyebilirsiniz.
  backYetki: null; // Gerçek tip neyse onu kullanabilirsiniz.
  backYetkiDetay: null; // Gerçek tip neyse onu kullanabilirsiniz.
  frontYetki: null; // Gerçek tip neyse onu kullanabilirsiniz.
  genelYetki: {
    id: number;
    userId: number;
    admin: boolean;
    front: boolean;
    back: boolean;
    // diğer alanlar...
  };
  // diğer alanlar...
}
interface MenuItem {
  id: number;
  title: string;
  icon: IconType;
  Component: React.ComponentType<any>;
  children?: MenuItem[];
}
const MenuItemComponent: React.FC<MenuItemProps> = ({ id, title, Icon, onClick }) => (
  <div className="menu-item menuitec d-flex justify-content-between align-items-center" onClick={() => onClick(id)}>
    <div className="icon-round">
      <Icon color="#e02d39" /> {/* icon prop'u bir fonksiyon olduğu için burada çağırıyoruz */}
      <div className="delete-icon">
        <RiDeleteBack2Fill color="#e02d39c9" size={20} />
      </div>
    </div>
    <div className="menu-title">{title}</div>
  </div>
);

const KolayErisim: React.FC<{ onMenuSelect: (menuId: number) => void }> = ({ onMenuSelect }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const user: UserData = JSON.parse(localStorage.getItem('user') || '{}');
  const fldKolayMenuStr = typeof user.user.fldKolayMenu === 'string' ? user.user.fldKolayMenu : '';
  const userkolaymenu: number[] = fldKolayMenuStr.split(',').map(Number) || [];// const userkolaymenu = user.user.fldKolayMenu.split(',');
  const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItem[]>([]);
const menuItems = useMenuItems();

const flattenMenuItems = (items: MenuItem[], result: MenuItem[] = [], level: number = 0): MenuItem[] => {
  items.forEach(item => {
    if (userkolaymenu.includes(item.id)) {
      const { children, ...rest } = item;
      result.push(rest);

      if (item.children && item.children.length > 0) {
        flattenMenuItems(item.children, result, level + 1);
      }
    }
  });

  return result;
};


useEffect(() => {
  const filteredAndFlattenedMenuItems = flattenMenuItems(menuItems); // menuItems, başlangıç menü öğelerinizdir
  console.log('Filtrelenmiş Menü Öğeleri:', filteredAndFlattenedMenuItems);
  setFilteredMenuItems(filteredAndFlattenedMenuItems);
}, []);


  const scrollLeft = () => {
    if (menuRef.current) {
      menuRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (menuRef.current) {
      menuRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };


  const menuVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: (index:number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: index * 0.1,
      },
    }),
  };
  
  return (
    <div className="container w-100">
      <div className="kolay-erisim w-100">
        <button
          className="scroll-button d-flex align-items-center"
          onClick={scrollLeft}
          style={{ height: "50px" }}
        >
          <TbChevronCompactLeft size={35} color="#fff" />
        </button>
        <div className="menu-container w-100" ref={menuRef}>
          <div className="menu w-100">
                  {filteredMenuItems.map((item:any, index:any) => (
              <motion.div key={item.id} custom={index} initial="hidden" animate="visible" variants={menuVariant}>
                <MenuItemComponent {...item} Icon={item.icon} onClick={onMenuSelect} />
              </motion.div>
            ))}

            {/* {MenuItem(menuItems[0])} */}
          </div>
        </div>{" "}
        <button
          className="scroll-button d-flex align-items-center"
          onClick={scrollRight}
          style={{ height: "50px" }}
        >
          <TbChevronCompactRight size={35} color="#fff" />
          {/* <img src={rightArrowIcon} alt="Sağ" /> */}
        </button>
      </div>
    </div>
  );
};

export default KolayErisim;
