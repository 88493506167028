// LoyaltyTanimlamalar.tsx
import React, { useEffect, useState } from "react";
import LoyaltyKartTabPanel from "./LoyaltyKartIslemleri/LoyaltyKartIslemleri";
import LoyaltyFirsatTabPanel from "./LoyaltyFirsatIslemleri/FirsatIslemleri";
import LoyaltyPuanlamaTabPanel from "./LoyaltyOtelPuanlamaIslemleri/LoyaltyOtelPuanlamaIslemleri";
import LoyaltyMisafirTabPanel from "../MisafirIslemleri/MisafirIslemleri/MisafirIslemleri";
import FrontDeneme from "../../../FrontDeneme/FrontDeneme";

// Özel olay ismini tanımla
const localStorageChangeEventName = "onLocalStorageChange";

// localStorage'da bir değer değiştiğinde özel olayı yayınlayan bir fonksiyon
export function emitLocalStorageChangeEvent() {
  const event = new CustomEvent(localStorageChangeEventName);
  window.dispatchEvent(event);
}

const LoyaltyTanimlamalar: React.FC = () => {
  const [otelGuid, setOtelGuid] = useState(localStorage.getItem("selectedHotelGuid") || "");

  useEffect(() => {
    // Olay dinleyicisi fonksiyonu 
    const handleOtelGuidChange = () => {
      const newOtelGuid = localStorage.getItem("selectedHotelGuid") || "";
      setOtelGuid(newOtelGuid);
      console.log("otelGuid değişti:", newOtelGuid);
    };
  
    // Özel olayı dinle
    window.addEventListener('otelGuidChanged', handleOtelGuidChange);
  
    // Komponent yok edildiğinde olay dinleyicisini temizle
    return () => {
      window.removeEventListener('otelGuidChanged', handleOtelGuidChange);
    };
  }, []);
  

  
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className=" p-2 mt-2 rounded-2 r-shadow-sm">
              <LoyaltyKartTabPanel otelGuid={otelGuid} />
            </div>
            <div className="mt-4 p-2 rounded-2 r-shadow-sm ">
              <LoyaltyPuanlamaTabPanel otelGuid={otelGuid} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="  p-2 rounded mt-2 r-shadow-sm ">
            <LoyaltyFirsatTabPanel otelGuid={otelGuid} />
            </div>
          </div>
          {/* <div className="col-md-12">
            <FrontDeneme />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LoyaltyTanimlamalar;


