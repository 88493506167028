import Modal from "../../../../Modal/Modal/Modal";
import { Card, CardBody, CardHeader, CardTitle } from "react-bootstrap";
import { Button } from "@progress/kendo-react-buttons";
import { saveIcon } from "@progress/kendo-svg-icons";
import { TextBox } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Ripple } from "@progress/kendo-react-ripple";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";

export interface FaturaKodUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  data?: any;
}

const FaturaKodUpdateModal: React.FC<FaturaKodUpdateModalProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  const { dbId } = useDbId();
  const token = localStorage.getItem("token");

  const [defaultValues, setDefaultValues] = useState<any>({
    id: undefined,
    billNo: undefined,
    billType: undefined,
    billDate: undefined,
    eBill: undefined,
    billScenario: undefined,
    currencyCode: undefined,
    currencySum: undefined,
    hotelCode: undefined,
    withholdingCode: undefined,
    exceptionCode: undefined,
    eBillSpecialBase: undefined,
    eBillTour: undefined,
    selfAgency: undefined,
    eBillShapeCode: undefined,
    firstAddress: undefined,
    secondAddress: undefined,
    thirdAddress: undefined,
    fourthAddress: undefined,
    fifthAddress: undefined,
    firstDescription: undefined,
    secondDescription: undefined,
    thirdDescription: undefined,
    firstTax: undefined,
    secondTax: undefined,
    entranceDate: undefined,
    endDate: undefined,
    duration: undefined,
    billEmail: undefined,
    gitti: undefined,
    billNo__: undefined,
  });

  const id = useRef<any>();
  const billNo = useRef<any>();
  const billType = useRef<any>();
  const billDate = useRef<any>();
  const eBill = useRef<any>();
  const billScenario = useRef<any>();
  const currencyCode = useRef<any>();
  const currencySum = useRef<any>();
  const hotelCode = useRef<any>();
  const withholdingCode = useRef<any>();
  const exceptionCode = useRef<any>();
  const eBillSpecialBase = useRef<any>();
  const eBillTour = useRef<any>();
  const selfAgency = useRef<any>();
  const eBillShapeCode = useRef<any>();

  // Address
  const firstAddress = useRef<any>();
  const secondAddress = useRef<any>();
  const thirdAddress = useRef<any>();
  const fourthAddress = useRef<any>();
  const fifthAddress = useRef<any>();

  // Descriptions
  const firstDescription = useRef<any>();
  const secondDescription = useRef<any>();
  const thirdDescription = useRef<any>();

  // Taxation
  const firstTax = useRef<any>();
  const secondTax = useRef<any>();

  // Bavel
  const entranceDate = useRef<any>();
  const endDate = useRef<any>();
  const duration = useRef<any>();
  const billEmail = useRef<any>();

  // E-devlet
  const gitti = useRef<any>();
  const billNo__ = useRef<any>();


  const [secondDescriptionTest, setSecondDescriptionTest] = useState("");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  const [body, setBody] = useState<any>({
    db_Id: parseInt(dbId),
    Id: "",
  });

  useEffect(() => {
    setBody((prevBody: any) => ({
      ...prevBody,
      Id: data ? parseInt(data.Id) : "",
    }));
  }, [isOpen]);

  useEffect(() => {
    try {
      axios
        .post("https://frontapi.rmosweb.com/api/Fat/Getir_Id", body, {
          headers,
        })
        .then((response) => {
          setDefaultValues({
            ...defaultValues,
            id: response.data.value[0].Id,
            billNo: response.data.value[0].No,
            firstDescription: response.data.value[0].Acik1,
            secondDescription: response.data.value[0].Acik2,
            firstAddress: response.data.value[0].Adres1,
            secondAddress: response.data.value[0].Adres2,
            thirdAddress: response.data.value[0].Adres3,
            duration: response.data.value[0].Geceleme,
            billDate: response.data.value[0].Tarihi,
            entranceDate: response.data.value[0].Giris,
            endDate: response.data.value[0].Cikis,
            eBill: response.data.value[0].E_fatura_eh,
            billNo__: response.data.value[0].Edevlet_Fatno,
            billScenario: response.data.value[0].E_fatura_senaryo,
            billType: response.data.value[0].Tipi,
            eBillTour: response.data.value[0].Efatura_turu,
            selfAgency: response.data.value[0].Kendi,
            currencySum: response.data.value[0].Doviztutar
          });
          console.log("Response in update: ", response.data.value[0]);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }

    console.log("The body is: ", body);
  }, [body]);

  const saveButtonHandler = () => {
    const dsabody = {
      a: id.current.value,
      b: billNo.current.value,
      c: firstDescription.current.value,
      d: currencySum.current.value
    };

    console.log("Save button info: ", dsabody);
  };

  //Single ComboBox props
  const billTypeItems = ["C", "C_TEK", "C_OZET"];
  const efaturaComboItems = ["E", "A", "K", "H"];
  const eBillScenarioItems = ["TEMELFATURA", "TICARIFATURA"];
  const eBillTourItems = [
    "SATIS",
    "IADE",
    "ISTISNA",
    "OZELMATRAH",
    "TEVKIFAT",
    "ARACTESCIL",
  ];
  const kendiacenteItems = ["Acenta", "Kendi", "Firma"];

  //MultiCombobox props
  const currencycolumns = [
    { field: "Kodlar_Ad", header: "Kodlar_Ad", width: "150px" },
    { field: "Kodlar_Kod", header: "Kodlar_Kod", width: "100px" },
  ];

  const hotelCodeColumns = [
    { field: "Otel_Kod", header: "Otel_Kod", width: "150px" },
    { field: "Otel_Ad", header: "Otel_Ad", width: "100px" },
  ];

  const withholdingCodeColumns = [
    { field: "Kod", header: "Kod", width: "150px" },
    { field: "Ad", header: "Ad", width: "300px" },
    { field: "Oran", header: "Oran", width: "100px" },
  ];

  const exceptionCodeColumns = [
    { field: "Kod", header: "Kod", width: "150px" },
    { field: "Ad", header: "Ad", width: "300px" },
  ];

  const specialBaseColumns = [
    { field: "Kod", header: "Kod", width: "150px" },
    { field: "Ad", header: "Ad", width: "300px" },
  ];

  const ebillShapeColumns = [
    { field: "Kod", header: "Kod", width: "150px" },
    { field: "Ad", header: "Ad", width: "300px" },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      header={<h4 className="m-0 p-0">Fatura Kod Update</h4>}
    >
      <Card>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Ripple>
            <Tooltip anchorElement="target" position="bottom">
              <Button
                svgIcon={saveIcon}
                className="blacklist-button"
                fillMode={null}
                title="Kaydet"
                onClick={saveButtonHandler}
            
              />
            </Tooltip>
          </Ripple>
        </div>
      </Card>

      <div style={{ display: "flex" }}>
        <Card style={{ width: "30%" }}>
          <CardHeader>
            <CardTitle>Kodlar</CardTitle>
          </CardHeader>
          <CardBody>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ marginTop: "0.4rem" }}>Id</span>
              <TextBox
                style={{ width: "50%" }}
                ref={id}
                defaultValue={defaultValues.id}
                disabled
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>Fatura Nosu</span>
              <TextBox
                style={{ width: "50%" }}
                ref={billNo}
                defaultValue={defaultValues.billNo}
                disabled
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>Fatura Tipi</span>
              <ComboBox
                ref={billType}
                style={{ width: "50%" }}
                data={billTypeItems}
                defaultValue={defaultValues.billType}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>Fatura Tarihi</span>
              <div style={{ width: "50%" }}>
                <DatePicker
                  defaultValue={new Date(defaultValues.billDate)}
                  format={"dd/MM/yyyy"}
                  ref={billDate}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>E Fatura</span>
              <ComboBox
                style={{ width: "50%" }}
                data={efaturaComboItems}
                defaultValue={defaultValues.eBill}
                ref={eBill}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>Fatura Senaryo</span>
              <ComboBox
                style={{ width: "50%" }}
                data={eBillScenarioItems}
                defaultValue={defaultValues.billScenario}
                ref={billScenario}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>Döviz Kodu</span>
              <div style={{ width: "50%" }}>
                <MultiColumnComboBox columns={currencycolumns} />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>Döviz Tutar</span>
              <TextBox style={{ width: "50%" }} ref={currencySum} defaultValue={defaultValues.currencySum} />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>Otel Kodu</span>
              <div style={{ width: "50%" }}>
                <MultiColumnComboBox columns={hotelCodeColumns} />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>Tevkifat Kodu</span>
              <div style={{ width: "50%" }}>
                <MultiColumnComboBox columns={withholdingCodeColumns} />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>İstisna Kodu</span>
              <div style={{ width: "50%" }}>
                <MultiColumnComboBox columns={exceptionCodeColumns} />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>E-Fat Ozel Matrah</span>
              <div style={{ width: "50%" }}>
                <MultiColumnComboBox columns={specialBaseColumns} />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>E-Fat Türu</span>
              <ComboBox
                style={{ width: "50%" }}
                data={eBillTourItems}
                ref={eBillTour}
                defaultValue={defaultValues.eBillTour}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>Kendi Acente</span>
              <ComboBox
                style={{ width: "50%" }}
                data={kendiacenteItems}
                defaultValue={defaultValues.selfAgency}
                ref={selfAgency}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.4rem",
              }}
            >
              <span style={{ marginTop: "0.4rem" }}>E-Fat Şekil_Kodu</span>
              <div style={{ width: "50%" }}>
                <MultiColumnComboBox columns={[]} />
              </div>
            </div>
          </CardBody>
        </Card>
        <div style={{ width: "70%" }}>
          <Card>
            <CardHeader>
              <CardTitle>Adresler</CardTitle>
            </CardHeader>
            <CardBody>
              <TextBox
                style={{ height: "1.75rem" }}
                ref={firstAddress}
                defaultValue={defaultValues.firstAddress}
              />
              <TextBox
                style={{ height: "1.75rem" }}
                ref={secondAddress}
                defaultValue={defaultValues.secondAddress}
              />
              <TextBox
                style={{ height: "1.75rem" }}
                ref={thirdAddress}
                defaultValue={defaultValues.thirdAddress}
              />
              <div style={{ display: "flex" }}>
                <TextBox style={{ height: "1.75rem" }} ref={fourthAddress} />
                <TextBox style={{ height: "1.75rem" }} ref={fifthAddress} />
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Açıklamalar</CardTitle>
            </CardHeader>
            <CardBody>
              <TextBox
                style={{ height: "1.75rem" }}
                ref={firstDescription}
                defaultValue={defaultValues.firstDescription}
              />
              {/* <TextBox
                style={{ height: "1.75rem" }}
                ref={secondDescription}
                defaultValue={defaultValues.secondDescription}
              /> */}
              <TextBox defaultValue={defaultValues.secondDescription} value={secondDescriptionTest} onChange={(e) => setSecondDescriptionTest(e.target.value as string)}/>

              <TextBox style={{ height: "1.75rem" }} ref={thirdDescription} />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Vergi</CardTitle>
            </CardHeader>
            <CardBody>
              <div style={{ display: "flex" }}>
                <TextBox style={{ height: "1.75rem" }} />
                <TextBox style={{ height: "1.75rem" }} />
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Bavel Update</CardTitle>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ marginTop: "0.4rem" }}>Giriş Tarihi</span>
                  <div style={{ width: "65%" }}>
                    <DatePicker
                      ref={entranceDate}
                      defaultValue={new Date(defaultValues.entranceDate)}
                      format={"dd/MM/yyyy"}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0.2rem",
                  }}
                >
                  <span style={{ marginTop: "0.4rem" }}>Çıkış Tarihi</span>
                  <div style={{ width: "65%" }}>
                    <DatePicker
                      ref={endDate}
                      defaultValue={new Date(defaultValues.endDate)}
                      format={"dd/MM/yyyy"}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0.2rem",
                  }}
                >
                  <span>Geceleme</span>
                  <div style={{ width: "65%" }}>
                    <TextBox
                      style={{ height: "1.75rem" }}
                      ref={duration}
                      defaultValue={defaultValues.duration}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0.2rem",
                  }}
                >
                  <span>Fatura Mail</span>
                  <div style={{ width: "65%" }}>
                    <TextBox style={{ height: "1.75rem" }} />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>E Devlet</CardTitle>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "0.2rem",
                }}
              >
                <span>Gitti</span>
                <div style={{ width: "65%" }}>
                  <TextBox style={{ height: "1.75rem" }} disabled />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "0.2rem",
                }}
              >
                <span>Fatura No </span>
                <div style={{ width: "65%" }}>
                  <TextBox
                    style={{ height: "1.75rem" }}
                    disabled
                    ref={billNo__}
                    defaultValue={defaultValues.billNo__}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginTop: "0.2rem",
                }}
              >
                <Checkbox label={"Günlük Çıksın"} />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </Modal>
  );
};

export default FaturaKodUpdateModal;
