import React, { Component, FC, useEffect, useState, useRef } from "react";
import "./Header.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import KolayErisim from "./Kolay Erişim Butonları/KolayErisim";
import Profil from "./Profil/Profil";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCog,
  faSlidersH,
  faToolbox,
  faEllipsisVertical,
  faCaretDown,
  faCaretUp,
  faDatabase,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { BsDatabaseCheck } from "react-icons/bs";
import logo from "../../Images/RmosWebLogo4.svg";
import UserProfile from "./UserProfile";
import { SlEnvolopeLetter } from "react-icons/sl";
import { BsFullscreen } from "react-icons/bs";
import { IoIosNotificationsOutline } from "react-icons/io";
interface User {
  hotels: Hotel[];
  // Diğer kullanıcı alanları buraya eklenebilir (örneğin fldUserID, fldName vb.)
}
interface Hotel {
  fldOtelID: string;
  fldOtelAdi: string;
  fldOtelGuid: string;
}
type Databases = {
  hotels?: Array<{
    fldOtelID: string;
    fldOtelGuid: string;
    fldOtelAdi: string;
  }>;
  databases?: Array<{ program_Id: number; id: string; ad: string }>;
};
export class HotelComboBox extends React.Component {
  componentDidMount() {
    this.selectFirstHotel();
    this.selectFirstOnBuro();
    window.addEventListener("otelGuidChanged", this.handleOtelGuidChanged);
    window.addEventListener("onBuroGuidChanged", this.handleOnburoIDChanged);
  }
  componentWillUnmount() {
    // Olay dinleyicisini kaldırın
    window.removeEventListener("otelGuidChanged", this.handleOtelGuidChanged);
    window.removeEventListener("onBuroGuidChanged", this.handleOnburoIDChanged);
  }
  handleOtelGuidChanged = () => {
    // Olay tetiklendiğinde komponenti güncelleyin
    this.forceUpdate();
  };
  handleOnburoIDChanged = () => {
    // Olay tetiklendiğinde komponenti güncelleyin
    this.forceUpdate();
  };
  /* 28.03.2024 15:12 kod değişikliği yapıldı yapılam sebebei sayfa yenilenince seçili olan otel guid sıfırlanıyordu */

  /* Burada kullanıcı verileri alınıyor ve otel bilgileri alınıyor. */
  handleHotelChange = (selectedHotelId: string) => {
    const userData: User = JSON.parse(localStorage.getItem("user") || "{}");
    const hotels = userData.hotels || [];
    const selectedHotel = hotels.find(
      (hotel) => hotel.fldOtelID === selectedHotelId
    );

    if (selectedHotel) {
      localStorage.setItem("selectedHotelGuid", selectedHotel.fldOtelGuid);
      window.dispatchEvent(new CustomEvent("otelGuidChanged"));
    }
    this.forceUpdate(); // Bu satırı burada tutmanız durumunda handleOtelGuidChanged çağrıldığında ekstra bir güncelleme yapılır.
  };

  selectFirstHotel = () => {
    const userData: User = JSON.parse(localStorage.getItem("user") || "{}");
    const hotels = userData.hotels || [];
    const selectedHotelGuid = localStorage.getItem("selectedHotelGuid");

    if (hotels.length > 0 && !selectedHotelGuid) {
      const firstHotel = hotels[0];
      localStorage.setItem("selectedHotelGuid", firstHotel.fldOtelGuid);
    }
  };
  handleOnBuroChange = (selectedDbId: string) => {
    const userData: any = JSON.parse(localStorage.getItem("user") || "{}");
    const databases = userData.databases || [];
    const selectedDb = databases.find((db: any) => db.id === selectedDbId);

    if (selectedDb) {
      localStorage.setItem("selectedDbId", selectedDb.id);
      localStorage.setItem("selectedDbName", selectedDb.ad); // Otel adını da sakla
      window.dispatchEvent(new CustomEvent("onBuroGuidChanged"));
    }
    this.forceUpdate(); // Bu, componenti günceller.
  };
  selectFirstOnBuro = () => {
    const userData: any = JSON.parse(localStorage.getItem("user") || "{}");
    const databases = userData.databases || [];
    const frontOfficeDbs = databases.filter((db: any) => db.program_Id === 1);
    const selectedDbId = localStorage.getItem("selectedDbId");

    if (frontOfficeDbs.length > 0 && !selectedDbId) {
      const firstDb = frontOfficeDbs[0];
      localStorage.setItem("selectedDbId", firstDb.id);
      localStorage.setItem("selectedDbName", firstDb.ad); // Otel adını da sakla
    }
  };

  render() {
    const userData: any = JSON.parse(localStorage.getItem("user") || "{}");
    const hotels = userData.hotels || [];
    const selectedHotelGuid = localStorage.getItem("selectedHotelGuid");

    const databases = userData.databases || [];
    const frontOfficeDbs = databases.filter((db: any) => db.program_Id === 1);
    const accountingDbs = databases.filter((db: any) => db.program_Id === 2);

    const selectedDbId = localStorage.getItem("selectedDbId");

    console.log("selectedDbId dbideş", selectedDbId);

    return (
      <div className="dropdown">
        <div className="dropbtn d-flex align-items-center">
          <FontAwesomeIcon icon={faDatabase} color="#a3b18a" />
          <span className="ms-2"></span>
          Veritabanı Seçimi
          <span className="ms-2"></span>
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
        <div className="dropdown-content text-start">
          <div className="submenu">
            <div className="alink">Önbüro »</div>
            <div className="submenu-content text-start">
              {frontOfficeDbs.map((db: any) => (
                <div
                  key={db.id}
                  onClick={() => this.handleOnBuroChange(db.id)}
                  className={`alink ${
                    selectedDbId == db.id ? "selected-database" : ""
                  }`}
                  style={{
                    backgroundColor:
                      selectedDbId == db.id ? "#255588" : "",
                    color:
                    selectedDbId == db.id ? "white" : "",
                    fontWeight:
                    selectedDbId == db.id ? "bold" : "",
                  }}
                >
                  {db.ad} {db.id}
                </div>
              ))}
            </div>
          </div>{" "}
          <div className="alink">Muhasebe</div>
          <div className="submenu">
            <div className="alink">Sadakat Sistemi »</div>
            <div className="submenu-content text-start">
              {hotels.map((hotel: any) => (
                <div
                  className={`alink ${
                    selectedHotelGuid === hotel.fldOtelGuid
                      ? "selected-hotel"
                      : ""
                  }`}
                  key={hotel.fldOtelID}
                  onClick={() => this.handleHotelChange(hotel.fldOtelID)}
                  style={{
                    backgroundColor:
                      selectedHotelGuid === hotel.fldOtelGuid ? "#255588" : "",
                    color:
                      selectedHotelGuid === hotel.fldOtelGuid ? "white" : "",
                    fontWeight:
                      selectedHotelGuid === hotel.fldOtelGuid ? "bold" : "",
                  }}
                >
                  {hotel.fldOtelAdi}
                </div>
              ))}
            </div>
          </div>
          <div className="alink">Bordro</div>
        </div>
      </div>
    );
  }
}
interface HeaderProps {
  onMenuSelect: (menuId: number) => void;
}

const Header: React.FC<HeaderProps> = ({ onMenuSelect }) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userSurName, setUserSurName] = useState("");
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  // Menü dışında bir yere tıklandığında menüyü kapat
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    if (userStr) {
      const userObj = JSON.parse(userStr);
      setUserName(userObj.user.fldName);
      setUserSurName(userObj.user.fldSurname);
    }
  }, []);

  const handleLogout = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key !== "Language" && key !== "gridLayouts") {
        localStorage.removeItem(key);
      }
    });
    navigate("/Login");
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((e) => {
        console.error("Tam ekran moduna geçilemedi: ", e);
      });
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };
  return (
    <header className="App-header">
      <div className="header-section left">
        <img src={logo} className="logo" alt="Logo" />
      </div>
      <div className="header-section center">
        {/* Kolay Erişim Butonları */}
        <KolayErisim onMenuSelect={onMenuSelect} />
      </div>
      <div className="header-section right d-flex align-items-center justify-content-end">
        {/* Profil bilgisi ve ikonları içeren bir div */}
        <div className="user-and-icons d-flex align-items-center">
          {/* Profil Bilgisi Bileşeni */}
          <div className="user-profile d-flex flex-row align-items-center">
            <span
              className="user-name text-white me-2"
              style={{ cursor: "pointer" }}
              onMouseOver={(e) => {
                const target = e.target as HTMLElement; // EventTarget'ı HTMLElement'e cast et
                target.style.color = "#e02d39";
              }}
              onMouseOut={(e) => {
                const target = e.target as HTMLElement; // EventTarget'ı HTMLElement'e cast et
                target.style.color = "white";
              }}
            >
              {userName} {userSurName}
            </span>
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              onClick={toggleMenu}
              className="icon-close icon-pointer icon-white"
              size="lg"
              style={{ cursor: "pointer" }}
              onMouseOver={(e) => {
                const svgElement = e.currentTarget; // doğrudan SVGElement olarak kullanabiliriz
                svgElement.style.color = "#e02d39";
                svgElement.style.transform = "scale(1.1)";
              }}
              onMouseOut={(e) => {
                const svgElement = e.currentTarget; // doğrudan SVGElement olarak kullanabiliriz
                svgElement.style.color = "white";
                svgElement.style.transform = "scale(1)";
              }}
            />
            {isMenuVisible && (
              <div className="dropdown-menu show" ref={menuRef}>
                <div className="dropdown-item">
                  <FontAwesomeIcon icon={faUserCog} className="me-2" />{" "}
                  Kullanıcı Ayarları
                </div>
                <div className="dropdown-item">
                  <FontAwesomeIcon icon={faSlidersH} className="me-2" /> Sistem
                  Parametreleri
                </div>
                <div className="dropdown-item">
                  <FontAwesomeIcon icon={faToolbox} className="me-2" /> Şifre
                  Değişikliği
                </div>
                <div className="dropdown-item">
                  <FontAwesomeIcon icon={faToolbox} className="me-2" /> Deneme
                  Ayarlar 2
                </div>
                <div className="dropdown-item">
                  <FontAwesomeIcon icon={faToolbox} className="me-2" /> Deneme
                  Ayarlar 3
                </div>
                <div className="dropdown-item">
                  <FontAwesomeIcon icon={faToolbox} className="me-2" /> Deneme
                  Ayarlar 4
                </div>
                {/* Diğer menü öğeleri burada yer alabilir */}
              </div>
            )}
            <img
              src="https://img.lovepik.com/png/20231110/profile-picture-vector-pictures-cartoon-sticker_555674_wh860.png"
              alt="Profil"
              style={{
                width: "35px",
                height: "35px",
                marginLeft: "9px",
                borderRadius: "20%",
              }}
            />
          </div>

          {/* İkonlar */}
          <div className="icons-wrapper d-flex align-items-center">
            <BsFullscreen
              color="#fff"
              style={{ marginRight: "10px" }}
              size={20}
              onClick={toggleFullScreen}
            />
            <IoIosNotificationsOutline
              color="#fff"
              style={{ marginRight: "10px" }}
              size={27}
            />
            <SlEnvolopeLetter
              color="#fff"
              style={{ marginRight: "10px" }}
              size={20}
            />
            {/* Güvenli Çıkış Butonu, sadece ikon */}
            <button
              className="btn btn-primary logoutButton"
              onClick={handleLogout}
              style={{ background: "transparent", border: "none" }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
