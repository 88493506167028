import axios, { AxiosError } from 'axios';

// POST isteği için gönderilecek veri türü
interface PostData {
  [key: string]: any;
}

// API'den alınacak yanıtın türü
interface ResponseData {
  [key: string]: any;
}

// Axios POST isteği yapan yardımcı fonksiyon
const postRequest = async (url: string, data: PostData, token?: string): Promise<ResponseData> => {
  console.log(`Request: ${url}`, data);
  console.log('Token:', token);
  console.log('url:', url); 
  console.log('response:', data);
  const storedToken = localStorage.getItem('token');
  const authToken = token || storedToken;

  try {
    const response = await axios.post<ResponseData>(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authToken ? `Bearer ${authToken}` : '',
      },
    });
    console.log(`Response: ${url}`, response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios kaynaklı hata
      console.error('Axios error:', error.message);

      if (error.response) {
        // Sunucu yanıt verdi ancak hata durum kodu döndü
        console.error('Response error data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);

        switch (error.response.status) {
          case 400:
            throw new Error(`Bad Request: ${JSON.stringify(error.response.data)}`);
          case 401:
            throw new Error('Unauthorized: Invalid or missing token.');
          case 403:
            throw new Error('Forbidden: You do not have permission to access this resource.');
          case 404:
            throw new Error(`Not Found: ${error.response.data.message || 'The requested resource could not be found.'}`);
          case 500:
            throw new Error(`Internal Server Error: ${error.response.data.message || 'An error occurred on the server.'}`);
          default:
            throw new Error(`Unexpected status code ${error.response.status}: ${JSON.stringify(error.response.data)}`);
        }
      } else if (error.request) {
        // İstek gönderildi ancak yanıt alınmadı
        console.error('Request error:', error.request);
        throw new Error('No response received from server');
      } else {
        // İstek hazırlığında bir hata oluştu
        console.error('Unexpected error during request setup:', error.message);
        throw new Error('Unexpected error occurred during request setup');
      }
    } else {
      // Axios dışında bir hata oluştu
      console.error('Unexpected non-Axios error:', (error as Error).message);
      throw new Error('An unexpected error occurred');
    }
  }
};

export default postRequest;
