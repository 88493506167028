import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RoomRack.scss";
import {
  FaChevronDown,
  FaChevronUp,
  FaArrowRight,
  FaArrowLeft,
  FaExpand,
  FaCompress,
  FaEye,
  FaQuestionCircle,
  FaTrash,
  FaRestroom,
} from "react-icons/fa";
import { Checkbox } from "@progress/kendo-react-inputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";
import { FaBoltLightning } from "react-icons/fa6";
import { IoReload } from "react-icons/io5";
import tinycolor from "tinycolor2";
import useContextMenu from "../../../../Modal/SagTikMenu/ContextMenu";
import RoomChangeModal from "../../Modal/RoomChangeModal/RoomChangeModal";
import RmosGrid2 from "../../../../../Components/DinamikRmosGrid/RmosGrid2";
import { ColumnMenu } from "../../../../../Kendo/ColumnMenu";
import { format } from "date-fns";

const RoomRack: React.FC = () => {
  const [sideOpen, setSideOpen] = useState(false);
  const [expandedCards, setExpandedCards] = useState<string[]>([]);
  const [roomData, setRoomData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true); // Loader için state
  const { dbId, otelAdi } = useDbId();
  const [cardSize, setCardSize] = useState("small"); // default to small
  const [detailedView, setDetailedView] = useState<string[]>([]);
  const [konum, setKonum] = useState<any[]>([]);
  const [katlar, setKatlar] = useState<any[]>([]);
  const [yatak, setYatak] = useState<any[]>([]);
  const [manzara, setManzara] = useState<any[]>([]);
  const [hkdurum, setHkdurum] = useState<any[]>([]);
  const [bölge, setBölge] = useState<any[]>([]);
  const [roomRackBody, setRoomRackBody] = useState<any>({
    db_Id: 9,
    xRoomhrk_Sirket: 0,
    xRoomhrk_Bas_tar: "2023-05-13",
    xRoomhrk_Bit_tar: "2023-07-19",
    xRoomhrk_Konum: null,
    xRez_Geceleme: 7,
    xTum_Tek: 1,
    xtip: 4,
    xRoomhrk_Odano: "00106",
    xSay: 400,
    xchkFis_Fazla_otel_10: 0,
    xKonumlar: null,
    xKatlar: null,
    xYatak: null,
    xManzara: null,
    xBolge: null,
    xHkdurum: null,
    xDolu_Bos: null,
    xAcentalar: null,
  });
  const [selectedKodlar, setSelectedKodlar] = useState<any>({
    Konum: [],
    Katlar: [],
    Yatak: [],
    Hkdurum: [],
    Bölge: [],
    Acenta: [],
  });
  const [acenta, setAcenta] = useState<any[]>([]);
  const [isOpenRoomChange, setIsOpenRoomChange] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [doluBos, setDoluBos] = useState<any[]>([
    { Kod: "B", Ad: "Boş" },
    { Kod: "D", Ad: "Dolu" },
  ]);
  const [roomDataDoluBos, setRoomDataDoluBos] = useState<any[]>([]);
  const contextMenuItems = [
    {
      label: "Edit",
      icon: <FaEye />,
      onClick: (data: any) => alert(`Edit clicked for ${data.OdaNo}`),
    },
    {
      label: "Room Change",
      icon: <FaRestroom />,
      onClick: (data: any) => {
        setIsOpenRoomChange(true);
        setRowData(data);
      },
    },
  ];

  const { handleContextMenu, renderContextMenu } =
    useContextMenu(contextMenuItems);

  useEffect(() => {
    fetchDoluBos();
    fetchData();
    fetchSinifKodlari();
    fetchAcenta();
  }, []);

  useEffect(() => {
    console.log("konum:", konum);
    console.log("katlar:", katlar);
    console.log("yatak:", yatak);
    console.log("hkdurum:", hkdurum);
    console.log("bölge:", bölge);
  }, [konum, katlar, yatak, hkdurum, bölge]);

  useEffect(() => {
    console.log("Selected Kodlar:", selectedKodlar);
  }, [selectedKodlar]);

  useEffect(() => {
    console.log("RoomRackBody:useeffectg", roomRackBody);
    fetchData();
  }, [roomRackBody]);

  useEffect(() => {
    console.log("RoomData:", roomData);
  }, [roomData]);

  const fetchData = async () => {
    console.log("RoomRackBody fetchdata:", roomRackBody);
    setLoading(true); // Verileri çekmeye başlamadan önce yükleyiciyi göster
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Procedure/StpRoomhrk_Bul",
        roomRackBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRoomData(response.data.value || []); // Add a default value in case data.value is null
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false); // Veriler çekildikten sonra yükleyiciyi gizle
  };
  const fetchAcenta = async () => {
    const requestBody = {
      db_Id: dbId,
      Kod: "ALL",
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Acenta/Getir_Kod",
        requestBody,
        { headers }
      );
      if (response.data.isSucceded) {
        // Acenta verilerini dönüştürme
        const acentaData = response.data.value.map((item: any) => ({
          Kod: item.Kodu ? item.Kodu.toString() : "",
          Ad: item.Adi ? item.Adi.toString() : "",
        }));
        setAcenta(acentaData);
        console.log("Acenta:", acentaData);
      }
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };

  const fetchDoluBos = async () => {
    console.log("RoomRackBody fetchdolubos:", roomRackBody);
    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Procedure/StpRoomhrk_Bul",
        {
          db_Id: 9,
          xRoomhrk_Sirket: 0,
          xRoomhrk_Bas_tar: "2023-05-13",
          xRoomhrk_Bit_tar: "2023-07-19",
          xRoomhrk_Konum: null,
          xRez_Geceleme: 7,
          xTum_Tek: 1,
          xtip: 10,
          xRoomhrk_Odano: "00106",
          xSay: 400,
          xchkFis_Fazla_otel_10: 0,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setRoomDataDoluBos(response.data.value || []); // Add a default value in case data.value is null
      console.log("RoomRackBody fetchdolubos:", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSinifKodlari = async () => {
    const requestBody = {
      db_Id: dbId,
      Sinif: "05,08,13,54,07,53",
      Kod: "",
      Tip: 888,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Kodlar/Getir_Kod",
        requestBody,
        { headers }
      );
      if (response.data.isSucceded) {
        const value = response.data.value;
        setKonum(value.filter((item: any) => item.Sinif === "05") || []);
        setKatlar(value.filter((item: any) => item.Sinif === "08") || []);
        setYatak(value.filter((item: any) => item.Sinif === "13") || []);
        setHkdurum(value.filter((item: any) => item.Sinif === "07") || []);
        setBölge(value.filter((item: any) => item.Sinif === "53") || []);
      }
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };

  const toggleSidebar = () => {
    setSideOpen(!sideOpen);
  };

  const toggleCard = (card: string) => {
    setExpandedCards((prev) =>
      prev.includes(card) ? prev.filter((c) => c !== card) : [...prev, card]
    );
  };

  const handleExpand = () => setCardSize("large");
  const handleCompress = () => setCardSize("medium");
  const handleDefault = () => setCardSize("small");

  const handleMouseEnter = (roomNo: string) => {
    setDetailedView((prev) => [...prev, roomNo]);
  };

  const handleMouseLeave = (roomNo: string) => {
    setDetailedView((prev) => prev.filter((no) => no !== roomNo));
  };

  const handleSelectionChange = (card: string, event: any) => {
    const selectedItems = event.target.value;
    const selectedKodlarForCard = selectedItems.map((item: any) => item.Kod);

    console.log(`Selected items for ${card}:`, selectedKodlarForCard);

    setSelectedKodlar((prevSelectedKodlar: any) => ({
      ...prevSelectedKodlar,
      [card]: selectedKodlarForCard,
    }));

    const joinedKodlar = selectedKodlarForCard.length
      ? selectedKodlarForCard.join(", ")
      : null;

    if (card === "Kat Apart") {
      console.log(`Updating xKatlar with: ${joinedKodlar}`);
      setRoomRackBody((prev: any) => ({
        ...prev,
        xKatlar: joinedKodlar,
      }));
    }
    if (card === "Konum") {
      console.log(`Updating xKonumlar with: ${joinedKodlar}`);
      setRoomRackBody((prev: any) => ({
        ...prev,
        xKonumlar: joinedKodlar,
      }));
    }
    if (card === "Bölge / Blok") {
      console.log(`Updating xBolgeBlok with: ${joinedKodlar}`);
      setRoomRackBody((prev: any) => ({
        ...prev,
        xBolge: joinedKodlar,
      }));
    }
    if (card === "Yatak Tipi") {
      console.log(`Updating xYatakTipi with: ${joinedKodlar}`);
      setRoomRackBody((prev: any) => ({
        ...prev,
        xYatak: joinedKodlar,
      }));
    }
    if (card === "HK Durum") {
      console.log(`Updating xHKDurum with: ${joinedKodlar}`);
      setRoomRackBody((prev: any) => ({
        ...prev,
        xHkdurum: joinedKodlar,
      }));
    }
    if (card === "Dolu Boş") {
      console.log(`Updating xDolu_Bos with: ${joinedKodlar}`);
      setRoomRackBody((prev: any) => ({
        ...prev,
        xDolu_Bos: joinedKodlar,
      }));
    }
    if (card === "Acenta Seçimi") {
      console.log(`Updating xAcentaSecimi with: ${joinedKodlar}`);
      setRoomRackBody((prev: any) => ({
        ...prev,
        xAcentalar: joinedKodlar,
      }));
    }
  };

  const handleButtonClick = () => {
    console.log("Selected Kodlar:", selectedKodlar);
    const selectedValues = {
      xKonumlar: selectedKodlar.Konum?.join(", ") || null,
      xKatApart: selectedKodlar["Kat Apart"]?.join(", ") || null,
      xBolgeBlok: selectedKodlar["Bölge / Blok"]?.join(", ") || null,
      xYatakTipi: selectedKodlar["Yatak Tipi"]?.join(", ") || null,
      xHKDurum: selectedKodlar["HK Durum"]?.join(", ") || null,
      xDolu_Bos: selectedKodlar["Dolu Boş"]?.join(", ") || null,
      xAcentaSecimi: selectedKodlar["Acenta Seçimi"]?.join(", ") || null,
    };
    console.log("Selected Values:", selectedValues);
    setRoomRackBody((prev: any) => ({
      ...prev,
      ...selectedValues,
    }));
    console.log("RoomRackBody: handleButtonClick", roomRackBody);
  };

  const cardOptions = [
    { text: "Option 1", value: 1 },
    { text: "Option 2", value: 2 },
    { text: "Option 3", value: 3 },
  ];

  const cards = [
    "Konum",
    "Kat Apart",
    "Bölge / Blok",
    "Yatak Tipi",
    "HK Durum",
    "Dolu Boş",
    "Acenta Seçimi",
  ];

  const getDataByCard = (card: string) => {
    let data = [];
    switch (card) {
      case "Konum":
        data = konum;
        break;
      case "Kat Apart":
        data = katlar;
        break;
      case "Bölge / Blok":
        data = bölge;
        break;
      case "Yatak Tipi":
        data = yatak;
        break;
      case "HK Durum":
        data = hkdurum;
        break;
      case "Acenta Seçimi":
        data = acenta;
        break;
      case "Dolu Boş":
        data = doluBos;
        break;
      default:
        data = [];
    }

    // Veri yapısının doğru olduğundan emin olun
    return data.map((item) => ({
      ...item,
      Ad: item.Ad ? item.Ad.toString() : "",
      Kod: item.Kod ? item.Kod.toString() : "",
    }));
  };

  const lightenColor = (color: string, amount: number = 10) => {
    return tinycolor(color).lighten(amount).toString();
  };

  const darkenColor = (color: string, amount: number = 20) => {
    return tinycolor(color).darken(amount).toString();
  };

  const Bosluk: React.FC<{ count?: number }> = ({ count }) => {
    return (
      <>
        {Array(count)
          .fill(null)
          .map((_, index) => (
            <>&nbsp;</>
          ))}
      </>
    );
  };
  // Kullanımı örneği
  // <Bosluk count={15} /> 15 boşluk oluşturur.

  const onClose = () => setIsOpenRoomChange(false);

  return (
    <div className="room-rack container-fluid">
      {otelAdi}
      <div className="row">
        <div
          className={`col-md-2 bg-light mt-3 sidebar overflow-auto ${
            sideOpen ? "roomopen" : "roomclosed"
          }`}
          style={{ height: "85vh" }}
        >
          <div className="w-100 d-flex flex-column">
            {cards.map((card) => (
              <div
                key={card}
                className={`room-rack-card bg-white border rounded-top mb-2 ${
                  expandedCards.includes(card) ? "expanded" : ""
                }`}
              >
                <div
                  className="room-rack-card-header d-flex justify-content-between p-2"
                  onClick={() => toggleCard(card)}
                >
                  <span className="room-rack-card-title">{card}</span>
                  {expandedCards.includes(card) ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </div>
                {expandedCards.includes(card) && (
                  <div className="room-rack-card-body p-2 bg-light rounded">
                    <MultiSelect
                      data={getDataByCard(card)}
                      textField="Ad"
                      dataItemKey="Kod"
                      onChange={(event) => handleSelectionChange(card, event)}
                      value={(selectedKodlar[card] || []).map((kod: any) =>
                        getDataByCard(card).find((item) => item.Kod === kod)
                      )}
                      placeholder={`${card} Seçiniz`}
                    />
                  </div>
                )}
              </div>
            ))}
            <button
              className="btn btn-primary mt-3"
              onClick={handleButtonClick}
            >
              Odaları Getir
            </button>
          </div>
        </div>
        <div className={`col-md-${sideOpen ? "10" : "12"} mt-3`}>
          <div className="row">
            <div className="col-md-3 d-flex flex-wrap align-items-center gap-2">
              <div>
                <button className="btn" onClick={toggleSidebar}>
                  {sideOpen ? (
                    <FaArrowLeft color="black" />
                  ) : (
                    <FaArrowRight color="black" />
                  )}
                </button>
              </div>
              <div>
                <FaBoltLightning />
                işlemler
                <span className="ms-3">
                  {" "}
                  <span>
                    <IoReload />
                  </span>
                  <span className="ms-2">333</span>
                </span>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status-container">
                {roomDataDoluBos.map((status: any, index) => (
                  <div key={index} className="status-item">
                    <div
                      className="status-color"
                      style={{ backgroundColor: status.Rengi }}
                    ></div>
                    <span className="status-text">
                      {status["Durum Rengi"]} {status.Sayisi}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="icon-buttons w-100">
            <FaExpand onClick={handleExpand} />
            <FaCompress onClick={handleCompress} />
            <FaEye onClick={handleDefault} />
          </div>
          {loading ? (
            <div className="container w-100 h-100">
              <div className="row w-100 h-100 d-flex align-items-center justify-content-center">
                <div
                  className="loader"
                  style={{ height: "50px", width: "50px" }}
                ></div>
              </div>
            </div>
          ) : (
            <div className="w-100 d-flex flex-wrap mt-3 room-card-container">
              {roomData.map((room, index) => (
                <div
                  key={index}
                  className={`room-card bordered shadow ${cardSize}`}
                  onContextMenu={(e) => handleContextMenu(e, room)} // Sağ tıklama menüsünü ekliyoruz ve odayı gönderiyoruz
                >
                  <div className="p-2">
                    <div className="room-card-header bg-light rounded p-1 d-flex justify-content-between align-items-center">
                      {cardSize === "small" && (
                        <span className="fw-bold">{room.OdaNo}</span>
                      )}
                      {cardSize !== "small" && (
                        <span className="fw-bold ">
                          <strong>HK Durum:</strong> {room.HKDurum || "N/A"}
                        </span>
                      )}
                      <FaQuestionCircle
                        className="question-icon"
                        onMouseEnter={() => handleMouseEnter(room.OdaNo)}
                        onMouseLeave={() => handleMouseLeave(room.OdaNo)}
                      />
                    </div>
                    {cardSize === "medium" && (
                      <div className="room-card-details">
                        <p>
                          <strong>Ad Soyad:</strong> {room.AdSoyad || "N/A"}
                        </p>
                        <p>
                          <strong>Konum:</strong> {room.Konum || "N/A"}
                        </p>
                        <p>
                          <strong>Giriş Tarihi:</strong>{" "}
                          {room.GirisTarihi || "N/A"}
                        </p>
                      </div>
                    )}
                    {cardSize === "large" && (
                      <div className="room-card-details">
                        <p>
                          <strong>Ad Soyad:</strong> {room.AdSoyad || "N/A"}
                        </p>
                        <p>
                          <strong>Konum:</strong> {room.Konum || "N/A"}
                        </p>
                        <p>
                          <strong>Giriş Tarihi:</strong>{" "}
                          {room.GirisTarihi || "N/A"}
                        </p>
                        <p>
                          <strong>Çıkış Tarihi:</strong>{" "}
                          {room.CikisTarihi || "N/A"}
                        </p>
                        <p>
                          <strong>Acenta:</strong> {room.Acenta || "N/A"}
                        </p>
                        <p>
                          <strong>HK Durum:</strong> {room.HKDurum || "N/A"}
                        </p>
                      </div>
                    )}
                    {detailedView.includes(room.OdaNo) && (
                      <div className="detailed-info">
                        <div className="detailed-info-content">
                          <p>
                            <strong>RezId</strong>
                            <Bosluk count={20} /> {room.RezId}
                          </p>
                          <p>
                            <strong>Oda No:</strong>
                            <Bosluk count={15} /> {room.OdaNo}
                          </p>
                          <p>
                            <strong>Adi:</strong>
                            <Bosluk count={25} /> {room.Adı}
                          </p>
                          <p>
                            <strong>Soyadi:</strong>
                            <Bosluk count={17} /> {room.Soyadı}
                          </p>
                          <p>
                            <strong>HK Durum:</strong>
                            <Bosluk count={9} /> {room["HK Tanımı"]}
                          </p>
                          <p>
                            <strong>Yatak Tipi:</strong>
                            <Bosluk count={10} /> {room["Yatak Tanımı"]}
                          </p>
                          <p>
                            <strong>Manzara Tipi:</strong>
                            <Bosluk count={3} /> {room["Manzara Tanımı"]}a
                          </p>
                          <p>
                            <strong>Konum:</strong>
                            <Bosluk count={16} /> {room["Konum Tanımı"]}
                          </p>
                          <p>
                            <strong>Acenta:</strong>
                            <Bosluk count={16} /> {room.Acenta}
                          </p>
                          <p>
                            <strong>Voucher:</strong>
                            <Bosluk count={14} /> {room.Voucher}
                          </p>
                          <p>
                            <strong>Giriş Tarihi:</strong>
                            <Bosluk count={9} />
                            {formatDate(room["Giriş Tarihi"])}
                          </p>
                          <p>
                            <strong>Çıkış Tarihi:</strong>
                            <Bosluk count={9} />
                            {formatDate(room["Çıkış Tarihi"])}
                          </p>
                          <p>
                            <strong>Alış Tarihi:</strong>
                            <Bosluk count={10} /> {room.AlisTarihi}
                          </p>
                          <p>
                            <strong>Odeme:</strong>
                            <Bosluk count={16} /> {room.Odeme}
                          </p>
                          <p>
                            <strong>Doviz Kodu:</strong>
                            <Bosluk count={11} /> {room.DovizKodu}
                          </p>
                          <p>
                            <strong>Room Bakiye:</strong>
                            <Bosluk count={10} /> {room.RoomBakiye}
                          </p>
                          <p>
                            <strong>Extra Bakiye:</strong>
                            <Bosluk count={9} /> {room.ExtraBakiye}
                          </p>
                          <p>
                            <strong>Pansiyon:</strong>
                            <Bosluk count={13} /> {room.Pansiyon}
                          </p>
                          <p>
                            <strong>Notlar:</strong>
                            <Bosluk count={15} /> {room.Notlar}
                          </p>
                          <p>
                            <strong>Trace:</strong>
                            <Bosluk count={16} /> {room.Trace}
                          </p>
                          <p>
                            <strong>Kapı Kartı:</strong>
                            <Bosluk count={11} /> {room.KapiKarti}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="room-card-footer rounded-bottom d-flex justify-content-center align-items-center"
                    style={{ background: room.Rengi }}
                  >
                    {cardSize !== "small" && (
                      <span className="fw-bold text-white fs-3">
                        {room.OdaNo}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {renderContextMenu()} {/* Sağ tıklama menüsünü burada render ediyoruz */}
      <RoomChangeModal
        isOpen={isOpenRoomChange}
        onClose={onClose}
        data={rowData}
      />
      {/* <RmosGrid2 initialColumns={initialColumns} />; */}
    </div>
  );
};

export default RoomRack;
// const initialColumns = [
//   { field: "id", title: "ID", show: true, columnMenu: ColumnMenu },
//   { field: "name", title: "Name", show: true, columnMenu: ColumnMenu },
//   { field: "category", title: "Category", show: true, columnMenu: ColumnMenu },
//   { field: "price", title: "Price", show: true, columnMenu: ColumnMenu }
// ];

const formatDate = (dateString: string | null) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return format(date, 'MM/dd/yyyy');
};
