import * as React from "react";
import {
  Grid,
  GridDataStateChangeEvent,
  GridColumn as Column,
  GridColumnProps,
  GridHandle,
  GridToolbar,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { DataResult, process, State } from "@progress/kendo-data-query";
import { PopupPropsContext } from "@progress/kendo-react-popup";
import { classNames } from "@progress/kendo-react-common";
import { ColumnMenuContext } from "../../Kendo/ColumnMenuContext";
import { ColumnMenu } from "../../Kendo/ColumnMenu";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import postRequest from "../PostRequest/PostRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import "./RmosGrid.css";



interface FooterCalculation {
  field: string;
  type: "sum" | "count" | "average";
}

interface RmosGridProps {
  apiUrl: string;
  requestBody: object;
  gridKey: string;
  initialColumns?: GridColumnProps[];
  height?: string | number | undefined;
  contextMenuItems?: { label: string; action: string; icon?: JSX.Element }[];
  onContextMenuClick?: (action: string, rowData: any) => void;

  showFooterCell?: boolean;
  footerCalculations?: FooterCalculation[];
  onRowDoubleClick?: (rowData: any) => void;
}

const RmosGrid: React.FC<RmosGridProps> = ({
  apiUrl,
  requestBody,  
  gridKey,
  initialColumns = [],
  height = "67vh",
  contextMenuItems = [],
  onContextMenuClick = () => {},
  showFooterCell = false,
  footerCalculations = [],
  onRowDoubleClick = () => {},
}) => {
  const [fullData, setFullData] = React.useState<any[]>([]);
  const [displayData, setDisplayData] = React.useState<any[]>([]);
  const gridRef = React.useRef<GridHandle>(null);
  const [dataState, setDataState] = React.useState<State>({
    take: 50,
    skip: 0,
    sort: [],
  });
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const [savedLayouts, setSavedLayouts] = React.useState<
    {
      name: string;
      dataState: State;
      columnsState: GridColumnProps[];
      isDefault?: boolean;
    }[]
  >([]);
  const [selectedLayout, setSelectedLayout] = React.useState<{
    name: string;
    dataState: State;
    columnsState: GridColumnProps[];
    isDefault?: boolean;
  } | null>(null);
  const [filter, setFilter] = React.useState<string>("");
  const [dataloading, setDataLoading] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [layoutName, setLayoutName] = React.useState<string>("");
  const [isDefault, setIsDefault] = React.useState<boolean>(false);
  const [columnsState, setColumnsState] =
    React.useState<GridColumnProps[]>(initialColumns);
  const [contextMenu, setContextMenu] = React.useState<{
    visible: boolean;
    x: number;
    y: number;
    rowData: any | null;
  }>({
    visible: false,
    x: 0,
    y: 0,
    rowData: null,
  });

  const prevRequestBody = React.useRef(requestBody);

  const fetchData = async () => {
    setDataLoading(true);
    try {
      const token: any = localStorage.getItem("token");
      const response = await postRequest(apiUrl, requestBody, token);
      const data = response.value || []; // Ensure data is always an array
      setFullData(data);
      setDisplayData(process(data, dataState).data);
      setDataLoading(false);

      // Dinamik kolon oluşturma
      if (initialColumns.length === 0 && data.length > 0) {
        const dynamicColumns = Object.keys(data[0]).map((key) => ({
          id: key,
          field: key,
          title: key,
          columnMenu: ColumnMenu,
        }));
        setColumnsState(dynamicColumns);

        // Sütunlar yüklendikten sonra yerel depodan düzeni yükle
        const defaultLayout = loadSavedLayouts();
        if (defaultLayout) {
          applyLayout(defaultLayout);
          setSelectedLayout(defaultLayout);
        }
      }
    } catch (error) {
      console.error("API request failed:", error);
      setDataLoading(false);
    }
  };

  React.useEffect(() => {
    if (JSON.stringify(prevRequestBody.current) !== JSON.stringify(requestBody)) {
      prevRequestBody.current = requestBody;
      fetchData();      
    }
  }, [requestBody]);
  

  React.useEffect(() => {
    loadSavedLayouts();
  }, []);

  React.useEffect(() => {
    const defaultLayout = savedLayouts.find((layout) => layout.isDefault);
    if (defaultLayout) {
      applyLayout(defaultLayout);
      setSelectedLayout(defaultLayout);
    }
  }, [savedLayouts]);

  React.useEffect(() => {
    if (!dataloading && fullData.length > 0 && columnsState.length > 0) {
      onAutoSize();
    }
  }, [fullData, dataloading, columnsState]);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    setDisplayData(process(fullData, event.dataState).data);
  };

  const onColumnsChange = React.useCallback((cols: GridColumnProps[]) => {
    setColumnsState(cols);
  }, []);

  const onAutoSize = React.useCallback(() => {
    const ids = columnsState.map((c) => c.id!);
    gridRef.current?.fitColumns(ids);
  }, [columnsState]);

  const saveCurrentLayout = () => {
    setShowModal(true);
  };

  const handleSave = () => {
    if (!layoutName) return;

    const newLayout = {
      name: layoutName,
      dataState: dataState,
      columnsState: columnsState,
      isDefault: isDefault,
    };

    let allLayouts = JSON.parse(localStorage.getItem("gridLayouts") || "{}");
    if (!allLayouts[gridKey]) {
      allLayouts[gridKey] = [];
    }

    if (isDefault) {
      allLayouts[gridKey] = allLayouts[gridKey].map((layout: any) => ({
        ...layout,
        isDefault: false,
      }));
    }

    const existingIndex = allLayouts[gridKey].findIndex(
      (layout: any) => layout.name === layoutName
    );
    if (existingIndex !== -1) {
      allLayouts[gridKey][existingIndex] = newLayout;
    } else {
      allLayouts[gridKey].push(newLayout);
    }

    localStorage.setItem("gridLayouts", JSON.stringify(allLayouts));
    setSavedLayouts(allLayouts[gridKey]);
    setShowModal(false);
    setLayoutName("");
    setIsDefault(false);
  };

  const loadSavedLayouts = () => {
    const allLayouts = JSON.parse(localStorage.getItem("gridLayouts") || "{}");
    setSavedLayouts(allLayouts[gridKey] || []);
    const defaultLayout = (allLayouts[gridKey] || []).find(
      (layout: any) => layout.isDefault
    );
    return defaultLayout;
  };

  const applyLayout = (layout: {
    name: string;
    dataState: State;
    columnsState: GridColumnProps[];
  }) => {
    setDataState(layout.dataState);
    const updatedColumns = layout.columnsState.map((col) => ({
      ...col,
      columnMenu: ColumnMenu,
    }));
    setColumnsState(updatedColumns);
  };

  const deleteLayout = (layoutName: string) => {
    let allLayouts = JSON.parse(localStorage.getItem("gridLayouts") || "{}");
    if (allLayouts[gridKey]) {
      allLayouts[gridKey] = allLayouts[gridKey].filter(
        (layout: any) => layout.name !== layoutName
      );
      localStorage.setItem("gridLayouts", JSON.stringify(allLayouts));
      setSavedLayouts(allLayouts[gridKey]);
    }
  };

  const onColumnReorder = (event: any) => {
    const newColumnsState = event.columns;
    setColumnsState(newColumnsState);
    console.log("New column order:", newColumnsState);
  };

  const handleLayoutChange = (event: ComboBoxChangeEvent) => {
    const selected = event.value
      ? savedLayouts.find((layout) => layout.name === event.value.name)
      : null;
    setSelectedLayout(selected || null);
    if (selected) {
      applyLayout(selected);
    }
  };

  const handleFilterChange = (event: any) => {
    setFilter(event.filter.value);
  };

  const filteredLayouts = savedLayouts.filter((layout) =>
    layout.name.toLowerCase().includes(filter.toLowerCase())
  );

  const onPageChange = (event: GridPageChangeEvent) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    });
    setDisplayData(
      process(fullData, {
        ...dataState,
        skip: event.page.skip,
        take: event.page.take,
      }).data
    );
  };

  const handleRowContextMenu = (event: React.MouseEvent, rowData: any) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      rowData: rowData,
    });
  };

  const handleMenuClick = (action: string) => {
    if (contextMenu.rowData) {
      onContextMenuClick(action, contextMenu.rowData);
    }
    setContextMenu({ visible: false, x: 0, y: 0, rowData: null });
  };

  const handleClickOutside = () => {
    if (contextMenu.visible) {
      setContextMenu({ visible: false, x: 0, y: 0, rowData: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu]);

  const handleDoubleClick = (rowData: any) => {
    onRowDoubleClick(rowData);
  };

  const calculateFooterValues = (
    field: string,
    type: "sum" | "count" | "average"
  ) => {
    const formatter = new Intl.NumberFormat("tr-TR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    if (type === "sum") {
      const sum = fullData.reduce((acc, item) => {
        const value = parseFloat(item[field]);
        return acc + (isNaN(value) ? 0 : value);
      }, 0);
      return formatter.format(sum);
    }

    if (type === "count") {
      const count = fullData.filter(
        (item) => item[field] !== undefined && item[field] !== null
      ).length;
      return formatter.format(count);
    }

    if (type === "average") {
      const sum = fullData.reduce((acc, item) => {
        const value = parseFloat(item[field]);
        return acc + (isNaN(value) ? 0 : value);
      }, 0);
      const count = fullData.filter(
        (item) => item[field] !== undefined && item[field] !== null
      ).length;
      const average = count === 0 ? 0 : sum / count;
      return formatter.format(average);
    }

    return null;
  };

  const CustomFooterCell = (props: any) => {
    const columnCalculation = footerCalculations.find(
      (calc) => calc.field === props.field
    );
    const value = columnCalculation
      ? calculateFooterValues(columnCalculation.field, columnCalculation.type)
      : null;
    return (
      <td colSpan={props.colSpan}>
        {props.columnIndex === 0 ? `Toplam Veri: ${fullData.length}` : value}
      </td>
    );
  };

  return (
    <>
      {dataloading ? (
        <div>Loading...</div>
      ) : (
        <ColumnMenuContext.Provider
          value={{
            onColumnsChange,
            onAutoSize,
            columnsState,
            columns: columnsState,
          }}
        >
          <PopupPropsContext.Provider
            value={({ popupClass, ...props }) => {
              const newPopupClass = classNames(popupClass, {
                "k-column-menu-tabbed": popupClass
                  ?.toString()
                  .includes("k-column-menu"),
              });

              return { ...props, popupClass: newPopupClass };
            }}
          >
            <Grid
              data={displayData}
              {...dataState}
              total={fullData.length}
              onDataStateChange={dataStateChange}
              sortable={true}
              pageSize={8}
              ref={gridRef}
              pageable={{
                buttonCount: 4,
                pageSizes: [50, 70, 100, 500],
                pageSizeValue: pageSizeValue,
              }}
              onPageChange={onPageChange}
              resizable={true}
              reorderable={true}
              onColumnReorder={onColumnReorder}
              style={{ height: height }}
              filterable={false}
              rowRender={(row, rowProps) => (
                <tr
                  {...(row.props as any)}
                  onContextMenu={(e) =>
                    handleRowContextMenu(e, rowProps.dataItem)
                  }
                  onDoubleClick={() => handleDoubleClick(rowProps.dataItem)}
                >
                  {row.props.children}
                </tr>
              )}
            >
              <GridToolbar>
                <Button onClick={fetchData}>
                  {" "}
                  <FontAwesomeIcon icon={faRefresh} />
                </Button>
                <Button onClick={saveCurrentLayout}>Kaydet</Button>
                <ComboBox
                  data={filteredLayouts}
                  textField="name"
                  onChange={handleLayoutChange}
                  value={selectedLayout}
                  placeholder="Kayıtlı düzenleri seçin"
                  filterable={true}
                  onFilterChange={handleFilterChange}
                />
                {selectedLayout && (
                  <Button onClick={() => deleteLayout(selectedLayout.name)}>
                    Sil
                  </Button>
                )}
              </GridToolbar>
              {columnsState.map((c, index) => (
                <Column
                  key={c.id}
                  {...c}
                  footerCell={
                    showFooterCell
                      ? (props) => (
                          <CustomFooterCell
                            {...props}
                            field={c.field}
                            columnIndex={index}
                            colSpan={1}
                          />
                        )
                      : undefined
                  }
                />
              ))}
            </Grid>
            {contextMenu.visible && (
              <RightClickMenu
                x={contextMenu.x}
                y={contextMenu.y}
                items={contextMenuItems}
                onMenuClick={handleMenuClick}
              />
            )}
          </PopupPropsContext.Provider>
        </ColumnMenuContext.Provider>
      )}
      {showModal && (
        <Dialog title="Düzen Kaydet" onClose={() => setShowModal(false)}>
          <div>
            <Input
              label="Düzen Adı"
              value={layoutName}
              onChange={(e) => setLayoutName(e.value)}
            />
            <Checkbox
              label="Varsayılan Olarak Ayarla"
              checked={isDefault}
              onChange={(e) => setIsDefault(e.value)}
            />
          </div>
          <DialogActionsBar>
            <Button onClick={handleSave}>Kaydet</Button>
            <Button onClick={() => setShowModal(false)}>İptal</Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default RmosGrid;

// Sağ Tıklama Menüsü Componenti

interface RightClickMenuProps {
  x: number;
  y: number;
  items: { label: string; action: string; icon?: JSX.Element }[];
  onMenuClick: (action: string) => void;
}

const RightClickMenu: React.FC<RightClickMenuProps> = ({
  x,
  y,
  items,
  onMenuClick,
}) => {
  return (
    <ul
      className="right-click-menu animate"
      style={{
        position: "absolute",
        top: y - 35,
        left: x + 15,
        backgroundColor: "white",
        border: "1px solid #ddd",
        padding: "0",
        listStyle: "none",
        zIndex: 2000,
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      {items.map((item) => (
        <li
          key={item.action}
          className="right-click-menu-item"
          onClick={() => onMenuClick(item.action)}
        >
          {item.icon && <span className="menu-icon">{item.icon}</span>}
          <span className="menu-label">{item.label}</span>
        </li>
      ))}
    </ul>
  );
};
