import React, { useEffect, useState } from "react";
import RmosGrid from "../../Components/DinamikRmosGrid/RmosGrid";
import { TextBox } from "@progress/kendo-react-inputs";

const FrontDeneme2 = () => {
  const [inputValue, setInputValue] = useState("");
  const apiUrl = "https://frontapi.rmosweb.com/api/Acenta/Getir_KodNoAuth";
  const requestBody = {
    db_Id: 9,
    Kod: "ALL?",
  };
  const gridKey = "FrontDeneme2";

  const footerCalculations: {
    field: string;
    type: "sum" | "count" | "average";
  }[] = [
    { field: "Grubu", type: "sum" },
    { field: "Kurkodu", type: "count" },
    { field: "Ulkekodu", type: "average" },
  ];

  const handleRowDoubleClick = (rowData: any) => {
    console.log("Double-clicked row data:", rowData);
    // Here you can pass the rowData to another component or use it as needed
  };

  const handleInputChange = (event: any) => {
    console.log("TextBox value:", event.target.value);
  };

  return (
    <div>
      <h1>RmosGrid Örneği</h1>
      <TextBox
        name="kendoTextBox"
        placeholder="Search..."
        onChange={handleInputChange}
      />
      <RmosGrid
        apiUrl={apiUrl}
        requestBody={requestBody}
        gridKey={gridKey}
        showFooterCell={true}
        footerCalculations={footerCalculations}
        onRowDoubleClick={handleRowDoubleClick}
      />
    </div>
  );
};

export default FrontDeneme2;
