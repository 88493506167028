import React, { useEffect, useState } from "react";
import { Form, Tabs, Tab, Tooltip } from "react-bootstrap";
import { LoyaltyTanimlamalarApi } from "../LoyaltyTanimlamalarApi";
import useAlert from "../../../../Modal/Alert/useAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHotel,
  faMoon,
  faBed,
  faCalculator,
  faInfoCircle,
  faCalendarDay,
  faCheck,
  faInfo,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@progress/kendo-react-buttons";

import { NumericTextBox } from "@progress/kendo-react-inputs";
import { GelismisPuanlamaAyarlari } from "./GelismisPuanlamaAyarlari";

//tabpanel işlemi içi kullanılacak bileşen ve fonksiyonlar burada yer alacak.
const LoyaltyPuanlamaTabPanel: React.FC<{ otelGuid: string }> = ({
  otelGuid,
}) => {
  const [activeTab, setActiveTab] = useState<string>("kartEkle");

  return (
    <Tabs activeKey={activeTab} onSelect={(k) => k && setActiveTab(k)}>
      <Tab eventKey="kartEkle" title="Standart Puanlama ayarları">
        <LoyaltyOtelPuanlamaAyarlari otelGuid={otelGuid} />
      </Tab>
      <Tab eventKey="GelismisParametre" title="Gelişmiş Parametre Ayarları">
      <GelismisPuanlamaAyarlari otelguid={otelGuid} />
      </Tab>
      {/* <Tab eventKey="kartDuzenle" title="">
      </Tab> */}
    </Tabs>
  );
};
//tabpanel işlemi içi kullanılacak bileşen ve fonksiyonlar burada yer alacak.

//otel puanlama ayarları için kullanılacak bileşen ve fonksiyonlar burada yer alacak.
const LoyaltyOtelPuanlamaAyarlari: React.FC<{ otelGuid: string }> = ({
  otelGuid,
}) => {
  const [otelMaksKartSayisi, setOtelMaksKartSayisi] = useState(0);
  const [gecelemeKatsayisi, setGecelemeKatsayisi] = useState(0);
  const [konaklamaKatsayisi, setKonaklamaKatsayisi] = useState(0);
  const [hesaplamaTuru, setHesaplamaTuru] = useState(0);
  const { showAlert, AlertComponents } = useAlert(); // useAlert hook'unu kullan
  const hesaplamaTuruBilgileri = [
    "Kalışa göre hesaplama",
    "Gelişe göre hesaplama",
    "Kalış ve gelişe göre hesaplama",
  ];
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyOtelPuanlamaAyarlari(
        {
          otelGuid,
          otelMaksKartSayisi,
          gecelemeKatsayisi,
          konaklamaKatsayisi,
          hesaplamaTuru,
        }
      );
      console.log(response);
      showAlert("success", "Puanlama ayarları başarıyla güncellendi !"); // Display success message
      // Burada başarılı yanıt üzerine istediğiniz işlemleri yapabilirsiniz.
    } catch (error) {
      console.error(error);
      // Hata yönetimi
      showAlert("error", "Bir hata oluştu!"); // Hata oluştuğunda kullanıcıyı bilgilendir
    }
  };
  const fetchData = async () => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyPuanlamaCek(
        otelGuid
      );
      console.log(response);
      setOtelMaksKartSayisi(response.otelMaksKartSayisi);
      setGecelemeKatsayisi(response.gecelemeKatsayisi);
      setKonaklamaKatsayisi(response.konaklamaKatsayisi);
      setHesaplamaTuru(response.hesaplamaTuru);
    } catch (error) {
      console.error(error);
      showAlert("error", "Bir hata oluştu!");
    }
  };
  useEffect(() => {

    fetchData();
  }, [otelGuid]);
  return (
    <>
      {AlertComponents}

      {/* <div className="container">
        <form onSubmit={handleSubmit} className="k-form">
          <div className="mb-3">
            <label htmlFor="otelMaksKartSayisi" className="k-label">
              <FontAwesomeIcon icon={faHotel} /> Otel Maksimum Kart Sayısı
            </label>
            <NumericTextBox
              value={otelMaksKartSayisi}
              onChange={(e) => setOtelMaksKartSayisi(e.value || 0)}
              className="k-input"
            />
          </div>

          <div className="mb-3">
            <label htmlFor="gecelemeKatsayisi" className="k-label">
              <FontAwesomeIcon icon={faCalendarDay} /> Geceleme Katsayısı
            </label>
            <NumericTextBox
              value={gecelemeKatsayisi}
              onChange={(e) => setGecelemeKatsayisi(e.value || 0)}
              className="k-input"
            />
          </div>

          <div className="mb-3">
            <label htmlFor="konaklamaKatsayisi" className="k-label">
              <FontAwesomeIcon icon={faCalendarDay} /> Konaklama Katsayısı
            </label>
            <NumericTextBox
              value={konaklamaKatsayisi}
              onChange={(e) => setKonaklamaKatsayisi(e.value || 0)}
              className="k-input"
            />
          </div>

          <div className="mb-3">
            <label htmlFor="hesaplamaTuru" className="k-label">
              <FontAwesomeIcon icon={faCalculator} /> Hesaplama Türü
            </label>
            <NumericTextBox
              value={hesaplamaTuru}
              onChange={(e) => setHesaplamaTuru(e.value || 0)}
              className="k-input"
            />
          </div>

          <Button type="submit">Kaydet</Button>
        </form>
      </div> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-8 d-flex align-items-start mt-3">
            <form onSubmit={handleSubmit} className="row d-flex">
              <div className="col-6 d-flex align-items-start p-1">
                <label htmlFor="kartAdi" className="r-fs-16">
                  <FontAwesomeIcon icon={faHotel} />
                  <span className="ms-2"></span> Otel Maksimum Kart Sayısı
                </label>
              </div>
              <div className="col-6 p-1">
                <NumericTextBox
                  value={otelMaksKartSayisi}
                  onChange={(e) => setOtelMaksKartSayisi(e.value || 0)}
                  className="r-input-sm"
                />
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <label htmlFor="kartBaslangicPuani" className="r-fs-16">
                  <FontAwesomeIcon icon={faBed} />
                  <span className="ms-1"></span> Geceleme Katsayısı
                </label>
              </div>
              <div className="col-6 p-1">
                <NumericTextBox
                  value={gecelemeKatsayisi}
                  onChange={(e) => setGecelemeKatsayisi(e.value || 0)}
                  className="r-input-sm"
                />
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <label htmlFor="kartBitisPuani" className="r-fs-16">
                  <FontAwesomeIcon icon={faCalendarDay} />
                  <span className="ms-2"></span> Konaklama Katsayısı
                </label>
              </div>
              <div className="col-6 p-1">
                <NumericTextBox
                  value={konaklamaKatsayisi}
                  onChange={(e) => setKonaklamaKatsayisi(e.value || 0)}
                  className="r-input-sm"
                />
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <label htmlFor="kartBitisPuani" className="r-fs-16">
                  <FontAwesomeIcon icon={faCalculator} />
                  <span className="ms-2"></span> Hesaplama Türü
                </label>
              </div>
              <div className="col-6 p-1 ">
                <NumericTextBox
                  value={hesaplamaTuru}
                  onChange={(e) => setHesaplamaTuru(e.value || 0)}
                  className="r-input-sm"
                />
              </div>
              <div className="col-12 d-flex p-0 justify-content-end align-items-center ">
                <Button
                  className=" mt-4 r-buton-danger d-flex align-items-center"
                  type="button"
                  onClick={() => {
                    setOtelMaksKartSayisi(0);
                    setGecelemeKatsayisi(0);
                    setKonaklamaKatsayisi(0);
                    setHesaplamaTuru(0);
                    showAlert("info", "Puanlama ayarları iptal edildi !");
                  }}
                >
                  <FontAwesomeIcon icon={faClose} />
                  <span className="ms-3">Vazgeç</span>
                </Button>
                <span className="ms-3"></span>
                <Button
                  className=" mt-4 r-buton-success d-flex align-items-center"
                  type="submit"
                >
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="ms-3">Tamamla</span>
                </Button>
              </div>
            </form>
          </div>
          <div className="col-4">
            <div className="col-12 mt-3">
              <div className="info-panel ">
                <div className="">
                  <div className="info-panel__title r-fs-16 d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#255588"
                      className="fs-4 mt-"
                    />
                    <span className="ms-4"></span>
                    Hesaplama Türleri
                  </div>
                </div>
                <div className="info-panel__content">
                  <div className="info-panel__item bg-light rounded shadow-sm">
                    <p className="p-0 m-0">
                      Hesaplama türü{" "}
                      <span className="fw-bold text-danger">0</span> ise Kalışa
                      göre hesaplama
                    </p>
                  </div>
                  <div className="info-panel__item bg-light rounded shadow-sm">
                    <p className="p-0 m-0">
                      Hesaplama türü{" "}
                      <span className="fw-bold text-danger">1</span> ise Gelişe
                      göre hesaplama
                    </p>
                  </div>
                  <div className="info-panel__item bg-light rounded shadow-sm">
                    <p className="p-0 m-0">
                      Hesaplama türü{" "}
                      <span className="fw-bold text-danger">2</span> ise Kalış
                      ve gelişe göre hesaplama
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3"></div>
        </div>
      </div>
    </>
  );
};
//otel puanlama ayarları için kullanılacak bileşen ve fonksiyonlar burada yer alacak.

export default LoyaltyPuanlamaTabPanel;
