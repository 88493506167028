import React, { useEffect, useState } from "react";
import {
  Grid,
  GridColumn as Column,
  GridColumnProps,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Checkbox, Input, RadioButton } from "@progress/kendo-react-inputs";
import { Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faRestroom } from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import UstMenuBar from "../../../../../Components/Basliklar/UstMenuBar";
import RmosGrid from "../../../../../Components/DinamikRmosGrid/RmosGrid";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";
import RoomChangeModal from "../../Modal/RoomChangeModal/RoomChangeModal";
import NotesModal from "./NotesModal";
import BlackListModal from "../../Modal/BlacklListModal/BlackListModal";

const initialDataState = {
  take: 10,
  skip: 0,
  group: [],
  filter: null,
};

const MisafirListesi: React.FC = () => {
  const apiUrl = "https://frontapi.rmosweb.com/api/Procedure/StpRez_Bul";
  const { dbId } = useDbId();

  const initialRequestBody = {
    db_Id: dbId,
    xRez_Id: 0,
    xRez_Master_detay: "M",
    xRez_Sirket: 0,
    xtip: 2,
    xMacente_Kodu: "T",
    xRez_rih: "I",
    xRez_Giris_tarihi: "2023-08-01",
    xRez_Cikis_tarihi: "2023-08-01",
    xRez_Alis_tarihi: "2023-08-01",
    xRez_Sistem_tarihi: "2024-08-01",
    xRez_Blokeli: 9,
    xRez_Odano: null,
    tek_durum: 0,
    xchkKonum_10: 0,
    xKonumlar: null,
    xchkAcenta_10: 0,
    xAcentalar: null,
    xchkKonaklama_10: 0,
    xKonaklama: null,
    xchkGiris_tarihi_10: 0,
    xGiris_Tarih_1: null,
    xGiris_Tarih_2: null,
    xchkCikis_tarihi_10: 0,
    xCikis_Tarih_1: null,
    xCikis_Tarih_2: null,
    xchkAlis_tarihi_10: 0,
    xAlis_Tarih_1: null,
    xAlis_Tarih_: null,
    xchkKonaklama_tar_10: 0,
    xKonaklama_Tar1: null,
    xKonaklama_Tar2: null,
    xchkOdeme_10: 0,
    xOdeme: null,
    xchkMusteritipi_10: 0,
    xMusteritipi: null,
    xchkUlke_10: 0,
    xUlke: null,
    xchkSource_10: 0,
    xSource: null,
    xchkChanel_10: 0,
    xChanel: null,
    xchkFis_Fazla_otel_10: 0,
    xFazla_Otel: null,
    xchkVoucher_10: 0,
    xVoucher: "0",
    xchkOda_10: 0,
    xOda: "0",
    gecerli_Yil: 2023,
    xchkAdi_1: 0,
    xAdi_1: null,
    xchkSoy_1: 0,
    xSoy_1: null,
    beraber_10: 1,
    yerliyabanci: 0,
    devam_10: 0,
    xRez_C_W: "C",
    gir_cik: 0,
    bakiye_10: 0,
    eski_Kontrol_10: 0,
    extra_10: 0,
    olustur: null,
    olustur_Kumhrk: null,
    xchkRih_10: 0,
    xRih: "T",
    otel_Kodu: "001",
    hizli_Normal: 0,
  };

  const gridKey = "MisafirListesi";
  const [requestBody, setRequestBody] = useState(initialRequestBody);
  const [xRezRih, setXRezRih] = useState("I");
  const [beraberindekilerCiksin, setBeraberindekilerCiksin] = useState(false);
  const [extraFoliolarCiksin, setExtraFoliolarCiksin] = useState(false);
  useEffect(() => {
    setRequestBody((prev) => ({ ...prev, db_Id: dbId }));
  }, [dbId]);

  useEffect(() => {
    setRequestBody((prevData) => ({
      ...prevData,
      xRez_rih: xRezRih,
    }));
  }, [xRezRih]);

  const handleRadioChange = (value: string) => {
    setXRezRih(value);
  };

  useEffect(() => {
    console.log("requestBody", requestBody);
  }, [requestBody]);

  const [isOpenRoomChange, setIsOpenRoomChange] = useState(false);
  const [isOpenNotes, setIsOpenNotes] = useState(false);
  const [isOpenBlackList, setIsOpenBlackList] = useState(false);

  const [rowData, setRowData] = useState({} as any);
  const onClose = () => setIsOpenRoomChange(false);
  const onCloseNotes = () => setIsOpenNotes(false);
  const onCloseBlackList = () => setIsOpenBlackList(false);

  const handleContextMenuClick = (action: string, rowData: any) => {
    console.log("handleContextMenuClick", action, rowData);

    if (action === "OdaDegisimi") {
      console.log("Oda Değişimi");
      setRowData(rowData);
      setIsOpenRoomChange(true);
    }
    if (action == "BlackList") {
      setRowData(rowData);
      setIsOpenBlackList(true);
    }
    if (action == "NotEkle") {
      setRowData(rowData);
      setIsOpenNotes(true);
    }
  };

  const handleCheckboxChange = (label: string, isChecked: boolean) => {
    if (label === "Beraberindekiler Çıksın") {
      setBeraberindekilerCiksin(isChecked);
    }
    if (label === "Extra Foliolar Çıksın") {
      setExtraFoliolarCiksin(isChecked);
    }
  };

  const contexMenu = [
    {
      label: "Oda Değişimi",
      action: "OdaDegisimi",
      icon: <FontAwesomeIcon icon={faRestroom} />,
    },
    {
      label: "BlackList",
      action: "BlackList",
      icon: <FontAwesomeIcon icon={faRestroom} />,
    },
    {
      label: "Not Ekle",
      action: "NotEkle",
      icon: <FontAwesomeIcon icon={faRestroom} />,
    },
  ];
  return (
    <>
      <RoomChangeModal
        isOpen={isOpenRoomChange}
        onClose={onClose}
        data={rowData}
      />
      <BlackListModal
        isOpen={isOpenBlackList}
        onClose={onCloseBlackList}
        data={rowData}
      />
      <NotesModal isOpen={isOpenNotes} onClose={onCloseNotes} data={rowData} />
      <div className="container-fluid mt-4">
        <div className="row">
          <UstMenuBar>
            <div>
              <RadioButton
                name="rihGroup"
                value="I"
                label="InHouse"
                checked={xRezRih === "I"}
                onChange={() => handleRadioChange("I")}
              />
            </div>
            <div>
              <RadioButton
                name="rihGroup"
                value="H"
                label="History"
                checked={xRezRih === "H"}
                onChange={() => handleRadioChange("H")}
              />
            </div>
            <div>
              <RadioButton
                name="rihGroup"
                value="T"
                label="All"
                checked={xRezRih === "T"}
                onChange={() => handleRadioChange("T")}
              />
            </div>
          </UstMenuBar>
          <div className="col-md-12 bg-white shadow-lg rounded p-3 mt-1">
            <Tabs defaultActiveKey="MusteriDetay" id="uncontrolled-tab-example">
              <Tab eventKey="MusteriDetay" title="Müşteri Detay">
                <RmosGrid
                  apiUrl={apiUrl}
                  requestBody={requestBody}
                  gridKey={gridKey}
                  contextMenuItems={contexMenu}
                  onContextMenuClick={handleContextMenuClick}
                />
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex justify-content-between gap-2">
                        <div className="w-100 d-flex justify-content-between gap-2">
                          <div className="text-start">
                            <Checkbox label={"Tutar Çıkmasın"} color="red" />
                          </div>
                          <div className="text-start">
                            <Checkbox
                              defaultChecked={false}
                              label={"Beraberindekiler Çıksın"}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  "Beraberindekiler Çıksın",
                                  e.value
                                )
                              }
                            />
                          </div>

                          <div className="text-start">
                            <Checkbox
                              defaultChecked={false}
                              label={"Extra Foliolar Çıksın"}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  "Extra Foliolar Çıksın",
                                  e.value
                                )
                              }
                            />
                          </div>
                          <div className="text-start">
                            <Checkbox
                              defaultChecked={true}
                              label={"İsimler Maskeli Çıksın"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="ResmiMusteriListesi" title="Resmi Müşteri Listesi">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-between gap-2">
                        <div className="d-flex justify-content-between align-items-center">
                          Liste Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Şekil (1)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Şekil (2)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Şekil (3) İkili"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Tarihler Arası"
                          />
                        </div>
                        <div>
                          <Checkbox
                            label={"Eski Inhouse Kontrol"}
                            color="red"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Inhouse (AKtif)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Eski Inhouse"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Eski Inhouse (Basılan)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="T.Arası (Eski Inhouse)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="PolisDefteri" title="Polis Defteri">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-contet-start gap-2">
                        <div className="d-flex justify-content-center align-items-center">
                          Liste Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          Jan.Akbs Kodu
                        </div>
                        <div>
                          <Input type="text" placeholder=""></Input>
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="TC Vatandaşı"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Yabancı Misafir"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Tüm"
                          />
                        </div>
                        <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                          <FontAwesomeIcon icon={faRefresh} /> EGM'den Doldur
                        </div>
                        <div>
                          <Checkbox
                            label={"Beraberindekiler Çıksın"}
                            color="red"
                            onChange={(e) =>
                              handleCheckboxChange(
                                "Beraberindekiler Çıksın",
                                e.value
                              )
                            }
                          />
                        </div>
                        <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                          <FontAwesomeIcon icon={faRefresh} /> Tabloyu Düş
                        </div>
                        <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                          <FontAwesomeIcon icon={faRefresh} /> Boş Kayıtları
                          Temizle
                        </div>
                        <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                          <FontAwesomeIcon icon={faRefresh} /> Hatalı Kayıtlar
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 w-100 d-flex align-items-start  justify-contet-start gap-2 mt-3">
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> Değişkenleri Kaydet
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> XML Oluştur
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> CRM Kontrol
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> AKBS Robot
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> Excel (EGM)
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> AKBS Web
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> EGM Gönder
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> EGm (Parçalı)
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> EGM Otomatik
                  </div>
                </div>
              </Tab>
              <Tab eventKey="OdaDegisimi" title="Oda Değişimi">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-start gap-2">
                        <div className="d-flex justify-content-between align-items-center">
                          Başlangıç Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          Bitiş Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="UzatmaAlanlar" title="Uzatma Alanlar">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-start gap-2">
                        <div className="d-flex justify-content-between align-items-center">
                          Başlangıç Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          Bitiş Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Erken Çıkanlar"
                            checked={true}
                          />
                        </div>
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Uzatma Yapanlar"
                          />
                        </div>
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Hepsi"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Ozet" title="Özet"></Tab>
              <Tab eventKey="InhouseSpecialDate" title="Inhouse Special Date">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-start gap-2">
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Doğum Günü"
                            checked={true}
                          />
                        </div>
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Evlilik Yıldönümü"
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          Rapor Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="InhouseRepeatSpecial"
                title="Inhouse Repeat Special"
              >
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-between gap-2">
                        {/* birinci */}
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Repeat Guest List"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Posting Yapmayacak Odalar"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Inhouse Connection"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="NoShow"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Pax Cout"
                          />
                        </div>
                      </div>
                      <div className="w-100 d-flex align-items-center  justify-content-between gap-2">
                        {/* ikinci */}
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Konum Uyuşmazlık"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Beraber Odalar (Birleşen)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Dışarda Geceleyenler"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Yöndirilmiş Odalar(Routing)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Hayali (PM) Odalar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default MisafirListesi;
